import { SearchShow } from '../v2/show';
import { SearchEpisode } from '../v2/episode';
import usePagination from '../../hooks/usePagination';
import { transformArray, transformShow, transformEpisode } from '../transform';

export function useSearchShow({ categoryId, queryString }) {
  const { data, ...args } = usePagination({
    dataKey: 'playerShowFind',
    query: SearchShow,
    variables: {
      categoryId,
      queryString,
    },
    skip: queryString === '',
    useSkip: true,
  });

  if (!data) {
    return args;
  }

  return {
    data: {
      shows: transformArray(data.playerShowFind, transformShow),
    },
    ...args,
  };
}

export function useSearchEpisode({ categoryId, queryString, skip }) {
  const { data, ...args } = usePagination({
    dataKey: 'playerEpisodeFind',
    query: SearchEpisode,
    variables: {
      categoryId,
      queryString,
    },
    skip: true,
  });

  if (!data) {
    return args;
  }

  return {
    data: {
      episodes: transformArray(data.playerEpisodeFind, transformEpisode),
    },
    ...args,
  };
}
