import React from 'react';
import { makeStyles } from '@material-ui/styles';
import { useApolloClient } from '@apollo/client';
import { Distribution } from '../../entities/distribution';
import { ShowMembership } from '../../entities/membership';
import { ExternalLink, WebsiteSetting } from '../../entities/show';
import { Define } from '../../define';
import { ViewFlink } from '../../gql/queries';
import DonateItem from './DonateItem';
import RssItem from './RssItem';
import CommentItem from './CommentItem';
import VoicemailItem from './VoicemailItem';
import ExternalLinkItem from './ExternalLinkItem';
import DistributionList from './DistributionList';
import FirstoryItem from './FirstoryItem';

const useStyles = makeStyles(() => ({
  list: {
    margin: 0,
    padding: 0,
    listStyle: 'none',
    width: '100%',
  },
}));

function PlatformList({
  type,
  showId,
  episodeId,
  distributionMap,
  showDistributionMap,
  externalLinks,
  websiteSetting,
  showMembership,
}: PlatformListProps): JSX.Element {
  const classes = useStyles();
  const client = useApolloClient();

  const handlePlatformClick = React.useCallback(
    async (platform: string) => {
      await client.query({
        query: ViewFlink,
        variables: {
          userId: showId,
          platform,
        },
        context: {
          endpoint: 'v1',
        },
      });
    },
    [client, showId],
  );

  const membershipActive = !!showMembership?.active;

  return (
    <ul className={classes.list}>
      {websiteSetting?.flinkShowDonate && membershipActive && (
        <DonateItem showId={showId} />
      )}
      {type === 'episode' && episodeId && websiteSetting?.flinkShowComment && (
        <CommentItem episodeId={episodeId} />
      )}
      {websiteSetting?.flinkShowVoiceMail && <VoicemailItem showId={showId} />}
      {websiteSetting?.flinkShowRssFeed && <RssItem showId={showId} />}
      <FirstoryItem
        type={type}
        id={type === 'show' ? showId : episodeId}
        onClick={handlePlatformClick}
      />
      <DistributionList
        type={type}
        websiteSetting={websiteSetting}
        distributionMap={distributionMap}
        showDistributionMap={showDistributionMap}
        onItemClick={handlePlatformClick}
      />
      {websiteSetting?.flinkShowExternal &&
        externalLinks.map((externalLink, i) => (
          <ExternalLinkItem
            key={i}
            externalLink={externalLink}
            onClick={handlePlatformClick}
          />
        ))}
    </ul>
  );
}

type PlatformListProps = {
  type: 'show' | 'episode';
  showId: string;
  episodeId?: string;
  distributionMap: Map<Define.Distribution.Platform.Type, Distribution>;
  showDistributionMap?: Map<Define.Distribution.Platform.Type, Distribution>;
  externalLinks: ExternalLink[];
  websiteSetting?: WebsiteSetting;
  showMembership?: ShowMembership;
};

export default PlatformList;
