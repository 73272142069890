import React from 'react';
import { FormattedMessage } from 'react-intl';
import { makeStyles } from '@material-ui/styles';
import { Button, Typography, CircularProgress, Theme } from '@material-ui/core';
import TapPayDirectPayForm from '../../../components/TapPayDirectPayForm';
import PoweredByTappay from '../../../components/PoweredByTappay';
import useTapPayDirectPayForm, {
  DirectPayFormValues,
} from '../../../hooks/useTapPayDirectPayForm';

const useStyles = makeStyles((theme: Theme) => ({
  scrollContainer: {
    height: '100%',
    overflowY: 'auto',
  },
  container: {
    padding: theme.spacing(2),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  title: {
    fontWeight: 'bold',
  },
  bindCardHint: {
    fontWeight: 'bold',
    maxWidth: 390,
  },
  cardButton: {
    margin: theme.spacing(0.5),
  },
  cardButtonRow: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(4),
    display: 'flex',
  },
}));

function CreateCreditCardForm({
  submitting,
  onSubmit,
  onCancel,
}: CreateCreditCardFormProps): JSX.Element {
  const classes = useStyles();

  const {
    formRef,
    values,
    cardType,
    canGetPrime,
    canSubmit,
    getPrime,
    onValueChange,
  } = useTapPayDirectPayForm({ email: '' });

  const handleSubmit = React.useCallback(async () => {
    if (!canGetPrime || !canSubmit) {
      return;
    }

    const result = await getPrime();
    onSubmit({
      prime: result.card.prime,
      ...values,
    });
  }, [values, canGetPrime, canSubmit, getPrime, onSubmit]);

  return (
    <div className={classes.scrollContainer}>
      <div className={classes.container}>
        <Typography className={classes.title}>
          <FormattedMessage id="payment.directPay" />
        </Typography>
        <TapPayDirectPayForm
          ref={formRef}
          values={values}
          cardType={cardType}
          onValueChange={onValueChange}
        />
        <Typography variant="caption" className={classes.bindCardHint}>
          <FormattedMessage id="payment.bindCard">
            {chunks => <>** {chunks} **</>}
          </FormattedMessage>
        </Typography>
        <div className={classes.cardButtonRow}>
          <Button
            variant="contained"
            color="primary"
            disabled={!canGetPrime || !canSubmit}
            className={classes.cardButton}
            onClick={handleSubmit}
          >
            {submitting ? (
              <CircularProgress size={20} color="inherit" />
            ) : (
              <FormattedMessage id="general.confirm" />
            )}
          </Button>
          <Button className={classes.cardButton} onClick={onCancel}>
            <FormattedMessage id="general.cancel" />
          </Button>
        </div>
        <PoweredByTappay />
      </div>
    </div>
  );
}

export type CreateCreditCardSubmitValues = DirectPayFormValues & {
  prime: string;
};

type CreateCreditCardFormProps = {
  submitting: boolean;
  onSubmit(args: CreateCreditCardSubmitValues): void;
  onCancel(): void;
};

export default CreateCreditCardForm;
