import React from 'react';
import { Link } from 'react-router-dom';
import { makeStyles } from '@material-ui/styles';
import { Paper, Theme, Typography } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) => ({
  paper: {
    marginBottom: theme.spacing(2),
  },
  container: {
    display: 'flex',
    textDecoration: 'none',
    padding: theme.spacing(2),
  },
  iconContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    paddingRight: theme.spacing(2),
  },
  content: {
    flex: 1,
  },
  title: {
    fontWeight: 'bold',
    textDecoration: 'none',
  },
  description: {
    textDecoration: 'none',
  },
  actionContainer: {
    paddingLeft: theme.spacing(2),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
}));

function InfoCard({
  title,
  description,
  icon,
  action,
  link,
}: InfoCardProps): JSX.Element {
  const classes = useStyles();

  const content = (
    <div className={classes.container}>
      {icon ? <div className={classes.iconContainer}>{icon}</div> : null}
      <div className={classes.content}>
        <Typography className={classes.title} color="textPrimary">
          {title}
        </Typography>
        <Typography className={classes.description} color="textPrimary">
          {description}
        </Typography>
      </div>
      {action ? <div className={classes.actionContainer}>{action}</div> : null}
    </div>
  );

  return (
    <Paper variant="outlined" className={classes.paper}>
      {link ? <Link to={link}>{content}</Link> : content}
    </Paper>
  );
}

type InfoCardProps = {
  title: React.ReactNode;
  description?: React.ReactNode;
  icon?: React.ReactNode;
  action?: React.ReactNode;
  link?: string;
};

export default InfoCard;
