import React from 'react';
import { useIntl } from 'react-intl';
import cx from 'classnames';
import { makeStyles } from '@material-ui/styles';
import { Typography, Theme } from '@material-ui/core';
import EventIcon from '@material-ui/icons/Event';
import TimeIcon from '@material-ui/icons/Timer';
import CommentIcon from '@material-ui/icons/ChatBubbleOutline';
import StarIcon from '@material-ui/icons/StarBorder';
import useDateFormat from '../hooks/useDateFormat';
import { getMMSSfromSeconds } from '../utils';
import { Episode } from '../entities/episode';

const useStyles = makeStyles((theme: Theme) => ({
  row: {
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap',
  },
  item: {
    display: 'flex',
    alignItems: 'center',
    paddingRight: theme.spacing(2),
  },
  text: {
    paddingLeft: theme.spacing(1),
  },
  icon: {
    color: theme.palette.text.primary,
  },
}));

function EpisodeInfoRow({
  className,
  episode,
}: EpisodeInfoRowProps): JSX.Element | null {
  const intl = useIntl();
  const classes = useStyles();
  const createdAt = useDateFormat(episode.publishedAt);
  const duration = getMMSSfromSeconds(episode.duration / 1000);
  const ratingValue = episode.aggregateRating?.value || 0;
  const ratingCount = episode.aggregateRating?.count || 0;
  const items = [
    { Icon: EventIcon, text: createdAt },
    { Icon: TimeIcon, text: duration },
    {
      Icon: CommentIcon,
      text: intl.formatMessage(
        { id: 'comment.commentCount' },
        { count: episode.commentCount },
      ),
    },
    {
      Icon: StarIcon,
      text:
        ratingCount > 0
          ? intl.formatMessage(
              { id: 'podcast.ratingValue' },
              { ratingValue: ratingValue.toFixed(2) },
            )
          : intl.formatMessage({ id: 'podcast.noRating' }),
    },
  ];

  if (!episode.publishedAt) {
    return null;
  }

  return (
    <div className={cx(classes.row, className)}>
      {items.map(({ Icon, text }) => (
        <div key={text} className={classes.item}>
          <Icon className={classes.icon} fontSize="small" />
          <Typography
            variant="caption"
            color="textPrimary"
            className={classes.text}
          >
            {text}
          </Typography>
        </div>
      ))}
    </div>
  );
}

type EpisodeInfoRowProps = {
  className?: string;
  episode: Episode;
};

export default EpisodeInfoRow;
