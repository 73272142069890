import React from 'react';
import { makeStyles } from '@material-ui/styles';
import {
  Button,
  ButtonProps,
  CircularProgress,
  Theme,
} from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) => ({
  loading: {
    marginLeft: theme.spacing(1),
  },
}));

function ButtonWithLoading({
  loading,
  onClick,
  children,
  size,
  ...props
}: ButtonWithLoadingProps): JSX.Element {
  const classes = useStyles();
  return (
    <Button size={size} onClick={loading ? undefined : onClick} {...props}>
      {children}
      {loading && (
        <span className={classes.loading}>
          <CircularProgress size={size === 'small' ? 10 : 14} color="inherit" />
        </span>
      )}
    </Button>
  );
}

type ButtonWithLoadingProps = {
  loading?: boolean;
} & ButtonProps;

export default ButtonWithLoading;
