import { Theme, makeStyles } from '@material-ui/core';

export const useStyles = makeStyles<Theme, { fontSize: string }>(theme => {
  const headings = [1, 2, 3, 4, 5, 6].reduce<Record<string, any>>(
    (acc, level) => {
      const fontSize = [2.125, 1.625, 1.375, 1.125, 1, 0.875][level - 1];
      const letterSpacing = 0.01 * (6 / level);
      const lineHeight = [1.3, 1.35, 1.4, 1.45, 1.5, 1.5][level - 1];
      const marginTop = 0.25 * (6 / level);
      acc[`& h${level}`] = {
        fontFamily: theme.typography.fontFamily,
        fontWeight: 700,
        marginTop: `${marginTop}rem`,
        marginBottom: theme.spacing(1),
        fontSize: `${fontSize}rem`,
        letterSpacing: `${letterSpacing}rem`,
        lineHeight,
        '@media (max-width: 768px)': {
          fontSize: `${fontSize * 0.8}rem`,
        },
      };

      return acc;
    },
    {},
  );

  return {
    root: {
      '& p': {
        marginTop: 0,
        marginBottom: '1.25rem',
      },
      ...headings,
      '& img': {
        maxWidth: '100%',
        marginBottom: '1rem',
        borderRadius: theme.shape.borderRadius,
      },
      '& hr': {
        border: 0,
        height: '1px',
        backgroundColor: 'rgba(255, 255, 255, 0.12)', // adjusted according to the dark theme
        margin: theme.spacing(4, 0),
      },
      '& a': {
        color: theme.palette.primary.main, // adjusted according to the dark theme
        textDecoration: 'none',
        '&:hover': {
          color: theme.palette.primary.light, // adjusted according to the dark theme
          textDecoration: 'underline',
        },
      },
      '& blockquote': {
        fontSize: props => `calc(${props.fontSize} * 1.125)`,
        fontFamily: "Georgia, 'Times New Roman', serif",
        lineHeight: 1.55,
        margin: theme.spacing(5),
        background: '#101010', // adjusted according to the dark theme
        padding: theme.spacing(3),
        borderLeft: `0.2rem solid ${theme.palette.primary.main}`, // adjusted according to the dark theme
        '& > p': {
          margin: 0,
        },
      },
      '& li > p': {
        margin: 0,
      },
      '& ul, & ol': {
        paddingLeft: theme.spacing(5),
        marginBottom: theme.spacing(4),
        '& li': {
          marginTop: theme.spacing(0.5),
        },
        '& ul, & ol': {
          marginBottom: 'inherit',
        },
      },
    },
  };
});
