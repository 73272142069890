import React from 'react';
import Menu from '@material-ui/core/Menu';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Avatar from '@material-ui/core/Avatar';
import useSwitchAccount from '../hooks/useSwitchAccount';

function MultipleAccountMenu({
  children,
}: MultipleAccountMenuProps): JSX.Element | null {
  const anchorRef = React.useRef<HTMLDivElement>(null);

  const {
    data: accountData,
    switchAccount,
    menuOpen,
    openMenu,
    closeMenu,
  } = useSwitchAccount();

  const accounts =
    accountData && accountData.multipleAccounts
      ? accountData.multipleAccounts
      : [];

  return (
    <div ref={anchorRef}>
      {children(openMenu)}
      <Menu
        anchorEl={anchorRef.current}
        open={menuOpen}
        onClose={closeMenu}
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <List dense>
          {accounts.map((a: Account) => (
            <ListItem key={a.id} button onClick={() => switchAccount(a.id)}>
              <ListItemAvatar>
                <Avatar variant="rounded" src={a.avatar} />
              </ListItemAvatar>
              <ListItemText>{a.name}</ListItemText>
            </ListItem>
          ))}
        </List>
      </Menu>
    </div>
  );
}

type MultipleAccountMenuProps = {
  children(openMenu: () => void): React.ReactNode;
};

type Account = {
  id: string;
  avatar: string;
  name: string;
};

export default MultipleAccountMenu;
