import React from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  RadioGroup,
  Radio,
  makeStyles,
  Theme,
} from '@material-ui/core';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import ButtonWithLoading from '../../../../../components/ButtonWithLoading';
import { PaymentMethod } from '../../../../../entities/membership';
import CardTypeImage from '../../../PaymentMethod/CardTypeImage';

const useStyles = makeStyles((theme: Theme) => ({
  cardRow: {
    display: 'flex',
    alignItems: 'center',
  },
  cardImage: {
    height: 24,
    marginRight: theme.spacing(1),
  },
}));

function PaymentMethodSelector({
  submitting,
  defaultValue,
  open,
  paymentMethods,
  onClose,
  onSubmit,
}: PaymentMethodSelectorProps): JSX.Element {
  const classes = useStyles();

  const [value, setValue] = React.useState(defaultValue);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValue(event.target.value);
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>
        <FormattedMessage id="donation.paymentMethod.select" />
      </DialogTitle>
      <DialogContent>
        <RadioGroup value={value} onChange={handleChange}>
          {paymentMethods.map(m => (
            <FormControlLabel
              key={m.id}
              value={m.id}
              control={<Radio />}
              label={
                <div className={classes.cardRow}>
                  <CardTypeImage
                    className={classes.cardImage}
                    type={m.cardType}
                  />
                  {m.cardFirst6.substring(0, 4)} {m.cardFirst6.substring(4, 6)}
                  ** **** {m.cardLast4}
                </div>
              }
            />
          ))}
        </RadioGroup>
        <Button
          color="secondary"
          component={Link}
          to="/donation/payment-methods"
        >
          <FormattedMessage id="general.edit" />
        </Button>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>
          <FormattedMessage id="general.cancel" />
        </Button>
        <ButtonWithLoading loading={submitting} onClick={() => onSubmit(value)}>
          <FormattedMessage id="general.confirm" />
        </ButtonWithLoading>
      </DialogActions>
    </Dialog>
  );
}

type PaymentMethodSelectorProps = {
  submitting: boolean;
  defaultValue: string;
  open: boolean;
  paymentMethods: PaymentMethod[];
  onClose: () => void;
  onSubmit: (paymentMethodId: string) => void;
};

export default PaymentMethodSelector;
