import { gql } from '@apollo/client';
import { ShowInfoFragment, CategoryFragment } from './fragments';

export const GetShowInfo = gql`
  query GetShowInfo($showId: String!) {
    playerShowFindOneByUrlSlug(urlSlug: $showId) {
      ...ShowInfoFragment
      episodeCount
      canRemoveFlinkAds
    }
  }
  ${ShowInfoFragment}
`;

export const GetShowInfoByPlatformId = gql`
  query GetShowInfoByPlatformId($platformType: Int!, $platformId: String!) {
    playerShowFindOneByPlatformId(
      platformType: $platformType
      platformId: $platformId
    ) {
      id
      name
      author
      avatar
      urlSlug
    }
  }
`;

export const GetShowSetting = gql`
  query GetShowSetting($showId: ID!) {
    playerShowFindOne(showId: $showId) {
      id
      setting {
        enableSpotifyOpenAccess
      }
    }
  }
`;

export const GetPodcastCategories = gql`
  query GetCategories {
    playerCategoryFind(subCategory: false) {
      ...CategoryFragment
    }
  }
  ${CategoryFragment}
`;

export const SearchShow = gql`
  query SearchShow(
    $showIds: [ID!]
    $categoryId: ID
    $queryString: String
    $first: Int
    $skip: Int
  ) {
    playerShowFind(
      showIds: $showIds
      categoryId: $categoryId
      queryString: $queryString
      take: $first
      skip: $skip
    ) {
      id
      name
      avatar
      urlSlug
    }
  }
`;

export const GetDiscoverUser = gql`
  query GetDiscoverUser {
    banner: discoverUser(type: BANNER_FEATURE) {
      id
    }
    hot: discoverUser(type: HOT, first: 10) {
      id
    }
    trending: discoverUser(type: TRENDING, first: 10) {
      id
    }
  }
`;

export const UpdateShow = gql`
  mutation UpdateShow($name: String, $avatar: Upload) {
    studioShowUpdate(name: $name, avatar: $avatar) {
      id
      name
      avatar
    }
  }
`;

export const SearchShowWithSpotify = gql`
  query SearchShow($showIds: [ID!], $first: Int, $skip: Int) {
    playerShowFind(showIds: $showIds, take: $first, skip: $skip) {
      id
      name
      avatar
      urlSlug
      distributions {
        status
        platformType
        platformId
        platformUrl
      }
    }
  }
`;
