import { Button, makeStyles, Theme, Typography } from '@material-ui/core';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import { ShowMembership } from '../../../entities/membership';
import { Show } from '../../../entities/show';

const useStyles = makeStyles((theme: Theme) => ({
  subtitle: {
    fontWeight: 'normal',
    marginBottom: theme.spacing(2),
  },
  description: {
    marginBottom: theme.spacing(2),
  },
}));

function ShowInfo({ show, membershipSetting }: ShowInfoProps): JSX.Element {
  const classes = useStyles();

  return (
    <React.Fragment>
      <Typography
        variant="h5"
        color="textPrimary"
        component={Link}
        to={`/user/${show.urlSlug || show.id}`}
      >
        {show.name}
      </Typography>
      <Typography variant="h6" color="textPrimary" className={classes.subtitle}>
        {show.author}
      </Typography>
      <Typography color="textPrimary" className={classes.description}>
        {membershipSetting?.description}
      </Typography>
    </React.Fragment>
  );
}

type ShowInfoProps = {
  show: Pick<Show, 'id' | 'name' | 'author' | 'urlSlug'>;
  membershipSetting?: ShowMembership;
};

export default ShowInfo;
