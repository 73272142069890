import { Define } from '../define';

export namespace Distribution {
  export namespace Platform {
    export const apple = 1;
    export const breaker = 2;
    export const castbox = 3;
    export const castro = 4;
    export const google = 5;
    export const kkbox = 6;
    export const overcast = 7;
    export const playerfm = 8;
    export const pocketcast = 9;
    export const podcastaddict = 10;
    export const radiopublic = 11;
    export const soundon = 12;
    export const spotify = 13;
    export const stitcher = 14;

    export const supportAutoShowDistribution: ReadonlyArray<Type> = [
      kkbox,
      pocketcast,
      spotify,
    ];
    export const supportAutoShowChecking: ReadonlyArray<Type> = [
      apple,
      castbox,
      castro,
      google,
      kkbox,
      overcast,
      pocketcast,
      spotify,
    ];
    export const supportAutoEpisodeChecking: ReadonlyArray<Type> = [
      apple,
      google,
      kkbox,
      spotify,
    ];

    export type Type =
      | typeof apple
      | typeof breaker
      | typeof castbox
      | typeof castro
      | typeof google
      | typeof kkbox
      | typeof overcast
      | typeof playerfm
      | typeof pocketcast
      | typeof podcastaddict
      | typeof radiopublic
      | typeof soundon
      | typeof spotify
      | typeof stitcher;
  }
  export namespace Status {
    export const notSupportCheckStatus = 0;
    /**
     * @description default status for each distribution
     */
    export const pending = 1;
    /**
     * @description after submitting a new show to platform (only for show distribution)
     */
    export const submitted = 2;
    /**
     * @description after the show or episode is visible on the target platform
     */
    export const active = 3;
    /**
     * @description if `pending` more than 3 days
     */
    export const error = 4;

    export type Type =
      | typeof notSupportCheckStatus
      | typeof pending
      | typeof submitted
      | typeof active
      | typeof error;
  }
}

export type Distribution = {
  platformType: Define.Distribution.Platform.Type;
  platformId?: string | null;
  platformUrl: string;
  status: Define.Distribution.Status.Type;
};

type V1DistributionStatus = 'NONE' | 'PENDING' | 'SUBMITTED' | 'ACTIVE';

/**
 * @deprecated use Distribution instead
 */
export type V1Distribution = {
  applePodcastStatus: V1DistributionStatus;
  applePodcastUrl?: string | null;
  breakerStatus: V1DistributionStatus;
  breakerUrl?: string | null;
  castboxStatus: V1DistributionStatus;
  castboxUrl?: string | null;
  castroStatus: V1DistributionStatus;
  castroUrl?: string | null;
  googlePodcastStatus: V1DistributionStatus;
  googlePodcastUrl?: string | null;
  kkboxStatus: V1DistributionStatus;
  kkboxUrl?: string | null;
  overcastStatus: V1DistributionStatus;
  overcastUrl?: string | null;
  playerFMStatus: V1DistributionStatus;
  playerFMUrl?: string | null;
  pocketcastStatus: V1DistributionStatus;
  pocketcastUrl?: string | null;
  podcastAddictStatus: V1DistributionStatus;
  podcastAddictUrl?: string | null;
  radiopublicStatus: V1DistributionStatus;
  radiopublicUrl?: string | null;
  soundonStatus: V1DistributionStatus;
  soundonUrl?: string | null;
  spotifyId?: string | null;
  spotifyStatus: V1DistributionStatus;
  stitcherStatus: V1DistributionStatus;
  stitcherUrl?: string | null;
};
