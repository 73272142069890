import { useParams, useLocation, useRouteMatch } from 'react-router-dom';
import { makeStyles } from '@material-ui/styles';
import { Theme } from '@material-ui/core';
import Layout from '../WebPlayer/Layout';
import { useWebPlayer } from '../WebPlayer/context';
import GoogleAd from '../../components/GoogleAd';
import NotFound from '../../components/NotFound';
import RedirectWithStatus from '../../components/RedirectWithStatus';
import CenterLoading from '../../components/CenterLoading';
import { useGetMe } from '../../gql/hooks/user';
import { darkTheme, useCustomTheme } from '../../theme';
import { useGetShowMembership } from '../../gql/hooks/membership/get-show-setting';
import { useGetShowInfo } from '../../gql/hooks/show/show';
import ShowHeader from '../UserSite/header/ShowHeader';
import ShowContent from './ShowContent';

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    display: 'flex',
  },
  content: {
    flex: 1,
  },
  sidebar: {
    padding: theme.spacing(4),
    '@media (max-width: 700px)': {
      display: 'none',
    },
  },
  ads: {
    // background: 'steelblue',
    display: 'block',
    height: 250,
    width: 300,
    marginBottom: theme.spacing(4),
  },
}));

function Show({ urlSlug }: ShowProps): JSX.Element {
  const classes = useStyles();

  const isEmbedRoute = useRouteMatch('/embed');
  const { isWebPlayer } = useWebPlayer();
  const location = useLocation();

  const { loading, data } = useGetShowInfo(urlSlug);
  const { data: meData } = useGetMe();

  const showId = data?.playerShowFindOneByUrlSlug?.id;
  const { loading: membershipLoading, data: membershipData } =
    useGetShowMembership(showId);

  const customTheme = useCustomTheme({
    websiteSetting: data?.playerShowFindOneByUrlSlug?.websiteSetting,
  });

  if (loading || membershipLoading) {
    return (
      <Layout theme={isEmbedRoute ? customTheme : darkTheme}>
        <CenterLoading />
      </Layout>
    );
  }

  const show = data?.playerShowFindOneByUrlSlug;
  if (!show) {
    return (
      <Layout hideController theme={isEmbedRoute ? customTheme : darkTheme}>
        <NotFound />
      </Layout>
    );
  }

  const link = isWebPlayer ? `/user/${show.id}` : '/';

  if (!!show.urlSlug && urlSlug !== show.urlSlug) {
    let pathname = location.pathname.replace(urlSlug, show.urlSlug);
    if (isEmbedRoute) {
      pathname = '/embed' + pathname;
    }
    return <RedirectWithStatus to={{ ...location, pathname }} />;
  }

  const hideAds = show.canRemoveFlinkAds;
  const hasLogin = !!(meData && meData.studioShowFindOne);
  const isMe = hasLogin && meData?.studioShowFindOne?.id === show.id;

  return (
    <Layout
      logoLink={link}
      defaultTitle={show.name}
      defaultImageUrl={show.avatar}
      theme={isEmbedRoute ? customTheme : darkTheme}
    >
      <ShowHeader isWebPlayer isEmbed={!!isEmbedRoute} show={show} />
      <div className={classes.container}>
        <div className={classes.content}>
          <ShowContent
            loading={loading}
            show={show}
            isMe={isMe}
            hasLogin={hasLogin}
            showMembership={membershipData && membershipData.show}
            hideAds={hideAds}
          />
        </div>
        {!hideAds && (
          <div className={classes.sidebar}>
            <GoogleAd slot="7018166812" format="auto" className={classes.ads} />
            <GoogleAd slot="7018166812" format="auto" className={classes.ads} />
            <GoogleAd slot="7018166812" format="auto" className={classes.ads} />
            <GoogleAd slot="7018166812" format="auto" className={classes.ads} />
            <GoogleAd slot="7018166812" format="auto" className={classes.ads} />
          </div>
        )}
      </div>
    </Layout>
  );
}

function ShowWithUrlSlugCheck() {
  const isEmbedRoute = useRouteMatch('/embed');
  const { urlSlug } = useParams<{ urlSlug: string }>();
  const location = useLocation();

  if (urlSlug !== urlSlug.toLowerCase()) {
    let pathname = `/user/${urlSlug.toLowerCase()}`;
    if (isEmbedRoute) {
      pathname = '/embed' + pathname;
    }
    return <RedirectWithStatus to={{ ...location, pathname }} status={301} />;
  }

  return <Show urlSlug={urlSlug} />;
}

type ShowProps = {
  urlSlug: string;
};

export default ShowWithUrlSlugCheck;
export { Show };
export { default as Me } from './Me';
