import { useQuery } from '@apollo/client';
import { Distribution } from '../../../entities/distribution';
import { GetShowInfoByPlatformId } from '../../v2/show';

export function useGetShowInfoByPlatformId(
  platformType: Distribution.Platform.Type,
  platformId: string | null,
) {
  const result = useQuery<
    GetShowInfoByPlatformIdData,
    GetShowInfoByPlatformIdVariables
  >(GetShowInfoByPlatformId, {
    variables: {
      platformType,
      platformId,
    },
    skip: !platformId,
  });

  return result;
}

type GetShowInfoByPlatformIdData = {
  playerShowFindOneByPlatformId: {
    id: string;
    name: string | null;
    author: string | null;
    avatar: string | null;
    urlSlug: string | null;
  };
};

type GetShowInfoByPlatformIdVariables = {
  platformType: Distribution.Platform.Type;
  platformId: string | null;
};
