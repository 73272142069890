import React from 'react';
import { useIntl, FormattedMessage } from 'react-intl';
import { makeStyles, useTheme } from '@material-ui/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Button from '@material-ui/core/Button';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { useTracker } from '../analytics/tracker';
import config from '../config';
import { useAlert } from './Alert';

const useStyles = makeStyles(theme => ({
  container: {
    backgroundColor: '#242424',
  },
  preview: {
    paddingBottom: theme.spacing(2),
  },
  previewFrame: {
    width: '100%',
    height: props => (props.hideContent ? 180 : 500),
    border: 'none',
    [theme.breakpoints.down('sm')]: {
      height: props => (props.hideContent ? 180 : 400),
    },
  },
  input: {
    width: '100%',
  },
}));

function EmbedSettingModal({ type, itemId, open, onClose }) {
  const intl = useIntl();
  const tracker = useTracker();
  const { showSnackbarMessage } = useAlert();
  const [options, setOptions] = React.useState({
    showDescription: false,
    showComments: false,
    useIframe: false,
  });
  const hideContent =
    type === 'story' && !options.showDescription && !options.showComments;
  const classes = useStyles({ hideContent });
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const checkboxLabel = {
    showDescription: 'embed.showDescription',
    showComments: 'embed.showComments',
    useIframe: 'embed.useIframe',
  };

  const handleCopy = React.useCallback(() => {
    showSnackbarMessage('general.copied', {
      variant: 'success',
      vertical: 'top',
      horizontal: 'center',
    });
    tracker.copyEmbedUrl();
  }, [showSnackbarMessage, tracker]);

  const handleBooleanChange = name => e => {
    setOptions({ ...options, [name]: e.target.checked });
  };

  const url = React.useMemo(() => {
    const u = new URL(`${config.url}/embed/${type}/${itemId}`);
    if (options.showDescription) {
      u.searchParams.set('description', '1');
    }
    if (options.showComments) {
      u.searchParams.set('comments', '1');
    }
    return u.href;
  }, [type, itemId, options]);

  const height = hideContent ? 180 : 500;
  const iframeCode = `<iframe src="${url}" height="${height}" frameborder="0" scrolling="no"></iframe>`;
  const text = options.useIframe ? iframeCode : url;
  const optionRow =
    type === 'story'
      ? ['showDescription', 'showComments', 'useIframe']
      : ['useIframe'];

  return (
    <Dialog
      open={open}
      onClose={onClose}
      fullScreen={fullScreen}
      fullWidth
      maxWidth="md"
      classes={{ paper: classes.container }}
    >
      <DialogTitle>
        <FormattedMessage id="embed.player" />
      </DialogTitle>
      <DialogContent>
        <div className={classes.preview}>
          <iframe
            src={url}
            title="Embed Player"
            className={classes.previewFrame}
          />
        </div>
        <DialogContentText>
          {optionRow.map(name => (
            <FormControlLabel
              key={name}
              control={
                <Checkbox
                  checked={!!options[name]}
                  onChange={handleBooleanChange(name)}
                  name={name}
                />
              }
              label={intl.formatMessage({ id: checkboxLabel[name] })}
            />
          ))}
          <TextField
            variant="outlined"
            value={text}
            className={classes.input}
          />
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>
          <FormattedMessage id="general.confirm" />
        </Button>
        <CopyToClipboard text={text} onCopy={handleCopy}>
          <Button
            variant="contained"
            color="primary"
            size="large"
            className={classes.copyButton}
          >
            <FormattedMessage id="general.copy" />
          </Button>
        </CopyToClipboard>
      </DialogActions>
    </Dialog>
  );
}

export default EmbedSettingModal;
