import React from 'react';
import { useIntl } from 'react-intl';
import Voicemail from '@material-ui/icons/Voicemail';
import config from '../../config';
import PlatformItem from './PlatformItem';

function VoicemailItem({ showId }: VoicemailItemProps): JSX.Element {
  const url = `${config.url}/user/${showId}/voicemails`;
  const intl = useIntl();

  return (
    <PlatformItem
      title={intl.formatMessage({ id: 'website.voicemail' })}
      url={url}
      startIcon={<Voicemail />}
    />
  );
}

type VoicemailItemProps = {
  showId: string;
};

export default VoicemailItem;
