import React from 'react';
import { Theme, Tooltip } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { ExternalLink } from '../entities/show';
import { Define } from '../define';
import PlatformIcon from './icons/PlatformIcon';

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    display: 'flex',
  },
  item: {
    marginRight: theme.spacing(1),
    width: 30,
    height: 30,
  },
  icon: {
    color: theme.palette.text.primary,
    width: 30,
    height: 30,
  },
}));

function Item({ title, url, children }: ItemProps): JSX.Element {
  const classes = useStyles();

  return (
    <Tooltip title={title}>
      <a
        className={classes.item}
        href={url}
        target="_blank"
        rel="noopener noreferrer"
      >
        {children}
      </a>
    </Tooltip>
  );
}

function LinkItem({ externalLink }: LinkItemProps): JSX.Element | null {
  const classes = useStyles();

  const hasUrl = !!externalLink.url;
  if (!hasUrl) {
    return null;
  }

  if (externalLink.type === Define.Show.ExternalLink.custom) {
    return (
      <Item title={externalLink.title || ''} url={externalLink.url}>
        <PlatformIcon
          type={{
            type: externalLink.type,
            variant: 'external',
          }}
          className={classes.icon}
        />
      </Item>
    );
  }

  return (
    <Item
      title={Define.Show.ExternalLink.Title[externalLink.type]}
      url={externalLink.url}
    >
      <PlatformIcon
        type={{
          type: externalLink.type,
          variant: 'external',
        }}
        className={classes.icon}
      />
    </Item>
  );
}

function ExternalLinkRow({ externalLinks }: ExternalLinkRowProps): JSX.Element {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      {externalLinks.map((externalLink, i) => (
        <LinkItem key={i} externalLink={externalLink} />
      ))}
    </div>
  );
}

type ExternalLinkRowProps = {
  externalLinks: ExternalLink[];
};

type ItemProps = {
  title: string;
  url: string;
  children: React.ReactNode;
};

type LinkItemProps = {
  externalLink: ExternalLink;
};

export default ExternalLinkRow;
