import React from 'react';
import { makeStyles } from '@material-ui/styles';
import { PlayableData } from '../../components/Player/type';
import EpisodeList from '../WebPlayer/EpisodeList';
import { useWebPlayer } from '../WebPlayer/context';
import { useGetShowEpisode } from '../../gql/hooks/episode/episode';

const useStyles = makeStyles({
  container: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    overflow: 'auto',
  },
});

function ShowEpisodes({ showId, hideAds }: ShowEpisodesProps): JSX.Element {
  const { loading, data, hasMore, loadMore, isFetchingAfter } =
    useGetShowEpisode({ showId });
  const { nowPlaying, isPlaying, play, toggle, setNowPlaying, isNowPlaying } =
    useWebPlayer();
  const classes = useStyles();

  const episodes = data?.playerEpisodeFind || [];

  const handlePlayPauseClick = React.useCallback(
    (data: PlayableData) => {
      if (isNowPlaying(data)) {
        toggle();
      } else {
        setNowPlaying(data);
        play();
      }
    },
    [isNowPlaying, setNowPlaying, play, toggle],
  );

  return (
    <div className={classes.container}>
      <EpisodeList
        type="USER"
        loading={loading}
        episodes={episodes}
        hasMore={hasMore}
        loadMore={loadMore}
        isPlaying={isPlaying}
        nowPlaying={nowPlaying}
        onPlayPauseClick={handlePlayPauseClick}
        isFetchingAfter={isFetchingAfter}
        hideAds={hideAds}
      />
    </div>
  );
}

type ShowEpisodesProps = {
  showId: string;
  hideAds?: boolean;
};

export default ShowEpisodes;
