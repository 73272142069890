import React from 'react';
import loadable from '@loadable/component';
import { ThemeProvider, makeStyles } from '@material-ui/styles';
import { Drawer, Dialog, useMediaQuery } from '@material-ui/core';
import CenterLoading from '../../../components/CenterLoading';
import { lightTheme } from '../../../theme';
import { ShowMembership } from '../../../entities/membership';
import {
  PaymentFormValues,
  PaymentSubmitValues,
  PaymentTierPlanValues,
} from './type';

const PaymentStep = loadable(() => import('./PaymentStep'));

const useStyles = makeStyles({
  dialogContainer: {
    width: 500,
  },
  contentContainer: {
    minHeight: 300,
  },
  loadingContainer: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
  },
});

function PaymentModal({
  open,
  submitting,
  membershipSetting,
  paymentTier,
  values,
  disableEmailEdit,
  onValueChange,
  onSubmit,
  onCancel,
}: PaymentModalProps): JSX.Element {
  const classes = useStyles();
  const isSmall = useMediaQuery('(max-width: 800px)');
  const [hasOpened, setHasOpened] = React.useState(false);

  React.useEffect(() => {
    if (open && !hasOpened) {
      const t = setTimeout(() => {
        setHasOpened(true);
      }, 500);
      return () => {
        clearTimeout(t);
      };
    }
  }, [open, hasOpened]);

  const content = (
    <div className={classes.contentContainer}>
      {hasOpened ? (
        <PaymentStep
          membershipSetting={membershipSetting}
          paymentTier={paymentTier}
          values={values}
          disableEmailEdit={disableEmailEdit}
          submitting={submitting}
          onValueChange={onValueChange}
          onSubmit={onSubmit}
          onCancel={onCancel}
        />
      ) : (
        <div className={classes.loadingContainer}>
          <CenterLoading />
        </div>
      )}
    </div>
  );

  return isSmall ? (
    <Drawer keepMounted anchor="bottom" open={open}>
      {content}
    </Drawer>
  ) : (
    <Dialog
      keepMounted
      open={open}
      classes={{ paper: classes.dialogContainer }}
    >
      {content}
    </Dialog>
  );
}

function PaymentModalWithTheme(props: PaymentModalProps): JSX.Element {
  return (
    <ThemeProvider theme={lightTheme}>
      <PaymentModal {...props} />
    </ThemeProvider>
  );
}

type PaymentModalProps = {
  membershipSetting: ShowMembership;
  open: boolean;
  submitting: boolean;
  values: PaymentFormValues;
  disableEmailEdit?: boolean;
  paymentTier: PaymentTierPlanValues;
  onValueChange(
    name: keyof PaymentFormValues,
  ): (e: React.ChangeEvent<HTMLInputElement>) => void;
  onSubmit(values: PaymentSubmitValues): Promise<void>;
  onCancel(): void;
};

export default PaymentModalWithTheme;
