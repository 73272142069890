import React from 'react';
import { FormattedMessage } from 'react-intl';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { Typography, useMediaQuery } from '@material-ui/core';
import CopyTextField from '../../../../components/CopyTextField';
import config from '../../../../config';
import { Show } from '../../../../entities/show';
import { Define } from '../../../../define';
import { SupportedPlatforms, WebSupportedPlatforms } from './util';
import PlatformItem from './PlatformItem';

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    marginTop: theme.spacing(2),
    width: '100%',
    maxWidth: 600,
  },
  sectionTitle: {
    fontWeight: 'bold',
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(1),
  },
  section: {
    paddingTop: theme.spacing(4),
  },
  input: {
    width: '100%',
  },
}));

function PrivatePlatform({
  show,
  privateRssToken,
}: PrivatePlatformProps): JSX.Element {
  const classes = useStyles();
  const rssUrl = `${config.rssUrlBase}/rss/user/${show.id}/${privateRssToken}`;
  const isSmall = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));

  const platforms = isSmall ? SupportedPlatforms : WebSupportedPlatforms;

  const spotify = show.distributions?.find(
    d => d.platformType === Define.Distribution.Platform.spotify,
  );

  const spotifyUrl = spotify
    ? `/link-with-spotify?spotifyShowUri=spotify:show:${spotify.platformId}`
    : ``;

  return (
    <div className={classes.container}>
      <Typography className={classes.sectionTitle}>
        <FormattedMessage id="donation.privateRss" />
      </Typography>
      <CopyTextField className={classes.input} value={rssUrl} />
      <div className={classes.section}>
        {platforms.map((type: Define.Distribution.Platform.Type) => (
          <PlatformItem
            key={type}
            type={type}
            url={
              type === Define.Distribution.Platform.spotify
                ? spotifyUrl
                : rssUrl
            }
          />
        ))}
      </div>
    </div>
  );
}

type PrivatePlatformProps = {
  show: Pick<Show, 'id' | 'name' | 'avatar' | 'urlSlug' | 'distributions'>;
  privateRssToken?: string | null;
};

export default PrivatePlatform;
