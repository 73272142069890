import { gql } from '@apollo/client';
import { EntitlementFragment } from './fragments';

export const GetPosts = gql`
  query GetPosts($showId: ID!, $first: Int, $skip: Int) {
    playerPostFind(showId: $showId, take: $first, skip: $skip) {
      id
      createdAt
      title
      contentUrl
      entitlements {
        ...EntitlementFragment
      }
    }
  }
  ${EntitlementFragment}
`;

export const GetPostDetail = gql`
  query GetPostDetail($postId: ID!) {
    playerPostFindOne(postId: $postId) {
      id
      createdAt
      show {
        id
        name
        author
        avatar
        urlSlug
      }
      title
      subtitle
      contentUrl
      entitlements {
        ...EntitlementFragment
      }
    }
  }
  ${EntitlementFragment}
`;
