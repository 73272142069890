import Container from '@material-ui/core/Container';
import CommentList from '../../components/CommentList';
import CenterLoading from '../../components/CenterLoading';
import { useGetShowComments } from '../../gql/hooks/comment';

function ShowComments({ userId, userData, hideAds }) {
  const {
    loading: commentsLoading,
    data: commentsData,
    hasMore,
    isFetchingAfter,
    loadMore,
  } = useGetShowComments(userId);

  if (commentsLoading) {
    return <CenterLoading />;
  }

  const comments = commentsData?.playerCommentFind || [];

  return (
    <Container maxWidth="md">
      <CommentList
        type="SHOW"
        user={userData.userInfo}
        commentCount={0}
        comments={comments}
        hasMore={hasMore}
        loadMore={loadMore}
        loading={isFetchingAfter}
        hideAds={hideAds}
      />
    </Container>
  );
}

export default ShowComments;
