import React from 'react';
import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { supportedLangs } from '../i18n';
import { LangContext } from '../i18n/LangProvider';

const LanguageItem = React.forwardRef(({ code, label, onClick }, ref) => {
  const handleClick = React.useCallback(() => {
    onClick(code);
  }, [code, onClick]);

  return (
    <MenuItem ref={ref} value={code} onClick={handleClick}>
      {label}
    </MenuItem>
  );
});

LanguageItem.displayName = 'LanguageItem';

function LanguagePicker() {
  const { lang, onLangChange } = React.useContext(LangContext);
  const [anchorEl, setAnchorEl] = React.useState(null);

  const currentLang = supportedLangs[lang];

  const handleMenuOpen = React.useCallback(event => {
    setAnchorEl(event.currentTarget);
  }, []);

  const handleMenuClose = React.useCallback(() => {
    setAnchorEl(null);
  }, []);

  const handleItemClick = React.useCallback(
    lang => {
      onLangChange(lang);
      handleMenuClose();
    },
    [onLangChange, handleMenuClose],
  );

  return (
    <div>
      <Button
        variant="outlined"
        aria-controls="language-menu"
        aria-haspopup="true"
        endIcon={<ExpandMoreIcon />}
        onClick={handleMenuOpen}
      >
        {currentLang}
      </Button>
      <Menu
        id="language-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleMenuClose}
      >
        {Object.keys(supportedLangs).map(code => (
          <LanguageItem
            key={code}
            code={code}
            label={supportedLangs[code]}
            onClick={handleItemClick}
          />
        ))}
      </Menu>
    </div>
  );
}

function LanguagePickerContainer(props) {
  return <LanguagePicker {...props} />;
}

export default LanguagePickerContainer;
