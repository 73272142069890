import { useQuery } from '@apollo/client';
import { GetMe } from '../v2/user';
import { transformArray, transformShow } from '../transform';
import { useAuth } from '../../pages/Auth/context';
import { Show } from '../../entities/show';
import { User } from '../../entities/user';

export function useGetMe() {
  const { token } = useAuth();

  return useQuery<GetMeLegacyData>(GetMe, {
    skip: !token,
  });
}

export type GetMeData = {
  studioShowFindOne?: Pick<
    Show,
    'id' | 'name' | 'avatar' | 'categories' | 'import'
  >;
  studioUserFindOne?: Pick<User, 'id' | 'email' | 'nationId'>;
  studioShowFind: Pick<Show, 'id' | 'name' | 'avatar'>[];
};

export function useGetMeLegacy() {
  const { token } = useAuth();

  const { data, ...args } = useQuery<GetMeLegacyData>(GetMe, {
    skip: !token,
  });

  if (!data) {
    return { data, ...args };
  }

  return {
    data: {
      ...data,
      me: {
        ...transformShow(data.studioShowFindOne),
        account: data.studioUserFindOne,
      },
      multipleAccounts: transformArray(
        data.studioShowFind,
        transformShow,
      ).filter((s: { name?: string }) => !!s.name),
    },
    ...args,
  };
}

export type GetMeLegacyData = {
  studioShowFindOne: {
    id: string;
    name: string;
    avatar: string;
    categories: {
      id: string;
      nameZh: string;
      nameEn: string;
    }[];
    import: {
      originRssUrl: string;
      status: number;
    };
  };
  studioUserFindOne: {
    id: string;
    email: string;
    nationId: string;
  };
  studioShowFind: {
    id: string;
    name: string;
    avatar: string;
  }[];
};
