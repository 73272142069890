import React from 'react';
import IconButton, { IconButtonProps } from '@material-ui/core/IconButton';
import PlayFilledIcon from '@material-ui/icons/PlayCircleFilled';
import PlayIcon from '@material-ui/icons/PlayArrow';
import PauseFilledIcon from '@material-ui/icons/PauseCircleFilled';
import PauseIcon from '@material-ui/icons/Pause';
import LoadingIcon from '@material-ui/icons/MoreHoriz';

function getIconComponent({ isLoading, isPlaying, fill }) {
  if (isLoading) {
    return LoadingIcon;
  }

  if (isPlaying) {
    return fill ? PauseFilledIcon : PauseIcon;
  }

  return fill ? PlayFilledIcon : PlayIcon;
}

function PlayPauseIcon({
  className,
  button,
  size = 50,
  fill,
  buttonSize,
  color = 'black',
  isLoading,
  isPlaying,
  onClick,
}: PlayPauseIconProps): JSX.Element {
  const Icon = getIconComponent({ isLoading, isPlaying, fill });

  if (button) {
    return (
      <IconButton
        aria-label="play-pause"
        size={buttonSize}
        onClick={onClick}
        className={className}
      >
        <Icon style={{ color, fontSize: size }} />
      </IconButton>
    );
  }

  return (
    <Icon
      style={{ color, fontSize: size }}
      className={className}
      onClick={onClick}
    />
  );
}

type PlayPauseIconProps = {
  className?: string;
  button?: boolean;
  size?: number;
  fill?: boolean;
  buttonSize?: IconButtonProps['size'];
  color?: string;
  isLoading?: boolean;
  isPlaying?: boolean;
  onClick?: () => unknown;
};

export default PlayPauseIcon;
