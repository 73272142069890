import React from 'react';
import { FormattedMessage } from 'react-intl';
import { makeStyles } from '@material-ui/styles';
import { Typography, Theme } from '@material-ui/core';
import Html from '../../../../components/Html';
import { SubscriptionWithShow } from '../../type';

const useStyles = makeStyles((theme: Theme) => ({
  description: {},
  sectionTitle: {
    fontWeight: 'bold',
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(1),
  },
}));

function SubscriptionDescription({
  subscription,
}: SubscriptionDescriptionProps): JSX.Element {
  const classes = useStyles();

  const { tier } = subscription;

  return (
    <div>
      <Typography className={classes.sectionTitle}>
        <FormattedMessage id="general.description" />
      </Typography>
      <Html className={classes.description} html={tier.description} />
      <Typography className={classes.sectionTitle} />
      <Html className={classes.description} html={tier.hiddenContent || '-'} />
    </div>
  );
}

type SubscriptionDescriptionProps = {
  subscription: SubscriptionWithShow;
};

export default SubscriptionDescription;
