import React from 'react';
import { makeStyles } from '@material-ui/styles';
import PlatformIcon from '../../components/icons/PlatformIcon';
import { Define } from '../../define';
import { ExternalLink } from '../../entities/show';
import PlatformItem from './PlatformItem';

const useStyles = makeStyles(() => ({
  icon: {
    width: 28,
    height: 28,
  },
}));

const FlinkTitles = {
  [Define.Show.ExternalLink.facebook]: 'facebook',
  [Define.Show.ExternalLink.instagram]: 'instagram',
  [Define.Show.ExternalLink.twitter]: 'twitter',
  [Define.Show.ExternalLink.linkedIn]: 'linkedin',
  [Define.Show.ExternalLink.medium]: 'medium',
  [Define.Show.ExternalLink.youtube]: 'youtube',
} as const;

function ExternalLinkItem({
  externalLink,
  onClick,
}: ExternalLinkItemProps): JSX.Element | null {
  const classes = useStyles();

  const flinkTitle = externalLink.title || FlinkTitles[externalLink.type];

  if (!externalLink.url) {
    return null;
  }

  return (
    <PlatformItem
      title={
        externalLink.title || Define.Show.ExternalLink.Title[externalLink.type]
      }
      url={externalLink.url}
      startIcon={
        <PlatformIcon
          className={classes.icon}
          type={{
            type: externalLink.type,
            variant: 'external',
          }}
        />
      }
      onClick={() => onClick(flinkTitle)}
    />
  );
}

type ExternalLinkItemProps = {
  externalLink: ExternalLink;
  onClick: (platform: string) => void;
};

export default ExternalLinkItem;
