import React from 'react';
import { useLocation, useRouteMatch } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import { formatISODuration, htmlToText } from '../../../utils';
import config from '../../../config';
import { Episode } from '../../../entities/episode';

function getTranscriptSuffix({
  isTranscriptRoute,
  lang,
}: {
  isTranscriptRoute: boolean;
  lang: string;
}) {
  if (!isTranscriptRoute) {
    return '';
  }

  return lang === 'zh' ? ' 逐字稿' : ' Transcript';
}

function getDescription(episode: EpisodeHeaderProps['episode']): string {
  if (episode.description) {
    return htmlToText(episode.description);
  }

  if (episode.show.intro) {
    return episode.show.intro;
  }

  return '';
}

function EpisodeHeader({
  isWebPlayer,
  isEmbed,
  episode,
}: EpisodeHeaderProps): JSX.Element {
  const location = useLocation();
  const isTranscriptRoute = useRouteMatch('/:type/:id/transcript');

  const { id, show, audioUrl, imageUrl, createdAt, duration, commentCount } =
    episode;

  const { avatar, urlSlug } = show;
  const lang = (show.language || 'ZH').toLowerCase();
  const transcript = getTranscriptSuffix({
    isTranscriptRoute: !!isTranscriptRoute,
    lang,
  });

  const title = isWebPlayer
    ? `${episode.title}${transcript} - Podcast on Firstory`
    : `${episode.title}${transcript} | ${show.name}`;
  const description =
    getDescription(episode) + '\n Podcast powered by Firstory.';
  const image = imageUrl || avatar;

  if (isWebPlayer && isEmbed) {
    const baseUrl = config.url;
    const playerUrl = `${config.url}/story/${episode.id}`;
    const embedPlayerUrl =
      baseUrl + `/embed/story/${episode.id}${location.search}`;
    const oembedUrl = `${baseUrl}/oembed?url=${encodeURIComponent(
      embedPlayerUrl,
    )}`;
    const canonicalUrl = playerUrl;

    return (
      <Helmet>
        <meta name="description" content={description} />
        <meta property="og:title" content={title} />
        <meta property="og:type" content="music.song" />
        <meta property="og:description" content={description} />
        <meta property="og:site_name " content={show.name} />
        <meta property="og:audio:type" content="audio/vnd.facebook.bridge" />
        {image && <meta property="og:image" content={image} />}
        <meta property="og:image:width" content="1200" />
        <meta property="og:image:height" content="630" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:site" content="@firstorylab" />
        <meta name="twitter:url" content={canonicalUrl} />
        <meta name="twitter:title" content={title} />
        <meta name="twitter:description" content={description} />
        {image && <meta name="twitter:image" content={image} />}
        <link rel="canonical" href={canonicalUrl} />
        <link
          rel="alternate"
          type="application/json+oembed"
          href={oembedUrl}
          title="Firstory Embed Player"
        />
      </Helmet>
    );
  }

  let subdomain = urlSlug;
  if (urlSlug === 'firstorylab') {
    subdomain = 'lab';
  }
  const websiteBaseUrl = `https://${subdomain}.firstory.io`;
  const playerBaseUrl = config.url;
  const baseUrl = isWebPlayer ? playerBaseUrl : websiteBaseUrl;

  const websiteUrl = `${websiteBaseUrl}/episodes/${id}`;
  const playerUrl = `${config.url}/story/${id}`;

  const authorWebsiteUrl = websiteBaseUrl;
  const authorPlayerUrl = `${config.url}/user/${show.urlSlug || show.id}`;

  const embedPlayerUrl =
    baseUrl +
    `/embed/${isWebPlayer ? 'story' : 'episodes'}/${id}${location.search}`;
  const oembedUrl = `${baseUrl}/oembed?url=${encodeURIComponent(
    embedPlayerUrl,
  )}`;

  const canonicalUrl = isWebPlayer ? playerUrl : websiteUrl;
  const authorUrl = isWebPlayer ? authorPlayerUrl : authorWebsiteUrl;

  const schemaData = {
    '@context': 'https://schema.org',
    '@type': 'PodcastEpisode',
    name: episode.title,
    author: { '@type': 'Person', name: show.author || show.name },
    description,
    url: canonicalUrl,
    datePublished: createdAt,
    image,
    offers: [{ '@type': 'Offer', price: 'Free' }],
    associatedMedia: {
      '@type': 'MediaObject',
      duration: formatISODuration(duration),
      contentUrl: audioUrl,
      embedUrl: embedPlayerUrl,
    },
    partOfSeries: {
      '@type': 'PodcastSeries',
      name: show.name,
      url: authorUrl,
    },
    commentCount: commentCount,
  };

  return (
    <Helmet>
      <html lang={lang} />
      <title>{title}</title>
      <meta name="description" content={description} />
      <meta property="og:title" content={title} />
      <meta property="og:type" content="music.song" />
      <meta property="og:description" content={description} />
      <meta property="og:site_name " content={show.name} />
      <meta property="og:audio:type" content="audio/vnd.facebook.bridge" />
      {image && <meta property="og:image" content={image} />}
      <meta property="og:image:width" content="1200" />
      <meta property="og:image:height" content="630" />
      <meta property="og:url" content={canonicalUrl} />
      <link rel="canonical" href={canonicalUrl} />
      <link
        rel="alternate"
        type="application/json+oembed"
        href={oembedUrl}
        title={title}
      />
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:site" content="@firstorylab" />
      <meta name="twitter:url" content={canonicalUrl} />
      <meta name="twitter:title" content={title} />
      <meta name="twitter:description" content={description} />
      {image && <meta name="twitter:image" content={image} />}
      <script type="application/ld+json">{JSON.stringify(schemaData)}</script>
    </Helmet>
  );
}

type EpisodeHeaderProps = {
  isWebPlayer?: boolean;
  isEmbed?: boolean;
  episode: Pick<
    Episode,
    | 'id'
    | 'title'
    | 'description'
    | 'show'
    | 'audioUrl'
    | 'imageUrl'
    | 'createdAt'
    | 'duration'
    | 'commentCount'
  >;
};

export default EpisodeHeader;
