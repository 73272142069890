import React from 'react';
import { ApolloClient, InMemoryCache, DefaultContext } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { createUploadLink } from 'apollo-upload-client';
import { customFetch } from '../utils';
import config from '../config';

type Token = string | null;
const TOKEN_KEY = '@firstory/token';
let localToken: Token = null;
try {
  localToken = localStorage.getItem(TOKEN_KEY);
} catch (error) {
  console.log(error);
}

function useApollo() {
  const tokenRef = React.useRef<Token>(localToken);
  const [token, setToken] = React.useState(tokenRef.current);

  const client = React.useMemo(() => {
    const authLink = setContext(
      ({ operationName, variables }, previousContext: ApolloContext) => {
        let token = tokenRef.current;
        let uri = config.endpoints.simple;

        if (previousContext?.endpoint === 'v1') {
          uri = config.endpoints.v1;
        }

        if (previousContext?.endpoint === 'membership') {
          uri = config.endpoints.membership;
        }

        if (
          operationName &&
          /AuthLoginWithTwoFa/.test(operationName) &&
          variables &&
          variables._token
        ) {
          token = variables._token;
        }

        return {
          uri,
          headers: {
            ...previousContext.headers,
            authorization: token ? `Bearer ${token}` : '',
          },
        };
      },
    );

    const httpLink = createUploadLink({
      uri: config.endpoints.simple,
      fetch: customFetch,
    });

    return new ApolloClient({
      link: authLink.concat(httpLink),
      cache: new InMemoryCache().restore(window.__APOLLO_STATE__),
    });
  }, []);

  const onTokenChange = React.useCallback(token => {
    tokenRef.current = token;
    setToken(token);
    try {
      if (token === null) {
        localStorage.removeItem(TOKEN_KEY);
      } else {
        localStorage.setItem(TOKEN_KEY, token);
      }
    } catch (error) {
      console.log(error);
    }
  }, []);

  return { client, token, tokenRef, onTokenChange };
}

type ApolloContext = DefaultContext & {
  endpoint?: Endpoint;
};

export default useApollo;
