import React from 'react';
import { useMutation } from '@apollo/client';
import { SubscribeShowFreeTier } from '../../v2/membership';
import { CheckoutStatus } from '../../../entities/membership';

export function useSubscribeShowFreeTier() {
  const [fn] = useMutation<
    SubscribeShowFreeTierData,
    SubscribeShowFreeTierVariables
  >(SubscribeShowFreeTier);

  const subscribeShowFreeTier = React.useCallback(
    (variables: SubscribeShowFreeTierVariables) => {
      return fn({
        variables,
        context: { endpoint: 'membership' },
      });
    },
    [fn],
  );

  return subscribeShowFreeTier;
}

type SubscribeShowFreeTierData = {
  audienceSubscribeShowFreeTier: {
    subscriptionId: string;
    status: CheckoutStatus.Type;
  };
};

type SubscribeShowFreeTierVariables = {
  showId: string;
  planId: string;
  email: string;
  donationName: string;
  message: string;
};
