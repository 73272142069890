import { gql } from '@apollo/client';
import {
  ShowInfoFragment,
  EpisodeFragment,
  EntitlementFragment,
} from './fragments';

export const SearchEpisode = gql`
  query SearchEpisode(
    $showId: ID
    $playlistId: ID
    $queryString: String
    $first: Int
    $skip: Int
  ) {
    playerEpisodeFind(
      showId: $showId
      playlistId: $playlistId
      queryString: $queryString
      take: $first
      skip: $skip
    ) {
      ...EpisodeFragment
      entitlements {
        ...EntitlementFragment
      }
    }
  }
  ${EpisodeFragment}
  ${EntitlementFragment}
`;

export const GetEpisodeDetail = gql`
  query GetEpisodeDetail($episodeId: ID!) {
    playerEpisodeFindOne(episodeId: $episodeId) {
      id
      title
      audioUrl
      imageUrl
      publishedAt
      duration
      description
      canViewFlink
      show {
        ...ShowInfoFragment
        canRemoveFlinkAds
      }
      tags
      distributions {
        status
        platformType
        platformId
        platformUrl
      }
      entitlements {
        ...EntitlementFragment
      }
    }
  }
  ${ShowInfoFragment}
  ${EntitlementFragment}
`;
