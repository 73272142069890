import { Route, Switch, useRouteMatch } from 'react-router-dom';
import { makeStyles } from '@material-ui/styles';
import { Container, NoSsr, Theme } from '@material-ui/core';
import CenterLoading from '../../components/CenterLoading';
import GoogleAd from '../../components/GoogleAd';
import { ShowMembership } from '../../entities/membership';
import { Show } from '../../entities/show';
import ShowEpisodes from './ShowEpisodes';
import ShowComments from './ShowComments';
import ShowCommunity from './ShowCommunity';
import ShowVoiceMailBoxList from './ShowVoiceMailBoxList';
import HeaderRow from './HeaderRow';
import ShowInfo from './ShowInfo';

const useStyles = makeStyles((theme: Theme) => ({
  ads: {
    width: '100%',
    height: 180,
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(2),
  },
}));

function ShowContent({
  loading,
  show,
  isMe,
  showMembership,
  hideAds,
  hasLogin,
}: ShowContentProps): JSX.Element {
  const classes = useStyles();

  const isEmbed = useRouteMatch('/embed');

  if (loading) {
    return <CenterLoading />;
  }

  return (
    <div>
      {!isEmbed && (
        <Container maxWidth="md">
          {isMe && <HeaderRow />}
          {!isEmbed && (
            <ShowInfo
              showBlueCheck={show.isCreator}
              title={show.name}
              subtitle={show.author}
              explicit={show.explicit}
              category={show.categories[0]}
              description={show.intro}
              imageUrl={show.avatar}
              episodeCount={show.episodeCount}
              showId={show.id}
              urlSlug={show.urlSlug}
              showImport={show.import}
              distributions={show.distributions}
              websiteSetting={show.websiteSetting}
              showMembership={showMembership}
              externalLinks={show.externalLinks}
              ugc={!show.episodeCount || show.episodeCount <= 5}
            />
          )}
          {!hideAds && (
            <GoogleAd
              slot="9491468670"
              format="horizontal"
              fullWidthResponsive
              className={classes.ads}
            />
          )}
        </Container>
      )}
      <Switch>
        <Route path={['/user/:id/comments', '/embed/user/:id/comments']}>
          <NoSsr>
            <ShowComments
              userId={show.id}
              userData={{ userInfo: show }}
              hideAds={hideAds}
            />
          </NoSsr>
        </Route>
        <Route path="/user/:id/voicemails">
          <NoSsr>
            <ShowVoiceMailBoxList userId={show.id} />
          </NoSsr>
        </Route>
        <Route path="/user/:id/community">
          <NoSsr>
            <ShowCommunity show={show} hasLogin={hasLogin} />
          </NoSsr>
        </Route>
        <Route path={['/user/:id', '/embed/user/:id']}>
          <NoSsr>
            <ShowEpisodes showId={show.id} hideAds={hideAds} />
          </NoSsr>
        </Route>
      </Switch>
    </div>
  );
}

type ShowContentProps = {
  loading: boolean;
  show: Show;
  isMe: boolean;
  showMembership?: ShowMembership;
  hideAds: boolean;
  hasLogin: boolean;
};

export default ShowContent;
