import React from 'react';
import { ContextException } from '../../utils/exception.custom';
import { WebPlayerContextValues } from './type';

const WebPlayerContext = React.createContext<
  WebPlayerContextValues | undefined
>(undefined);

export function useWebPlayer() {
  const value = React.useContext(WebPlayerContext);
  if (!value) {
    throw new ContextException('useWebPlayer', 'WebPlayerProvider');
  }
  return value;
}

export default WebPlayerContext;
