import { colors } from '../constants';

const logo = `%c
         fffffffffffff          
          fffffffffffffff       
           fffffffffffffffff    
             fffffffffffffff    

       ffffffffffffff           
      fffffffffffffff           
     fffffffffffffff            

      ffff                      
     ffffff                     
     ffffff                     
      fff                       
`;

export function logHiringMessage() {
  // const lang = navigator.language;

  console.log(logo, `color: ${colors.tomato}`);

  // if (lang === 'zh') {
  console.log('%c你開 console 找樂子', 'font-size: 24px');
  console.log('%c我們開職缺找人才', 'font-size: 24px');
  console.log('%c歡迎加入我們！', 'font-size: 24px');
  // } else {
  //   console.log('%cYou are looking for code', 'font-size: 24px');
  //   console.log('%cWe are looking for talent', 'font-size: 24px');
  //   console.log('%cJoin us!', 'font-size: 24px');
  // }

  console.log(
    '%chttps://www.cakeresume.com/companies/firstory/jobs',
    'font-size: 24px',
  );
}
