import React from 'react';
import { useHistory, useLocation } from 'react-router-dom';

function useNextLocation() {
  const history = useHistory();
  const location = useLocation<{ background: Location | undefined }>();

  const goNext = React.useCallback(
    (next, options = {}) => {
      const background = location.state?.background;
      const queryParams = new URLSearchParams(location.search);
      const nextValue = queryParams.get('next');
      let n = nextValue || next || background || '/';

      if (next && options.forceNext) {
        n = next;
      }

      history.push('/refresh');
      if (n !== '/') {
        history.replace(n);
      }
    },
    [history, location],
  );

  return goNext;
}

export default useNextLocation;
