import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Typography, Theme, TextField, Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import ButtonWithLoading from '../../../components/ButtonWithLoading';
import { MessageId } from '../../../i18n';
import PriceInfo from './PriceInfo';
import { DonorInfoFormValues, PaymentTierPlanValues } from './type';

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    width: '100%',
    height: '100%',
    padding: 20,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  paymentFormContainer: {
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  title: {
    fontWeight: 'bold',
  },
  inputGroup: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  input: {
    marginTop: theme.spacing(0.5),
  },
  cancelButton: {
    margin: 5,
  },
  buttonRow: {
    marginTop: 20,
    display: 'flex',
    alignItems: 'center',
  },
}));

function DonorInfoForm({
  hasStripe,
  paymentTier,
  values,
  disableEmailEdit,
  onValueChange,
  onSubmit,
  onCancel,
}: DonorInfoFormProps): JSX.Element {
  const intl = useIntl();
  const classes = useStyles();
  const isFree = paymentTier.tierPlan?.plan.price.money.amount === 0;

  const canSubmit = values.email.trim() !== '';

  const [submitting, setSubmitting] = React.useState(false);

  const handleSubmit = React.useCallback(async () => {
    setSubmitting(true);
    try {
      await onSubmit();
    } finally {
      setSubmitting(false);
    }
  }, [onSubmit]);

  let submitMessageId: MessageId = 'general.next';

  if (hasStripe) {
    submitMessageId = 'payment.checkout';
  }

  if (isFree) {
    submitMessageId = 'donation.subscribe.free';
  }

  return (
    <div className={classes.container}>
      <Typography className={classes.title}>
        <FormattedMessage id="payment.enterInfo" />
      </Typography>
      <PriceInfo paymentTier={paymentTier} />
      <div className={classes.inputGroup}>
        <Typography color="textPrimary">
          <FormattedMessage id="payment.email" /> (
          <FormattedMessage id="general.required" />)
        </Typography>
        <TextField
          variant="outlined"
          type="email"
          className={classes.input}
          disabled={disableEmailEdit}
          placeholder={intl.formatMessage({ id: 'payment.email' })}
          value={values.email}
          onChange={onValueChange('email')}
        />
        <Typography variant="caption" color="textPrimary">
          <FormattedMessage id="payment.emailHint" />
        </Typography>
      </div>
      <div className={classes.inputGroup}>
        <Typography color="textPrimary">
          <FormattedMessage id="payment.donationName" /> (
          <FormattedMessage id="general.optional" />)
        </Typography>
        <TextField
          variant="outlined"
          className={classes.input}
          placeholder={intl.formatMessage({ id: 'donation.anonymous' })}
          value={values.donationName}
          onChange={onValueChange('donationName')}
        />
      </div>
      <div className={classes.inputGroup}>
        <Typography color="textPrimary">
          <FormattedMessage id="payment.message" /> (
          <FormattedMessage id="general.optional" />)
        </Typography>
        <TextField
          multiline
          minRows={3}
          variant="outlined"
          className={classes.input}
          placeholder={intl.formatMessage({ id: 'payment.messagePlaceholder' })}
          value={values.message}
          onChange={onValueChange('message')}
        />
      </div>
      <div className={classes.buttonRow}>
        <ButtonWithLoading
          loading={submitting}
          variant="contained"
          color="primary"
          disabled={!canSubmit}
          onClick={handleSubmit}
        >
          <FormattedMessage id={submitMessageId} />
        </ButtonWithLoading>
        <Button className={classes.cancelButton} onClick={onCancel}>
          <FormattedMessage id="general.cancel" />
        </Button>
      </div>
    </div>
  );
}

type DonorInfoFormProps = {
  hasStripe: boolean;
  paymentTier: PaymentTierPlanValues;
  values: DonorInfoFormValues;
  disableEmailEdit?: boolean;
  onValueChange(
    name: keyof DonorInfoFormValues,
  ): (e: React.ChangeEvent<HTMLInputElement>) => void;
  onSubmit(): Promise<void>;
  onCancel(): void;
};

export default DonorInfoForm;
