import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import cx from 'classnames';
import { makeStyles, useTheme } from '@material-ui/styles';
import { Link, Typography, Slider, Theme } from '@material-ui/core';
import FirstoryLogo from '../Logo';
import LazyImage from '../LazyImage';
import PlayPauseIcon from '../icons/PlayPauseIcon';
import { useWebPlayer } from '../../pages/WebPlayer/context';
import useDisclosure from '../../hooks/useDisclosure';
import { Show } from '../../entities/show';
import { PlayableData } from '../Player/type';
import { getMMSSfromSeconds, imageproxy } from '../../utils';
import EmbedControllerOptionRow from './EmbedControllerOptionRow';

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    position: 'relative',
    width: '100%',
    height: 180,
    padding: theme.spacing(2),
    display: 'flex',
    backgroundColor: theme.palette.background.default,
    boxSizing: 'border-box',
  },
  imageContainer: {
    position: 'relative',
    height: 148,
    width: 148,
    borderRadius: 10,
    overflow: 'hidden',
    marginRight: theme.spacing(1),
    cursor: 'pointer',
    '@media (max-width: 500px)': {
      height: 115,
      width: 115,
    },
    '&:hover': {
      opacity: 0.8,
    },
  },
  image: {
    height: '100%',
    width: '100%',
    objectFit: 'cover',
  },
  right: {
    flex: 1,
    minWidth: 0,
    display: 'flex',
    flexDirection: 'column',
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(4),
  },
  info: {
    flex: 1,
    position: 'relative',
  },
  timestamp: {
    opacity: 0.8,
    marginRight: theme.spacing(1),
    '@media (max-width: 500px)': {
      fontSize: '0.6rem',
    },
  },
  text: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  title: {
    fontWeight: 'bold',
    '@media (max-width: 500px)': {
      fontSize: '0.9rem',
    },
  },
  author: {
    display: 'inline-block',
    '@media (max-width: 500px)': {
      fontSize: '0.7rem',
    },
  },
  logoContainer: {
    position: 'absolute',
    top: theme.spacing(1),
    right: theme.spacing(1),
    display: 'flex',
    alignItems: 'center',
  },
  logo: {
    width: 18,
    height: 18,
    marginRight: theme.spacing(0.5),
    '@media (max-width: 500px)': {
      width: 12,
      height: 12,
    },
  },
  logoTitle: {
    color: theme.palette.primary.main,
    fontWeight: 'bold',
    '@media (max-width: 500px)': {
      fontSize: '0.7rem',
    },
  },
  controlContainer: {
    position: 'absolute',
    display: 'flex',
    alignItems: 'flex-end',
    right: theme.spacing(2),
    bottom: theme.spacing(2),
    left: 148 + 16 + 8,
    '@media (max-width: 500px)': {
      alignItems: 'center',
      left: theme.spacing(2),
      top: 16 + 115,
      bottom: 0,
    },
  },
  sliderContainer: {
    flex: 1,
    marginBottom: -1,
    display: 'flex',
    alignItems: 'center',
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(2),
    '@media (max-width: 500px)': {
      marginBottom: 5,
    },
  },
  sliderRail: {
    height: 10,
    borderRadius: 5,
    backgroundColor: theme.palette.secondary.main,
  },
  sliderTrack: {
    height: 10,
    borderRadius: 5,
    backgroundColor: theme.palette.secondary.main,
  },
  sliderThumb: {
    display: 'none',
  },
  playIcon: {
    backgroundColor: theme.palette.primary.main,
    '&:hover': {
      backgroundColor: theme.palette.primary.dark,
    },
  },
  playPauseContainer: {
    '@media (max-width: 500px)': {
      transform: 'translateY(-10px)',
    },
  },
}));

function getAuthorLinkPath({
  author,
  isWebPlayer,
}: {
  author?: Show | null;
  isWebPlayer?: boolean;
}): string {
  if (isWebPlayer && author) {
    return `/user/${author.urlSlug || author.id}`;
  }

  return '/';
}

function Logo({ playerHideLogo, link }: LogoProps): JSX.Element | null {
  const classes = useStyles();
  const theme = useTheme<Theme>();

  const logo = (
    <div className={classes.logoContainer}>
      <FirstoryLogo
        color={theme.palette.background.default}
        backgroundColor={theme.palette.primary.main}
        className={classes.logo}
      />
      <Typography className={classes.logoTitle}>Firstory</Typography>
    </div>
  );

  if (playerHideLogo) {
    return null;
  }

  if (link) {
    return (
      <RouterLink to={link} target="_blank" rel="noreferrer noopener">
        {logo}
      </RouterLink>
    );
  }

  return (
    <a href="https://firstory.me" target="_blank" rel="noreferrer noopener">
      {logo}
    </a>
  );
}

function EmbedController({
  defaultTitle,
  defaultSubtitle,
  defaultImageUrl,
  logoLink,
  nowPlaying,
  isLoading,
  isPlaying,
  timeMs,
  onPlayPauseClick,
  onSliderChange,
  onSliderAfterChange,
}: EmbedControllerProps): JSX.Element {
  const { isWebPlayer } = useWebPlayer();
  const classes = useStyles();
  const theme = useTheme<Theme>();
  const { isOpen, open, close } = useDisclosure();

  const author = nowPlaying?.show;
  const title = nowPlaying?.title || defaultTitle;
  const subtitle = author?.name || defaultSubtitle;
  const imageUrl = nowPlaying?.imageUrl || defaultImageUrl;
  let durationMs = 0;

  if (nowPlaying?.duration) {
    durationMs = nowPlaying.duration;
  }

  const handleSliderChangeCommitted = React.useCallback(
    (_, v: number) => {
      onSliderAfterChange(v);
    },
    [onSliderAfterChange],
  );

  return (
    <div className={classes.container}>
      <div className={classes.imageContainer} onClick={onPlayPauseClick}>
        {imageUrl ? (
          <LazyImage
            src={imageUrl}
            placeholder={imageproxy(imageUrl, 50)}
            alt={title}
            className={classes.image}
          />
        ) : (
          <Logo />
        )}
      </div>
      <div className={classes.right}>
        <div className={classes.info}>
          <Typography
            variant="h6"
            color="primary"
            component="h1"
            className={cx(classes.text, classes.title)}
          >
            {nowPlaying?.title ?? title}
          </Typography>
          <Link
            color="primary"
            component={RouterLink}
            to={{
              pathname: getAuthorLinkPath({
                author,
                isWebPlayer,
              }),
              state: { from: 'CONTROLLER' },
            }}
            target="_blank"
            className={cx(classes.text, classes.author)}
          >
            {subtitle || ''}
          </Link>
          <EmbedControllerOptionRow
            show={author}
            episode={nowPlaying}
            isShareOpen={isOpen}
            onShareClick={open}
            onShareClose={close}
          />
        </div>
      </div>
      <Logo
        playerHideLogo={author?.websiteSetting?.playerHideLogo}
        link={logoLink}
      />
      <div className={classes.controlContainer}>
        <div className={classes.sliderContainer}>
          <Slider
            classes={{
              rail: classes.sliderRail,
              track: classes.sliderTrack,
              thumb: classes.sliderThumb,
            }}
            max={durationMs}
            value={timeMs}
            onChange={onSliderChange}
            onChangeCommitted={handleSliderChangeCommitted}
          />
        </div>
        <Typography
          color="primary"
          variant="caption"
          className={classes.timestamp}
        >
          {getMMSSfromSeconds(timeMs / 1000)} /{' '}
          {getMMSSfromSeconds(durationMs / 1000)}
        </Typography>
        <div className={classes.playPauseContainer}>
          <PlayPauseIcon
            button
            size={24}
            color={theme.palette.background.default}
            isLoading={isLoading}
            isPlaying={isPlaying}
            onClick={onPlayPauseClick}
            className={classes.playIcon}
          />
        </div>
      </div>
    </div>
  );
}

type EmbedControllerProps = {
  defaultTitle?: string;
  defaultSubtitle?: string;
  defaultImageUrl?: string | null;
  logoLink?: string | null;
  nowPlaying: PlayableData | null;
  isLoading: boolean;
  isPlaying: boolean;
  timeMs: number;
  onPlayPauseClick: () => void;
  onSliderChange: (e: React.ChangeEvent, v: number) => void;
  onSliderAfterChange: (v: number) => void;
};

type LogoProps = {
  playerHideLogo?: boolean;
  link?: string | null;
};

export default EmbedController;
