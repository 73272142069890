import React from 'react';
import { FormattedMessage } from 'react-intl';
import { makeStyles } from '@material-ui/styles';
import { Typography, Theme, Paper, Button, Grid } from '@material-ui/core';
import { useLocation } from 'react-router';
import Html from '../../../components/Html';
import {
  CustomerStatus,
  isSubscriptionActiveLike,
  Plan,
  Subscription,
  Tier,
  TrialInterval,
} from '../../../entities/membership';
import { GetMeLegacyData } from '../../../gql/hooks/user';
import { formatMoney, sortPlans } from './util';
import PlanItem from './PlanItem';

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    padding: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  title: {
    textAlign: 'center',
  },
  amount: {
    textAlign: 'center',
    fontWeight: 'bold',
  },
  interval: {
    textAlign: 'center',
    display: 'block',
  },
  description: {
    padding: theme.spacing(2, 0),
    marginBottom: theme.spacing(2),
  },
  submitButton: {
    width: '100%',
    marginTop: theme.spacing(2),
  },
}));

function TierButtonText({
  firstPlan,
  customerStatus,
  trialInterval,
  currentSubscription,
}: TierButtonTextProps): JSX.Element {
  const hasSubscribed = isSubscriptionActiveLike(currentSubscription);
  const isSubscribedToFree = currentSubscription?.plan.price.money.amount === 0;

  if (!firstPlan.price.interval) {
    return <FormattedMessage id="donation.donate" />;
  }

  if (!customerStatus?.hasTrialed && trialInterval) {
    return (
      <FormattedMessage
        id="donation.subscribe.trial"
        values={{ day: trialInterval.amount }}
      />
    );
  }

  if (hasSubscribed) {
    if (isSubscribedToFree) {
      return <FormattedMessage id="donation.subscribe.upgrade" />;
    }

    return <FormattedMessage id="donation.subscribe.duplicate" />;
  }

  return <FormattedMessage id="donation.subscribe" />;
}

function TierItem({
  me,
  currentSubscription,
  tier,
  customerStatus,
  trialInterval,
  onSelect,
}: TierItemProps): JSX.Element | null {
  const classes = useStyles();
  const location = useLocation();

  const plans = sortPlans(tier.plans);
  const firstPlan = plans[0];

  const [selectedPlan, setSelectedPlan] = React.useState(firstPlan);

  const isRecurring = !!selectedPlan.price.interval;
  const isFree = selectedPlan.price.money.amount === 0;
  const hasLogin = !!me;
  const hasSubscribed = isSubscriptionActiveLike(currentSubscription);
  const isSubscribedToFree = currentSubscription?.plan.price.money.amount === 0;

  const disableSubscribe = hasSubscribed && !isSubscribedToFree;

  const handleSelect = React.useCallback(() => {
    if (isRecurring && !isFree && !hasLogin) {
      const currentPath = location.pathname + location.search;
      window.location.href = `/login?next=${encodeURIComponent(currentPath)}`;

      return;
    }

    onSelect(tier, selectedPlan);
  }, [location, tier, selectedPlan, isRecurring, isFree, hasLogin, onSelect]);

  const handlePlanSelect = React.useCallback((plan: Plan) => {
    setSelectedPlan(plan);
  }, []);

  if (tier.plans.length === 0) {
    return null;
  }

  return (
    <Paper variant="outlined" className={classes.container}>
      <Typography className={classes.title}>{tier.title}</Typography>
      <Typography variant="h6" className={classes.amount}>
        {formatMoney(firstPlan.price.money)}
      </Typography>
      {firstPlan.price.interval ? (
        <Typography variant="caption" className={classes.interval}>
          <FormattedMessage
            id={
              firstPlan.price.interval.unit === 'm'
                ? 'donation.monthly'
                : 'donation.yearly'
            }
          />
        </Typography>
      ) : null}
      {tier.description && (
        <Html html={tier.description} className={classes.description} />
      )}
      {plans.length > 1 ? (
        <Grid container spacing={1}>
          {plans.map(p => (
            <Grid key={p.id} item xs={4}>
              <PlanItem
                plan={p}
                selected={selectedPlan === p}
                onSelect={handlePlanSelect}
              />
            </Grid>
          ))}
        </Grid>
      ) : null}
      <Button
        disabled={disableSubscribe}
        variant="contained"
        color="primary"
        size="large"
        className={classes.submitButton}
        onClick={handleSelect}
      >
        <TierButtonText
          tier={tier}
          firstPlan={firstPlan}
          selectedPlan={selectedPlan}
          customerStatus={customerStatus}
          trialInterval={trialInterval}
          currentSubscription={currentSubscription}
        />
      </Button>
    </Paper>
  );
}

type TierItemProps = {
  tier: Tier;
  me?: GetMeLegacyData | null;
  currentSubscription?: Subscription | null;
  customerStatus?: CustomerStatus;
  trialInterval?: TrialInterval;
  onSelect(tier: Tier, plan: Plan): void;
};

type TierButtonTextProps = {
  tier: Tier;
  firstPlan: Plan;
  selectedPlan: Plan;
  customerStatus?: CustomerStatus;
  trialInterval?: TrialInterval;
  currentSubscription?: Subscription | null;
};

export default TierItem;
