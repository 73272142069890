import { FormattedMessage } from 'react-intl';
import { Link as RouterLink } from 'react-router-dom';
import { makeStyles } from '@material-ui/styles';
import { Button, Theme } from '@material-ui/core';
import MoneyIcon from '@material-ui/icons/AttachMoney';

const useStyles = makeStyles((theme: Theme) => ({
  header: {
    display: 'flex',
    justifyContent: 'flex-end',
    paddingTop: theme.spacing(2),
  },
  headerButton: {
    marginLeft: theme.spacing(2),
  },
}));

function HeaderRow(): JSX.Element {
  const classes = useStyles();

  return (
    <div className={classes.header}>
      <Button
        variant="outlined"
        size="large"
        startIcon={<MoneyIcon />}
        component={RouterLink}
        to="/donation"
      >
        <FormattedMessage id="podcast.manageDonation" />
      </Button>
      <Button
        variant="contained"
        color="primary"
        size="large"
        component="a"
        target="_blank"
        href="https://studio.firstory.me"
        className={classes.headerButton}
      >
        <FormattedMessage id="podcast.gotoStudio" />
      </Button>
    </div>
  );
}

export default HeaderRow;
