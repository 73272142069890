import React from 'react';
import { makeStyles } from '@material-ui/styles';
import { Button, Theme } from '@material-ui/core';
import { useWebPlayer } from '../../pages/WebPlayer/context';
import PlayPauseIcon from '../icons/PlayPauseIcon';
import useAudio from '../Player/useAudio';
import useAnimationFrame from '../../hooks/useAnimationFrame';
import { getMMSSfromSeconds } from '../../utils';
import { Comment } from '../../entities/comment';

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    paddingTop: theme.spacing(1),
  },
  button: {
    color: 'white',
    backgroundColor: '#555',
    '&:hover': {
      backgroundColor: '#666',
    },
  },
}));

function AudioContent({ audioComment }: AudioContentProps): JSX.Element {
  const classes = useStyles();

  const [isPlaying, setIsPlaying] = React.useState(false);
  const [currentTime, setCurrentTime] = React.useState(0);

  const { pause } = useWebPlayer();

  const handleEnded = React.useCallback(() => {
    setIsPlaying(false);
    setCurrentTime(0);
  }, []);

  const { currentTimeMsRef } = useAudio({
    defaultUrl: audioComment.url,
    onEnded: handleEnded,
  });

  useAnimationFrame(() => {
    setCurrentTime(currentTimeMsRef.current);
  }, isPlaying);

  const handlePlayPauseClick = React.useCallback(() => {
    if (isPlaying) {
      setIsPlaying(false);
    } else {
      pause();
      setIsPlaying(true);
    }
  }, [isPlaying, pause]);

  const durationInSeconds = audioComment.duration / 1000;
  const remainingSeconds = durationInSeconds - currentTime;

  return (
    <div className={classes.container}>
      <Button
        aria-label="Play"
        variant="contained"
        classes={{ root: classes.button }}
        onClick={handlePlayPauseClick}
      >
        <PlayPauseIcon size={25} color="white" isPlaying={isPlaying} />
        {getMMSSfromSeconds(remainingSeconds)}
      </Button>
    </div>
  );
}

type AudioContentProps = {
  audioComment: NonNullable<Comment['audioComment']>;
};

export default AudioContent;
