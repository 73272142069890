export namespace Define {
  export namespace Ad {
    export namespace Status {
      export const disable = 0;
      export const enable = 1;
      export const running = 2;
      export type Type = typeof disable | typeof enable | typeof running;
    }

    export namespace Campaign {
      export namespace Status {
        export const cancel = 0;
        export const start = 1;
        export type Type = typeof cancel | typeof start;
      }
    }
  }

  export namespace AudioEditor {
    export namespace Type {
      export const single = 1;
      export const multiple = 2;
      export type Type = typeof single | typeof multiple;
    }
  }

  export namespace AudioBlock {
    export namespace Type {
      export const main = 1;
      export const sharing = 2;
      export const ad = 3;
      export type Type = typeof main | typeof sharing | typeof ad;
    }
  }

  export namespace MusicPicker {
    export namespace Type {
      export const track = 1;
      export const album = 2;
      export const artist = 3;
      export type Type = typeof track | typeof album | typeof artist;
    }
  }

  export namespace Show {
    export namespace ImportStatus {
      export const pending = 1;
      export const hasRedirect = 2;
      export const success = 3;
      export type Type = typeof pending | typeof hasRedirect | typeof success;
    }

    export namespace ExternalLink {
      export const custom = 1;
      export const facebook = 2;
      export const instagram = 3;
      export const twitter = 4;
      export const linkedIn = 5;
      export const medium = 6;
      export const youtube = 7;
      export const discord = 8;
      export type Type =
        | typeof custom
        | typeof facebook
        | typeof instagram
        | typeof twitter
        | typeof linkedIn
        | typeof medium
        | typeof youtube
        | typeof discord;

      export const list = [
        custom,
        facebook,
        instagram,
        twitter,
        linkedIn,
        medium,
        youtube,
        discord,
      ] as const;

      export const nonCustomList = [
        facebook,
        instagram,
        twitter,
        linkedIn,
        medium,
        youtube,
        discord,
      ] as const;

      export const Title = {
        [facebook]: 'Facebook',
        [instagram]: 'Instagram',
        [twitter]: 'Twitter',
        [linkedIn]: 'LinkedIn',
        [medium]: 'Medium',
        [youtube]: 'YouTube',
        [discord]: 'Discord',
      } as const;
    }
  }

  export namespace Episode {
    export namespace EpisodeType {
      export const full = 1;
      export const trailer = 2;
      export const bonus = 3;
      export const map = {
        1: 'full',
        2: 'trailer',
        3: 'bonus',
        full,
        trailer,
        bonus,
      } as const;

      export type Enum = 'full' | 'trailer' | 'bonus';
      export type Type = typeof full | typeof trailer | typeof bonus;
    }

    export namespace DynamicInsertion {
      export namespace Status {
        export const error = 0;
        export const running = 1;
        export const success = 2;
        export type Type = typeof error | typeof running | typeof success;
      }
    }
  }

  export namespace Comment {
    export namespace CommentType {
      export const text = 1;
      export const audio = 2;
      export type Type = typeof text | typeof audio;
    }
  }

  export namespace Distribution {
    export namespace Platform {
      export const apple = 1;
      export const breaker = 2;
      export const castbox = 3;
      export const castro = 4;
      export const google = 5;
      export const kkbox = 6;
      export const overcast = 7;
      export const playerfm = 8;
      export const pocketcast = 9;
      export const podcastaddict = 10;
      export const radiopublic = 11;
      export const soundon = 12;
      export const spotify = 13;
      export const stitcher = 14;
      export const amazon = 15;
      export const noice = 16;

      export const list: ReadonlyArray<Type> = [
        apple,
        castbox,
        castro,
        kkbox,
        overcast,
        playerfm,
        pocketcast,
        podcastaddict,
        radiopublic,
        soundon,
        spotify,
        stitcher,
        amazon,
        noice,
      ];

      export const deprecated = {
        [breaker]: true,
        [google]: true,
      };

      export const supportAutoShowDistribution: ReadonlyArray<Type> = [
        kkbox,
        pocketcast,
        spotify,
      ];
      export const supportAutoShowChecking: ReadonlyArray<Type> = [
        apple,
        castbox,
        castro,
        kkbox,
        overcast,
        pocketcast,
        spotify,
      ];
      export const supportAutoEpisodeChecking: ReadonlyArray<Type> = [
        apple,
        kkbox,
        spotify,
      ];

      export type Type =
        | typeof apple
        | typeof breaker
        | typeof castbox
        | typeof castro
        | typeof google
        | typeof kkbox
        | typeof overcast
        | typeof playerfm
        | typeof pocketcast
        | typeof podcastaddict
        | typeof radiopublic
        | typeof soundon
        | typeof spotify
        | typeof stitcher
        | typeof amazon
        | typeof noice;

      export const Title = {
        [amazon]: 'Amazon Music',
        [apple]: 'Apple Podcasts',
        [breaker]: 'Breaker',
        [castbox]: 'Castbox',
        [castro]: 'Castro',
        [google]: 'Google Podcasts',
        [kkbox]: 'KKBOX',
        [noice]: 'Noice',
        [overcast]: 'Overcast',
        [playerfm]: 'Player FM',
        [pocketcast]: 'Pocket Casts',
        [podcastaddict]: 'Podcast Addict',
        [radiopublic]: 'RadioPublic',
        [soundon]: 'SoundOn',
        [spotify]: 'Spotify',
        [stitcher]: 'Stitcher',
      } as const;
    }
    export namespace Status {
      export const notSupportCheckStatus = 0;
      /**
       * @description default status for each distribution
       */
      export const pending = 1;
      /**
       * @description after submitting a new show to platform (only for show distribution)
       */
      export const submitted = 2;
      /**
       * @description after the show or episode is visible on the target platform
       */
      export const active = 3;
      /**
       * @description if `pending` more than 3 days
       */
      export const error = 4;

      export type Type =
        | typeof notSupportCheckStatus
        | typeof pending
        | typeof submitted
        | typeof active
        | typeof error;
    }
  }

  export namespace Payment {
    export namespace Provider {
      export const example = 0;
      export const stripe = 1;
      export const tapPay = 2;
      export type Type = typeof example | typeof stripe | typeof tapPay;
    }

    export namespace MerchantProviderStatus {
      export const pending = 0;
      export const active = 1;
      export const inactive = 2;
      export const trialing = 3;
      export type Type =
        | typeof pending
        | typeof active
        | typeof inactive
        | typeof trialing;
    }
  }

  export namespace Membership {
    export namespace Resource {
      export namespace Type {
        export const episode = 0;
        export const post = 1;
        export const list = [episode, post] as const;
        export type Type = typeof episode | typeof post;
      }
    }
  }
}
