import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import cx from 'classnames';
import { makeStyles } from '@material-ui/styles';
import {
  Avatar,
  Link,
  IconButton,
  Typography,
  Slider,
  Theme,
  useMediaQuery,
} from '@material-ui/core';
import ForwardIcon from '@material-ui/icons/Forward10';
import ReplayIcon from '@material-ui/icons/Replay10';
import PlayPauseIcon from '../icons/PlayPauseIcon';
import Logo from '../../components/Logo';
import { getMMSSfromSeconds } from '../../utils';
import { PlayableData } from '../Player/type';
import SpeedController from './SpeedController';

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    position: 'relative',
    height: 90,
    width: '100%',
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
    backgroundColor: '#1b1b1b',
    boxSizing: 'border-box',
    '@media(max-width:500px)': {
      paddingLeft: theme.spacing(1),
      paddingRight: theme.spacing(1),
    },
  },
  image: {
    height: 50,
    width: 50,
    borderRadius: 5,
    marginRight: theme.spacing(2),
    objectFit: 'cover',
    '@media(max-width:500px)': {
      marginRight: theme.spacing(1),
    },
  },
  smallIcon: {
    width: 30,
    height: 30,
  },
  playIcon: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  timestamp: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    opacity: 0.8,
  },
  info: {
    flex: 1,
    height: 70,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    textAlign: 'center',
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
  },
  title: {
    display: '-webkit-box',
    '-webkit-line-clamp': 2,
    '-webkit-box-orient': 'vertical',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  speedControl: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    '&:hover': {
      '& $speedAddIcon': {
        opacity: 1,
      },
    },
  },
  speedAddIcon: {
    opacity: 0,
    transition: 'all 0.5s',
  },
  speedButton: {
    marginTop: theme.spacing(0.5),
    marginBottom: theme.spacing(0.5),
    textTransform: 'none',
  },
  hideOnSm: {
    '@media(max-width:700px)': {
      display: 'none',
    },
  },
  slider: {
    position: 'absolute',
    top: -13,
    left: 0,
    right: 0,
    '@media (pointer: coarse)': {
      top: -20,
    },
  },
}));

function NormalController({
  defaultTitle,
  defaultSubtitle,
  defaultImageUrl,
  nowPlaying,
  isLoading,
  isPlaying,
  timeMs,
  onPlayPauseClick,
  onForwardClick,
  onBackwardClick,
  onSpeedChange,
  onSliderChange,
  onSliderAfterChange,
}: NormalControllerProps): JSX.Element {
  const classes = useStyles();
  const isSmall = useMediaQuery('(max-width: 700px)');
  const [speed, setSpeed] = React.useState(10);

  const author = nowPlaying?.show;
  const title = nowPlaying?.title || defaultTitle;
  const subtitle = author?.name || defaultSubtitle;
  const imageUrl = nowPlaying?.imageUrl || defaultImageUrl;

  let durationMs = 0;

  if (nowPlaying?.duration) {
    durationMs = nowPlaying.duration;
  }

  const handleSliderChangeCommitted = React.useCallback(
    (_, v: number) => {
      onSliderAfterChange(v);
    },
    [onSliderAfterChange],
  );

  React.useEffect(() => {
    onSpeedChange?.(speed / 10);
  }, [speed, onSpeedChange]);

  return (
    <div className={classes.container}>
      {imageUrl ? (
        <Avatar
          src={imageUrl}
          className={cx(classes.image, classes.hideOnSm)}
          alt={title}
        />
      ) : (
        <Logo className={cx(classes.image, classes.hideOnSm)} />
      )}
      <IconButton onClick={onBackwardClick} className={classes.hideOnSm}>
        <ReplayIcon className={classes.smallIcon} />
      </IconButton>
      <PlayPauseIcon
        color="white"
        size={isSmall ? 30 : 40}
        button={!isSmall}
        isLoading={isLoading}
        isPlaying={isPlaying}
        onClick={onPlayPauseClick}
      />
      <IconButton onClick={onForwardClick} className={classes.hideOnSm}>
        <ForwardIcon className={classes.smallIcon} />
      </IconButton>
      <Typography variant="caption" className={classes.timestamp}>
        {getMMSSfromSeconds(timeMs / 1000)} /{' '}
        {getMMSSfromSeconds(durationMs / 1000)}
      </Typography>
      <div className={classes.info}>
        <Typography className={classes.title}>{title}</Typography>
        {author ? (
          <Link
            component={RouterLink}
            to={{
              pathname: `/user/${author.urlSlug || author.id}`,
              state: { from: 'CONTROLLER' },
            }}
          >
            {subtitle}
          </Link>
        ) : null}
      </div>
      <SpeedController speed={speed} setSpeed={setSpeed} />
      <div className={classes.slider}>
        <Slider
          max={durationMs}
          value={timeMs}
          onChange={onSliderChange}
          onChangeCommitted={handleSliderChangeCommitted}
        />
      </div>
    </div>
  );
}

type NormalControllerProps = {
  defaultTitle?: string;
  defaultSubtitle?: string;
  defaultImageUrl?: string | null;
  nowPlaying: PlayableData | null;
  isLoading: boolean;
  isPlaying: boolean;
  timeMs: number;
  onPlayPauseClick: () => void;
  onForwardClick?: () => void;
  onBackwardClick?: () => void;
  onSpeedChange?: (speed: number) => void;
  onSliderChange: (e: any, v: number) => void;
  onSliderAfterChange: (v: number) => void;
};

export default NormalController;
