import React from 'react';
import { useIntl, FormattedMessage } from 'react-intl';
import cx from 'classnames';
import { makeStyles } from '@material-ui/styles';
import {
  Card,
  CardContent,
  CardHeader,
  Collapse,
  IconButton,
  Link,
  Typography,
  Theme,
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    marginTop: theme.spacing(6),
  },
  title: {
    marginBottom: theme.spacing(1),
  },
  item: {
    marginBottom: theme.spacing(1),
  },
  expand: {
    transform: 'rotate(0deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: 'rotate(180deg)',
  },
}));

function FaqItem({
  index,
  title,
  description,
  expanded,
  onExpandClick,
}: FaqItemProps): JSX.Element {
  const classes = useStyles();

  const handleExpandClick = React.useCallback(() => {
    onExpandClick(index);
  }, [index, onExpandClick]);

  return (
    <Card variant="outlined" className={classes.item}>
      <CardHeader
        title={title}
        titleTypographyProps={{ variant: 'body1' }}
        action={
          <IconButton
            className={cx(classes.expand, {
              [classes.expandOpen]: expanded,
            })}
            onClick={handleExpandClick}
            aria-expanded={expanded}
            aria-label="show more"
          >
            <ExpandMoreIcon />
          </IconButton>
        }
      />
      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <CardContent>
          <Typography variant="body2">{description}</Typography>
        </CardContent>
      </Collapse>
    </Card>
  );
}

function Faq() {
  const intl = useIntl();
  const classes = useStyles();

  const items: { title: string; description: React.ReactNode }[] = [
    {
      title: intl.formatMessage({ id: 'donation.faq.when.title' }),
      description: intl.formatMessage({ id: 'donation.faq.when.description' }),
    },
    {
      title: intl.formatMessage({ id: 'donation.faq.cancel.title' }),
      description: intl.formatMessage(
        { id: 'donation.faq.cancel.description' },
        {
          link: chunk => (
            <Link
              href="https://help.firstory.me/articles/5489925"
              target="_blank"
              rel="noreferrer noopener"
            >
              {chunk}
            </Link>
          ),
        },
      ),
    },
    {
      title: intl.formatMessage({ id: 'donation.faq.public.title' }),
      description: intl.formatMessage({
        id: 'donation.faq.public.description',
      }),
    },
    {
      title: intl.formatMessage({ id: 'donation.faq.name.title' }),
      description: intl.formatMessage(
        { id: 'donation.faq.name.description' },
        {
          link: chunk => (
            <Link
              href="https://help.firstory.me/articles/5489925"
              target="_blank"
              rel="noreferrer noopener"
            >
              {chunk}
            </Link>
          ),
        },
      ),
    },
    {
      title: intl.formatMessage({ id: 'donation.faq.help.title' }),
      description: intl.formatMessage(
        { id: 'donation.faq.help.description' },
        {
          link: chunk => <Link href="mailto:support@firstory.me">{chunk}</Link>,
        },
      ),
    },
  ];

  const [expandedIndex, setExpandedIndex] = React.useState(-1);

  const handleExpandClick = React.useCallback(
    index => {
      if (expandedIndex === index) {
        setExpandedIndex(-1);
      } else {
        setExpandedIndex(index);
      }
    },
    [expandedIndex],
  );

  return (
    <div className={classes.container}>
      <Typography color="textPrimary" variant="h6" className={classes.title}>
        <FormattedMessage id="donation.faq" />
      </Typography>
      {items.map((item, index) => (
        <FaqItem
          key={index}
          index={index}
          {...item}
          expanded={expandedIndex === index}
          onExpandClick={handleExpandClick}
        />
      ))}
    </div>
  );
}

type FaqItemProps = {
  index: number;
  title: string;
  description: React.ReactNode;
  expanded: boolean;
  onExpandClick(index: number): void;
};

export default Faq;
