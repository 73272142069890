import React from 'react';
import VoiceMailBoxDetail from '../UserSite/VoiceMail/VoiceMailBoxDetail';
import Layout from '../WebPlayer/Layout';
import Footer from '../UserSite/Layout/Footer';

function VoiceMail() {
  return (
    <Layout hideController hideNavbar>
      <VoiceMailBoxDetail showPodcastInfo />
      <Footer />
    </Layout>
  );
}

export default VoiceMail;
