import React from 'react';
import { Avatar, Theme, Typography } from '@material-ui/core';
import { makeStyles, ThemeProvider } from '@material-ui/styles';
import { Distribution } from '../../entities/distribution';
import { ShowMembership } from '../../entities/membership';
import { ExternalLink, WebsiteSetting } from '../../entities/show';
import { useCustomTheme } from '../../theme';
import GoogleAd from '../../components/GoogleAd';
import PoweredByFirstory from '../../components/PoweredByFirstory';
import { Define } from '../../define';
import PlatformList from './PlatformList';

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    width: '100%',
    height: '100%',
    overflow: 'auto',
    backgroundColor: theme.palette.background.default,
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    maxWidth: 450,
    margin: 'auto',
    padding: theme.spacing(4, 2),
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(2, 1),
    },
  },
  image: {
    height: 200,
    width: 200,
    borderRadius: 5,
    objectFit: 'cover',
    marginBottom: theme.spacing(2),
  },
  title: {
    fontWeight: 'bold',
    color: theme.palette.primary.main,
  },
  subtitle: {
    marginBottom: theme.spacing(4),
    color: theme.palette.primary.main,
    [theme.breakpoints.down('sm')]: {
      marginBottom: theme.spacing(2),
    },
  },
  ads: {
    width: '100%',
    height: 90,
    marginBottom: theme.spacing(2),
  },
}));

function Flink({
  type,
  hideAds,
  showId,
  episodeId,
  imageUrl,
  title,
  titleLink,
  subtitle,
  subtitleLink,
  distributionMap,
  showDistributionMap,
  externalLinks,
  websiteSetting,
  showMembership,
}: FlinkProps): JSX.Element {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <div className={classes.content}>
        <Avatar className={classes.image} src={imageUrl || ''} alt={title} />
        <Typography variant="h5" component="h1" className={classes.title}>
          <a
            href={titleLink}
            target="_blank"
            rel="noreferrer noopener"
            className={classes.title}
          >
            {title}
          </a>
        </Typography>
        <Typography variant="h6" component="h2" className={classes.subtitle}>
          <a
            href={subtitleLink}
            target="_blank"
            rel="noreferrer noopener"
            className={classes.subtitle}
          >
            {subtitle}
          </a>
        </Typography>
        <PlatformList
          type={type}
          showId={showId}
          episodeId={episodeId}
          distributionMap={distributionMap}
          showDistributionMap={showDistributionMap}
          externalLinks={externalLinks}
          websiteSetting={websiteSetting}
          showMembership={showMembership}
        />
        {!hideAds && (
          <GoogleAd
            slot="1695920893"
            format="horizontal"
            className={classes.ads}
          />
        )}
        <PoweredByFirstory />
      </div>
    </div>
  );
}

function FlinkWithTheme(props: FlinkProps): JSX.Element {
  const theme = useCustomTheme({ websiteSetting: props.websiteSetting });
  return (
    <ThemeProvider theme={theme}>
      <Flink {...props} />
    </ThemeProvider>
  );
}

type FlinkProps = {
  type: 'show' | 'episode';
  hideAds: boolean;
  showId: string;
  episodeId?: string;
  imageUrl?: string | null;
  title: string;
  titleLink: string;
  subtitle: string;
  subtitleLink: string;
  distributionMap: Map<Define.Distribution.Platform.Type, Distribution>;
  showDistributionMap?: Map<Define.Distribution.Platform.Type, Distribution>;
  externalLinks: ExternalLink[];
  websiteSetting?: WebsiteSetting;
  showMembership?: ShowMembership;
};

export default FlinkWithTheme;
