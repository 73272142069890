import React from 'react';
import { FormattedMessage } from 'react-intl';
import { makeStyles } from '@material-ui/styles';
import { Typography, Theme } from '@material-ui/core';
import LoadMore from '../LoadMore';
import GoogleAd from '../GoogleAd';
import { useCreateComment } from '../../gql/hooks/comment';
import { useGetMeLegacy } from '../../gql/hooks/user';
import { Show } from '../../entities/show';
import { Episode } from '../../entities/episode';
import { Comment } from '../../entities/comment';
import CommentForm from './CommentForm';
import CommentItem from './CommentItem';

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    width: '100%',
    paddingBottom: theme.spacing(6),
  },
  header: {
    display: 'flex',
    alignItems: 'center',
    paddingBottom: theme.spacing(2),
  },
  commentCount: {
    fontWeight: 'bold',
    paddingRight: theme.spacing(2),
  },
  commentForm: {
    width: '100%',
    paddingBottom: theme.spacing(6),
    display: 'flex',
    flexDirection: 'column',
  },
  commentInputContainer: {
    display: 'flex',
  },
  avatarContainer: {
    marginRight: theme.spacing(2),
  },
  avatar: {
    width: 32,
    height: 32,
    objectFit: 'cover',
    borderRadius: 3,
    cursor: 'pointer',
  },
  commentInput: {
    marginBottom: theme.spacing(1),
    width: '100%',
  },
  commentSubmitButton: {
    alignSelf: 'flex-end',
  },
  loginRequiredContainer: {
    border: `1px solid ${theme.palette.divider}`,
    borderRadius: 4,
    height: 96,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginBottom: theme.spacing(6),
  },
  rating: {
    paddingBottom: theme.spacing(2),
  },
  commentList: {
    paddingTop: theme.spacing(4),
  },
  ads: {
    width: '100%',
    height: 80,
    marginBottom: theme.spacing(4),
  },
}));

function CommentList({
  isEmbed,
  loading,
  type,
  user,
  record,
  commentCount,
  parentComment,
  comments,
  hasMore,
  hideAds,
  loadMore,
}: CommentListProps): JSX.Element {
  const classes = useStyles();
  const createComment = useCreateComment();
  const inputRef = React.useRef<HTMLInputElement>();
  const { data } = useGetMeLegacy();
  const me = data && data.me ? data.me : null;

  const handleParentReply = React.useCallback(() => {
    if (parentComment) {
      if (inputRef.current) {
        inputRef.current.focus();
      }
    }
  }, [parentComment]);

  return (
    <div className={classes.container}>
      <div className={classes.header}>
        {commentCount ? (
          <Typography color="textPrimary" className={classes.commentCount}>
            <FormattedMessage
              id="comment.commentCount"
              values={{ count: commentCount }}
            />
          </Typography>
        ) : null}
      </div>
      <CommentForm
        inputRef={inputRef}
        isEmbed={isEmbed}
        me={me}
        showMe
        user={user}
        record={record}
        comment={parentComment}
        createComment={createComment}
      />
      <div className={classes.commentList}>
        {comments.map((comment, i) => (
          <React.Fragment key={comment.id}>
            <CommentItem
              isEmbed={isEmbed}
              me={me}
              listType={type}
              comment={comment}
              parentComment={parentComment}
              createComment={createComment}
              openParentReply={handleParentReply}
            />
            {!hideAds && i % 10 === 6 && (
              <GoogleAd
                slot="9072666274"
                format="auto"
                fullWidthResponsive
                className={classes.ads}
              />
            )}
          </React.Fragment>
        ))}
      </div>
      <LoadMore hasMore={hasMore} loading={loading} loadMore={loadMore} />
    </div>
  );
}

type CommentListProps = {
  isEmbed?: boolean;
  loading: boolean;
  type?: string;
  user?: Show;
  record?: Episode;
  commentCount: number;
  parentComment?: Comment;
  comments: Comment[];
  hasMore: boolean;
  hideAds?: boolean;
  loadMore: () => Promise<unknown>;
};

export default CommentList;
