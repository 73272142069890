import React from 'react';
import { Route } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import { makeStyles } from '@material-ui/styles';
import { Typography, Link, Container, Paper, Theme } from '@material-ui/core';
import Logo from './Logo';
import GoogleAd from './GoogleAd';

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: "100%",
    flexDirection: 'column',
    height: '100%',
    minHeight: 300,
  },
  logo: {
    width: 36,
    height: 36,
    marginBottom: theme.spacing(2),
  },
  title: {
    fontWeight: 'bold',
    marginBottom: theme.spacing(2),
  },
  paper: {
    padding: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  content: {
    // marginBottom: theme.spacing(2),
  },
  ads: {},
}));

function NotFound(): JSX.Element {
  const classes = useStyles();

  return (
    <Route
      render={({ staticContext }) => {
        if (staticContext) {
          staticContext.statusCode = 404;
        }

        return (
          <div className={classes.container}>
            <a href="https://firstory.me">
              <Logo className={classes.logo} />
            </a>
            <Typography
              variant="h5"
              color="textPrimary"
              className={classes.title}
            >
              <FormattedMessage id="notFound.title" />
            </Typography>
            <Container maxWidth="sm">
              <Paper variant="outlined" className={classes.paper}>
                <Typography
                  variant="h5"
                  color="textPrimary"
                  className={classes.title}
                >
                  <FormattedMessage id="general.learnMore" />
                </Typography>
                <Typography className={classes.content}>
                  <FormattedMessage
                    id="notFound.audience"
                    values={{
                      link: (chunk: string) => (
                        <Link color="secondary" href="https://open.firstory.me">
                          {chunk}
                        </Link>
                      ),
                    }}
                  />
                  <br />
                  <FormattedMessage
                    id="notFound.podcaster"
                    values={{
                      link: (chunk: string) => (
                        <Link
                          color="secondary"
                          href="https://studio.firstory.me"
                        >
                          {chunk}
                        </Link>
                      ),
                    }}
                  />
                </Typography>
              </Paper>
              <GoogleAd format="auto" slot="4223648361" fullWidthResponsive />
            </Container>
          </div>
        );
      }}
    />
  );
}

export default NotFound;
