import { Button, Paper, Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';

const useStyles = makeStyles((theme: Theme) => ({
  me: {
    marginBottom: theme.spacing(1),
  },
  button: {
    width: '100%',
    height: 48,
    borderRadius: 1,
  },
}));

function ManageSection(): JSX.Element {
  const classes = useStyles();

  return (
    <Paper variant="outlined" className={classes.me}>
      <Button
        color="primary"
        component={Link}
        to="/donation"
        className={classes.button}
      >
        <FormattedMessage id="donation.manage" />
      </Button>
    </Paper>
  );
}

export default ManageSection;
