import React from 'react';
import PublicIcon from '@material-ui/icons/Public';
import Logo from '../Logo';
import { Define } from '../../define';

const distributionMap = {
  [Define.Distribution.Platform.apple]:
    '/assets/platforms/apple-podcasts-small.png',
  [Define.Distribution.Platform.google]:
    '/assets/platforms/google-podcasts.svg',
  [Define.Distribution.Platform.pocketcast]:
    '/assets/platforms/pocketcast-small.png',
  [Define.Distribution.Platform.spotify]: '/assets/platforms/spotify.svg',
  [Define.Distribution.Platform.kkbox]: '/assets/platforms/kkbox.png',
  [Define.Distribution.Platform.soundon]: '/assets/platforms/soundon-small.png',
  [Define.Distribution.Platform.overcast]: '/assets/platforms/overcast.svg',
  [Define.Distribution.Platform.castro]: '/assets/platforms/castro.svg',
  [Define.Distribution.Platform.amazon]: '/assets/platforms/amazon-music.png',
  [Define.Distribution.Platform.breaker]: '/assets/platforms/breaker.svg',
  [Define.Distribution.Platform.playerfm]: '/assets/platforms/playerfm.svg',
  [Define.Distribution.Platform.stitcher]: '/assets/platforms/stitcher.svg',
  [Define.Distribution.Platform.castbox]: '/assets/platforms/castbox.svg',
  [Define.Distribution.Platform.podcastaddict]:
    '/assets/platforms/podcastaddict.svg',
  [Define.Distribution.Platform.radiopublic]:
    '/assets/platforms/radio-public.svg',
  [Define.Distribution.Platform.noice]: '/assets/platforms/noice.png',
} as const;

const externalLinkMap = {
  [Define.Show.ExternalLink.facebook]: '/assets/platforms/facebook.svg',
  [Define.Show.ExternalLink.instagram]: '/assets/platforms/instagram.svg',
  [Define.Show.ExternalLink.twitter]: '/assets/platforms/twitter.svg',
  [Define.Show.ExternalLink.youtube]: '/assets/platforms/youtube.svg',
  [Define.Show.ExternalLink.linkedIn]: '/assets/platforms/linkedin.svg',
  [Define.Show.ExternalLink.medium]: '/assets/platforms/medium.svg',
  [Define.Show.ExternalLink.discord]: '/assets/platforms/discord-mark-blue.svg',
} as const;

const rssSrc = '/assets/platforms/rss.svg';

function PlatformIcon({
  type,
  className,
  style,
  size,
}: PlatformIconProps): JSX.Element | null {
  const sizeStyle = {
    width: size,
    height: size,
  };

  if (type?.variant === 'firstory') {
    return <Logo className={className} style={{ ...style, ...sizeStyle }} />;
  }

  const src = getIconSrc(type);

  if (!src) {
    return (
      <PublicIcon className={className} style={{ ...style, ...sizeStyle }} />
    );
  }

  return (
    <img
      src={src}
      alt="icon"
      className={className}
      style={{ ...style, ...sizeStyle }}
    />
  );
}

function getIconSrc(type?: PlatformIconType): string | null {
  if (!type) {
    return null;
  }

  if (type.variant === 'rss') {
    return rssSrc;
  }

  if (type.variant === 'distribution') {
    return distributionMap[type.type];
  }

  if (type.variant === 'external') {
    return externalLinkMap[type.type];
  }

  return null;
}

export type PlatformIconType =
  | {
      variant: 'distribution';
      type: Define.Distribution.Platform.Type;
    }
  | {
      variant: 'external';
      type: Define.Show.ExternalLink.Type;
    }
  | {
      variant: 'firstory';
      type: undefined;
    }
  | {
      variant: 'rss';
      type: undefined;
    };

export type PlatformIconProps = {
  type?: PlatformIconType;
  className?: string;
  size?: number;
  style?: React.CSSProperties;
};

export default PlatformIcon;
