import { Define } from '../define';

export type Money = {
  amount: number;
  currency: 'TWD' | 'USD';
};

export type Interval = {
  amount: number;
  unit: 'y' | 'm';
};

export type Price = {
  money: Money;
  interval?: Interval | null;
};

export type Plan = {
  id: string;
  price: Price;
};

export namespace TierStatus {
  export const active = 1;
  export const pause = 2;
  export const archived = 3;
  export type Type = typeof active | typeof pause | typeof archived;
  export const list = [active, pause, archived];
}

export type Tier = {
  id: string;
  status: TierStatus.Type;
  title: string;
  description: string;
  hiddenContent?: string;
  plans: Plan[];
};

export type CustomerStatus = {
  hasTrialed: boolean;
} | null;

export type TrialInterval = {
  amount: number;
  unit: 'd';
} | null;

export type ShowPaymentProvider = {
  provider: Define.Payment.Provider.Type;
  providerId: string;
  status: Define.Payment.MerchantProviderStatus.Type;
};

export type ShowMembership = {
  id: string;
  active: boolean;
  description: string;
  donationEnabled: boolean;
  subscriptionEnabled: boolean;
  donationTiers: Tier[];
  subscriptionTiers: Tier[];
  currentSubscription?: Subscription | null;
  paymentProviders: ShowPaymentProvider[];
  trialInterval?: TrialInterval;
  customerStatus?: CustomerStatus;
};

export type DiscordGuild = {
  id: string;
  name: string;
  icon?: string;
};

export type ShowDiscord = {
  showId: string;
  guild: DiscordGuild;
};

export namespace CheckoutStatus {
  export const init = 0;
  export const pending = 1;
  export const success = 2;
  export const active = 3;
  export const failed = 4;
  export const canceling = 5;
  export const canceled = 6;
  export type Type =
    | typeof init
    | typeof pending
    | typeof success
    | typeof active
    | typeof failed
    | typeof canceling
    | typeof canceled;
}

export namespace SubscriptionStatus {
  export const pending = 0;
  export const active = 1;
  export const paused = 2;
  export const failed = 3;
  export const canceling = 4;
  export const canceled = 5;
  export const trialing = 6;
  export const list = [
    pending,
    active,
    paused,
    failed,
    canceling,
    canceled,
    trialing,
  ] as const;
  export type Type =
    | typeof pending
    | typeof active
    | typeof paused
    | typeof failed
    | typeof canceling
    | typeof canceled
    | typeof trialing;
}

export namespace DonationStatus {
  export const pending = 0;
  export const success = 1;
  export const failed = 2;
  export const refunded = 3;
  export const list = [pending, success, failed, refunded] as const;
  export type Type =
    | typeof pending
    | typeof success
    | typeof failed
    | typeof refunded;
}

export type Subscription = {
  id: string;
  createdAt: string;
  nextPaymentDate: string | null;
  name: string;
  message: string;
  status: SubscriptionStatus.Type;
  show: {
    id: string;
    showId: string;
    hasResourceEntitlement: boolean;
    discord?: ShowDiscord | null;
  };
  tier: Tier;
  plan: Plan;
  paymentMethod?: PaymentMethod | null;
};

export type Donation = {
  id: string;
  createdAt: string;
  status: DonationStatus.Type;
  name: string;
  message: string;
  show: {
    id: string;
    showId: string;
  };
  tier: Tier;
  plan: Plan;
};

export type Audience = {
  id: string;
  hasLinkSpotify: boolean;
  spotify?: AudienceSpotify | null;
  discord?: AudienceDiscord | null;
};

export type AudienceSpotify = {
  id: string;
  name: string | null;
  imageUrl: string | null;
  url: string | null;
};

export type AudienceDiscord = {
  id: string;
  username: string;
  discriminator: string;
  globalName?: string | null;
  avatar?: string | null;
};

export namespace ResourceType {
  export const episode = 0;
  export const post = 0;
  export const list = [episode, post] as const;
  export type Type = typeof episode | typeof post;
}

export namespace EntitlementType {
  export const donation = 1;
  export const subscription = 2;
  export const list = [donation, subscription] as const;
  export type Type = typeof donation | typeof subscription;
}

export type Entitlement = {
  type: EntitlementType.Type;
  tier: {
    id: string;
    title: string;
  } | null;
};

export type Resource = {
  showId: string;
  resourceType: ResourceType.Type;
  resourceId: string;
  entitlements: Entitlement[];
};

export namespace CardType {
  export const unknown = -1;
  export const visa = 1;
  export const mastercard = 2;
  export const jcb = 3;
  export const unionpay = 4;
  export const amex = 5;
  export type Type =
    | typeof unknown
    | typeof visa
    | typeof mastercard
    | typeof jcb
    | typeof unionpay
    | typeof amex;
}

export type PaymentMethod = {
  id: string;
  cardFirst6: string;
  cardLast4: string;
  cardExpDate: string;
  cardType: CardType.Type;
  purchaseOrderCount: number;
};

export function getMaskedCardNumber(paymentMethod: PaymentMethod) {
  const { cardFirst6, cardLast4 } = paymentMethod;

  return `${cardFirst6.substring(0, 4)} ${cardFirst6.substring(
    4,
    6,
  )}** **** ${cardLast4}`;
}

export function getCardExpDate(paymentMethod: PaymentMethod) {
  const { cardExpDate } = paymentMethod;
  return `${cardExpDate.substring(0, 4)} / ${cardExpDate.substring(4, 6)}`;
}

export function isSubscriptionActiveLike(subscription?: Subscription | null) {
  const status = subscription?.status;
  return (
    status === SubscriptionStatus.active ||
    status === SubscriptionStatus.trialing
  );
}
