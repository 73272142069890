import React from 'react';
import { useIntl, FormattedMessage } from 'react-intl';
import { Redirect } from 'react-router';
import { makeStyles } from '@material-ui/styles';
import Button from '@material-ui/core/Button';
import CenterLoading from '../../components/CenterLoading';
import { useSearchShow, useSearchEpisode } from '../../gql/hooks/search';
import { SearchType } from '../../const/search';
import SearchResultGrid from './SearchResultGrid';

const useStyles = makeStyles(theme => ({
  container: {
    overflow: 'auto',
    padding: theme.spacing(2),
    paddingBottom: theme.spacing(8),
    '@media (max-width: 500px)': {
      padding: 0,
      paddingBottom: theme.spacing(6),
    },
  },
  buttonContainer: {
    padding: theme.spacing(2),
    display: 'flex',
    justifyContent: 'center',
  },
}));

function SearchResult({
  searchType,
  queryString,
  loading,
  results,
  isFetchingAfter,
  hasMore,
  loadMore,
}) {
  const intl = useIntl();
  const classes = useStyles();

  const titleMap = {
    [SearchType.SHOW]: 'Podcast',
    [SearchType.EPISODE]: intl.formatMessage({ id: 'podcast.episodes' }),
    [SearchType.TAG]: intl.formatMessage({ id: 'podcast.tags' }),
  };
  const title = titleMap[searchType];

  if (loading) {
    return <CenterLoading />;
  }

  return (
    <div className={classes.container}>
      <SearchResultGrid
        queryString={queryString}
        title={title}
        searchType={searchType}
        results={results}
      />
      {hasMore && (
        <div>
          {isFetchingAfter && <CenterLoading />}
          <div className={classes.buttonContainer}>
            <Button variant="outlined" size="large" onClick={loadMore}>
              <FormattedMessage id="general.loadMore" />
            </Button>
          </div>
        </div>
      )}
    </div>
  );
}

function SearchShowResult({ queryString }) {
  const { data, ...args } = useSearchShow({ queryString });

  const results = data && data.shows ? data.shows : [];

  return (
    <SearchResult searchType={SearchType.SHOW} results={results} {...args} />
  );
}

function SearchEpisodeResult({ queryString }) {
  const { data, ...args } = useSearchEpisode({ queryString });

  const results = data && data.episodes ? data.episodes : [];

  return (
    <SearchResult searchType={SearchType.EPISODE} results={results} {...args} />
  );
}

// TODO
// function SearchTagResult({ queryString }) {
//   const { data, ...args } = useSearchEpisode({ tags: [queryString] });

//   const results = data && data.episodes ? data.episodes : [];

//   return (
//     <SearchResult searchType={SearchType.EPISODE} results={results} {...args} />
//   );
// }

function SearchResultContainer({ searchType, queryString }) {
  if (searchType === SearchType.SHOW) {
    return <SearchShowResult queryString={queryString} />;
  }
  if (searchType === SearchType.EPISODE) {
    return <SearchEpisodeResult queryString={queryString} />;
  }
  if (searchType === SearchType.TAG) {
    return (
      <Redirect to={`/search/result/${encodeURIComponent(queryString)}`} />
    );
    // TODO
    // return <SearchTagResult queryString={queryString} />;
  }

  return null;
}

export default SearchResultContainer;
