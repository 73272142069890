import React from 'react';
import { useParams, useLocation, useRouteMatch } from 'react-router-dom';
import { makeStyles } from '@material-ui/styles';
import { Theme } from '@material-ui/core';
import Layout from '../WebPlayer/Layout';
import { useWebPlayer } from '../WebPlayer/context';
import EpisodeHeader from '../UserSite/header/EpisodeHeader';
import GoogleAd from '../../components/GoogleAd';
import NotFound from '../../components/NotFound';
import { darkTheme, useCustomTheme } from '../../theme';
import { useGetEpisodeDetail } from '../../gql/hooks/episode/episode';
import { useGetEpisodeComments } from '../../gql/hooks/comment';
import { useAuth } from '../Auth/context';
import EpisodeContent from './EpisodeContent';

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    display: 'flex',
  },
  content: {
    flex: 1,
  },
  sidebar: {
    padding: theme.spacing(4),
    '@media (max-width: 700px)': {
      display: 'none',
    },
  },
  ads: {
    // background: 'steelblue',
    display: 'block',
    height: 250,
    width: 300,
    marginBottom: theme.spacing(4),
  },
}));

function getLink({ data, isWebPlayer }) {
  if (!data || !data.feed) {
    return null;
  }

  return isWebPlayer ? `/story/${data.feed.id}` : `/episodes/${data.feed.id}`;
}

function Episode() {
  const classes = useStyles();

  const auth = useAuth();
  const { episodeId } = useParams<{ episodeId: string }>();
  const { isWebPlayer } = useWebPlayer();
  const isEmbed = !!useRouteMatch('/embed');
  const location = useLocation();
  const queryString = React.useMemo(() => {
    return new URLSearchParams(location.search);
  }, [location]);

  const { loading, data } = useGetEpisodeDetail(episodeId);
  const {
    loading: commentsLoading,
    data: commentsData,
    hasMore,
    loadMore,
  } = useGetEpisodeComments(episodeId);

  const episode = data?.playerEpisodeFindOne;

  const isCommentMode = queryString.get('m') === 'comment';
  const showDescription = queryString.get('description') === '1';
  const showComments = queryString.get('comments') === '1';

  const customTheme = useCustomTheme({
    websiteSetting: episode?.show.websiteSetting,
  });

  const hideAds = episode?.show.canRemoveFlinkAds;

  if (loading) {
    return <Layout loading theme={isEmbed ? customTheme : darkTheme} />;
  }

  if (!episode || episode.show.deleted) {
    return (
      <Layout hideController theme={isEmbed ? customTheme : darkTheme}>
        <NotFound />
      </Layout>
    );
  }

  if (!episode) {
    return <NotFound />;
  }

  const link = getLink({ data, isWebPlayer });

  const comments = commentsData?.playerCommentFind || [];

  const authMe = auth.getMe();

  return (
    <React.Fragment>
      <EpisodeHeader isWebPlayer isEmbed={isEmbed} episode={episode} />
      <Layout
        logoLink={link}
        defaultTitle={episode.title}
        defaultSubtitle={episode.show.name}
        defaultImageUrl={episode.imageUrl || episode.show.avatar}
        defaultEpisode={episode}
        theme={isEmbed ? customTheme : darkTheme}
        hideController={isCommentMode}
        hideContent={isEmbed && !showDescription && !showComments}
      >
        <div className={classes.container}>
          <div className={classes.content}>
            <EpisodeContent
              isEmbed={isEmbed}
              isCommentMode={isCommentMode}
              showDescription={showDescription}
              showComments={showComments}
              showFlink={episode.canViewFlink}
              storyLoading={loading}
              commentsLoading={commentsLoading}
              me={authMe ? { id: authMe.showId } : null}
              episode={episode}
              comments={comments}
              hasMore={hasMore}
              loadMore={loadMore}
              hideAds={hideAds}
            />
          </div>
          {!hideAds && (
            <div className={classes.sidebar}>
              <GoogleAd
                slot="7018166812"
                format="auto"
                className={classes.ads}
              />
              <GoogleAd
                slot="7018166812"
                format="auto"
                className={classes.ads}
              />
              <GoogleAd
                slot="7018166812"
                format="auto"
                className={classes.ads}
              />
              <GoogleAd
                slot="7018166812"
                format="auto"
                className={classes.ads}
              />
              <GoogleAd
                slot="7018166812"
                format="auto"
                className={classes.ads}
              />
            </div>
          )}
        </div>
      </Layout>
    </React.Fragment>
  );
}

export default Episode;
