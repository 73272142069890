import React from 'react';
import { ClassNameMap, makeStyles } from '@material-ui/styles';
import {
  IconButton,
  InputAdornment,
  InputProps,
  TextField,
  TextFieldProps,
  Theme,
} from '@material-ui/core';
import LinkIcon from '@material-ui/icons/Link';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { useAlert } from './Alert';

const useStyles = makeStyles((theme: Theme) => ({
  input: {
    color: theme.palette.text.primary,
  },
}));

function CopyTextField({
  className,
  classes = {},
  InputProps,
  value,
  disableCopy,
  onCopy,
  textFieldProps,
}: CopyTextFieldProps): JSX.Element {
  const { showSnackbarMessage } = useAlert();
  const cls = useStyles();
  const handleCopy = React.useCallback(() => {
    showSnackbarMessage('general.copied', {
      variant: 'success',
      vertical: 'top',
      horizontal: 'center',
    });
    if (onCopy) {
      onCopy();
    }
  }, [showSnackbarMessage, onCopy]);

  const isSmall = textFieldProps && textFieldProps.margin === 'dense';

  return (
    <TextField
      className={className}
      classes={classes}
      variant="outlined"
      value={value}
      {...textFieldProps}
      InputProps={{
        ...InputProps,
        classes: { input: cls.input },
        endAdornment: !disableCopy && (
          <InputAdornment position="end">
            <CopyToClipboard text={value} onCopy={handleCopy}>
              <IconButton
                aria-label="copy link"
                onClick={() => {
                  // eslint-disable-line @typescript-eslint/no-empty-function
                }}
                size={isSmall ? 'small' : 'medium'}
              >
                <LinkIcon />
              </IconButton>
            </CopyToClipboard>
          </InputAdornment>
        ),
      }}
    />
  );
}

type CopyTextFieldProps = {
  className?: string;
  classes?: ClassNameMap;
  InputProps?: InputProps;
  value: string;
  disableCopy?: boolean;
  onCopy?: () => void;
  textFieldProps?: TextFieldProps;
};

export default CopyTextField;
