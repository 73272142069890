import React from 'react';
import { Redirect, useParams } from 'react-router';
import CenterLoading from '../../../components/CenterLoading';
import NotFound from '../../../components/NotFound';
import { DonationStatus } from '../../../entities/membership';
import { useGetDonationDetail } from '../../../gql/hooks/membership/donation';
import useQueryString from '../../../hooks/useQueryString';

function DonationResult(): JSX.Element {
  const { donationId } = useParams<{ donationId: string }>();
  const query = useQueryString();

  const { loading, data } = useGetDonationDetail(donationId);

  if (loading) {
    return <CenterLoading />;
  }

  if (!data?.donation) {
    return <NotFound />;
  }

  const tapPayStatus = query.get('status');
  const tapPaySuccess = tapPayStatus === '0';
  const isSuccess =
    tapPaySuccess || data.donation.status === DonationStatus.success;

  return (
    <Redirect
      to={`/join/${data.donation.show.showId}?status=${isSuccess ? '0' : '1'}`}
    />
  );
}

export default DonationResult;
