import { gql } from '@apollo/client';

export const GetVoiceMailBoxes = gql`
  query GetVoiceMailBoxes(
    $showId: ID!
    $active: Boolean
    $first: Int
    $after: String
  ) {
    playerVoicemailBoxFind(
      showId: $showId
      active: $active
      take: $first
      after: $after
    ) {
      id
      title
      description
      active
      createdAt
      show {
        id
        name
        avatar
      }
    }
  }
`;

export const GetVoiceMailBoxDetail = gql`
  query GetVoiceMailBoxDetail($voicemailBoxId: ID!) {
    playerVoicemailBoxFindOne(voicemailBoxId: $voicemailBoxId) {
      id
      title
      description
      active
      createdAt
      show {
        id
        name
        author
        avatar
      }
    }
  }
`;

export const CreateVoiceMailMessage = gql`
  mutation CreateVoiceMailMessage(
    $commentTarget: CommentTargetInput!
    $name: String
    $message: String
    $audio: Upload
    $duration: Int
  ) {
    playerCommentCreate(
      type: 2
      commentTarget: $commentTarget
      name: $name
      message: $message
      audio: $audio
      duration: $duration
    ) {
      id
    }
  }
`;
