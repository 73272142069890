import React from 'react';
import { useLocation, useHistory } from 'react-router-dom';

function useRouterPagination(): [number, (page: number) => void] {
  const location = useLocation();
  const history = useHistory();

  const routerPage = React.useMemo<number>(() => {
    const query = new URLSearchParams(location.search);
    const p = query.get('page') || '1';
    const page = parseInt(p);
    if (isNaN(page)) {
      return 0;
    }
    return page - 1;
  }, [location]);

  const setRouterPage = React.useCallback(
    page => {
      const query = new URLSearchParams(location.search);
      if (page === 0) {
        query.delete('page');
      } else {
        query.set('page', page + 1);
      }
      history.push({
        ...location,
        search: query.toString(),
      });
    },
    [history, location],
  );

  return [routerPage, setRouterPage];
}

function usePagination<DataType>({
  data = [],
  pageSize = 20,
  hasMore,
  loadMore,
  loadBefore,
  hasRouterParams,
  dataHeadIndex = 0,
  dataEndIndex = 0,
}: UseTablePaginationArgs<DataType>): UseTablePaginationReturnValue<DataType> {
  const [routerPage, setRouterPage] = useRouterPagination();
  const [page, setPage] = React.useState<number>(routerPage);

  const slice = React.useMemo(() => {
    const empty = [...new Array(dataHeadIndex)].map(() => null);
    const arr = [...empty, ...data];
    return arr.slice(page * pageSize, (page + 1) * pageSize);
  }, [data, page, pageSize, dataHeadIndex]);

  const handleSetPage = React.useCallback(
    p => {
      if (p > page && hasMore && dataEndIndex <= (page + 1) * pageSize) {
        if (loadMore) {
          loadMore();
        }
      }
      setPage(p);
    },
    [page, pageSize, hasMore, loadMore, dataEndIndex],
  );

  React.useEffect(() => {
    if (page * pageSize <= dataHeadIndex && dataHeadIndex > 0 && loadBefore) {
      loadBefore();
    }
  }, [page, pageSize, dataHeadIndex, loadBefore]);

  React.useEffect(() => {
    if (hasRouterParams) {
      if (page !== routerPage) {
        setRouterPage(page);
      }
    }
  }, [hasRouterParams, page, routerPage, setRouterPage]);

  return {
    page,
    setPage: handleSetPage,
    data: slice,
  };
}

type UseTablePaginationArgs<DataType> = {
  data: DataType[];
  pageSize?: number;
  hasMore?: boolean;
  loadMore?: () => void;
  loadBefore?: () => void;
  hasRouterParams?: boolean;
  dataHeadIndex?: number;
  dataEndIndex?: number;
};

type UseTablePaginationReturnValue<DataType> = {
  page: number;
  setPage(page: number): void;
  data: (DataType | null)[];
};

export default usePagination;
export { useRouterPagination };
