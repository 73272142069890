import React from 'react';
import { makeStyles } from '@material-ui/styles';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Link,
} from '@material-ui/core';
import { FormattedMessage } from 'react-intl';

const useStyles = makeStyles({
  link: {},
});

function PaymentResultModal({
  open,
  success,
  onClose,
}: PaymentResultModalProps): JSX.Element {
  const classes = useStyles();

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>
        <FormattedMessage
          id={success ? 'donation.payment.success' : 'donation.payment.failed'}
        />
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          {success ? (
            <FormattedMessage id="donation.payment.success.description" />
          ) : (
            <FormattedMessage
              id="donation.payment.failed.description"
              values={{
                ol: (chunk: React.ReactNode) => <ol>{chunk}</ol>,
                li: (chunk: React.ReactNode) => <li>{chunk}</li>,
                link: (chunk: string) => (
                  <Link
                    target="_blank"
                    href="https://firstory.crisp.help/zh-tw/article/5pu05ob5oiw5yiq6zmk5lh55so5y2h-18yw7ws/"
                    className={classes.link}
                  >
                    {chunk}
                  </Link>
                ),
              }}
            />
          )}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          size="large"
          color="primary"
          onClick={onClose}
        >
          <FormattedMessage id="general.confirm" />
        </Button>
      </DialogActions>
    </Dialog>
  );
}

type PaymentResultModalProps = {
  open: boolean;
  success: boolean;
  onClose(): void;
};

export default PaymentResultModal;
