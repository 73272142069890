import React from 'react';
import { FormattedMessage } from 'react-intl';
import { makeStyles } from '@material-ui/styles';
import {
  Theme,
  Snackbar as MuiSnackbar,
  SnackbarContent,
  SnackbarOrigin,
} from '@material-ui/core';
import { green } from '@material-ui/core/colors';
import { MessageId } from '../../i18n';

const useStyles = makeStyles((theme: Theme) => ({
  success: {
    backgroundColor: green[600],
  },
  error: {
    backgroundColor: theme.palette.error.dark,
  },
}));

function Snackbar({
  open,
  onClose,
  variant = 'info',
  messageId,
  vertical = 'bottom',
  horizontal = 'left',
}: SnackbarProps): JSX.Element {
  const classes = useStyles();

  return (
    <MuiSnackbar
      anchorOrigin={{
        vertical,
        horizontal,
      }}
      open={open}
      autoHideDuration={3000}
      onClose={onClose}
    >
      <SnackbarContent
        className={classes[variant]}
        aria-describedby="snack-bar-message"
        message={
          <span id="snack-bar-message">
            {messageId && <FormattedMessage id={messageId} />}
          </span>
        }
      />
    </MuiSnackbar>
  );
}

type SnackbarVariant = 'info' | 'success' | 'error';

export type SnackbarProps = {
  open: boolean;
  onClose(): void;
  variant?: SnackbarVariant;
  messageId?: MessageId;
  vertical?: SnackbarOrigin['vertical'];
  horizontal?: SnackbarOrigin['horizontal'];
};

export default Snackbar;
