import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Link, Redirect } from 'react-router-dom';
import { Button, makeStyles, Theme } from '@material-ui/core';
import { ArrowBack } from '@material-ui/icons';
import { useGetAudienceDiscord } from '../../../gql/hooks/membership/audience';
import CenterLoading from '../../../components/CenterLoading';
import DiscordConnection from './Connection';
import DiscordServerList from './ServerList';

const useStyles = makeStyles((theme: Theme) => ({
  backRow: {
    marginBottom: theme.spacing(2),
  },
}));

function DiscordSetting(): JSX.Element {
  const classes = useStyles();

  const { loading, data } = useGetAudienceDiscord();

  if (loading) {
    return <CenterLoading />;
  }

  if (!data?.audience) {
    return <Redirect to="/donation/apps" />;
  }

  const discord = data.audience.discord;

  return (
    <div>
      <div className={classes.backRow}>
        <Button startIcon={<ArrowBack />} component={Link} to="/donation/apps">
          <FormattedMessage id="donation.manage" />
        </Button>
      </div>
      <DiscordConnection discord={discord} />
      {discord ? <DiscordServerList /> : null}
    </div>
  );
}

export default DiscordSetting;
