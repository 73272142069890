import React from 'react';
import { Redirect, useParams } from 'react-router';
import { ThemeProvider } from '@material-ui/core';
import CenterLoading from '../../../../components/CenterLoading';
import NotFound from '../../../../components/NotFound';
import { useGetSubscriptionDetail } from '../../../../gql/hooks/membership/subscription';
import { lightTheme } from '../../../../theme';
import { SubscriptionStatus } from '../../../../entities/membership';
import useQueryString from '../../../../hooks/useQueryString';
import SubscriptionResultContent from './Content';

function SubscriptionResult(): JSX.Element {
  const { subscriptionId } = useParams<{ subscriptionId: string }>();
  const query = useQueryString();

  const { loading, data } = useGetSubscriptionDetail(subscriptionId);

  if (loading) {
    return <CenterLoading />;
  }

  if (!data?.subscription) {
    return <NotFound />;
  }

  const tapPayStatus = query.get('status');
  const tapPaySuccess = tapPayStatus === '0';
  const success = data.subscription.status === SubscriptionStatus.active;

  if (!tapPaySuccess && !success) {
    return <Redirect to={`/join/${data.subscription.show.showId}?status=1`} />;
  }

  return <SubscriptionResultContent subscription={data.subscription} />;
}

function SubscriptionResultContainer(): JSX.Element {
  return (
    <ThemeProvider theme={lightTheme}>
      <SubscriptionResult />
    </ThemeProvider>
  );
}

export default SubscriptionResultContainer;
