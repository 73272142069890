import React from 'react';
import { Link } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import { makeStyles } from '@material-ui/styles';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import CenterLoading from '../../components/CenterLoading';
import SearchItem from './SearchItem';

const useStyles = makeStyles(theme => ({
  container: {
    padding: theme.spacing(2),
    paddingRight: 0,
  },
  loadingContainer: {
    height: 300,
  },
  sectionHeader: {
    display: 'flex',
    width: '100%',
    paddingRight: theme.spacing(2),
  },
  sectionTitle: {
    fontWeight: 'bold',
    flex: 1,
  },
  grid: {
    display: 'flex',
    flexWrap: 'wrap',
    position: 'relative',
  },
  gridItem: {
    width: '25%',
    maxWidth: 200,
    paddingRight: theme.spacing(2),
    '@media (max-width: 500px)': {
      width: '33.3%',
    },
  },
}));

function SearchResultGrid({
  loading,
  title,
  searchType,
  queryString,
  hasViewMore,
  results,
}) {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <div className={classes.sectionHeader}>
        <Typography variant="h4" className={classes.sectionTitle}>
          {title}
        </Typography>
        {hasViewMore && (
          <Button component={Link} to={`/search/${searchType}/${queryString}`}>
            <FormattedMessage id="general.viewMore" />
          </Button>
        )}
      </div>
      {loading ? (
        <div className={classes.loadingContainer}>
          <CenterLoading />
        </div>
      ) : (
        <div className={classes.grid}>
          {results.map(r => (
            <div key={r.id} className={classes.gridItem}>
              <SearchItem searchType={searchType} item={r} />
            </div>
          ))}
        </div>
      )}
    </div>
  );
}

export default SearchResultGrid;
