import React from 'react';
import useAnimationFrame from '../../hooks/useAnimationFrame';
import AudioAdapter from './AudioAdapter';

function createAudioElement() {
  if (typeof document === 'undefined') {
    return { audio: null, adapter: null };
  }

  const audio = document.createElement('audio');
  const adapter = new AudioAdapter(audio);
  adapter.ready();

  return { audio, adapter };
}

function setAudioSource(audio: HTMLAudioElement, url: string) {
  audio.innerHTML = '';
  const s1 = document.createElement('source');
  const s2 = document.createElement('source');
  s1.src = url;
  s2.src = url;
  s2.type = 'audio/mp4';
  audio.append(s1);
  audio.append(s2);
}

function useAudio({
  defaultUrl,
  onEnded,
  onLoadedMetadata,
}: UseAudioArgs): UseAudioValues {
  const [url, _setUrl] = React.useState<string | null>(defaultUrl || null);
  const [isLoading, setIsLoading] = React.useState(false);
  const [isPlaying, setIsPlaying] = React.useState(false);
  const [speed, setSpeed] = React.useState(1);
  const [durationMs, setDurationMs] = React.useState(0);
  const currentTimeMsRef = React.useRef(0);

  const [audio] = React.useState(() => {
    const { audio } = createAudioElement();
    if (audio && defaultUrl) {
      setAudioSource(audio, defaultUrl);
    }
    return audio;
  });

  const play = React.useCallback(() => {
    setIsPlaying(true);
    audio?.play().catch(error => {
      setIsPlaying(false);
      console.error('Failed to play audio:', error);
    });
  }, [audio]);

  const pause = React.useCallback(() => {
    setIsPlaying(false);
    audio?.pause();
  }, [audio]);

  const toggle = React.useCallback(() => {
    if (isPlaying) {
      pause();
    } else {
      play();
    }
    setIsPlaying(!isPlaying);
  }, [isPlaying, play, pause]);

  const setCurrentTime = React.useCallback(
    (ms: number | ((ms: number) => number)) => {
      const durationMs = audio ? audio.duration * 1000 : 0;
      const currentTimeMs = audio ? audio.currentTime * 1000 : 0;
      let msToSet = typeof ms === 'function' ? ms(currentTimeMs) : ms;

      if (msToSet < 0) {
        msToSet = 0;
      } else if (msToSet > durationMs) {
        msToSet = durationMs;
      }

      if (audio) {
        audio.currentTime = msToSet / 1000;
        currentTimeMsRef.current = msToSet;
      }
    },
    [audio],
  );

  const setUrl = React.useCallback(
    (newUrl: string) => {
      if (newUrl === url) {
        return;
      }

      _setUrl(newUrl);
      setIsLoading(true);
      if (audio) {
        setAudioSource(audio, newUrl);
      }
      audio?.load();

      if (isPlaying) {
        play();
      }
    },
    [audio, url, isPlaying, play],
  );

  React.useEffect(() => {
    function onLoadedMetadata() {
      const duration = audio?.duration || 0;
      const ms = Math.floor(duration * 1000);
      setDurationMs(ms);
      setIsLoading(false);
    }

    function onEnded() {
      setIsPlaying(false);
    }

    audio?.addEventListener('loadedmetadata', onLoadedMetadata);
    audio?.addEventListener('ended', onEnded);

    return () => {
      audio?.pause();
      audio?.removeEventListener('loadedmetadata', onLoadedMetadata);
      audio?.removeEventListener('ended', onEnded);
    };
  }, [audio]);

  React.useEffect(() => {
    if (audio) {
      audio.playbackRate = speed;
    }
  }, [audio, speed]);

  React.useEffect(() => {
    if (onLoadedMetadata) {
      onLoadedMetadata({ durationMs });
    }
  }, [onLoadedMetadata, durationMs]);

  React.useEffect(() => {
    if (onEnded) {
      audio?.addEventListener('ended', onEnded);

      return () => {
        audio?.removeEventListener('ended', onEnded);
      };
    }
  }, [audio, onEnded]);

  useAnimationFrame(() => {
    if (audio) {
      const currentTime = audio.currentTime;
      currentTimeMsRef.current = currentTime * 1000;
    }
  }, isPlaying);

  return {
    isLoading,
    isPlaying,
    durationMs,
    currentTimeMsRef,
    play,
    pause,
    toggle,
    setSpeed,
    setCurrentTime,
    setUrl,
  };
}

type UseAudioArgs = {
  defaultUrl?: string | null;
  onLoadedMetadata?: (args: { durationMs: number }) => unknown;
  onEnded?: () => unknown;
};

type UseAudioValues = {
  isLoading: boolean;
  isPlaying: boolean;
  durationMs: number;
  currentTimeMsRef: React.MutableRefObject<number>;
  play(): unknown;
  pause(): unknown;
  toggle(): unknown;
  setUrl(url: string): unknown;
  setSpeed(speed: number): unknown;
  setCurrentTime(ms: number | ((ms: number) => number)): unknown;
};

export default useAudio;
