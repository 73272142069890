import React from 'react';
import { useRouteMatch } from 'react-router';
import { ThemeProvider, makeStyles } from '@material-ui/styles';
import { Theme } from '@material-ui/core';
import Navbar from '../Navbar';
import Controller from '../../../components/Controller';
import { useWebPlayer } from '../context';
import { darkTheme } from '../../../theme';
import CenterLoading from '../../../components/CenterLoading';
import { PlayableData } from '../../../components/Player/type';

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    height: '100%',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    borderRadius: (props: StyleProps) => (props.isEmbed ? 10 : 0),
  },
  loadingContainer: {
    height: '100%',
    flex: 1,
    backgroundColor: props =>
      props.isEmbed
        ? theme.palette.background.paper
        : theme.palette.background.default,
  },
  top: {
    flex: 1,
    overflow: 'auto',
    display: 'flex',
    '@media (max-width: 900px)': {
      flexDirection: 'column',
    },
  },
  content: {
    flex: 1,
    overflow: 'auto',
    height: '100%',
    backgroundColor: props =>
      props.isEmbed
        ? theme.palette.background.paper
        : theme.palette.background.default,
  },
  '@global': {
    html: {
      height: props => (props.hideContent ? 'auto !important' : '100%'),
    },
  },
}));

function Layout({
  loading,
  isEmbed,
  logoLink,
  defaultTitle,
  defaultSubtitle,
  defaultImageUrl,
  defaultEpisode,
  children,
  hideController,
  hideContent,
  hideNavbar,
}: LayoutProps): JSX.Element {
  const isEmbedRoute = useRouteMatch('/embed');
  const isEmbedLayout = isEmbed || !!isEmbedRoute;
  const {
    nowPlaying,
    isLoading,
    isPlaying,
    currentTimeMsRef,
    toggle,
    play,
    setCurrentTime,
    setSpeed,
    setNowPlaying,
  } = useWebPlayer();
  const classes = useStyles({ isEmbed: isEmbedLayout, hideContent });

  const [slidingMs, setSlidingMs] = React.useState<number>(-1);

  const handlePlayPauseClick = React.useCallback(() => {
    if (nowPlaying) {
      toggle();
    } else if (defaultEpisode) {
      setNowPlaying(defaultEpisode);
      play();
    }
  }, [nowPlaying, toggle, defaultEpisode, setNowPlaying, play]);

  const handleSliderAfterChange = React.useCallback(
    (value: number) => {
      setCurrentTime(value);
      setSlidingMs(-1);
    },
    [setCurrentTime],
  );

  const forward = React.useCallback(() => {
    setCurrentTime(ms => ms + 10000);
  }, [setCurrentTime]);

  const backward = React.useCallback(() => {
    setCurrentTime(ms => ms - 10000);
  }, [setCurrentTime]);

  React.useEffect(() => {
    if (!nowPlaying && defaultEpisode) {
      setNowPlaying(defaultEpisode);
    }
  }, [nowPlaying, defaultEpisode, setNowPlaying]);

  const content = loading ? (
    <div className={classes.loadingContainer}>
      <CenterLoading />
    </div>
  ) : (
    <div id='layout-content' className={classes.content}>{children}</div>
  );

  return (
    <div className={classes.container}>
      {!isEmbedLayout && (
        <div className={classes.top}>
          {!hideNavbar && <Navbar />}
          {content}
        </div>
      )}
      {!hideController && (
        <Controller
          isEmbed={isEmbedLayout}
          logoLink={logoLink}
          defaultTitle={defaultTitle}
          defaultSubtitle={defaultSubtitle}
          defaultImageUrl={defaultImageUrl}
          nowPlaying={nowPlaying}
          isLoading={isLoading}
          isPlaying={isPlaying}
          slidingMs={slidingMs}
          currentTimeMsRef={currentTimeMsRef}
          onPlayPauseClick={handlePlayPauseClick}
          onForwardClick={forward}
          onBackwardClick={backward}
          onSpeedChange={setSpeed}
          onSliderChange={setSlidingMs}
          onSliderAfterChange={handleSliderAfterChange}
        />
      )}
      {isEmbedLayout && !hideContent ? content : null}
    </div>
  );
}

function LayoutWithTheme({
  theme = darkTheme,
  ...props
}: LayoutWithThemeProps): JSX.Element {
  return (
    <ThemeProvider theme={theme}>
      <Layout {...props} />
    </ThemeProvider>
  );
}

type StyleProps = {
  isEmbed?: boolean;
  hideContent?: boolean;
};

type LayoutProps = {
  loading?: boolean;
  isEmbed?: boolean;
  logoLink?: string | null;
  defaultTitle?: string;
  defaultSubtitle?: string;
  defaultImageUrl?: string | null;
  defaultEpisode?: PlayableData;
  children?: React.ReactNode;
  hideController?: boolean;
  hideContent?: boolean;
  hideNavbar?: boolean;
};

type LayoutWithThemeProps = LayoutProps & { theme?: Theme };

export default LayoutWithTheme;
