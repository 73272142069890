import React from 'react';
import { makeStyles } from '@material-ui/styles';
import { Distribution } from '../../entities/distribution';
import PlatformIcon from '../../components/icons/PlatformIcon';
import { Define } from '../../define';
import PlatformItem from './PlatformItem';

const useStyles = makeStyles(() => ({
  icon: {
    width: 28,
    height: 28,
  },
}));

const FlinkPlatforms = {
  [Define.Distribution.Platform.apple]: 'apple',
  [Define.Distribution.Platform.amazon]: 'amazon',
  [Define.Distribution.Platform.castbox]: 'castbox',
  [Define.Distribution.Platform.castro]: 'castro',
  [Define.Distribution.Platform.google]: 'google',
  [Define.Distribution.Platform.kkbox]: 'kkbox',
  [Define.Distribution.Platform.overcast]: 'overcast',
  [Define.Distribution.Platform.pocketcast]: 'pocketcasts',
  [Define.Distribution.Platform.podcastaddict]: 'podcastaddict',
  [Define.Distribution.Platform.radiopublic]: 'radioPublic',
  [Define.Distribution.Platform.spotify]: 'spotify',
  [Define.Distribution.Platform.stitcher]: 'stitcher',
} as const;

function DistributionItem({
  distribution,
  onClick,
}: DistributionItemProps): JSX.Element | null {
  const classes = useStyles();

  if (distribution.platformType === Define.Distribution.Platform.soundon) {
    return null;
  }

  if (
    distribution.status !== Define.Distribution.Status.active ||
    !distribution.platformUrl
  ) {
    return null;
  }

  return (
    <PlatformItem
      title={Define.Distribution.Platform.Title[distribution.platformType]}
      url={distribution.platformUrl}
      startIcon={
        <PlatformIcon
          className={classes.icon}
          type={{
            type: distribution.platformType,
            variant: 'distribution',
          }}
        />
      }
      onClick={() => onClick(FlinkPlatforms[distribution.platformType])}
    />
  );
}

type DistributionItemProps = {
  distribution: Distribution;
  onClick: (platform: string) => void;
};

export default DistributionItem;
