import config from '../../config';
import { loadScript } from './util';

export async function loadFbSdk() {
  if (window.scriptLoaded.fb) {
    return window.FB;
  }

  await loadScript('fb-sdk', 'https://connect.facebook.net/en_US/sdk.js');

  return new Promise(resolve => {
    window.fbAsyncInit = () => {
      window.FB.init({
        appId: config.fbAppId,
        autoLogAppEvents: true,
        xfbml: true,
        version: 'v4.0',
      });
      window.scriptLoaded.fb = true;
      resolve(window.FB);
    };
  });
}
