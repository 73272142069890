import React from 'react';
import { useRouteMatch } from 'react-router-dom';
import { makeStyles } from '@material-ui/styles';
import EpisodeList from '../WebPlayer/EpisodeList';
import { useWebPlayer } from '../WebPlayer/context';
import CenterLoading from '../../components/CenterLoading';
import { Playlist } from '../../entities/playlist';
import { Episode } from '../../entities/episode';
import PlaylistInfo from './PlaylistInfo';

const useStyles = makeStyles({
  container: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    overflow: 'auto',
  },
});

function PlaylistContent({
  loading,
  playlist,
  episodes,
  loadMore,
  hasMore,
}: PlaylistContentProps): JSX.Element {
  const { nowPlaying, isPlaying, play, toggle, setNowPlaying, isNowPlaying } =
    useWebPlayer();
  const classes = useStyles();
  const isEmbed = useRouteMatch('/embed');

  const handlePlayPauseClick = React.useCallback(
    (data: Episode) => {
      if (isNowPlaying(data)) {
        toggle();
      } else {
        setNowPlaying(data);
        play();
      }
    },
    [isNowPlaying, setNowPlaying, play, toggle],
  );

  if (loading) {
    return <CenterLoading />;
  }

  return (
    <div className={classes.container}>
      {!isEmbed && (
        <PlaylistInfo
          showId={playlist.show.id}
          title={playlist.title}
          subtitle={playlist.show.name}
          description={playlist.description}
          imageUrl={playlist.show.avatar}
          episodeCount={playlist.episodeCount}
          ugc
        />
      )}
      <EpisodeList
        type="PLAYLIST"
        loading={loading}
        episodes={episodes}
        hasMore={hasMore}
        loadMore={loadMore}
        isPlaying={isPlaying}
        nowPlaying={nowPlaying}
        onPlayPauseClick={handlePlayPauseClick}
      />
    </div>
  );
}

type PlaylistContentProps = {
  loading: boolean;
  playlist: Playlist;
  episodes: Episode[];
  loadMore(): unknown;
  hasMore: boolean;
};

export default PlaylistContent;
