import React from 'react';
import { useParams, useRouteMatch } from 'react-router-dom';
import Layout from '../WebPlayer/Layout';
import PlaylistHeader from '../UserSite/header/PlaylistHeader';
import NotFound from '../../components/NotFound';
import CenterLoading from '../../components/CenterLoading';
import {
  useGetPlaylistDetail,
  useGetPlaylistEpisodes,
} from '../../gql/hooks/playlist/detail';
import { darkTheme, useCustomTheme } from '../../theme';
import Content from './Content';

function Playlist() {
  const { playlistId } = useParams<{ playlistId: string }>();

  const { loading: playlistLoading, data: playlistData } =
    useGetPlaylistDetail(playlistId);

  const isTang =
    playlistData?.playerPlaylistFindOne.show.id === 'ckga7ibs77fgl0875bxwgl0y0';
  const isTangPrivatePlaylist = [
    'ckuqgawjcpmx90873ouf4ags1',
    'cl8xv2w0a0b6201s6fbl64qwl',
  ].includes(playlistId);

  const { data, hasMore, loadMore } = useGetPlaylistEpisodes({
    playlistId,
    isDraft: isTang && isTangPrivatePlaylist,
    skip: !playlistData?.playerPlaylistFindOne,
  });

  const isEmbedRoute = useRouteMatch('/embed');
  const customTheme = useCustomTheme({
    websiteSetting: playlistData?.playerPlaylistFindOne.show.websiteSetting,
  });

  if (playlistLoading) {
    return (
      <Layout theme={isEmbedRoute ? customTheme : darkTheme}>
        <CenterLoading />
      </Layout>
    );
  }

  if (!playlistData?.playerPlaylistFindOne) {
    return (
      <Layout theme={isEmbedRoute ? customTheme : darkTheme}>
        <NotFound />
      </Layout>
    );
  }

  const playlist = playlistData.playerPlaylistFindOne;
  const episodes = data?.playerEpisodeFind || [];

  const link = playlist ? `/playlists/${playlist.id}` : null;

  return (
    <Layout
      logoLink={link}
      defaultEpisode={episodes[0]}
      theme={isEmbedRoute ? customTheme : darkTheme}
    >
      <PlaylistHeader playlist={playlist} episodes={episodes} />
      <Content
        loading={playlistLoading}
        playlist={playlist}
        episodes={episodes}
        hasMore={hasMore}
        loadMore={loadMore}
      />
    </Layout>
  );
}

export default Playlist;
