import React from 'react';
import Color from 'color';
import { Button, Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { NavigateNext } from '@material-ui/icons';

const useStyles = makeStyles((theme: Theme) => ({
  button: {
    marginBottom: theme.spacing(1),
    fontWeight: 'bold',
    width: '100%',
    height: 45,
    justifyContent: 'flex-start',
    color: () => {
      const color = Color(theme.palette.secondary.main);
      const isLight = color.isLight();
      const textColor = isLight
        ? color.darken(0.7).hex()
        : color.lighten(0.7).hex();
      return textColor;
    },
  },
  icon: {
    position: 'absolute',
    left: theme.spacing(4),
    top: 0,
    bottom: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    [theme.breakpoints.down('sm')]: {
      left: theme.spacing(2),
    },
  },
  title: {
    flex: 1,
    textAlign: 'center',
  },
  arrow: {
    position: 'absolute',
    right: theme.spacing(4),
    top: 0,
    bottom: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    [theme.breakpoints.down('sm')]: {
      right: theme.spacing(2),
    },
  },
}));

function PlatformItem({
  title,
  url,
  startIcon,
  onClick,
}: PlatformItemProps): JSX.Element {
  const classes = useStyles();

  const handleClick = React.useCallback(() => {
    onClick?.();
  }, [onClick]);

  return (
    <li>
      <Button
        className={classes.button}
        color="secondary"
        variant="contained"
        size="large"
        component="a"
        href={url}
        target="_blank"
        rel="noreferrer noopener"
        onClick={handleClick}
      >
        <span className={classes.icon}>{startIcon}</span>
        <span className={classes.title}>{title}</span>
        <span className={classes.arrow}>
          <NavigateNext />
        </span>
      </Button>
    </li>
  );
}

type PlatformItemProps = {
  title: string;
  url: string;
  startIcon: React.ReactNode;
  onClick?: () => void;
};

export default PlatformItem;
