import React from 'react';
import cx from 'classnames';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(() => ({
  container: {
    display: 'block',
  },
  image: {
    height: 64,
  },
}));

function ListenOnSpotify({
  className,
  spotifyId,
  url,
}: ListenOnSpotifyProps): JSX.Element | null {
  const classes = useStyles();

  let link: string | null = null;

  if (spotifyId) {
    link = `https://open.spotify.com/show/${spotifyId}`;
  } else if (url) {
    link = url;
  }

  if (!link) {
    return null;
  }

  return (
    <a
      className={cx(classes.container, className)}
      href={link}
      target="_blank"
      rel="noopener noreferrer"
    >
      <img
        className={classes.image}
        src="/assets/platforms/spotify-podcast-badge-blk-grn.png"
        alt="spotify"
      />
    </a>
  );
}

type ListenOnSpotifyProps = {
  className?: string;
  spotifyId?: string;
  url?: string;
};

export default ListenOnSpotify;
