import React from 'react';
import { makeStyles } from '@material-ui/styles';
import { Avatar, Link, Theme, Typography } from '@material-ui/core';
import { FormattedMessage } from 'react-intl';
import { AudienceSpotify } from '../../entities/membership';

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    display: 'flex',
  },
  name: {
    marginTop: 0,
    marginBottom: theme.spacing(1),
  },
  image: {
    height: 48,
    width: 48,
    marginRight: theme.spacing(2),
  },
}));

function SpotifyAccountInfo({
  hasLinkSpotify,
  spotify,
}: SpotifyAccountInfoProps): JSX.Element {
  const classes = useStyles();

  if (spotify) {
    return (
      <div className={classes.container}>
        <Avatar
          src={spotify.imageUrl || undefined}
          className={classes.image}
          variant="rounded"
        />
        <div>
          <Link
            variant="h6"
            color="secondary"
            className={classes.name}
            href={spotify.url || '#'}
            target="_blank"
            rel="noopener noreferrer"
          >
            {spotify.name}
          </Link>
        </div>
      </div>
    );
  }

  if (hasLinkSpotify) {
    return (
      <div className={classes.container}>
        <Typography>
          <FormattedMessage id="donation.hasLinkSpotify" />
        </Typography>
      </div>
    );
  }

  return (
    <div className={classes.container}>
      <Typography>-</Typography>
    </div>
  );
}

type SpotifyAccountInfoProps = {
  hasLinkSpotify: boolean;
  spotify?: AudienceSpotify | null;
};

export default SpotifyAccountInfo;
