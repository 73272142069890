import React from 'react';
import { useMutation } from '@apollo/client';
import {
  AudienceAuthorizedDiscord,
  AudienceDisconnectDiscord,
  GetSubscriptionsWithDiscord,
} from '../../v2/membership';
import usePagination from '../../../hooks/usePagination';
import { Subscription, SubscriptionStatus } from '../../../entities/membership';

export function useAuthorizedDiscord() {
  const [fn] = useMutation<
    AudienceAuthorizedDiscordData,
    AudienceAuthorizedDiscordVariables
  >(AudienceAuthorizedDiscord);

  const authorizedDiscord = React.useCallback(
    (code: string) => {
      return fn({
        variables: { code },
        context: { endpoint: 'membership' },
      });
    },
    [fn],
  );

  return authorizedDiscord;
}

type AudienceAuthorizedDiscordData = {
  audienceAuthorizedDiscord: {
    success: boolean;
  };
};

type AudienceAuthorizedDiscordVariables = {
  code: string;
};

export function useGetSubscriptionsWithDiscord() {
  const result = usePagination<
    GetSubscriptionsWithDiscordData,
    GetSubscriptionsWithDiscordVariables
  >({
    dataKey: 'subscriptions',
    query: GetSubscriptionsWithDiscord,
    variables: {
      statuses: [
        SubscriptionStatus.trialing,
        SubscriptionStatus.active,
        SubscriptionStatus.canceling,
      ],
    },
    context: { endpoint: 'membership' },
  });

  return result;
}

type GetSubscriptionsWithDiscordData = {
  subscriptions: Subscription[];
};

type GetSubscriptionsWithDiscordVariables = {
  statuses: SubscriptionStatus.Type[];
};

export function useDisconnectDiscord() {
  const [fn] = useMutation<DisconnectDiscordData>(AudienceDisconnectDiscord);

  const disconnectDiscord = React.useCallback(() => {
    return fn({
      context: { endpoint: 'membership' },
      refetchQueries: ['GetAudienceDiscord'],
    });
  }, [fn]);

  return disconnectDiscord;
}

type DisconnectDiscordData = {
  audienceDisconnectDiscord: {
    success: boolean;
  };
};
