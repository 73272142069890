import React from 'react';
import { StaticContext } from 'react-router';
import { Route, Redirect } from 'react-router-dom';
import * as H from 'history';

function ExternalRedirect({ to }) {
  React.useEffect(() => {
    if (window.location) {
      window.location.replace(to);
    }
  }, [to]);

  return null;
}

function RedirectWithStatus({
  isExternal,
  to,
  status,
}: RedirectWithStatusProps): JSX.Element {
  return (
    <Route
      render={({ staticContext }) => {
        if (staticContext) {
          const c = staticContext as StaticContextWithStatus;
          if (isExternal) {
            if (typeof to === 'string') {
              c.url = to;
            } else if (typeof to === 'object') {
              c.url = to.pathname;
            }
          }
          c.status = status;
        }
        return isExternal ? <ExternalRedirect to={to} /> : <Redirect to={to} />;
      }}
    />
  );
}

type RedirectWithStatusProps = {
  isExternal?: boolean;
  to: string | H.Location;
  status?: number;
};

type StaticContextWithStatus = StaticContext & {
  status?: number;
  url?: string;
};

export default RedirectWithStatus;
