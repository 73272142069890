import React from 'react';
import { FormattedMessage } from 'react-intl';
import { makeStyles } from '@material-ui/styles';
import { Paper, Typography, Theme } from '@material-ui/core';
import {
  CustomerStatus,
  Plan,
  Subscription,
  Tier,
  TierStatus,
  TrialInterval,
} from '../../../entities/membership';
import { GetMeLegacyData } from '../../../gql/hooks/user';
import TierItem from './TierItem';
import MeInfo from './MeInfo';
import ManageSection from './ManageSection';
import FreeTierItem from './FreeTierItem';

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    padding: theme.spacing(2),
  },
  title: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(1),
  },
}));

function SubscriptionContent({
  enabled,
  tiers,
  me,
  currentSubscription,
  trialInterval,
  customerStatus,
  onSelect,
}: SubscriptionContentProps): JSX.Element {
  const classes = useStyles();

  const freeTier = tiers.find(t => t.plans[0]?.price.money.amount === 0);
  const subscriptionTiers = React.useMemo(() => {
    return tiers.filter(t => t.plans[0]?.price.money.amount !== 0);
  }, [tiers]);

  if (!enabled || tiers.length === 0) {
    return (
      <Paper variant="outlined" className={classes.container}>
        <Typography>
          <FormattedMessage id="donation.noSubscriptionTier" />
        </Typography>
      </Paper>
    );
  }

  return (
    <div>
      <ManageSection />
      {freeTier ? (
        <div>
          <Typography
            variant="h6"
            color="textPrimary"
            className={classes.title}
          >
            <FormattedMessage id="donation.freeTier" />
          </Typography>
          <FreeTierItem
            tier={freeTier}
            currentSubscription={currentSubscription}
            onSelect={onSelect}
          />
        </div>
      ) : null}
      <Typography variant="h6" color="textPrimary" className={classes.title}>
        <FormattedMessage id="donation.subscriptionTier" />
      </Typography>
      {me ? (
        <MeInfo me={me} isRecurring currentSubscription={currentSubscription} />
      ) : null}
      {subscriptionTiers
        .filter(t => t.status === TierStatus.active)
        .map(t => (
          <TierItem
            key={t.id}
            tier={t}
            me={me}
            currentSubscription={currentSubscription}
            onSelect={onSelect}
            customerStatus={customerStatus}
            trialInterval={trialInterval}
          />
        ))}
    </div>
  );
}

type SubscriptionContentProps = {
  enabled: boolean;
  tiers: Tier[];
  me: GetMeLegacyData | null;
  currentSubscription?: Subscription | null;
  trialInterval?: TrialInterval | null;
  customerStatus?: CustomerStatus;
  onSelect(tier: Tier, plan: Plan): void;
};

export default SubscriptionContent;
