import xss, { IFilterXSSOptions } from 'xss';
import _formatISODuration from 'date-fns/formatISODuration';
import _formatDate from 'date-fns/format';
import { RoleV1 } from '../entities/role';

export function imageproxy(src?: string | null, number?: number): string {
  const gcsPrefix =
    'https://storage.googleapis.com/firstory-709db.appspot.com/';
  const cdnPrefix = 'https://d3mww1g1pfq2pt.cloudfront.net/';
  if (!src) {
    return '';
  }
  if (src.startsWith(gcsPrefix)) {
    const path = src.replace(gcsPrefix, cdnPrefix);

    if (number) {
      // TODO
    }

    return path;
  }
  return src;
}

function padZero(num: number): string {
  if (num < 10) {
    return `0${num}`;
  }
  return num.toString();
}

export function formatDate(date?: string | Date | null): string {
  if (!date) {
    return '-';
  }

  try {
    return _formatDate(new Date(date), 'yyyy-MM-dd');
  } catch (error) {
    return '-';
  }
}

export function formatISODuration(ms: number): string {
  const s = Math.floor(ms / 1000);
  const hours = Math.floor(s / 3600);
  const hoursInSeconds = hours * 3600;
  const minutes = Math.floor((s - hoursInSeconds) / 60);
  const seconds = Math.floor(s % 60);

  return _formatISODuration({
    hours,
    minutes,
    seconds,
  });
}

export function getMMSSfromSeconds(time: number): string {
  const minutes = Math.floor(time / 60);
  const seconds = Math.floor(time % 60);
  return `${padZero(minutes)}:${padZero(seconds)}`;
}

type Track = { audioUrl?: string | null };
export function isSameTrack(a?: Track | null, b?: Track | null): boolean {
  if (a === b) {
    return true;
  }
  if (!a || !b) {
    return false;
  }
  return a.audioUrl === b.audioUrl;
}

export function hasUserRole(roles: RoleV1[], name: string): boolean {
  if (!roles) {
    return false;
  }
  return roles.some(r => r.name === name);
}

export function checkHasPermission(
  roles: RoleV1[],
  permission: string,
): boolean {
  for (let i = 0; i < roles.length; i++) {
    const hasPermission = roles[i].permissions.find(p => p.name === permission);
    if (hasPermission) {
      return true;
    }
  }
  return false;
}

export function countryCodeToEmoji(cc: string): string | null {
  const CC_REGEX = /^[a-z]{2}$/i;
  const OFFSET = 127397;
  if (!CC_REGEX.test(cc)) {
    return null;
  }
  const chars = [...cc.toUpperCase()].map(c => c.charCodeAt(0) + OFFSET);
  return String.fromCodePoint(...chars);
}

export function filterXss(
  str: string,
  filterOptions?: { ugc?: boolean },
): string {
  const options: IFilterXSSOptions = {
    whiteList: {
      div: [],
      a: ['href', 'target', 'rel'],
      p: [],
      b: [],
      span: [],
      strong: [],
      em: [],
      br: [],
      ul: [],
      ol: [],
      li: [],
      h1: [],
      h2: [],
      h3: [],
      h4: [],
      h5: [],
      h6: [],
      img: ['src', 'alt', 'width', 'height'],
    },
    stripIgnoreTag: true,
    stripIgnoreTagBody: ['style', 'script'],
    onTagAttr(tag, name, value) {
      if (tag === 'a' && name === 'rel' && filterOptions?.ugc) {
        return `${name}="${value + ' ugc'}"`;
      }
    },
  };
  const html = xss(str, options);
  return html.trim();
}

export function htmlToText(html: string): string {
  if (globalThis.window) {
    const element = document.createElement('div');
    element.innerHTML = html;
    return element.textContent || '';
  }

  if ((global as any).htmlToText) {
    return (global as any).htmlToText(html);
  }

  return html;
}

export * from './loadScript';
export { default as customFetch } from './customFetch';
