import React from 'react';
import Avatar from '@material-ui/core/Avatar';

const defaultPlaceholder =
  'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABAQAAAAA3bvkkAAAABGdBTUEAALGPC/xhBQAAACBjSFJNAAB6JgAAgIQAAPoAAACA6AAAdTAAAOpgAAA6mAAAF3CculE8AAAAAmJLR0QAAd2KE6QAAAAHdElNRQfjDBIIHzJoer8/AAAACklEQVQI12NgAAAAAgAB4iG8MwAAACV0RVh0ZGF0ZTpjcmVhdGUAMjAxOS0xMi0xOFQwODozMTo1MCswMDowMFW3pIIAAAAldEVYdGRhdGU6bW9kaWZ5ADIwMTktMTItMThUMDg6MzE6NTArMDA6MDAk6hw+AAAAAElFTkSuQmCC';

export function useIntersectionObserver({
  ref,
  onObserve,
}: UseIntersectionObserverProps) {
  React.useEffect(() => {
    let observer: globalThis.IntersectionObserver;
    let didCancel = false;

    if (ref) {
      if (global.IntersectionObserver) {
        observer = new global.IntersectionObserver(
          entries => {
            entries.forEach(entry => {
              if (
                !didCancel &&
                (entry.intersectionRatio > 0 || entry.isIntersecting)
              ) {
                if (onObserve) {
                  onObserve();
                }
                observer.unobserve(ref);
              }
            });
          },
          {
            threshold: 0.01,
            rootMargin: '75%',
          },
        );
        observer.observe(ref);
      } else if (onObserve) {
        onObserve();
      }
    }
    return () => {
      didCancel = true;
      if (observer && observer.unobserve && ref) {
        observer.unobserve(ref);
      }
    };
  }, [ref, onObserve]);
}

export function useLazyImageSrc({ src, placeholder = defaultPlaceholder }) {
  const [imageSrc, setImageSrc] = React.useState(placeholder);
  const [imageRef, setImageRef] = React.useState<HTMLDivElement | null>(null);

  const handleObserve = React.useCallback(() => {
    if (src !== imageSrc) {
      setImageSrc(src);
    }
  }, [src, imageSrc]);

  useIntersectionObserver({
    ref: imageRef,
    onObserve: handleObserve,
  });

  return {
    ref: setImageRef,
    src: imageSrc,
  };
}

function LazyImage({
  src,
  alt,
  placeholder = defaultPlaceholder,
  className,
  style,
}: LazyImageProps): JSX.Element {
  const { ref, src: imageSrc } = useLazyImageSrc({
    src,
    placeholder,
  });

  return (
    <Avatar
      variant="rounded"
      ref={ref}
      src={imageSrc}
      alt={alt}
      className={className}
      style={style}
    />
  );
}

type UseIntersectionObserverProps = {
  ref: HTMLDivElement | null;
  onObserve(): void;
};

type LazyImageProps = {
  src: string;
  alt?: string;
  placeholder?: string;
  className?: string;
  style?: React.CSSProperties;
};

export default LazyImage;
