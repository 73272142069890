import React from 'react';
import { Link, useParams } from 'react-router-dom';
import { useIntl, FormattedMessage } from 'react-intl';
import { makeStyles } from '@material-ui/styles';
import {
  Typography,
  Button,
  Container,
  Avatar,
  Theme,
} from '@material-ui/core';
import VoiceMailBoxHeader from '../header/VoiceMailBoxHeader';
import CenterLoading from '../../../components/CenterLoading';
import Linkify from '../../../components/Linkify';
import NotFound from '../../../components/NotFound';
import useDisclosure from '../../../hooks/useDisclosure';
import { useGetVoiceMailBoxDetail } from '../../../gql/hooks/voicemail';
import RecordModal from './RecordModal';

const useStyles = makeStyles((theme: Theme) => ({
  loadingContainer: {
    height: 300,
  },
  container: {
    paddingTop: theme.spacing(8),
  },
  info: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginBottom: theme.spacing(4),
  },
  avatar: {
    height: 96,
    width: 96,
  },
  name: {},
  authorName: {},
  title: {
    fontWeight: 'bold',
    marginBottom: theme.spacing(4),
    textAlign: 'center',
  },
  description: {
    whiteSpace: 'pre-line',
    marginBottom: theme.spacing(8),
    textAlign: 'center',
    minHeight: 180,
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'center',
    marginBottom: theme.spacing(4),
  },
}));

function VoiceMailBoxDetail({
  showId,
  showPodcastInfo,
}: VoiceMailBoxDetailProps): JSX.Element {
  const intl = useIntl();
  const classes = useStyles();
  const { id } = useParams<{ id: string }>();
  const { loading, data } = useGetVoiceMailBoxDetail(id);
  const modal = useDisclosure();

  if (loading) {
    return (
      <div className={classes.container}>
        <div className={classes.loadingContainer}>
          <CenterLoading />
        </div>
      </div>
    );
  }

  if (!data?.playerVoicemailBoxFindOne) {
    return <NotFound />;
  }

  if (showId) {
    if (data.playerVoicemailBoxFindOne.show.id !== showId) {
      return <NotFound />;
    }
  }

  const voiceMailBox = data.playerVoicemailBoxFindOne;
  const { show, title, description, active } = voiceMailBox;
  const authorLink = `/user/${show.id}`;

  return (
    <div className={classes.container}>
      <VoiceMailBoxHeader data={data} />
      <Container maxWidth="xs">
        {showPodcastInfo && (
          <div className={classes.info}>
            <Link to={authorLink}>
              <Avatar
                variant="rounded"
                className={classes.avatar}
                src={show.avatar}
                title={show.name}
              />
            </Link>
            <Typography
              variant="h6"
              color="textPrimary"
              component={Link}
              to={authorLink}
            >
              {show.name}
            </Typography>
            <Typography>{show.author}</Typography>
          </div>
        )}
        <Typography
          variant="h4"
          component="h1"
          color="textPrimary"
          className={classes.title}
        >
          {title}
        </Typography>
        {active && (
          <div className={classes.buttonContainer}>
            <Button
              variant="contained"
              color="primary"
              size="large"
              onClick={modal.open}
            >
              <FormattedMessage id="website.voicemail.reply" />
            </Button>
          </div>
        )}
        <Typography color="textPrimary" className={classes.description}>
          <Linkify>
            {description ||
              intl.formatMessage(
                { id: 'website.voicemail.defaultDescription' },
                { title: show.name },
              )}
          </Linkify>
        </Typography>
      </Container>
      <RecordModal
        voiceMailBox={voiceMailBox}
        open={modal.isOpen}
        onClose={modal.close}
      />
    </div>
  );
}

type VoiceMailBoxDetailProps = {
  showId?: string;
  showPodcastInfo?: boolean;
};

export default VoiceMailBoxDetail;
