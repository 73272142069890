class AudioAdapter {
  receiver: any;

  constructor(player: HTMLAudioElement) {
    this.init(player);
  }

  init(player: HTMLAudioElement) {
    const playerjs = require('player.js');

    playerjs.assert(player, 'AudioAdapter requires a player object');

    // Set up the actual receiver
    const receiver = new playerjs.Receiver();
    this.receiver = receiver;

    /* EVENTS */
    player.addEventListener('timeupdate', function () {
      const seconds = player.currentTime;
      const duration = player.duration;

      if (!seconds || !duration) {
        return false;
      }

      const value = {
        seconds: seconds,
        duration: duration,
      };
      receiver.emit('timeupdate', value);
    });

    player.addEventListener('ended', function () {
      receiver.emit('ended');
    });

    player.addEventListener('error', function () {
      receiver.emit('error');
    });

    /* METHODS */
    receiver.on('play', function () {
      player.play();
      receiver.emit('play');
    });

    receiver.on('pause', function () {
      player.pause();
      receiver.emit('pause');
    });

    receiver.on('getPaused', function (callback: (paused: boolean) => void) {
      callback(player.paused);
    });

    receiver.on(
      'getCurrentTime',
      function (callback: (currentTime: number) => void) {
        callback(player.currentTime);
      },
    );

    receiver.on('setCurrentTime', function (value: number) {
      player.currentTime = value;
    });

    receiver.on('getDuration', function (callback: (duration: number) => void) {
      callback(player.duration);
    });

    receiver.on('getVolume', function (callback: (volume: number) => void) {
      callback(player.volume * 100);
    });

    receiver.on('setVolume', function (value: number) {
      player.volume = value / 100;
    });

    receiver.on('mute', function () {
      player.muted = true;
    });

    receiver.on('unmute', function () {
      player.muted = false;
    });

    receiver.on('getMuted', function (callback: (muted: boolean) => void) {
      callback(player.muted);
    });

    receiver.on('getLoop', function (callback: (loop: boolean) => void) {
      callback(player.loop);
    });

    receiver.on('setLoop', function (value: boolean) {
      player.loop = value;
    });
  }

  ready() {
    this.receiver.ready();
  }
}

export default AudioAdapter;
