import React from 'react';
import {
  makeStyles,
  Paper,
  Typography,
  Theme,
  MenuItem,
} from '@material-ui/core';
import { FormattedMessage } from 'react-intl';
import {
  getCardExpDate,
  getMaskedCardNumber,
  PaymentMethod,
} from '../../../entities/membership';
import MoreMenu from '../../../components/MoreMenu';
import CardTypeImage from './CardTypeImage';

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    padding: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  titleRow: {
    display: 'flex',
    alignItems: 'center',
  },
  bottomRow: {
    marginTop: theme.spacing(2),
  },
  info: {
    flex: 1,
    display: 'flex',
    alignItems: 'center',
  },
  image: {
    height: 24,
    marginRight: theme.spacing(1),
  },
  cardNumber: {
    fontFamily: 'monospace',
  },
  cardExpDate: {
    fontFamily: 'monospace',
  },
}));

function PaymentMethodCard({
  onDelete,
  paymentMethod,
}: PaymentMethodCardProps): JSX.Element {
  const classes = useStyles();
  const { cardType } = paymentMethod;

  return (
    <Paper variant="outlined" className={classes.container}>
      <div className={classes.titleRow}>
        <div className={classes.info}>
          <CardTypeImage className={classes.image} type={cardType} />
          <Typography className={classes.cardNumber}>
            {getMaskedCardNumber(paymentMethod)}
          </Typography>
        </div>
        {onDelete && (
          <MoreMenu size="small">
            <MenuItem onClick={onDelete}>
              <FormattedMessage id="general.delete" />
            </MenuItem>
          </MoreMenu>
        )}
      </div>
      <div>
        <Typography className={classes.cardExpDate}>
          {getCardExpDate(paymentMethod)}
        </Typography>
      </div>
      {paymentMethod.purchaseOrderCount ? (
        <div className={classes.bottomRow}>
          <Typography>
            <FormattedMessage
              id="donation.paymentMethod.subscriptionCount"
              values={{
                count: paymentMethod.purchaseOrderCount,
              }}
            />
          </Typography>
        </div>
      ) : null}
    </Paper>
  );
}

type PaymentMethodCardProps = {
  onDelete?: () => void;
  paymentMethod: PaymentMethod;
};

export default PaymentMethodCard;
