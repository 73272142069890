import React from 'react';
import { get } from 'lodash';
import set from 'lodash/fp/set';
import {
  ApolloError,
  DocumentNode,
  FetchPolicy,
  useQuery,
  OperationVariables,
} from '@apollo/client';
import { useRouterPagination } from '../components/Table/useTablePagination';

const PAGE_SIZE = 20;

type IdItem = { id: string };

function appendData<Item extends IdItem>(
  originArr: Item[] = [],
  newArr: Item[] = [],
) {
  const map = {};
  originArr.forEach(d => {
    map[d.id] = d;
  });
  newArr.forEach(d => {
    map[d.id] = d;
  });
  return Object.values(map);
}

function usePagination<DataType, VariableType = OperationVariables>({
  dataKey,
  query,
  variables,
  skip,
  useSkip,
  hasRouterParams,
  fetchPolicy,
  context,
  ssr,
}: UsePaginationArgs<VariableType>): UsePaginationValue<DataType> {
  const [routerPage] = useRouterPagination();
  const initRouterPageRef = React.useRef(routerPage);
  const [dataHeadIndex, setDataHeadIndex] = React.useState(
    hasRouterParams ? routerPage * PAGE_SIZE : 0,
  );
  const [dataEndIndex, setDataEndIndex] = React.useState(
    hasRouterParams ? (routerPage + 1) * PAGE_SIZE : PAGE_SIZE,
  );
  const { error, networkStatus, data, fetchMore } = useQuery(query, {
    variables: {
      ...variables,
      first: PAGE_SIZE,
      skip: hasRouterParams ? PAGE_SIZE * initRouterPageRef.current : 0,
    },
    skip,
    fetchPolicy,
    context,
    ssr: typeof ssr === 'undefined' ? false : ssr,
  });

  const [hasMore, setHasMore] = React.useState(true);
  const [isFetchingAfter, setIsFetchingAfter] = React.useState(false);
  const [isFetchingBefore, setIsFetchingBefore] = React.useState(false);
  const loading = networkStatus === 1 || networkStatus === 2;
  const arrayData = React.useMemo(() => {
    return get(data, dataKey) || [];
  }, [data, dataKey]);
  const loadMore = React.useCallback(
    async skip => {
      if (isFetchingAfter || !arrayData || arrayData.length === 0) {
        return false;
      }
      setIsFetchingAfter(true);
      const last = arrayData[arrayData.length - 1];
      const v = {
        ...variables,
        first: PAGE_SIZE,
        skip: 0,
        after: null,
      };
      if (hasRouterParams || useSkip) {
        v.skip = dataEndIndex;
      } else {
        v.after = last ? last.id : null;
      }
      if (skip && typeof skip === 'number') {
        v.skip = skip;
      }
      try {
        const result = await fetchMore({
          variables: v,
          updateQuery: (prev, { fetchMoreResult }) => {
            if (!fetchMoreResult) {
              return prev;
            }
            const fetchMoreResultArr = get(fetchMoreResult, dataKey) || [];
            if (fetchMoreResultArr.length === 0) {
              setHasMore(false);
              return prev;
            }
            setDataEndIndex(dataEndIndex + fetchMoreResultArr.length);
            const a = appendData(get(prev, dataKey), fetchMoreResultArr);
            const s = set(dataKey, a, prev);
            return s;
          },
        });
        setIsFetchingAfter(false);
        return result;
      } catch (error) {
        setIsFetchingAfter(false);
        console.log('usePagination error', error.message);
        throw error;
      }
    },
    [
      dataKey,
      variables,
      arrayData,
      useSkip,
      hasRouterParams,
      fetchMore,
      isFetchingAfter,
      dataEndIndex,
    ],
  );

  const loadBefore = React.useCallback(async () => {
    if (isFetchingBefore || !arrayData || arrayData.length === 0) {
      return;
    }
    setIsFetchingBefore(true);
    const newDataHeadIndex = Math.max(dataHeadIndex - PAGE_SIZE, 0);
    const v = {
      ...variables,
      first: PAGE_SIZE,
      skip: newDataHeadIndex,
    };
    const result = await fetchMore({
      variables: v,
      updateQuery: (prev, { fetchMoreResult }) => {
        if (!fetchMoreResult) {
          return prev;
        }
        const fetchMoreResultArr = get(fetchMoreResult, dataKey) || [];
        if (fetchMoreResultArr.length === 0) {
          return prev;
        }
        setDataHeadIndex(newDataHeadIndex);
        return set(
          dataKey,
          appendData(fetchMoreResultArr, get(prev, dataKey)),
          prev,
        );
      },
    });
    setIsFetchingBefore(false);
    return result;
  }, [
    dataKey,
    variables,
    arrayData,
    dataHeadIndex,
    fetchMore,
    isFetchingBefore,
  ]);

  return {
    error,
    data,
    loading,
    hasMore,
    loadMore,
    loadBefore,
    isFetchingAfter,
    isFetchingBefore,
    dataHeadIndex,
    dataEndIndex,
  };
}

export type UsePaginationArgs<VariableType> = {
  dataKey: string;
  query: DocumentNode;
  variables?: VariableType;
  skip?: boolean;
  useSkip?: boolean;
  hasRouterParams?: boolean;
  fetchPolicy?: FetchPolicy;
  context?: any;
  ssr?: boolean;
};

type UsePaginationValue<DataType> = {
  error?: ApolloError;
  data?: DataType;
  loading: boolean;
  hasMore: boolean;
  loadMore(skip?: any): Promise<any>;
  loadBefore(): Promise<any>;
  isFetchingAfter: boolean;
  isFetchingBefore: boolean;
  dataHeadIndex: number;
  dataEndIndex: number;
};

export default usePagination;
