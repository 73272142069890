import React from 'react';
import config from '../../config';
import { PlayableData } from './type';
import useAudio from './useAudio';

const cdnPrefix = 'https://d3mww1g1pfq2pt.cloudfront.net';

function getAudioUrl(nowPlaying: PlayableData | null) {
  if (!nowPlaying?.audioUrl) {
    return null;
  }

  if (!nowPlaying.isDraft && nowPlaying.audioUrl.startsWith(cdnPrefix)) {
    return `${config.endpoints.trackPlay}/play.mp3?url=${encodeURIComponent(
      nowPlaying.audioUrl,
    )}`;
  }
  return nowPlaying.audioUrl;
}

function useMainPlayer(args?: UseMainPlayerArgs): UseMainPlayerValues {
  const defaultEpisode = args?.defaultEpisode || null;
  const [nowPlaying, _setNowPlaying] = React.useState<PlayableData | null>(
    defaultEpisode || null,
  );
  const defaultUrl = defaultEpisode ? getAudioUrl(defaultEpisode) : null;

  const {
    isLoading,
    isPlaying,
    durationMs,
    currentTimeMsRef,
    play,
    pause,
    toggle,
    setSpeed,
    setCurrentTime,
    setUrl,
  } = useAudio({ defaultUrl });

  const isNowPlaying = React.useCallback(
    (data: PlayableData) => {
      if (!nowPlaying) {
        return false;
      }
      const url = getAudioUrl(data);
      const nowPlayingUrl = getAudioUrl(nowPlaying);
      return url === nowPlayingUrl;
    },
    [nowPlaying],
  );

  const setNowPlaying = React.useCallback(
    (data: PlayableData) => {
      _setNowPlaying(data);
      const url = getAudioUrl(data);
      if (url) {
        setUrl(url);
      }
    },
    [setUrl],
  );

  return {
    nowPlaying,
    isLoading,
    isPlaying,
    durationMs,
    currentTimeMsRef,
    play,
    pause,
    toggle,
    setSpeed,
    setCurrentTime,
    setNowPlaying,
    isNowPlaying,
  };
}

type UseMainPlayerArgs = {
  defaultEpisode?: PlayableData;
};

export type UseMainPlayerValues = {
  nowPlaying: PlayableData | null;
  isLoading: boolean;
  isPlaying: boolean;
  durationMs: number;
  currentTimeMsRef: React.MutableRefObject<number>;
  play: () => void;
  pause: () => void;
  toggle: () => void;
  setSpeed: (speed: number) => void;
  setCurrentTime: (ms: number | ((ms: number) => number)) => void;
  setNowPlaying: (data: PlayableData) => void;
  isNowPlaying: (data: PlayableData) => boolean;
};

export default useMainPlayer;
