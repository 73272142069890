import React from 'react';
import { useIntl } from 'react-intl';
import { useMutation, useQuery } from '@apollo/client';
import { useAlert } from '../../../components/Alert';
import usePagination from '../../../hooks/usePagination';
import {
  CancelSubscription,
  GetShowForSubscriptionDetail,
  GetSubscriptionDetail,
  GetSubscriptions,
} from '../../v2/membership';
import { Subscription } from '../../../entities/membership';
import { Show } from '../../../entities/show';

export function useGetSubscriptions() {
  const result = usePagination<GetSubscriptionsData>({
    dataKey: 'subscriptions',
    query: GetSubscriptions,
    context: { endpoint: 'membership' },
  });

  return result;
}

type GetSubscriptionsData = {
  subscriptions: Subscription[];
};

export function useGetSubscriptionDetail(id: string) {
  const result = useQuery<
    GetSubscriptionDetailData,
    GetSubscriptionDetailVariables
  >(GetSubscriptionDetail, {
    variables: { id },
    context: { endpoint: 'membership' },
  });

  return result;
}

type GetSubscriptionDetailData = {
  subscription: Subscription | null;
};

type GetSubscriptionDetailVariables = {
  id: string;
};

export function useCancelSubscription(subscriptionId: string) {
  const intl = useIntl();

  const [cancelSponsorship] = useMutation<
    CancelSubscriptionData,
    CancelSubscriptionVariables
  >(CancelSubscription);
  const { openAlertDialog } = useAlert();

  const fn = React.useCallback(() => {
    openAlertDialog({
      variant: 'confirm',
      title: intl.formatMessage({ id: 'donation.cancelConfirm' }),
      async onConfirmClick() {
        try {
          await cancelSponsorship({
            variables: { subscriptionId },
            context: { endpoint: 'membership' },
            refetchQueries: [GetSubscriptionDetail],
          });
          setTimeout(() => {
            openAlertDialog({
              variant: 'info',
              title: intl.formatMessage({ id: 'donation.cancelSuccess' }),
            });
          }, 500);
        } catch (error) {
          //
        }
      },
    });
  }, [intl, cancelSponsorship, openAlertDialog, subscriptionId]);

  return fn;
}

type CancelSubscriptionData = {
  audienceCancelSubscription: {
    id: string;
  };
};

type CancelSubscriptionVariables = {
  subscriptionId: string;
};

export function useGetShowForSubscriptionDetail(args: {
  showId: string;
  skip?: boolean;
}) {
  const result = useQuery<
    GetShowForSubscriptionDetailData,
    GetShowForSubscriptionDetailVariables
  >(GetShowForSubscriptionDetail, {
    variables: {
      urlSlug: args.showId,
      showId: args.showId,
    },
    skip: args.skip,
  });

  return result;
}

type GetShowForSubscriptionDetailData = {
  playerShowFindOneByUrlSlug: Pick<
    Show,
    'id' | 'name' | 'avatar' | 'urlSlug' | 'distributions' | 'setting'
  > | null;
  audienceShowSubscriptionFindOne: {
    privateRssToken: string | null;
  } | null;
};

type GetShowForSubscriptionDetailVariables = {
  showId: string;
  urlSlug: string;
};
