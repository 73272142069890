import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import { makeStyles } from '@material-ui/styles';
import { Grid, Typography, Link, Theme, Container } from '@material-ui/core/';
import Linkify from '../../components/Linkify';
import LazyImage from '../../components/LazyImage';
import { imageproxy } from '../../utils';
import { useWebPlayer } from '../WebPlayer/context';

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    display: 'flex',
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
    '@media (max-width: 700px)': {
      flexDirection: 'column',
    },
  },
  imageContainer: {
    width: 220,
    paddingRight: theme.spacing(2),
    '@media (max-width: 700px)': {
      width: 200,
      paddingRight: 0,
      paddingBottom: theme.spacing(2),
    },
    '@media (max-width: 500px)': {
      width: 80,
    },
  },
  image: {
    height: 200,
    width: 200,
    borderRadius: 5,
    objectFit: 'cover',
    '@media (max-width: 500px)': {
      height: 80,
      width: 80,
    },
  },
  titleContainer: {
    position: 'relative',
    paddingBottom: theme.spacing(2),
  },
  title: {
    paddingRight: theme.spacing(4),
    fontWeight: 'bold',
  },
  subtitle: {
    paddingBottom: theme.spacing(2),
  },
  description: {
    width: '100%',
    whiteSpace: 'pre-line',
    overflowWrap: 'anywhere',
  },
  episodeCount: {
    textAlign: 'center',
    paddingTop: 5,
  },
}));

function PlaylistInfo({
  showId,
  title,
  subtitle,
  description,
  imageUrl,
  episodeCount,
  ugc,
}: PlaylistInfoProps): JSX.Element {
  const classes = useStyles();
  const { isWebPlayer } = useWebPlayer();

  return (
    <Container className={classes.container}>
      <div className={classes.imageContainer}>
        <LazyImage
          src={imageproxy(imageUrl, 500)}
          placeholder={imageproxy(imageUrl, 50)}
          className={classes.image}
          alt="avatar"
        />
        {episodeCount ? (
          <Typography
            variant="body2"
            component="div"
            className={classes.episodeCount}
          >
            <FormattedMessage
              id="podcast.episodeCount"
              values={{ count: episodeCount }}
            />
          </Typography>
        ) : null}
      </div>
      <Grid container direction="column">
        <div className={classes.titleContainer}>
          <Typography component="h1" variant="h5" className={classes.title}>
            {title}
          </Typography>
          <Typography component="h2" variant="h6" className={classes.subtitle}>
            <Link
              color="textSecondary"
              component={RouterLink}
              to={isWebPlayer ? `/user/${showId}` : '/'}
            >
              {subtitle}
            </Link>
          </Typography>
        </div>
        <Typography className={classes.description}>
          <Linkify ugc={ugc}>{description}</Linkify>
        </Typography>
      </Grid>
    </Container>
  );
}

type PlaylistInfoProps = {
  showId: string;
  title: string;
  subtitle: string;
  description?: string | null;
  imageUrl?: string | null;
  episodeCount?: number;
  ugc?: boolean;
};

export default PlaylistInfo;
