import React from 'react';
import { useLocation } from 'react-router-dom';
import * as GoogleAnalytics from 'react-ga';
import FacebookPixel from 'react-facebook-pixel';
import config from '../../config';
import TrackerContext from './context';

function TrackerProvider({
  userUrlSlug,
  userGaTrackingId,
  userFbPixelId,
  children,
}) {
  const location = useLocation();
  const [isInitialized, setIsInitialized] = React.useState(false);

  const userTrackerName = userUrlSlug ? userUrlSlug.replace(/-/g, '_') : '';

  const trackerNames = React.useMemo(() => {
    return userGaTrackingId ? [userTrackerName] : [];
  }, [userTrackerName, userGaTrackingId]);

  React.useEffect(() => {
    if (isInitialized) {
      GoogleAnalytics.set({ page: location.pathname }, trackerNames);
      GoogleAnalytics.pageview(location.pathname, trackerNames);
    }
  }, [isInitialized, location.pathname, trackerNames]);

  React.useEffect(() => {
    if (!isInitialized) {
      const args = [{ trackingId: config.ga }];
      if (userGaTrackingId) {
        args.push({
          trackingId: userGaTrackingId,
          gaOptions: { name: userTrackerName },
        });
      }
      const debug = process.env.NODE_ENV === 'development';
      GoogleAnalytics.initialize(args, { debug });
      FacebookPixel.init(config.fbPixelId, {}, { debug });
      FacebookPixel.pageView();
      if (userFbPixelId) {
        FacebookPixel.init(userFbPixelId, {}, { debug });
        FacebookPixel.pageView();
      }
      setIsInitialized(true);
    }
  }, [isInitialized, userTrackerName, userGaTrackingId, userFbPixelId]);

  const value = React.useMemo(() => {
    return { trackerNames };
  }, [trackerNames]);

  return (
    <TrackerContext.Provider value={value}>{children}</TrackerContext.Provider>
  );
}

export { TrackerProvider };
export { default as useTracker } from './useTracker';
