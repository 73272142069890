import React from 'react';
import MonetizationOn from '@material-ui/icons/MonetizationOn';
import { useIntl } from 'react-intl';
import config from '../../config';
import PlatformItem from './PlatformItem';

function DonateItem({ showId }: DonateItemProps): JSX.Element {
  const donateUrl = `${config.url}/join/${showId}`;
  const intl = useIntl();

  return (
    <PlatformItem
      title={intl.formatMessage({ id: 'podcast.donate' })}
      url={donateUrl}
      startIcon={<MonetizationOn />}
    />
  );
}

type DonateItemProps = {
  showId: string;
};

export default DonateItem;
