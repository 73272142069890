export type StackProps = {
  children: React.ReactNode;
  spacing?: number;
  direction?: 'row' | 'column';
  alignItems?: React.CSSProperties['alignItems'];
  justifyContent?: React.CSSProperties['justifyContent'];
  className?: string;
  style?: React.CSSProperties;
};

export function Stack(props: StackProps) {
  const {
    children,
    spacing = 0.5,
    direction = 'column',
    className,
    justifyContent,
    alignItems,
  } = props;
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: direction,
        gap: spacing * 8,
        justifyContent,
        alignItems,
        ...props.style,
      }}
      className={className}
    >
      {children}
    </div>
  );
}
