import React from 'react';
import { useMutation } from '@apollo/client';
import { CheckoutStatus, Money } from '../../../entities/membership';
import { DonateShow, SubscribeShow } from '../../v2/membership';
import { Define } from '../../../define';

export function useMembershipCheckout() {
  const [donateShow] = useMutation<DonateShowData, CheckoutVariables>(
    DonateShow,
  );
  const [subscribeShow] = useMutation<SubscribeShowData, CheckoutVariables>(
    SubscribeShow,
  );

  const checkout = React.useCallback(
    async ({ isRecurring, ...variables }: CheckoutArgs) => {
      const fn = isRecurring ? subscribeShow : donateShow;

      const { data } = await fn({
        variables,
        context: { endpoint: 'membership' },
      });

      if (!data) {
        return null;
      }

      if ('audienceDonateShow' in data) {
        return data.audienceDonateShow;
      }

      if ('audienceSubscribeShow' in data) {
        return data.audienceSubscribeShow;
      }

      return null;
    },
    [donateShow, subscribeShow],
  );

  return checkout;
}

type CheckoutData = {
  purchaseOrderId: string | null;
  status: CheckoutStatus.Type;
  redirectUrl: string | null;
};

type DonateShowData = {
  audienceDonateShow: CheckoutData;
};

type SubscribeShowData = {
  audienceSubscribeShow: CheckoutData;
};

type CheckoutVariables = {
  provider: Define.Payment.Provider.Type;
  prime?: string;
  showId: string;
  planId?: string;
  customPrice: Money | null;
  email: string;
  donationName: string;
  message: string;
  paymentName?: string;
  phoneNumber?: string;
};

type CheckoutArgs = CheckoutVariables & { isRecurring: boolean };
