import React from 'react';
import { FormattedMessage } from 'react-intl';
import { makeStyles } from '@material-ui/styles';
import { Typography, Theme } from '@material-ui/core/';
import Rating from '@material-ui/lab/Rating';

const useStyles = makeStyles((theme: Theme) => ({
  ratingRow: {
    display: 'flex',
    paddingButton: theme.spacing(2),
  },
  ratingText: {
    paddingLeft: 10,
  },
}));

function RatingRow({ value, count }: RatingRowProps): JSX.Element {
  const classes = useStyles();
  return (
    <div className={classes.ratingRow}>
      <Rating size="small" value={value} precision={0.5} readOnly />
      <Typography
        variant="caption"
        color="textSecondary"
        className={classes.ratingText}
      >
        <FormattedMessage
          id="podcast.ratingValue"
          values={{ ratingValue: value.toFixed(2) }}
        />
        ,{' '}
        <FormattedMessage
          id="podcast.ratingCount"
          values={{ ratingCount: count }}
        />
      </Typography>
    </div>
  );
}

type RatingRowProps = {
  value: number;
  count: number;
};

export default RatingRow;
