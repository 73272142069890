import React from 'react';
import { makeStyles } from '@material-ui/styles';
import { Grid, IconButton, Theme } from '@material-ui/core';
import ShareIcon from '@material-ui/icons/Share';
import MessageIcon from '@material-ui/icons/Message';
import RssFeedIcon from '@material-ui/icons/RssFeed';
import DownloadIcon from '@material-ui/icons/CloudDownload';
import VoicemailIcon from '@material-ui/icons/Voicemail';
import { Show } from '../../entities/show';
import config from '../../config';
import { PlayableData } from '../Player/type';
import ShareModal from './ShareModal';

const useStyles = makeStyles((theme: Theme) => ({
  optionIconButton: {
    height: 36,
    width: 36,
    backgroundColor: theme.palette.primary.main,
    '&:hover': {
      backgroundColor: theme.palette.primary.dark,
    },
  },
  optionIcon: {
    color: theme.palette.background.default,
  },
}));

function getShareUrl({
  episode,
  show,
}: {
  episode?: PlayableData | null;
  show?: Show | null;
}): string {
  if (episode) {
    return `https://open.firstory.me/story/${episode.id}/platforms`;
  }
  if (show) {
    return `https://open.firstory.me/user/${show.urlSlug || show.id}/platforms`;
  }
  return 'http://firstory.me';
}

function Option({ Icon, link, download, onClick }: OptionProps): JSX.Element {
  const classes = useStyles();

  const buttonProps = link
    ? {
        component: 'a',
        target: '_blank',
        rel: 'noreferrer noopener',
        href: link,
        download,
      }
    : {
        onClick,
      };

  return (
    <Grid item>
      <IconButton
        disableRipple
        color="primary"
        className={classes.optionIconButton}
        {...buttonProps}
      >
        <Icon className={classes.optionIcon} />
      </IconButton>
    </Grid>
  );
}

function EmbedControllerOptionRow({
  show,
  episode,
  isShareOpen,
  onShareClick,
  onShareClose,
}: EmbedControllerOptionRowProps): JSX.Element {
  const commentLink = episode
    ? `https://open.firstory.me/story/${episode.id}`
    : null;

  const voicemailLink = show
    ? `https://open.firstory.me/user/${show.urlSlug || show.id}/voicemails`
    : null;

  const rssUrl = show ? `${config.rssUrlBase}/rss/user/${show.id}` : null;
  const shareUrl = getShareUrl({ show, episode });
  const websiteSetting = show?.websiteSetting;

  return (
    <Grid container spacing={1}>
      <Option Icon={ShareIcon} onClick={onShareClick} />
      {websiteSetting?.playerShowComment && commentLink && (
        <Option Icon={MessageIcon} link={commentLink} />
      )}
      {websiteSetting?.playerShowVoicemail && voicemailLink && (
        <Option Icon={VoicemailIcon} link={voicemailLink} />
      )}
      {websiteSetting?.playerShowDownloadAudioFile && episode && (
        <Option Icon={DownloadIcon} link={episode.audioUrl} download />
      )}
      {websiteSetting?.playerShowRssFeed && rssUrl && (
        <Option Icon={RssFeedIcon} link={rssUrl} />
      )}
      <ShareModal url={shareUrl} open={isShareOpen} onClose={onShareClose} />
    </Grid>
  );
}

type EmbedControllerOptionRowProps = {
  show?: Show | null;
  episode?: PlayableData | null;
  isShareOpen: boolean;
  onShareClick: () => void;
  onShareClose: () => void;
};

type OptionProps = {
  Icon: any;
  link?: string | null;
  download?: boolean;
  onClick?: () => void;
};

export default EmbedControllerOptionRow;
