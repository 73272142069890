import React from 'react';
import {
  Link as RouterLink,
  Route,
  Switch,
  Redirect,
  useLocation,
} from 'react-router-dom';
import { FormattedMessage, useIntl } from 'react-intl';
import { Helmet } from 'react-helmet-async';
import { makeStyles } from '@material-ui/styles';
import {
  Typography,
  Theme,
  Tabs,
  Tab,
  Link,
  Container,
} from '@material-ui/core';
import Layout from '../WebPlayer/Layout';
import CenterLoading from '../../components/CenterLoading';
import { useGetMeLegacy } from '../../gql/hooks/user';
import DonationList from './DonationList';
import SubscriptionList from './Subscription/SubscriptionList';
import SubscriptionDetail from './Subscription/Detail';
import ConnectedApps from './ConnectedApps';
import DiscordSetting from './DiscordSetting';
import SpotifySetting from './SpotifySetting';
import PaymentMethodList from './PaymentMethod';

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    paddingTop: theme.spacing(4),
  },
  content: {
    padding: theme.spacing(4, 0),
    '@media (max-width: 500px)': {
      padding: theme.spacing(2, 0),
      margin: theme.spacing(0, -2),
    },
  },
  title: {
    fontWeight: 'bold',
  },
  tabs: {
    marginTop: theme.spacing(4),
  },
}));

function DonationHistory(): JSX.Element {
  const intl = useIntl();
  const classes = useStyles();
  const location = useLocation();

  const value =
    [
      '/donation/recurring',
      '/donation/one-time',
      '/donation/apps',
      '/donation/payment-methods',
    ].findIndex(p => location.pathname.startsWith(p)) || 0;

  const { loading, data } = useGetMeLegacy();

  if (loading) {
    return <CenterLoading />;
  }

  const email: string | null = data?.me?.account?.email;

  return (
    <div>
      <Typography className={classes.title} variant="h4">
        <FormattedMessage id="donation.manage" />
      </Typography>
      <Typography>
        {email ? (
          <FormattedMessage
            id="donation.accountEmail"
            values={{
              email: <b>{email}</b>,
            }}
          />
        ) : (
          <FormattedMessage id="donation.noEmail" />
        )}
      </Typography>
      <Typography>
        <FormattedMessage
          id="donation.manageHint"
          values={{
            manage: (chunk: string) => (
              <Link
                color="secondary"
                href="https://firstory.crisp.help/zh-tw/article/5aac5l2v6lsk5yqp5oiw5yqg5ywl56a55uu5pyd5zoh-mduir4/"
                target="_blank"
                rel="noopener noreferrer"
              >
                {chunk}
              </Link>
            ),
            unlock: (chunk: string) => (
              <Link
                color="secondary"
                href="https://firstory.crisp.help/zh-tw/article/6kej6y6w5pyd5zoh5bci5bgs5ywn5a65-1ve8z94/"
                target="_blank"
                rel="noopener noreferrer"
              >
                {chunk}
              </Link>
            ),
          }}
        />
      </Typography>
      <Tabs value={value} className={classes.tabs}>
        <Tab
          label={intl.formatMessage({ id: 'donation.recurring' })}
          component={RouterLink}
          to="/donation/recurring"
        />
        <Tab
          label={intl.formatMessage({ id: 'donation.oneTime' })}
          component={RouterLink}
          to="/donation/one-time"
        />
        <Tab
          label={intl.formatMessage({ id: 'donation.app.connected' })}
          component={RouterLink}
          to="/donation/apps"
        />
        <Tab
          label={intl.formatMessage({ id: 'donation.paymentMethod' })}
          component={RouterLink}
          to="/donation/payment-methods"
        />
      </Tabs>
      <div className={classes.content}>
        <Switch>
          <Route path="/donation/recurring">
            <SubscriptionList />
          </Route>
          <Route path="/donation/one-time">
            <DonationList />
          </Route>
          <Route path="/donation/apps/discord">
            <DiscordSetting />
          </Route>
          <Route path="/donation/apps/spotify">
            <SpotifySetting />
          </Route>
          <Route path="/donation/apps">
            <ConnectedApps />
          </Route>
          <Route path="/donation/payment-methods">
            <PaymentMethodList />
          </Route>
          <Route>
            <Redirect to="/donation/recurring" />
          </Route>
        </Switch>
      </div>
    </div>
  );
}

function Donation(): JSX.Element {
  const intl = useIntl();
  const classes = useStyles();

  return (
    <Layout hideController>
      <Helmet>
        <title>{intl.formatMessage({ id: 'donation.manage' })}</title>
      </Helmet>
      <Container maxWidth="md" className={classes.container}>
        <Switch>
          <Route path="/donation/recurring/:id">
            <SubscriptionDetail />
          </Route>
          <Route>
            <DonationHistory />
          </Route>
        </Switch>
      </Container>
    </Layout>
  );
}

export default Donation;
