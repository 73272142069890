import React from 'react';
import { Helmet } from 'react-helmet-async';
import config from '../../../config';
import { Define } from '../../../define';
import { Show } from '../../../entities/show';

function ShowHeader({
  isWebPlayer,
  isEmbed,
  show,
}: ShowHeaderProps): JSX.Element {
  if (isWebPlayer && isEmbed) {
    const baseUrl = config.url;
    const playerUrl = `${config.url}/user/${show.id}`;
    const embedPlayerUrl = baseUrl + `/embed/user/${show.id}`;
    const oembedUrl = `${baseUrl}/oembed?url=${encodeURIComponent(
      embedPlayerUrl,
    )}`;
    const canonicalUrl = playerUrl;

    return (
      <Helmet>
        <link rel="canonical" href={canonicalUrl} />
        <link
          rel="alternate"
          type="application/json+oembed"
          href={oembedUrl}
          title="Firstory Embed Player"
        />
      </Helmet>
    );
  }

  const { id, name, author, intro, avatar, urlSlug, language, distributions } =
    show;

  let subdomain = urlSlug;
  if (urlSlug === 'firstorylab') {
    subdomain = 'lab';
  }

  const title = isWebPlayer ? `${name} - Podcast on Firstory` : name;
  const description = intro + '\n Podcast powered by Firstory.';
  const lang = (language || 'ZH').toLowerCase();
  const applePodcast = distributions?.find(
    d => d.platformType === Define.Distribution.Platform.apple,
  );
  const applePodcastId = applePodcast ? applePodcast.platformId : null;

  const websiteBaseUrl = `https://${subdomain}.firstory.io`;
  const playerBaseUrl = config.url;
  const baseUrl = isWebPlayer ? playerBaseUrl : websiteBaseUrl;

  const websiteUrl = websiteBaseUrl;
  const playerUrl = `${playerBaseUrl}/user/${urlSlug || id}`;

  const embedPlayerUrl =
    baseUrl + (isWebPlayer ? `/embed/user/${urlSlug || id}` : '/embed');
  const oembedUrl = `${baseUrl}/oembed?url=${encodeURIComponent(
    embedPlayerUrl,
  )}`;

  const canonicalUrl = isWebPlayer ? playerUrl : websiteUrl;

  const schemaData = {
    '@context': 'https://schema.org',
    '@type': 'PodcastSeries',
    webFeed: `${config.rssUrlBase}/rss/user/${id}`,
    image: avatar,
    url: canonicalUrl,
    name: name,
    description,
    author: { '@type': 'Person', name: author || name },
    offers: [{ '@type': 'Offer', price: 'Free' }],
  };

  return (
    <Helmet>
      <html lang={lang} />
      <title>{title}</title>
      <meta name="description" content={description} />
      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} />
      <meta property="og:type" content="music.song" />
      {avatar && <meta property="og:image" content={avatar} />}
      <meta property="og:image:width" content="1200" />
      <meta property="og:image:height" content="630" />
      <meta property="og:url" content={canonicalUrl} />
      <meta property="og:site_name " content={title} />
      <meta property="og:audio:type" content="audio/vnd.facebook.bridge" />
      <link rel="canonical" href={canonicalUrl} />
      <link
        rel="alternate"
        type="application/json+oembed"
        href={oembedUrl}
        title={title}
      />
      {applePodcastId && (
        <meta name="apple-itunes-app" content={`app-id=${applePodcastId}`} />
      )}
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:site" content="@firstorylab" />
      <meta name="twitter:url" content={canonicalUrl} />
      <meta name="twitter:title" content={title} />
      <meta name="twitter:description" content={description} />
      {avatar && <meta name="twitter:image" content={avatar} />}
      <script type="application/ld+json">{JSON.stringify(schemaData)}</script>
    </Helmet>
  );
}

type ShowHeaderProps = {
  isWebPlayer?: boolean;
  isEmbed?: boolean;
  show: Pick<
    Show,
    | 'id'
    | 'name'
    | 'author'
    | 'intro'
    | 'avatar'
    | 'urlSlug'
    | 'language'
    | 'distributions'
  >;
};

export default ShowHeader;
