import React from 'react';
import { useIntl } from 'react-intl';
import { useMutation } from '@apollo/client';
import { CreateComment, DeleteComment, PlayerCommentFind } from '../v2/comment';
import { useAlert } from '../../components/Alert';
import usePagination from '../../hooks/usePagination';
import { Comment } from '../../entities/comment';

export function useCreateComment() {
  const [mutation] = useMutation<CreateCommentData, CreateCommentVariables>(
    CreateComment,
  );

  const createComment = React.useCallback(
    ({ showId, episodeId, commentId, name, message }: CreateCommentArgs) => {
      const variables: CreateCommentVariables = { name, message };
      if (showId) {
        variables.commentTarget = {
          type: 'show',
          id: showId,
        };
      }
      if (episodeId) {
        variables.commentTarget = {
          type: 'episode',
          id: episodeId,
        };
      }
      if (commentId) {
        variables.commentTarget = {
          type: 'comment',
          id: commentId,
        };
      }
      return mutation({
        variables,
        refetchQueries: ['PlayerCommentFind'],
      });
    },
    [mutation],
  );

  return createComment;
}

type CreateCommentData = {
  playerCommentCreate: Comment;
};

type CreateCommentVariables = {
  name: string;
  message: string;
  commentTarget?: {
    id: string;
    type: 'show' | 'episode' | 'comment' | 'voicemail' | 'showAndEpisode';
  };
};

type CreateCommentArgs = {
  showId?: string;
  episodeId?: string;
  commentId?: string;
  name: string;
  message: string;
};

export function useDeleteComment({ commentId }: DeleteCommentVariables) {
  const intl = useIntl();
  const { openAlertDialog } = useAlert();
  const [mutation] = useMutation<DeleteCommentData, DeleteCommentVariables>(
    DeleteComment,
    {
      refetchQueries: ['PlayerCommentFind'],
    },
  );
  const deleteComment = React.useCallback(() => {
    openAlertDialog({
      variant: 'confirm',
      title: intl.formatMessage({ id: 'general.confirm' }),
      description: intl.formatMessage(
        { id: 'general.confirmDelete' },
        { title: intl.formatMessage({ id: 'general.comment' }) },
      ),
      onConfirmClick() {
        return mutation({
          variables: { commentId },
        });
      },
    });
  }, [commentId, mutation, openAlertDialog, intl]);

  return deleteComment;
}

type DeleteCommentData = {
  audienceCommentDelete: {
    id: string;
  };
};

type DeleteCommentVariables = {
  commentId: string;
};

export function useGetShowComments(showId: string) {
  return usePagination<GetShowCommentsData, GetShowCommentsVariables>({
    dataKey: 'playerCommentFind',
    query: PlayerCommentFind,
    variables: { id: showId, type: 'showAndEpisode' },
  });
}

type GetShowCommentsData = {
  playerCommentFind: Comment[];
};

type GetShowCommentsVariables = {
  id: string;
  type: 'showAndEpisode';
};

export function useGetEpisodeComments(episodeId: string) {
  return usePagination<GetEpisodeCommentsData, GetEpisodeCommentsVariables>({
    query: PlayerCommentFind,
    dataKey: 'playerCommentFind',
    variables: {
      id: episodeId,
      type: 'episode',
    },
  });
}

type GetEpisodeCommentsData = {
  playerCommentFind: Comment[];
};

type GetEpisodeCommentsVariables = {
  id: string;
  type: 'episode';
};
