import React from 'react';
import PlatformIcon from '../../components/icons/PlatformIcon';
import config from '../../config';
import PlatformItem from './PlatformItem';

function RssItem({ showId }: RssItemProps): JSX.Element {
  const rssUrl = `${config.rssUrlBase}/rss/user/${showId}`;

  return (
    <PlatformItem
      title="RSS Feed"
      url={rssUrl}
      startIcon={<PlatformIcon type={{ type: undefined, variant: 'rss' }} />}
    />
  );
}

type RssItemProps = {
  showId: string;
};

export default RssItem;
