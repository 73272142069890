import {
  Button,
  List,
  ListItem,
  Paper,
  Theme,
  Typography,
  makeStyles,
} from '@material-ui/core';
import { Link, useLocation } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import { Post } from '../../entities/post';
import { Stack } from '../../components/Stack';

const useStyles = makeStyles((theme: Theme) => ({
  unlock: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: theme.spacing(6),
    marginBottom: theme.spacing(2),
    padding: theme.spacing(3.5),
  },
  unlockText: {
    marginBottom: theme.spacing(2),
  },
  button: {
    borderRadius: 20,
  },
  buttonContainer: {
    marginTop: theme.spacing(2),
    maxWidth: 300,
    width: '100%',
  },
}));

export function SubscriptionLock(props: {
  isLoggedIn: boolean;
  post: Post;
  children?: React.ReactNode;
}) {
  const { post, isLoggedIn } = props;
  const classes = useStyles();

  const postHasEntitlement = !!post.entitlements?.length;
  const location = useLocation();

  const postLocked = !post.contentUrl && postHasEntitlement;
  if (!postLocked) {
    return <>{props.children}</>;
  }

  return (
    <Paper variant="outlined" className={classes.unlock}>
      <Typography className={classes.unlockText} component="div">
        <FormattedMessage
          id="community.unlock"
          values={{
            name: <strong>{post.show.name}</strong>,
            tiers: (
              <List
                style={{
                  listStyleType: 'disc',
                  marginBlock: '1em',
                  paddingInlineStart: '1rem',
                }}
              >
                {post.entitlements?.map(
                  e =>
                    (
                      <ListItem
                        key={e.tier?.title}
                        style={{ display: 'list-item', fontWeight: 600 }}
                      >
                        {e.tier?.title}
                      </ListItem>
                    ) || '',
                )}
              </List>
            ),
          }}
        />
      </Typography>
      <Stack
        className={classes.buttonContainer}
        spacing={1.25}
        alignItems="center"
      >
        <Button
          variant="contained"
          color="primary"
          className={classes.button}
          component="a"
          href={`/join/${post.show.id}`}
          target="_blank"
          rel="noopener noreferrer"
          fullWidth
        >
          <FormattedMessage id="community.join" />
        </Button>

        {!isLoggedIn && (
          <Button
            component={Link}
            variant="contained"
            className={classes.button}
            to={{
              pathname: '/login',
              search: `?next=${location.pathname}`,
              state: { background: location },
            }}
            fullWidth
          >
            <FormattedMessage id="auth.login" />
          </Button>
        )}
      </Stack>
    </Paper>
  );
}
