import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import Home from '../Home';
import ShowWithUrlSlugCheck, { Me } from '../Show';
import Episode from '../Episode';
import Search from '../Search';
import Playlist from '../Playlist';
import VoiceMail from '../VoiceMail';
import Donation from '../Donation';
import LinkWithSpotify from '../LinkWithSpotify';
import PRoute from '../../components/PrivateRoute';
import useMainPlayer from '../../components/Player/useMainPlayer';
import NotFound from '../../components/NotFound';
import DiscordAuthorizeResult from '../Auth/DiscordAuthorizeResult';
import PostDetail from '../Show/PostDetail';
import Layout from './Layout';
import WebPlayerContext from './context';

function WebPlayer() {
  const mainPlayerProps = useMainPlayer({});

  const contextValue = React.useMemo(() => {
    return {
      ...mainPlayerProps,
      isWebPlayer: true,
    };
  }, [mainPlayerProps]);

  return (
    <WebPlayerContext.Provider value={contextValue}>
      <Switch>
        <Route path="/" exact>
          <Redirect to="/browse" />
        </Route>
        <Route path="/browse">
          <Home />
        </Route>
        <Route
          path={[
            '/search/:searchType/:queryString',
            '/search/:searchType',
            '/search',
          ]}
        >
          <Search />
        </Route>
        <Route path="/user/me">
          <Me />
        </Route>
        <Route path="/user/:userId/posts/:postId">
          <PostDetail />
        </Route>
        <Route path={['/user/:urlSlug', '/embed/user/:urlSlug']}>
          <ShowWithUrlSlugCheck />
        </Route>
        <Route path={['/story/:episodeId', '/embed/story/:episodeId']}>
          <Episode />
        </Route>
        <Route
          path={['/playlists/:playlistId', '/embed/playlists/:playlistId']}
        >
          <Playlist />
        </Route>
        <Route path="/voicemail/:id">
          <VoiceMail />
        </Route>
        <PRoute path="/donation">
          <Donation />
        </PRoute>
        <PRoute path="/link-with-spotify">
          <LinkWithSpotify />
        </PRoute>
        <PRoute path="/auth/discord/callback">
          <DiscordAuthorizeResult />
        </PRoute>
        <Route>
          <Layout hideController>
            <NotFound />
          </Layout>
        </Route>
      </Switch>
    </WebPlayerContext.Provider>
  );
}

export default WebPlayer;
