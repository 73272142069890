import React from 'react';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import { useIntl } from 'react-intl';
import { makeStyles } from '@material-ui/styles';
import Menu from '@material-ui/core/Menu';
import Avatar from '@material-ui/core/Avatar';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import MonetizationOnIcon from '@material-ui/icons/MonetizationOn';
import AuthContext from '../Auth/context';
import { useGetMeLegacy } from '../../gql/hooks/user';
import { Button } from '@material-ui/core';
import Skeleton from '@material-ui/lab/Skeleton';
import { ExitToAppOutlined } from '@material-ui/icons';

const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: theme.palette.action.hover,
    },
    padding: theme.spacing(1),
    marginLeft: theme.spacing(1),
  },
}));

function AccountMenu({ open, onOpen, onClose }) {
  const classes = useStyles();
  const anchorRef = React.useRef();
  const auth = React.useContext(AuthContext);
  const { loading, data } = useGetMeLegacy();
  const location = useLocation();
  const intl = useIntl();

  const handleLogoutClick = React.useCallback(() => {
    auth.handleLogout();
    onClose();
  }, [auth, onClose]);

  if (loading || typeof window === 'undefined') {
    return (
      <div style={{ padding: 8 }}>
        <Skeleton
          width={35}
          height={35}
          variant="circle"
          style={{ backgroundColor: '#555', marginLeft: 8 }}
        />
      </div>
    );
  }

  if (!data?.me) {
    return (
      <Button
        variant="contained"
        color="primary"
        component={RouterLink}
        to={{
          pathname: '/login',
          state: { background: location },
        }}
        style={{ marginLeft: 8 }}
      >
        {intl.formatMessage({ id: 'auth.login' })}
      </Button>
    );
  }

  return (
    <React.Fragment>
      <div ref={anchorRef} className={classes.container} onClick={onOpen}>
        {data?.me?.avatar ? (
          <Avatar src={data.me.avatar} />
        ) : (
          <AccountCircleIcon fontSize="large" />
        )}
      </div>
      <Menu
        anchorEl={anchorRef.current}
        open={open}
        onClose={onClose}
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <List dense>
          <ListItem button component={RouterLink} to={`/user/${data?.me.id}`}>
            <ListItemAvatar>
              <Avatar variant="rounded" src={data.me.avatar} />
            </ListItemAvatar>
            <ListItemText
              primary={data.me.name}
              secondary={data.me.account.email}
            />
          </ListItem>
          <ListItem button component={RouterLink} to="/donation">
            <ListItemAvatar>
              <Avatar variant="rounded">
                <MonetizationOnIcon />
              </Avatar>
            </ListItemAvatar>
            <ListItemText
              primary={intl.formatMessage({ id: 'podcast.manageDonation' })}
            />
          </ListItem>

          <ListItem button onClick={handleLogoutClick}>
            <ListItemAvatar>
              <Avatar variant="rounded">
                <ExitToAppOutlined />
              </Avatar>
            </ListItemAvatar>
            <ListItemText primary={intl.formatMessage({ id: 'auth.logout' })} />
          </ListItem>
        </List>
      </Menu>
    </React.Fragment>
  );
}

export default AccountMenu;
