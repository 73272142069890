import React from 'react';
import { FormattedMessage } from 'react-intl';
import { useLocation } from 'react-router-dom';
import { makeStyles } from '@material-ui/styles';
import { Paper, Typography, Theme, Link } from '@material-ui/core';
import { GetMeLegacyData } from '../../../gql/hooks/user';
import { useAuth } from '../../Auth/context';
import { Subscription, SubscriptionStatus } from '../../../entities/membership';

const useStyles = makeStyles((theme: Theme) => ({
  me: {
    padding: theme.spacing(2),
    marginBottom: theme.spacing(1),
  },
  logout: {
    cursor: 'pointer',
  },
}));

function MeInfo({
  me,
  isRecurring,
  currentSubscription,
}: MeInfoProps): JSX.Element | null {
  const classes = useStyles();
  const location = useLocation();
  const { handleLogout } = useAuth();

  const logout = React.useCallback(() => {
    handleLogout({
      next: location.pathname,
    });
  }, [handleLogout, location]);

  if (!me) {
    return null;
  }

  return (
    <Paper variant="outlined" className={classes.me}>
      <React.Fragment>
        <Typography color="textPrimary">
          <FormattedMessage
            id="payment.loggedInAs"
            values={{
              email: <b>{me.studioUserFindOne.email}</b>,
              logout: (chunk: string) => (
                <Link className={classes.logout} onClick={logout}>
                  {chunk}
                </Link>
              ),
            }}
          />
        </Typography>
        {isRecurring &&
          currentSubscription?.status === SubscriptionStatus.active && (
            <Typography color="textPrimary">
              <FormattedMessage
                id="donation.currentTier"
                values={{ title: <b>{currentSubscription.tier.title}</b> }}
              />
            </Typography>
          )}
      </React.Fragment>
    </Paper>
  );
}

type MeInfoProps = {
  me: GetMeLegacyData | null;
  isRecurring?: boolean;
  currentSubscription?: Subscription | null;
};

export default MeInfo;
