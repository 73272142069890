import React from 'react';
import { makeStyles } from '@material-ui/styles';
import { Theme } from '@material-ui/core';
import { Show } from '../../../entities/show';

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    background: theme.palette.background.default,
    width: '100%',
    '@media(min-width: 800px)': {
      height: '100%',
      display: 'flex',
      flexDirection: 'row',
    },
  },
  imageContainer: {
    position: 'relative',
    overflow: 'hidden',
    '@media(max-width: 800px)': {
      height: 250,
    },
    '@media(max-width: 500px)': {
      height: 150,
    },
    '@media(min-width: 800px)': {
      flex: 1,
    },
  },
  backgroundImage: {
    position: 'absolute',
    width: '100%',
    filter: 'blur(5px)',
    opacity: 0.87,
    backgroundImage: (props: StyleProps) => `url(${props.backgroundImage})`,
    backgroundSize: 'cover',
    '@media(max-width: 800px)': {
      bottom: 80,
      '&::after': {
        content: '""',
        display: 'block',
        paddingBottom: '100%',
      },
    },
    '@media(max-width: 500px)': {
      bottom: 30,
    },
    '@media(min-width: 800px)': {
      height: '100%',
      backgroundPosition: 'center',
    },
  },
  avatar: {
    position: 'absolute',
    left: 0,
    right: 0,
    bottom: 0,
    margin: 'auto',
    objectFit: 'cover',
    '@media(max-width: 800px)': {
      width: 200,
      height: 200,
    },
    '@media(max-width: 500px)': {
      width: 120,
      height: 120,
    },
    '@media(min-width: 800px)': {
      top: 0,
      width: '90%',
      maxWidth: 450,
      '&::after': {
        content: '""',
        display: 'block',
        paddingBottom: '90%',
      },
    },
  },
  mainContainer: {
    overflow: 'auto',
    display: 'flex',
    flexDirection: 'column',
    boxSizing: 'border-box',
    '@media(min-width: 800px)': {
      width: 500,
    },
    '@media(max-width: 800px)': {
      paddingBottom: 120,
    },
  },
}));

function JoinMemberLayout({
  show,
  children,
}: JoinMemberLayoutProps): JSX.Element {
  const { name, avatar } = show;
  const classes = useStyles({ backgroundImage: avatar });

  return (
    <div className={classes.container}>
      <div className={classes.imageContainer}>
        <div className={classes.backgroundImage} />
        <img src={avatar || '#'} alt={name} className={classes.avatar} />
      </div>
      <div className={classes.mainContainer}>{children}</div>
    </div>
  );
}

type JoinMemberLayoutProps = {
  show: Pick<Show, 'name' | 'avatar'>;
  children: React.ReactNode;
};

type StyleProps = {
  backgroundImage: string | null;
};

export default JoinMemberLayout;
