import React from 'react';
import { Helmet } from 'react-helmet-async';
import config from '../../../config';
import { Define } from '../../../define';
import { Distribution } from '../../../entities/distribution';
import { htmlToText } from '../../../utils';

function FlinkHeader({
  type,
  title: _title,
  description: _description,
  imageUrl,
  language,
  urlSlugOrId,
  distributions,
}: FlinkHeaderProps): JSX.Element {
  const title = `${_title} Podcast Platforms - Flink by Firstory`;

  const description = React.useMemo(() => {
    const d = htmlToText(_description);
    return d + '\n Podcast powered by Firstory.';
  }, [_description]);

  const lang = (language || 'ZH').toLowerCase();

  const playerUrlPrefix = type === 'show' ? 'user' : 'story';

  const playerUrl = `${config.url}/${playerUrlPrefix}/${urlSlugOrId}/platforms`;

  const oembedUrl = `${config.url}/oembed?url=${encodeURIComponent(playerUrl)}`;
  const canonicalUrl = playerUrl;

  const platforms = React.useMemo(() => {
    const distributionMap: Map<
      Define.Distribution.Platform.Type,
      Distribution
    > = new Map();

    distributions.forEach(d => {
      distributionMap.set(d.platformType, d);
    });

    const list: ListItem[] = [
      {
        '@type': 'ListItem',
        position: 1,
        url: `${config.url}/${playerUrlPrefix}/${urlSlugOrId}`,
      },
    ];

    const platforms = [
      Define.Distribution.Platform.kkbox,
      Define.Distribution.Platform.spotify,
      Define.Distribution.Platform.apple,
      Define.Distribution.Platform.google,
      Define.Distribution.Platform.pocketcast,
    ] as const;

    platforms.forEach((platform, i) => {
      const url = distributionMap.get(platform)?.platformUrl;

      if (url) {
        list.push({
          '@type': 'ListItem',
          position: i + 1,
          url,
        });
      }
    });

    return list;
  }, [distributions, playerUrlPrefix, urlSlugOrId]);

  const schemaData = {
    '@context': 'https://schema.org',
    '@type': 'ItemList',
    itemListElement: platforms,
  };

  return (
    <Helmet>
      <html lang={lang} />
      <title>{title}</title>
      <meta name="description" content={description} />
      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} />
      <meta property="og:type" content="music.song" />
      {imageUrl && <meta property="og:image" content={imageUrl} />}
      <meta property="og:image:width" content="1200" />
      <meta property="og:image:height" content="630" />
      <meta property="og:url" content={canonicalUrl} />
      <meta property="og:site_name " content={title} />
      <meta property="og:audio:type" content="audio/vnd.facebook.bridge" />
      <link rel="canonical" href={canonicalUrl} />
      <link
        rel="alternate"
        type="application/json+oembed"
        href={oembedUrl}
        title={title}
      />
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:site" content="@firstorylab" />
      <meta name="twitter:url" content={canonicalUrl} />
      <meta name="twitter:title" content={title} />
      <meta name="twitter:description" content={description} />
      {imageUrl && <meta name="twitter:image" content={imageUrl} />}
      <script type="application/ld+json">{JSON.stringify(schemaData)}</script>
    </Helmet>
  );
}

type FlinkHeaderProps = {
  type: 'show' | 'episode';
  title: string;
  description: string;
  imageUrl: string;
  language: string | null;
  urlSlugOrId: string;
  distributions: Distribution[];
};

type ListItem = {
  '@type': 'ListItem';
  position: number;
  url: string;
};

export default FlinkHeader;
