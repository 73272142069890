import React from 'react';
import { makeStyles } from '@material-ui/styles';
import { Typography, Link, Theme } from '@material-ui/core';
import Logo from '../../../components/Logo';

const useStyles = makeStyles((theme: Theme) => ({
  footer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    paddingTop: theme.spacing(12),
    paddingBottom: theme.spacing(4),
  },
  logo: {
    height: 24,
    width: 24,
    marginRight: theme.spacing(1),
  },
  firstory: {
    textDecoration: 'underline',
    fontSize: '0.8rem',
  },
}));

function Footer(): JSX.Element {
  const classes = useStyles();

  return (
    <footer className={classes.footer}>
      <Logo className={classes.logo} />
      <Typography variant="caption" color="textPrimary">
        Podcast powered and distributed by{' '}
        <Link
          color="textPrimary"
          href="https://firstory.me"
          target="_blank"
          rel="noreferrer noopener"
          className={classes.firstory}
        >
          Firstory
        </Link>
      </Typography>
    </footer>
  );
}

export default Footer;
