import { FormattedMessage } from 'react-intl';
import { makeStyles } from '@material-ui/styles';
import { Container, Grid, Paper, Theme, Typography } from '@material-ui/core';
import CenterLoading from '../../components/CenterLoading';
import { useGetPosts } from '../../gql/hooks/post';
import { Show } from '../../entities/show';
import LoadMore from '../../components/LoadMore';
import { ShowMembership, SubscriptionStatus } from '../../entities/membership';
import PostItem from './PostItem';

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(12),
  },
  subscription: {
    padding: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
}));

function ShowCommunity({
  show,
  showMembership,
  hasLogin,
}: ShowCommunityProps): JSX.Element {
  const classes = useStyles();
  const { loading, data, loadMore, hasMore, isFetchingAfter } = useGetPosts(
    show.id,
  );

  if (loading) {
    return <CenterLoading key="loading" />;
  }

  const posts = data?.playerPostFind || [];
  const currentSubscription = showMembership?.currentSubscription;
  const isActive =
    currentSubscription?.status === SubscriptionStatus.active ||
    currentSubscription?.status === SubscriptionStatus.canceling;

  return (
    <Container maxWidth="md" className={classes.container}>
      {isActive && (
        <Paper variant="outlined" className={classes.subscription}>
          <Typography color="textPrimary">
            <FormattedMessage
              id="donation.currentTier"
              values={{ title: <b>{currentSubscription.tier.title}</b> }}
            />
          </Typography>
        </Paper>
      )}
      {posts.length === 0 && (
        <Typography color="textPrimary">
          <FormattedMessage id="community.noPost" />
        </Typography>
      )}
      <Grid container spacing={2}>
        {posts.map(p => (
          <PostItem
            key={p.id}
            author={show}
            post={{ ...p, show }}
            hasLogin={hasLogin}
          />
        ))}
      </Grid>
      <LoadMore
        hasMore={hasMore}
        loadMore={loadMore}
        loading={isFetchingAfter}
      />
    </Container>
  );
}

type ShowCommunityProps = {
  show: Show;
  showMembership?: ShowMembership;
  hasLogin: boolean;
};

export default ShowCommunity;
