import { gql } from '@apollo/client';
import { CommentFragment } from './fragments';

export const PlayerCommentFind = gql`
  query PlayerCommentFind(
    $type: CommentTargetType!
    $id: ID!
    $first: Int
    $after: String
  ) {
    playerCommentFind(
      commentTarget: { type: $type, id: $id }
      take: $first
      after: $after
    ) {
      ...CommentFragment
    }
  }
  ${CommentFragment}
`;

export const CreateComment = gql`
  mutation CreateComment(
    $commentTarget: CommentTargetInput!
    $name: String
    $message: String
  ) {
    playerCommentCreate(
      type: 1
      commentTarget: $commentTarget
      name: $name
      message: $message
    ) {
      id
      type
      name
      createdAt
      author {
        id
        name
        avatar
      }
      textComment {
        message
      }
      audioComment {
        url
        duration
      }
    }
  }
`;

export const DeleteComment = gql`
  mutation DeleteComment($commentId: ID!) {
    audienceCommentDelete(commentId: $commentId) {
      id
    }
  }
`;
