import React from 'react';
import { Route, Switch, useLocation } from 'react-router-dom';
import { IntlProvider } from 'react-intl';
import { ThemeProvider, makeStyles } from '@material-ui/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import loadable from '@loadable/component';
import WebPlayer from './pages/WebPlayer';
import JoinMember from './pages/Donation/Join';
import SubscriptionResult from './pages/Donation/Join/SubscriptionResult';
import Refresh from './pages/Refresh';
import { AlertProvider } from './components/Alert';
import ErrorBoundary from './components/ErrorBoundary';
import OneLink from './components/OneLink';
import RedirectWithStatus from './components/RedirectWithStatus';
import { lightTheme, darkTheme } from './theme';
import { loadHotjar } from './utils';
import { defaultLang, messages } from './i18n';
import { LangContext } from './i18n/LangProvider';
import DonationResult from './pages/Donation/Join/DonationResult';
import ShowFlink from './pages/Distribution/ShowFlink';
import EpisodeFlink from './pages/Distribution/EpisodeFlink';
import { TrackerProvider } from './analytics/tracker';
import { useUserContext } from './hooks/useUserContext';

const Auth = loadable(() => import('./pages/Auth'));
const UserSite = loadable(() => import('./pages/UserSite'));

const rootStyle = {
  display: 'flex',
  height: '100%',
  width: '100%',
  margin: 0,
  padding: 0,
  overflowX: 'hidden',
  backgroundColor: 'transparent',
};

const useStyles = makeStyles({
  '@global': {
    html: rootStyle,
    body: rootStyle,
    '#root': rootStyle,
    a: {
      textDecoration: 'none',
    },
  },
});

function Main({ subdomain }) {
  useStyles();
  useUserContext();
  const { lang } = React.useContext(LangContext);
  const location = useLocation();
  const background = location.state && location.state.background;
  const isWebPlayer = !subdomain || subdomain === 'open';
  let showUrlSlug = subdomain;
  if (subdomain === 'lab') {
    showUrlSlug = 'firstorylab';
  }

  React.useEffect(() => {
    loadHotjar();
  }, []);

  const authRoute = (
    <Route path={['/login', '/signup', '/password-reset']}>
      <Auth subdomain={subdomain} />
    </Route>
  );

  if (
    subdomain === 'firstorylab' ||
    subdomain === 'cjyqorlaf2pg20743imp927zg'
  ) {
    const redirectUrl = `https://lab.firstory.io${location.pathname}`;
    return <RedirectWithStatus isExternal to={redirectUrl} status={301} />;
  }

  return (
    <IntlProvider
      locale={lang}
      messages={messages[lang]}
      defaultLocale={defaultLang}
    >
      <ThemeProvider theme={isWebPlayer ? darkTheme : lightTheme}>
        <AlertProvider>
          <ErrorBoundary>
            <TrackerProvider>
              <CssBaseline />
              <Switch location={background || location}>
                <Route path="/store" component={OneLink} />
                {authRoute}
                <Route path="/user/:showId/platforms">
                  <ShowFlink />
                </Route>
                <Route path="/story/:episodeId/platforms">
                  <EpisodeFlink />
                </Route>
                <Route path="/join/:showId">
                  <JoinMember />
                </Route>
                <Route path="/donation-result/:donationId">
                  <DonationResult />
                </Route>
                <Route path="/subscription-result/:subscriptionId">
                  <SubscriptionResult />
                </Route>
                <Route path="/refresh">
                  <Refresh />
                </Route>
                <Route>
                  {isWebPlayer ? (
                    <WebPlayer />
                  ) : (
                    showUrlSlug !== 'auth' && (
                      <UserSite showUrlSlug={showUrlSlug} />
                    )
                  )}
                </Route>
              </Switch>
              {background && authRoute}
            </TrackerProvider>
          </ErrorBoundary>
        </AlertProvider>
      </ThemeProvider>
    </IntlProvider>
  );
}

export default Main;
