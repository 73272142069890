import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import NoSsr from '@material-ui/core/NoSsr';
import { useGetMeLegacy } from '../gql/hooks/user';
import CenterLoading from './CenterLoading';

function PrivateRoute({ children, ...rest }) {
  const { loading, data } = useGetMeLegacy();

  return (
    <Route
      {...rest}
      render={({ location }) => {
        if (loading) {
          return <CenterLoading />;
        }

        if (!data || !data.me) {
          const next = `${location.pathname}${location.search}`;

          return (
            <Redirect
              to={{
                pathname: '/login',
                search: `?next=${encodeURIComponent(next)}`,
              }}
            />
          );
        } else {
          return children;
        }
      }}
    />
  );
}

function PrivateRouteNoSsr(props) {
  return (
    <NoSsr>
      <PrivateRoute {...props} />
    </NoSsr>
  );
}

export default PrivateRouteNoSsr;
