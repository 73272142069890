import React from 'react';
import { makeStyles } from '@material-ui/styles';
import { Container, NoSsr, Theme } from '@material-ui/core';
import CommentList from '../../components/CommentList';
import CenterLoading from '../../components/CenterLoading';
import { useTracker } from '../../analytics/tracker';
import GoogleAd from '../../components/GoogleAd';
import { Episode } from '../../entities/episode';
import { Comment } from '../../entities/comment';
import EpisodeInfo from './EpisodeInfo';

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    paddingBottom: theme.spacing(8),
  },
  embedContainer: {
    paddingTop: theme.spacing(2),
    margin: 'auto',
  },
  donationRankContainer: {
    paddingBottom: theme.spacing(8),
    '@media (max-width: 500px)': {},
  },
  commentListContainer: {},
  ads: {
    height: 180,
    width: '100%',
    marginBottom: theme.spacing(6),
  },
}));

function EpisodeContent({
  isEmbed,
  isCommentMode,
  showDescription,
  showComments,
  showFlink,
  storyLoading,
  commentsLoading,
  me,
  episode,
  comments,
  hasMore,
  hideAds,
  loadMore,
}: EpisodeContentProps): JSX.Element {
  const classes = useStyles();
  const tracker = useTracker();

  const title = episode.title;
  const author = episode.show;

  React.useEffect(() => {
    if (title) {
      tracker.viewStoryDetail({
        title,
        author,
      });
    }
  }, [title, author, tracker]);

  if (storyLoading) {
    return <CenterLoading />;
  }

  return (
    <Container
      maxWidth="md"
      className={isEmbed ? classes.embedContainer : classes.container}
    >
      <EpisodeInfo
        me={me}
        isEmbed={isEmbed}
        isCommentMode={isCommentMode}
        showDescription={showDescription}
        showFlink={showFlink}
        episode={episode}
      />
      {!hideAds && (
        <GoogleAd
          slot="3281283547"
          format="auto"
          fullWidthResponsive
          className={classes.ads}
        />
      )}
      {(!isEmbed || showComments) && (
        <div className={classes.commentListContainer}>
          <NoSsr>
            <CommentList
              isEmbed={isEmbed}
              loading={commentsLoading}
              record={episode}
              commentCount={episode.commentCount}
              comments={comments}
              hasMore={hasMore}
              loadMore={loadMore}
            />
          </NoSsr>
        </div>
      )}
    </Container>
  );
}

type EpisodeContentProps = {
  isEmbed?: boolean;
  isCommentMode?: boolean;
  showDescription?: boolean;
  showComments?: boolean;
  showFlink?: boolean;
  storyLoading: boolean;
  commentsLoading: boolean;
  me: { id: string } | null;
  episode: Episode;
  comments: Comment[];
  hasMore: boolean;
  loadMore: () => Promise<unknown>;
  hideAds?: boolean;
};

export default EpisodeContent;
