import React from 'react';
import { FormattedMessage } from 'react-intl';
import config from '../../config';
import SpotifyBaseButton from './BaseButton';

function SpotifyLoginButton({
  scopes,
  disabled,
}: SpotifyLoginButtonProps): JSX.Element {
  const url = React.useMemo(() => {
    const u = new URL('https://accounts.spotify.com/authorize');
    u.searchParams.set('client_id', config.spotifyOpenAccessClientId);
    u.searchParams.set('response_type', 'code');
    u.searchParams.set(
      'redirect_uri',
      config.url + '/link-with-spotify/callback',
    ),
      u.searchParams.set('scope', scopes.join(' '));

    return u.href;
  }, [scopes]);

  return (
    <SpotifyBaseButton
      size="large"
      component="a"
      href={url}
      disabled={disabled}
    >
      <FormattedMessage id="donation.loginWithSpotify" />
    </SpotifyBaseButton>
  );
}

type SpotifyLoginButtonProps = {
  scopes: string[];
  disabled?: boolean;
};

export default SpotifyLoginButton;
