import React from 'react';
import { makeStyles } from '@material-ui/styles';
import { Link, Typography, Theme } from '@material-ui/core';
import FavoriteIcon from '@material-ui/icons/Favorite';

const useStyles = makeStyles((theme: Theme) => ({
  madeBy: {
    marginTop: theme.spacing(4),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  love: {
    fontSize: 12,
    margin: theme.spacing(0, 0.5),
  },
}));

function PoweredByFirstory() {
  const classes = useStyles();

  return (
    <div className={classes.madeBy}>
      <Typography variant="body2" color="textSecondary">
        Made with{' '}
      </Typography>
      <FavoriteIcon color="primary" className={classes.love} />
      <Typography variant="body2" color="textSecondary">
        by{' '}
        <Link
          href="https://firstory.me"
          target="_blank"
          rel="noreferrer noopener"
        >
          Firstory
        </Link>
      </Typography>
    </div>
  );
}

export default PoweredByFirstory;
