import React from 'react';
import { useIntl } from 'react-intl';
import { makeStyles } from '@material-ui/styles';
import {
  Avatar,
  Typography,
  TableRow,
  TableCell,
  Theme,
} from '@material-ui/core';
import { PaginationTable } from '../../components/Table';
import CenterLoading from '../../components/CenterLoading';
import { useGetDonations } from '../../gql/hooks/membership/donation';
import { useSearchShow } from '../../gql/hooks/show/search';
import { formatDate } from '../../utils';
import { DonationWithShow } from './type';
import { formatMoney } from './Join/util';

const useStyles = makeStyles((theme: Theme) => ({
  info: {
    display: 'flex',
    alignItems: 'center',
  },
  name: {
    marginLeft: theme.spacing(1),
    fontWeight: 'bold',
  },
  nowrap: {
    whiteSpace: 'nowrap',
  },
}));

function Row({ data }: RowProps): JSX.Element {
  const classes = useStyles();
  const { createdAt, show, plan } = data;

  return (
    <TableRow>
      <TableCell component="th" scope="row">
        <div className={classes.info}>
          <Avatar variant="rounded" src={show.avatar || ''} />
          <Typography className={classes.name}>{show.name}</Typography>
        </div>
      </TableCell>
      <TableCell className={classes.nowrap}>{formatDate(createdAt)}</TableCell>
      <TableCell className={classes.nowrap}>
        {formatMoney(plan.price.money)}
      </TableCell>
    </TableRow>
  );
}

function DonationList(): JSX.Element {
  const intl = useIntl();
  const { loading, data, ...paginationProps } = useGetDonations();

  const donations = data?.donations || [];

  const showIds = Array.from(new Set(donations.map(d => d.show.showId)));

  const { loading: showLoading, data: showData } = useSearchShow({
    showIds,
    skip: showIds.length === 0,
  });

  if (loading || showLoading) {
    return <CenterLoading />;
  }

  const headers = [
    intl.formatMessage({ id: 'donation.podcast' }),
    intl.formatMessage({ id: 'donation.date' }),
    intl.formatMessage({ id: 'donation.amount' }),
  ];

  const showMap: Record<
    string,
    {
      id: string;
      name: string;
      avatar: string | null;
      urlSlug: string | null;
    }
  > = {};
  showData?.playerShowFind?.forEach(s => {
    showMap[s.id] = s;
  });

  const arr = donations.map(d => ({
    ...d,
    show: {
      ...d.show,
      ...showMap[d.show.showId],
    },
  }));

  return (
    <PaginationTable
      columnCount={4}
      headers={headers}
      data={arr}
      {...paginationProps}
      renderRow={data => <Row key={data.id} data={data} />}
    />
  );
}

type RowProps = {
  data: DonationWithShow;
};

export default DonationList;
