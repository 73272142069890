import config from '../../config';
import { loadScript } from './util';

export async function loadTappaySdk() {
  // await loadScript('tappay-sdk', 'https://js.tappaysdk.com/tpdirect/v5.1.0');
  await loadScript('tappay-sdk', `${config.url}/js/tappay.js`);

  if (window.scriptLoaded.tappay) {
    return window.TPDirect;
  }

  const { appId, appKey, serverType } = config.tappay;

  window.TPDirect.setupSDK(appId, appKey, serverType);

  window.scriptLoaded.tappay = true;

  return window.TPDirect;
}
