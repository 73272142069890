import React from 'react';
import { Helmet } from 'react-helmet-async';
import { formatISODuration } from '../../../utils';
import config from '../../../config';
import { Episode } from '../../../entities/episode';
import { Playlist } from '../../../entities/playlist';

function PlaylistHeader({
  playlist,
  episodes,
}: PlaylistHeaderProps): JSX.Element {
  const { show } = playlist;
  const { urlSlug } = show;

  let subdomain = urlSlug;
  if (urlSlug === 'firstorylab') {
    subdomain = 'lab';
  }
  const title = `${playlist.title} | ${show.name}`;
  const description = playlist.description || show.intro || '';
  const lang = (show.language || 'ZH').toLowerCase();
  const imageUrl = show.avatar;

  const websiteBaseUrl = `https://${subdomain}.firstory.io`;
  const websiteUrl = `${websiteBaseUrl}/playlists/${playlist.id}`;

  const playerBaseUrl = config.url;
  const embedPlayerUrl = playerBaseUrl + `/embed/playlists/${playlist.id}`;
  const oembedUrl = `${playerBaseUrl}/oembed?url=${encodeURIComponent(
    embedPlayerUrl,
  )}`;

  const canonicalUrl = websiteUrl;

  const schemaData = {
    '@context': 'https://schema.org',
    '@type': 'MusicPlaylist',
    name: title,
    author: { '@type': 'Person', name: show.author || show.name },
    description,
    url: canonicalUrl,
    image: imageUrl,
    offers: [{ '@type': 'Offer', price: 'Free' }],
    track: episodes.map(e => ({
      '@type': 'MusicRecording',
      name: e.title,
      url: `${websiteBaseUrl}/episodes/${e.id}`,
      duration: formatISODuration(e.duration),
      genre: 'Storytelling',
      description: e.description,
    })),
  };

  return (
    <Helmet>
      <html lang={lang} />
      <title>{title}</title>
      <meta name="description" content={description} />
      <meta property="og:title" content={title} />
      <meta property="og:type" content="music.playlist" />
      <meta property="og:description" content={description} />
      <meta property="og:site_name " content={show.name} />
      <meta property="og:audio:type" content="audio/vnd.facebook.bridge" />
      {imageUrl && <meta property="og:image" content={imageUrl} />}
      <meta property="og:image:width" content="1200" />
      <meta property="og:image:height" content="630" />
      <meta property="og:url" content={canonicalUrl} />
      <link rel="canonical" href={canonicalUrl} />
      <link
        rel="alternate"
        type="application/json+oembed"
        href={oembedUrl}
        title={title}
      />
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:site" content="@firstorylab" />
      <meta name="twitter:url" content={canonicalUrl} />
      <meta name="twitter:title" content={title} />
      <meta name="twitter:description" content={description} />
      {imageUrl && <meta name="twitter:image" content={imageUrl} />}
      <script type="application/ld+json">{JSON.stringify(schemaData)}</script>
    </Helmet>
  );
}

type PlaylistHeaderProps = {
  playlist: Playlist;
  episodes: Episode[];
};

export default PlaylistHeader;
