import React from 'react';
import { useIntl, FormattedMessage } from 'react-intl';
import cx from 'classnames';
import { makeStyles } from '@material-ui/styles';
import Typography from '@material-ui/core/Typography';
import Tooltip from '@material-ui/core/Tooltip';
import LockIcon from '@material-ui/icons/LockOutlined';
import HelpIcon from '@material-ui/icons/HelpOutline';
import { amex, card, jcb, mastercard, union, visa } from '../assets/cards';
import { DirectPayFormValues } from '../hooks/useTapPayDirectPayForm';

const useStyles = makeStyles({
  '@global': {
    '#card-number': {
      paddingLeft: 65,
    },
    '.tpfield': {
      position: 'relative',
      fontSize: 18,
      height: 56,
      width: '100%',
      borderWidth: 1,
      borderStyle: 'solid',
      borderColor: 'rgba(0, 0, 0, 0.23)',
      borderRadius: 4,
      marginTop: 5,
      marginBottom: 15,
      padding: '0 14px',
      display: 'flex',
      alignItems: 'center',
    },
    '.tappay-field-focus': {
      borderColor: 'rgba(0, 0, 0, 0.87)',
    },
    'input.tpfield': {
      fontSize: 16,
      backgroundColor: 'transparent',
    },
    'input.tpfield:focus': {
      outline: 'none',
      borderColor: 'rgba(0, 0, 0, 0.87)',
    },
  },
  container: {
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  inputRow: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
    maxWidth: 400,
  },
  labelRow: {
    display: 'flex',
    alignItems: 'center',
  },
  inputGroup: {
    position: 'relative',
    width: '100%',
    maxWidth: 400,
  },
  inputGroupSmall: {
    width: '45%',
    maxWidth: 180,
  },
  cardImg: {
    height: 30,
    marginRight: 10,
    position: 'absolute',
    left: 14,
  },
  lockIcon: {
    position: 'absolute',
    right: 0,
    top: 0,
  },
  lockTooltip: {
    fontSize: 12,
  },
  cvvTooltip: {
    maxWidth: 150,
    fontSize: 12,
  },
});

const cardImages = {
  mastercard,
  visa,
  jcb,
  amex,
  union,
};

const TapPayDirectPayForm = React.forwardRef(
  (
    {
      className,
      style,
      values,
      cardType,
      disableEmailEdit,
      onValueChange,
    }: DirectPayFormProps,
    ref: React.RefObject<HTMLDivElement>,
  ) => {
    const intl = useIntl();
    const classes = useStyles();
    const cardImg = cardImages[cardType] || card;

    return (
      <div ref={ref} className={cx(className, classes.container)} style={style}>
        <div className={classes.inputGroup}>
          <Typography color="textPrimary">
            <FormattedMessage id="payment.cardNumber" />
          </Typography>
          <div className="tpfield" id="card-number">
            <img className={classes.cardImg} src={cardImg} alt="card" />
          </div>
          <div className={classes.lockIcon}>
            <Tooltip
              title={intl.formatMessage({ id: 'payment.secure' })}
              placement="top"
              classes={{ tooltip: classes.lockTooltip }}
            >
              <LockIcon color="action" />
            </Tooltip>
          </div>
        </div>
        <div className={classes.inputRow}>
          <div className={classes.inputGroupSmall}>
            <Typography color="textPrimary">
              <FormattedMessage id="payment.cardExpire" />
            </Typography>
            <div className="tpfield small" id="card-expiration-date" />
          </div>
          <div className={classes.inputGroupSmall}>
            <div className={classes.labelRow}>
              <Typography color="textPrimary">
                <FormattedMessage id="payment.cardCVV" />
              </Typography>
              <Tooltip
                title={intl.formatMessage({ id: 'payment.cardCVVHint' })}
                placement="top"
                classes={{ tooltip: classes.cvvTooltip }}
              >
                <HelpIcon color="action" fontSize="small" />
              </Tooltip>
            </div>
            <div className="tpfield small" id="card-ccv" />
          </div>
        </div>
        <div className={classes.inputGroup}>
          <Typography color="textPrimary">
            <FormattedMessage id="payment.cardHolderName" />
          </Typography>
          <input
            className="tpfield"
            placeholder={intl.formatMessage({ id: 'payment.name' })}
            value={values.paymentName}
            onChange={onValueChange('paymentName')}
          />
        </div>
        <div className={classes.inputGroup}>
          <Typography color="textPrimary">
            <FormattedMessage id="payment.cardHolderPhone" />
          </Typography>
          <input
            className="tpfield"
            placeholder="+886912345678"
            value={values.phoneNumber}
            onChange={onValueChange('phoneNumber')}
          />
        </div>
        <div className={classes.inputGroup}>
          <Typography color="textPrimary">
            <FormattedMessage id="payment.cardHolderEmail" />
          </Typography>
          <input
            disabled={disableEmailEdit}
            className="tpfield"
            type="email"
            placeholder={intl.formatMessage({ id: 'payment.email' })}
            value={values.email}
            onChange={onValueChange('email')}
          />
        </div>
      </div>
    );
  },
);

TapPayDirectPayForm.displayName = 'TapPayDirectPayForm';

type DirectPayFormProps = {
  className?: string;
  style?: React.CSSProperties;
  values: DirectPayFormValues;
  cardType: TapPay.DirectCardType;
  disableEmailEdit?: boolean;
  onValueChange(
    name: keyof DirectPayFormValues,
  ): (e: React.ChangeEvent<HTMLInputElement>) => void;
};

export default TapPayDirectPayForm;
