import React from 'react';
import { loadTappaySdk } from '../utils';

function useTapPayDirectPayForm({ email }: UsePaymentFormArgs) {
  const [ref, setFormRef] =
    React.useState<React.RefObject<HTMLFormElement> | null>(null);
  const [cardType, setCardType] =
    React.useState<TapPay.DirectCardType>('unknown');
  const [canGetPrime, setCanGetPrime] = React.useState(false);
  const [canSubmit, setCanSubmit] = React.useState(false);
  const [values, setValues] = React.useState<DirectPayFormValues>({
    paymentName: '',
    phoneNumber: '',
    email,
  });

  const formRef = React.useCallback(r => {
    setFormRef(r);
  }, []);

  React.useEffect(() => {
    if (!ref) {
      return;
    }

    loadTappaySdk().then(TPDirect => {
      TPDirect.card.setup({
        fields: {
          number: {
            element: '#card-number',
            placeholder: '**** **** **** ****',
          },
          expirationDate: {
            element: '#card-expiration-date',
            placeholder: 'MM / YY',
          },
          ccv: {
            element: '#card-ccv',
            placeholder: 'CCV',
          },
        },
        styles: {
          input: {
            'font-size': '16px',
          },
          '.invalid': {
            color: 'red',
          },
        },
      });
      TPDirect.card.onUpdate(update => {
        setCanGetPrime(update.canGetPrime);
        setCardType(update.cardType);
      });
    });
  }, [ref]);

  React.useEffect(() => {
    const { paymentName, phoneNumber, email } = values;
    if (
      paymentName.trim() !== '' &&
      phoneNumber.trim() !== '' &&
      email.trim() !== ''
    ) {
      setCanSubmit(true);
    } else {
      setCanSubmit(false);
    }
  }, [values]);

  const getPrime = React.useCallback(async () => {
    const TPDirect = await loadTappaySdk();
    return new Promise<TapPay.DirectCardGetPrimeResult>(resolve =>
      TPDirect.card.getPrime(resolve),
    );
  }, []);

  const handleValueChange =
    (name: string) => (event: React.ChangeEvent<HTMLInputElement>) => {
      setValues({ ...values, [name]: event.target.value });
    };

  return {
    formRef,
    values,
    cardType,
    canGetPrime,
    canSubmit,
    getPrime,
    onValueChange: handleValueChange,
  };
}

type UsePaymentFormArgs = {
  email: string;
};

export type DirectPayFormValues = {
  email: string;
  paymentName: string;
  phoneNumber: string;
};

export default useTapPayDirectPayForm;
