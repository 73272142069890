import { Redirect } from 'react-router-dom';
import { makeStyles } from '@material-ui/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import Layout from '../WebPlayer/Layout';
import { useGetMeLegacy } from '../../gql/hooks/user';

const useStyles = makeStyles({
  container: {
    width: '100%',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
});

function Content({ loading, data }) {
  const classes = useStyles();

  if (loading) {
    return (
      <div className={classes.container}>
        <CircularProgress />
      </div>
    );
  }

  if (!data || !data.me) {
    return <Redirect to="/" />;
  }

  return (
    <Redirect
      to={{
        pathname: `/user/${data.me.urlSlug || data.me.id}`,
        state: { from: 'ME' },
      }}
    />
  );
}

function Me() {
  const queryProps = useGetMeLegacy();

  return (
    <Layout>
      <Content {...queryProps} />
    </Layout>
  );
}

export default Me;
