import React from 'react';
import { useMutation } from '@apollo/client';
import { AudienceAuthorizedSpotify } from '../../v2/membership';

export function useAuthorizedSpotify() {
  const [fn] = useMutation<
    AudienceAuthorizedSpotifyData,
    AudienceAuthorizedSpotifyVariables
  >(AudienceAuthorizedSpotify);

  const authorizedSpotify = React.useCallback(
    (code: string) => {
      return fn({
        variables: { code },
        context: { endpoint: 'membership' },
      });
    },
    [fn],
  );

  return authorizedSpotify;
}

type AudienceAuthorizedSpotifyData = {
  audienceAuthorizedSpotify: {
    completionUrl: string;
  };
};

type AudienceAuthorizedSpotifyVariables = {
  code: string;
};
