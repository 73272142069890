import { useQuery } from '@apollo/client';
import { VoiceMailBox } from '../../entities/voicemail';
import { GetVoiceMailBoxDetail } from '../v2/voicemail';

export function useGetVoiceMailBoxDetail(id: string) {
  return useQuery<GetVoiceMailBoxDetailData, GetVoiceMailBoxDetailVariables>(
    GetVoiceMailBoxDetail,
    {
      variables: {
        voicemailBoxId: id,
      },
    },
  );
}

type GetVoiceMailBoxDetailData = {
  playerVoicemailBoxFindOne: VoiceMailBox;
};

type GetVoiceMailBoxDetailVariables = {
  voicemailBoxId: string;
};
