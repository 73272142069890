import { useEffect, useLayoutEffect, useState } from 'react';

const useIsomorphicEffect =
  typeof document !== 'undefined' ? useLayoutEffect : useEffect;

export function useScrollDirection() {
  const [scrollDirection, setScrollDirection] = useState<'up' | 'down' | null>(
    null,
  );

  useIsomorphicEffect(() => {
    let lastScrollY = window.scrollY;
    const handleScroll = () => {
      const scrollY = window.scrollY;

      const direction = scrollY > lastScrollY ? 'down' : 'up';

      if (direction !== scrollDirection) {
        if (scrollY - lastScrollY > 1 || scrollY - lastScrollY < -1) {
          setScrollDirection(direction);
        }
      }

      lastScrollY = scrollY > 0 ? scrollY : 0;
    };

    if (typeof document !== 'undefined') {
      window.addEventListener('scroll', handleScroll, { passive: true });

      return () => {
        window.removeEventListener('scroll', handleScroll);
      };
    }
  }, [scrollDirection]);

  return { direction: scrollDirection };
}
