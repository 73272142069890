import React from 'react';
import { Button, makeStyles, Theme } from '@material-ui/core';
import { FormattedMessage } from 'react-intl';
import {
  PaymentMethod,
  Subscription,
} from '../../../../../entities/membership';
import PaymentMethodCard from '../../../PaymentMethod/Card';
import {
  useGetPaymentMethods,
  useUpdateSubscriptionPaymentMethod,
} from '../../../../../gql/hooks/membership/payment-method';
import CenterLoading from '../../../../../components/CenterLoading';
import useDisclosure from '../../../../../hooks/useDisclosure';
import PaymentMethodSelector from './PaymentMethodSelector';

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    paddingTop: theme.spacing(4),
  },
}));

function SubscriptionPaymentMethod({
  subscription,
  paymentMethod,
}: SubscriptionPaymentMethodProps): JSX.Element {
  const classes = useStyles();
  const modal = useDisclosure();
  const [submitting, setSubmitting] = React.useState(false);

  const { loading, data } = useGetPaymentMethods();
  const updateSubscriptionPaymentMethod = useUpdateSubscriptionPaymentMethod();

  const handleSubmit = async (paymentMethodId: string) => {
    setSubmitting(true);
    try {
      await updateSubscriptionPaymentMethod({
        subscriptionId: subscription.id,
        paymentMethodId,
      });
    } catch (error) {
    } finally {
      setSubmitting(false);
      modal.close();
    }
  };

  if (loading) {
    return <CenterLoading />;
  }

  const paymentMethods = data?.paymentMethods || [];

  return (
    <div className={classes.container}>
      <PaymentMethodCard paymentMethod={paymentMethod} />
      <Button variant="contained" color="primary" onClick={modal.open}>
        <FormattedMessage id="general.edit" />
      </Button>
      <PaymentMethodSelector
        submitting={submitting}
        defaultValue={paymentMethod.id}
        paymentMethods={paymentMethods}
        open={modal.isOpen}
        onClose={modal.close}
        onSubmit={handleSubmit}
      />
    </div>
  );
}

type SubscriptionPaymentMethodProps = {
  subscription: Subscription;
  paymentMethod: PaymentMethod;
};

export default SubscriptionPaymentMethod;
