import React from 'react';
import { Link as RouterLink, useRouteMatch } from 'react-router-dom';
import { useIntl, FormattedMessage } from 'react-intl';
import { makeStyles } from '@material-ui/styles';
import {
  Button,
  Chip,
  Link,
  Paper,
  Tab,
  Tabs,
  Typography,
  Theme,
} from '@material-ui/core';
import { useWebPlayer } from '../WebPlayer/context';
import AlbumCover from '../../components/AlbumCover';
import ReadMore from '../../components/ReadMore';
import Html from '../../components/Html';
import ListenPlatformRow from '../../components/ListenPlatformRow';
import EpisodeInfoRow from '../../components/EpisodeInfoRow';
import TagRow from '../../components/TagRow';
import { imageproxy, isSameTrack } from '../../utils';
import { Episode } from '../../entities/episode';
import PlayButton from './PlayButton';

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(8),
    '@media (max-width: 500px)': {
      paddingTop: theme.spacing(2),
    },
  },
  infoContainer: {
    display: 'flex',
    flexDiretion: 'row',
    flexWrap: 'wrap',
    paddingBottom: 20,
    '@media (max-width: 500px)': {
      flexDirection: 'column',
      paddingBottom: 10,
    },
  },
  left: {
    marginRight: 20,
  },
  info: {
    '@media (min-width: 500px)': {
      flex: 1,
      minWidth: 300,
    },
    '@media (max-width: 500px)': {
      width: '100%',
    },
  },
  title: {
    textDecoration: 'none',
    color: 'white',
  },
  infoRow: {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },
  image: {
    borderRadius: 5,
    objectFit: 'cover',
    marginBottom: 5,
    height: 200,
    width: 200,
    '@media (max-width: 500px)': {
      height: 80,
      width: 80,
    },
  },
  descriptionHtml: {
    width: '100%',
    overflowWrap: 'anywhere',
  },
  timeRow: {
    display: 'flex',
    alignItems: 'center',
    paddingTop: 5,
    paddingBottom: 5,
  },
  listenPlatform: {
    fontWeight: 'bold',
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  flink: {
    marginBottom: theme.spacing(1),
  },
  time: {
    paddingRight: theme.spacing(2),
    paddingLeft: 5,
  },
  play: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  tabs: {
    paddingBottom: theme.spacing(4),
  },
  description: {
    whiteSpace: 'pre-line',
  },
  transcript: {
    textIndent: theme.spacing(4),
    marginBottom: theme.spacing(2),
  },
  transcriptStart: {
    padding: theme.spacing(2),
    marginBottom: theme.spacing(4),
  },
  transcriptEnd: {
    padding: theme.spacing(2),
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(4),
  },
}));

function Content({
  isTranscriptRoute,
  hasTranscript,
  transcript,
  description,
  ugc,
}: ContentProps): JSX.Element | null {
  const classes = useStyles();

  if (isTranscriptRoute) {
    if (!hasTranscript) {
      return null;
    }

    return (
      <ReadMore>
        <div className={classes.description}>
          <Paper variant="outlined" className={classes.transcriptStart}>
            <Typography>
              <FormattedMessage id="podcast.transcript.kkboxStart" />
            </Typography>
          </Paper>
          {transcript?.map((t, i) => (
            <Typography key={i} className={classes.transcript}>
              {t}
            </Typography>
          ))}
          <Paper variant="outlined" className={classes.transcriptEnd}>
            <Typography>
              <FormattedMessage id="podcast.transcript.kkboxEnd" />
            </Typography>
          </Paper>
        </div>
      </ReadMore>
    );
  }

  return (
    <ReadMore>
      {description ? (
        <Html
          ugc={ugc}
          className={classes.descriptionHtml}
          html={description}
        />
      ) : (
        <FormattedMessage id="general.noDescription" />
      )}
    </ReadMore>
  );
}

function EpisodeInfo({
  me,
  isEmbed,
  isCommentMode,
  showDescription,
  showFlink,
  episode,
}: EpisodeInfoProps): JSX.Element | null {
  const intl = useIntl();
  const { nowPlaying, isPlaying, play, toggle, setNowPlaying, isNowPlaying } =
    useWebPlayer();
  const classes = useStyles();
  const { id, title, imageUrl, show, description, distributions, transcript } =
    episode;
  const isTranscriptRoute = !!useRouteMatch('/story/:id/transcript');
  const hasTranscript = transcript && transcript.length > 0;

  const handlePlayPauseClick = React.useCallback(() => {
    if (isNowPlaying(episode)) {
      toggle();
    } else if (episode) {
      setNowPlaying(episode);
      play();
    }
  }, [episode, isNowPlaying, play, setNowPlaying, toggle]);

  const isRecordPlaying = isSameTrack(nowPlaying, episode) ? isPlaying : false;

  // const episodeCount = author.episodeCount || 0;
  const ugc = false; // episodeCount <= 5;

  if (isEmbed && !showDescription) {
    return null;
  }

  return (
    <div className={classes.container}>
      {isEmbed ? null : (
        <div className={classes.infoContainer}>
          <div className={classes.left}>
            <AlbumCover
              imageUrl={
                imageproxy(imageUrl, 500) || imageproxy(show.avatar, 500)
              }
              createdAt={episode.createdAt}
              className={classes.image}
            />
          </div>
          <div className={classes.info}>
            <Typography component="h1" variant="h5">
              {title}
            </Typography>
            <Link
              component={RouterLink}
              color="textSecondary"
              to={{
                pathname: `/user/${show.urlSlug || show.id}`,
                state: { from: 'STORY' },
              }}
            >
              {show.name}
            </Link>
            <EpisodeInfoRow episode={episode} className={classes.infoRow} />
            <TagRow tags={episode.tags} />
            <Typography className={classes.listenPlatform}>
              <FormattedMessage id="podcast.distribution" />
            </Typography>
            {showFlink && (
              <div className={classes.flink}>
                <Chip
                  size="small"
                  color="secondary"
                  variant="outlined"
                  label={intl.formatMessage({ id: 'podcast.flink' })}
                  clickable
                  component={RouterLink}
                  to={`/story/${id}/platforms`}
                />
              </div>
            )}
            <ListenPlatformRow showId={show.id} distributions={distributions} />
            <div className={classes.play}>
              {isCommentMode ? (
                <Button
                  variant="contained"
                  color="primary"
                  component={RouterLink}
                  to={{
                    pathname: `/story/${id}`,
                    state: { from: 'COMMENT' },
                  }}
                >
                  <FormattedMessage id="podcast.viewEpisode" />
                </Button>
              ) : (
                <PlayButton
                  me={me}
                  episode={episode}
                  onClick={handlePlayPauseClick}
                  isPlaying={isRecordPlaying}
                />
              )}
            </div>
          </div>
        </div>
      )}
      {!isCommentMode && (
        <div>
          {hasTranscript && (
            <div className={classes.tabs}>
              <Tabs value={isTranscriptRoute ? 1 : 0}>
                <Tab
                  label={intl.formatMessage({ id: 'general.description' })}
                  component={RouterLink}
                  to={`/story/${id}`}
                />
                <Tab
                  label={intl.formatMessage({ id: 'podcast.transcript' })}
                  component={RouterLink}
                  to={`/story/${id}/transcript`}
                />
              </Tabs>
            </div>
          )}
          <Content
            isTranscriptRoute={isTranscriptRoute}
            hasTranscript={hasTranscript}
            transcript={transcript}
            description={description}
            ugc={ugc}
          />
        </div>
      )}
    </div>
  );
}

type EpisodeInfoProps = {
  me: { id: string } | null;
  isEmbed?: boolean;
  isCommentMode?: boolean;
  showDescription?: boolean;
  showFlink?: boolean;
  episode: Episode;
};

type ContentProps = {
  isTranscriptRoute?: boolean;
  hasTranscript?: boolean;
  transcript?: string[];
  description: string;
  ugc?: boolean;
};

export default EpisodeInfo;
