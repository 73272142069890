import { makeStyles } from '@material-ui/styles';
import {
  AppBar,
  Box,
  Chip,
  Container,
  Slide,
  Theme,
  Toolbar,
  Typography,
  useScrollTrigger,
} from '@material-ui/core';
import { Link as RouterLink } from 'react-router-dom';
import { Post } from '../../../entities/post';
import Content from './Post';
import { useScrollDirection } from '../../../hooks/useScrollDirection';
import { SubscriptionLock } from '../SubscriptionLock';
import { ReactNode } from 'react';
import { useGetMe } from '../../../gql/hooks/user';
import { FormattedMessage } from 'react-intl';
import CenterLoading from '../../../components/CenterLoading';
import AccountMenu from '../../WebPlayer/AccountMenu';
import useDisclosure from '../../../hooks/useDisclosure';

const useStyles = makeStyles<Theme, { direction: 'up' | 'down' | null }>(
  (theme: Theme) => ({
    container: {
      maxWidth: 750,
      marginTop: theme.spacing(16),
      marginBottom: theme.spacing(14),
    },
    header: {
      paddingTop: theme.spacing(1),
      paddingBottom: theme.spacing(0.5),
      backgroundColor: theme.palette.background.default,
      borderBottom: `1px solid ${theme.palette.divider}`,
    },
    toolbar: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      '@media (max-width: 768px)': {
        justifyContent: 'unset',
        gap: theme.spacing(2),
      },
    },
    unlock: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      marginTop: theme.spacing(6),
      marginBottom: theme.spacing(2),
      padding: theme.spacing(2),
    },
    unlockText: {
      marginBottom: theme.spacing(2),
    },
    unlockButton: {
      marginTop: theme.spacing(2),
    },
    titleRow: {
      marginBottom: theme.spacing(4),
    },
    avatar: {
      height: theme.spacing(5),
      borderRadius: '10%',
      cursor: 'pointer',
    },
    showName: {
      fontSize: '2rem',
      fontWeight: 700,
      margin: 0,
      '@media (max-width: 768px)': {
        fontSize: '1.2rem',
      },
    },
    link: {
      textDecoration: 'none',
      color: 'inherit',
    },
    rightSection: {
      display: 'flex',
      '@media (max-width: 768px)': {
        marginLeft: 'auto',
      },
    },
  }),
);

function PostContent({ post }: PostContentProps): JSX.Element {
  const { direction } = useScrollDirection();
  const classes = useStyles({ direction });
  const { show, title, contentUrl, createdAt } = post;
  const { data: me, loading } = useGetMe();
  const isLoggedIn = Boolean(me && me.studioUserFindOne);
  const accountMenu = useDisclosure();

  if (loading) {
    return <CenterLoading />;
  }

  const date = new Intl.DateTimeFormat('en-US', {
    dateStyle: 'medium',
  }).format(new Date(createdAt));

  const communityUrl = `/user/${show.urlSlug || show.id}/community`;

  const postLocked = Boolean(!contentUrl && post.entitlements?.length);

  const LockComponent = ({ children }: { children: ReactNode }) => {
    return (
      <SubscriptionLock post={post} isLoggedIn={isLoggedIn}>
        {children}
      </SubscriptionLock>
    );
  };

  return (
    <>
      <HideOnScroll
        window={() => {
          const target = document.getElementById('layout-content');
          if (!target) return window;
          return target as unknown as Window;
        }}
      >
        <AppBar className={classes.header}>
          <Toolbar className={classes.toolbar}>
            <RouterLink to={communityUrl} className={classes.link}>
              <img src={post.show?.avatar || ''} className={classes.avatar} />
            </RouterLink>
            <Typography className={classes.showName} variant="h4">
              <RouterLink to={communityUrl} className={classes.link}>
                {post.show.name}
              </RouterLink>
            </Typography>
            <Box className={classes.rightSection}>
              <AccountMenu
                open={accountMenu.isOpen}
                onOpen={accountMenu.open}
                onClose={accountMenu.close}
              />
            </Box>
          </Toolbar>
        </AppBar>
      </HideOnScroll>
      <Container maxWidth={false} className={classes.container}>
        <div style={{ marginBottom: 8, marginLeft: 23 }}>
          {postLocked && (
            <Chip
              label={<FormattedMessage id="general.paidOnlyContent" />}
              variant="outlined"
              color="primary"
            />
          )}
        </div>
        <Content
          contentUrl={contentUrl}
          title={title}
          subtitle={post.subtitle}
          avatarUrl={post.show.avatar || ''}
          date={date}
          author={post.show.author || post.show.name || ''}
          communityUrl={communityUrl}
          LockComponent={LockComponent}
        />
      </Container>
    </>
  );
}

function HideOnScroll(props: {
  children: React.ReactElement;
  window?: () => Window;
}) {
  const { children, window } = props;
  const trigger = useScrollTrigger({
    target: window ? window() : undefined,
  });

  return (
    <Slide appear={false} direction="down" in={!trigger}>
      {children}
    </Slide>
  );
}

type PostContentProps = {
  post: Post;
  scrollTarget?: () => HTMLElement | Window;
};

export default PostContent;
