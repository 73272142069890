if (global.window) {
  window.scriptLoaded = {
    fb: false,
    tappay: false,
    mixpanel: false,
    hotjar: false,
  };

  window.loadScriptPromise = {};
}

export * from './facebook';
export * from './hotjar';
export * from './tappay';
export * from './mixpanel';
