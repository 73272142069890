import { useQuery } from '@apollo/client';
import { Donation } from '../../../entities/membership';
import usePagination from '../../../hooks/usePagination';
import { GetDonationDetail, GetDonations } from '../../v2/membership';

export function useGetDonationDetail(id: string) {
  const result = useQuery<GetDonationDetailData, GetDonationDetailVariables>(
    GetDonationDetail,
    {
      variables: { id },
      context: { endpoint: 'membership' },
    },
  );

  return result;
}

type GetDonationDetailData = {
  donation: Donation | null;
};

type GetDonationDetailVariables = {
  id: string;
};

export function useGetDonations() {
  const result = usePagination<GetDonationsData>({
    dataKey: 'donations',
    query: GetDonations,
    context: { endpoint: 'membership' },
  });

  return result;
}

type GetDonationsData = {
  donations: Donation[];
};
