import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { FormattedMessage, useIntl } from 'react-intl';
import { makeStyles } from '@material-ui/styles';
import { Typography, Avatar, MenuItem, Theme, Link } from '@material-ui/core';
import MoreMenu from '../../components/MoreMenu';
import { useWebPlayer } from '../../pages/WebPlayer/context';
import useDateFormat from '../../hooks/useDateFormat';
import { useDeleteComment } from '../../gql/hooks/comment';
import { Comment } from '../../entities/comment';
import { Show } from '../../entities/show';
import CommentForm from './CommentForm';
import AudioContent from './AudioContent';
import TextContent from './TextContent';

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    display: 'flex',
    paddingBottom: theme.spacing(2),
  },
  left: {
    paddingRight: theme.spacing(2),
  },
  right: {
    paddingRight: theme.spacing(2),
  },
  moreMenu: {
    opacity: 0,
  },
  image: {
    width: 32,
    height: 32,
    objectFit: 'cover',
    borderRadius: 3,
    cursor: 'pointer',
  },
  info: {
    '&:hover': {
      '& $moreMenu': {
        opacity: 1,
      },
    },
  },
  titleRow: {
    display: 'flex',
    alignItems: 'center',
    paddingBottom: theme.spacing(1),
  },
  author: {
    fontWeight: 'bold',
    paddingRight: theme.spacing(2),
    cursor: 'pointer',
  },
  createdAt: {
    marginRight: theme.spacing(1),
    '&:hover': {
      textDecoration: 'underline',
    },
  },
  bottom: {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },
  replyButton: {
    cursor: 'pointer',
    '&:hover': {
      textDecoration: 'underline',
    },
  },
  replyAtEpisode: {
    marginBottom: theme.spacing(1),
  },
  commentList: {
    paddingTop: theme.spacing(2),
  },
}));

function getCommentLink(comment: Comment): string {
  if (comment.author) {
    return `/user/${comment.author.id}/comments/${comment.id}`;
  }
  if (comment.episode) {
    return `/story/${comment.episode.id}/comments/${comment.id}`;
  }
  return '#';
}

function getCommentReplyLinkForEmbed(comment: Comment): string {
  if (comment.author) {
    return `https://open.firstory.me/user/${comment.author.id}/comments`;
  }
  if (comment.episode) {
    return `https://open.firstory.me/story/${comment.episode.id}/comments`;
  }
  return '#';
}

function CommentContent({ comment }: { comment: Comment }): JSX.Element | null {
  if (comment.textComment) {
    return <TextContent message={comment.textComment.message} />;
  }
  if (comment.audioComment) {
    return <AudioContent audioComment={comment.audioComment} />;
  }
  return null;
}

function CommentItem({
  isEmbed,
  me,
  listType,
  comment,
  parentComment,
  createComment,
  openParentReply,
}: CommentItemProps): JSX.Element {
  const intl = useIntl();
  const classes = useStyles();
  const { isWebPlayer } = useWebPlayer();
  const [replyOpen, setReplyOpen] = React.useState(false);
  const inputRef = React.useRef<HTMLInputElement>();
  const deleteComment = useDeleteComment({ commentId: comment.id });

  const { author } = comment;
  const createdAt = useDateFormat(comment.createdAt);

  const handleReplyOpen = React.useCallback(() => {
    setReplyOpen(true);
    if (inputRef.current) {
      inputRef.current.focus();
    }
  }, []);

  const comments = comment.comments || [];

  const authorPath = React.useMemo(() => {
    if (!author) {
      return '';
    }
    if (isWebPlayer) {
      return `/user/${author.urlSlug || author.id}`;
    }

    return `https://open.firstory.me/user/${author.urlSlug || author.id}`;
  }, [author, isWebPlayer]);

  const authorId = author ? author.id : null;
  const authorImage = author ? author.avatar : '#';
  const authorName = author
    ? author.name
    : intl.formatMessage({
        id: 'comment.anonymous',
      });
  const img = (
    <Avatar src={authorImage} className={classes.image} alt={authorName} />
  );

  const commentLink = getCommentLink(comment);
  const replyProps = isEmbed
    ? {
        component: 'a',
        target: '_blank',
        rel: 'noreferrer noopener',
        href: getCommentReplyLinkForEmbed(comment),
      }
    : {
        onClick: parentComment ? openParentReply : handleReplyOpen,
      };

  return (
    <div>
      {listType === 'SHOW' && comment.episode && (
        <div className={classes.replyAtEpisode}>
          <Typography variant="caption">
            <FormattedMessage
              id="comment.replyAtEpisode"
              values={{
                title: (
                  <Link
                    to={`/story/${comment.episode.id}`}
                    component={RouterLink}
                  >
                    {comment.episode.title}
                  </Link>
                ),
              }}
            />
          </Typography>
        </div>
      )}
      <div className={classes.container}>
        <div className={classes.left}>
          {isWebPlayer ? (
            <RouterLink to={authorPath}>{img}</RouterLink>
          ) : (
            <a href={authorPath}>{img}</a>
          )}
        </div>
        <div className={classes.right}>
          <div className={classes.info}>
            <div className={classes.titleRow}>
              <Typography
                className={classes.author}
                color="textPrimary"
                component={isWebPlayer ? RouterLink : 'a'}
                {...(isWebPlayer
                  ? { to: authorPath }
                  : {
                      href: authorPath,
                      target: '_blank',
                      rel: 'noreferrer noopener',
                    })}
              >
                {authorName}
              </Typography>
              {me && me.id === authorId && (
                <div className={classes.moreMenu}>
                  <MoreMenu size="small">
                    <MenuItem onClick={deleteComment}>
                      <FormattedMessage id="general.delete" />
                    </MenuItem>
                  </MoreMenu>
                </div>
              )}
            </div>
            <CommentContent comment={comment} />
            <div className={classes.bottom}>
              <Typography
                component={RouterLink}
                color="textSecondary"
                variant="caption"
                className={classes.createdAt}
                to={commentLink}
              >
                {createdAt}
              </Typography>
              <Typography
                color="textSecondary"
                variant="caption"
                className={classes.replyButton}
                {...replyProps}
              >
                <FormattedMessage id="comment.reply" />
              </Typography>
            </div>
          </div>
          {replyOpen && !parentComment && (
            <CommentForm
              me={me}
              comment={parentComment || comment}
              createComment={createComment}
              inputRef={inputRef}
              autoFocus
            />
          )}
          <div className={classes.commentList}>
            {comments.map(c => (
              <CommentItem
                key={c.id}
                isEmbed={isEmbed}
                me={me}
                comment={c}
                parentComment={comment}
                createComment={createComment}
                openParentReply={handleReplyOpen}
              />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}

type CommentItemProps = {
  isEmbed?: boolean;
  me: Show | null;
  listType?: string;
  comment: Comment;
  parentComment?: Comment;
  createComment: (comment: {
    name: string;
    message: string;
    showId?: string;
    episodeId?: string;
    commentId?: string;
  }) => Promise<unknown>;
  openParentReply?: () => void;
};

export default CommentItem;
