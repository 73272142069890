import { Box, BoxProps } from '@material-ui/core';
import { useStyles } from './HtmlStyleProvider.styles';
import { forwardRef } from 'react';

export interface HtmlStyleProviderProps extends Omit<BoxProps, 'ref'> {}

export const HtmlStyleProvider = forwardRef<HTMLDivElement, HtmlStyleProviderProps>(
  (props, ref) => {
    const { className, ...rest } = props;
    const classes = useStyles({ fontSize: rest.fontSize });

    return (
      <Box
        component={'div'}
        className={`${classes.root} ${className}`}
        {...({ ref } as any)}
        {...rest}
      />
    );
  },
);
