import React from 'react';
import { useHistory, useParams } from 'react-router';
import { apm } from '@elastic/apm-rum';
import { ThemeProvider } from '@material-ui/core';
import PaymentModal from '../Payment/PaymentModal';
import PaymentResultModal from '../Payment/ResultModel';
import { PaymentFormValues, PaymentSubmitValues } from '../Payment/type';
import CenterLoading from '../../../components/CenterLoading';
import NotFound from '../../../components/NotFound';
import useDisclosure from '../../../hooks/useDisclosure';
import { useGetShowMembership } from '../../../gql/hooks/membership/get-show-setting';
import { useMembershipCheckout } from '../../../gql/hooks/membership/checkout';
import { GetMeLegacyData, useGetMeLegacy } from '../../../gql/hooks/user';
import {
  CheckoutStatus,
  Money,
  Plan,
  Tier,
} from '../../../entities/membership';
import { lightTheme } from '../../../theme';
import useQueryString from '../../../hooks/useQueryString';
import { Define } from '../../../define';
import { Show } from '../../../entities/show';
import { useGetShowInfo } from '../../../gql/hooks/show/show';
import FreeJoinModal from '../Payment/FreeJoinModal';
import { useSubscribeShowFreeTier } from '../../../gql/hooks/membership/free';
import FreeResultModal from '../Payment/FreeResultModal';
import { useAlert } from '../../../components/Alert';
import JoinMemberContent from './Content';
import JoinMemberLayout from './Layout';

function JoinMember({ show, me }: JoinMemberProps): JSX.Element {
  const { loading, data } = useGetShowMembership(show.id);
  const qs = useQueryString();
  const history = useHistory();
  const { openErrorDialog } = useAlert();

  const status = qs.get('status');
  const hasStatus = typeof status === 'string';
  const isSuccess = status === '0';

  const [selectedTierPlan, setTierPlan] = React.useState<{
    tier: Tier;
    plan: Plan;
  } | null>(null);
  const [customPrice, setCustomPrice] = React.useState<Money | null>(null);

  const [submitting, setSubmitting] = React.useState(false);
  const [success, setSuccess] = React.useState(hasStatus ? isSuccess : true);

  const email = me?.studioUserFindOne.email;

  const [values, setValues] = React.useState<PaymentFormValues>({
    email: email || '',
    donationName: '',
    message: '',
  });

  const checkout = useMembershipCheckout();
  const subscribeFreeTier = useSubscribeShowFreeTier();

  const paymentModal = useDisclosure();
  const resultModal = useDisclosure(hasStatus);

  const freeModal = useDisclosure();
  const freeResultModal = useDisclosure();

  const handleValueChange =
    (name: keyof PaymentFormValues) =>
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setValues({
        ...values,
        [name]: e.target.value,
      });
    };

  const handleTierPlanSelect = React.useCallback(
    (tier: Tier, plan: Plan) => {
      if (plan.price.money.amount === 0) {
        setTierPlan({ tier, plan });
        freeModal.open();
        return;
      }

      if (plan.price.interval && !me) {
        return;
      }
      setTierPlan({ tier, plan });
      paymentModal.open();
    },
    [freeModal, paymentModal, me],
  );

  const handleCustomSelect = React.useCallback(
    (amount: number) => {
      setCustomPrice({ amount, currency: 'TWD' });
      paymentModal.open();
    },
    [paymentModal],
  );

  const handleSuccess = React.useCallback(() => {
    setSuccess(true);
    resultModal.open();
  }, [resultModal]);

  const handleFailed = React.useCallback(() => {
    setSuccess(false);
    resultModal.open();
  }, [resultModal]);

  const handleRedirect = React.useCallback((url: string) => {
    window.location.href = url;
  }, []);

  const showMembership = data?.show;

  const handleFreeSubmit = React.useCallback(async () => {
    if (!showMembership) {
      return;
    }

    if (!selectedTierPlan?.plan) {
      return;
    }

    setSubmitting(true);

    try {
      const result = await subscribeFreeTier({
        ...values,
        showId: show.id,
        planId: selectedTierPlan?.plan.id,
      });

      const subscriptionId =
        result.data?.audienceSubscribeShowFreeTier.subscriptionId;
      if (subscriptionId) {
        history.push(`/subscription-result/${subscriptionId}`);
        return;
      }

      setSuccess(false);
      freeResultModal.open();
    } catch (error) {
      if (error?.message.includes('AlreadySubscribed')) {
        openErrorDialog('donation.subscribe.duplicate');
        return;
      }

      setSuccess(false);
      freeResultModal.open();
      apm.captureError(error);
    } finally {
      setSubmitting(false);
    }
  }, [
    history,
    values,
    show,
    showMembership,
    selectedTierPlan,
    freeResultModal,
    subscribeFreeTier,
    openErrorDialog,
  ]);

  const handleSubmit = React.useCallback(
    async (values: PaymentSubmitValues) => {
      if (!showMembership) {
        return;
      }

      if (!selectedTierPlan && !customPrice) {
        return;
      }

      setSubmitting(true);

      const hasStripe = showMembership.paymentProviders.some(
        p => p.provider === Define.Payment.Provider.stripe,
      );

      try {
        const result = await checkout({
          ...values,
          provider: hasStripe
            ? Define.Payment.Provider.stripe
            : Define.Payment.Provider.tapPay,
          isRecurring: !!selectedTierPlan?.plan.price.interval,
          showId: show.id,
          planId: selectedTierPlan?.plan.id,
          customPrice: customPrice,
        });

        if (result) {
          if (
            result.status === CheckoutStatus.success ||
            result.status === CheckoutStatus.active
          ) {
            handleSuccess();
            return;
          }

          if (result.redirectUrl) {
            handleRedirect(result.redirectUrl);
            return;
          }

          handleFailed();
        }
      } catch (error) {
        handleFailed();
        apm.captureError(error);
      } finally {
        setSubmitting(false);
      }
    },
    [
      checkout,
      show,
      showMembership,
      selectedTierPlan,
      customPrice,
      handleSuccess,
      handleFailed,
      handleRedirect,
    ],
  );

  const handleCancel = React.useCallback(() => {
    setTierPlan(null);
    setCustomPrice(null);
    paymentModal.close();
    freeModal.close();
  }, [paymentModal, freeModal]);

  if (loading) {
    return <CenterLoading />;
  }

  if (!data?.show) {
    return <NotFound />;
  }

  const { customerStatus, trialInterval } = data.show;
  let isBindCard = false;

  if (
    !customerStatus?.hasTrialed &&
    trialInterval &&
    selectedTierPlan?.plan.price.interval
  ) {
    isBindCard = true;
  }

  return (
    <JoinMemberLayout show={show}>
      <JoinMemberContent
        show={show}
        me={me}
        membershipSetting={data.show}
        onTierPlanSelect={handleTierPlanSelect}
        onCustomSelect={handleCustomSelect}
      />
      <PaymentModal
        membershipSetting={data.show}
        open={paymentModal.isOpen}
        submitting={submitting}
        paymentTier={{
          tierPlan: selectedTierPlan,
          customPrice,
          isBindCard,
        }}
        values={values}
        disableEmailEdit={!!email}
        onValueChange={handleValueChange}
        onSubmit={handleSubmit}
        onCancel={handleCancel}
      />
      <FreeJoinModal
        membershipSetting={data.show}
        open={freeModal.isOpen}
        submitting={submitting}
        values={values}
        disableEmailEdit={!!email}
        paymentTier={{
          tierPlan: selectedTierPlan,
          customPrice,
          isBindCard,
        }}
        onValueChange={handleValueChange}
        onSubmit={handleFreeSubmit}
        onCancel={handleCancel}
      />
      <PaymentResultModal
        success={success}
        open={resultModal.isOpen}
        onClose={resultModal.close}
      />
      <FreeResultModal
        success={success}
        open={freeResultModal.isOpen}
        onClose={freeResultModal.close}
      />
    </JoinMemberLayout>
  );
}

function JoinMemberContainer() {
  const { showId } = useParams<{ showId: string }>();
  const { loading, data } = useGetShowInfo(showId);
  const { loading: meLoading, data: meData } = useGetMeLegacy();

  if (loading || meLoading) {
    return <CenterLoading />;
  }

  const show = data?.playerShowFindOneByUrlSlug;
  if (!show) {
    return <NotFound />;
  }

  return <JoinMember show={show} me={meData || null} />;
}

function JoinMemberWithTheme() {
  return (
    <ThemeProvider theme={lightTheme}>
      <JoinMemberContainer />
    </ThemeProvider>
  );
}

type JoinMemberProps = {
  show: Pick<Show, 'id' | 'name' | 'author' | 'avatar' | 'urlSlug'>;
  me: GetMeLegacyData | null;
};

export default JoinMemberWithTheme;
