import React from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { Button } from '@material-ui/core';
import PlatformIcon from '../../../../components/icons/PlatformIcon';
import { Define } from '../../../../define';
import { getPlatformUrl } from './util';

const useStyles = makeStyles((theme: Theme) => ({
  button: {
    marginBottom: theme.spacing(1),
    fontWeight: 'bold',
    width: '100%',
    height: 45,
    justifyContent: 'flex-start',
  },
  icon: {
    width: 28,
    height: 28,
    marginRight: theme.spacing(2),
  },
  itemIcon: {
    position: 'absolute',
    left: theme.spacing(4),
    top: 0,
    bottom: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    [theme.breakpoints.down('sm')]: {
      left: theme.spacing(2),
    },
  },
  itemTitle: {
    flex: 1,
    textAlign: 'center',
  },
}));

function PlatformItem({ type, url }: PlatformItemProps) {
  const classes = useStyles();
  const title = Define.Distribution.Platform.Title[type];
  const link = getPlatformUrl(type, url);

  return (
    <div>
      <Button
        className={classes.button}
        variant="contained"
        size="large"
        component="a"
        target="_blank"
        rel="noopener noreferrer"
        href={link}
      >
        <span className={classes.itemIcon}>
          <PlatformIcon
            type={{ variant: 'distribution', type }}
            className={classes.icon}
          />
        </span>
        <span className={classes.itemTitle}>{title}</span>
      </Button>
    </div>
  );
}

type PlatformItemProps = {
  type: Define.Distribution.Platform.Type;
  url: string;
};

export default PlatformItem;
