import { OverridedMixpanel } from 'mixpanel-browser';
import config from '../../config';

export async function loadMixpanelSdk(): Promise<OverridedMixpanel> {
  const mixpanel = (await import('mixpanel-browser')).default;

  if (!window.scriptLoaded.mixpanel) {
    if (!window.loadScriptPromise['mixpanel']) {
      window.loadScriptPromise['mixpanel'] = new Promise<OverridedMixpanel>(
        resolve => {
          mixpanel.init(config.mixpanelToken, {
            loaded() {
              window.scriptLoaded.mixpanel = true;
              resolve(mixpanel);
            },
          });
        },
      ).catch(() => {
        //
      });
    }

    return window.loadScriptPromise['mixpanel'] as Promise<OverridedMixpanel>;
  }

  return mixpanel;
}
