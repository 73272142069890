import React from 'react';
import { makeStyles } from '@material-ui/styles';
import Button from '@material-ui/core/Button';
import { Lock } from '@material-ui/icons';
import PlayPauseIcon from '../../components/icons/PlayPauseIcon';
import useDisclosure from '../../hooks/useDisclosure';
import { Episode } from '../../entities/episode';
import SubscribeTierToUnlock from '../../components/SubscribeTierToUnlock';
import { Me } from '../../entities/me';

const useStyles = makeStyles(() => ({
  lockContainer: {
    display: 'inline-block',
  },
}));

function PlayButton({
  me,
  episode,
  isPlaying,
  onClick,
}: PlayButtonProps): JSX.Element {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState<HTMLDivElement | null>(null);
  const popover = useDisclosure();

  if (!episode.audioUrl && episode.entitlements?.length) {
    return (
      <div>
        <div ref={setAnchorEl} className={classes.lockContainer}>
          <Button
            aria-label="Play"
            variant="contained"
            color="primary"
            onClick={popover.open}
          >
            <Lock />
          </Button>
          <SubscribeTierToUnlock
            me={me}
            anchorEl={anchorEl}
            episode={episode}
            open={popover.isOpen}
            onClose={popover.close}
          />
        </div>
      </div>
    );
  }

  return (
    <Button
      aria-label="Play"
      variant="contained"
      color="primary"
      onClick={onClick}
    >
      <PlayPauseIcon size={25} color="white" isPlaying={isPlaying} />
      {isPlaying ? 'Pause' : 'Play'}
    </Button>
  );
}

type PlayButtonProps = {
  me: { id: string } | null;
  episode: Episode;
  isPlaying: boolean;
  onClick(): void;
};

export default PlayButton;
