import { useQuery } from '@apollo/client';
import { GetPosts, GetPostDetail } from '../v2/community';
import usePagination from '../../hooks/usePagination';
import { Post } from '../../entities/post';

export function useGetPosts(showId: string) {
  const result = usePagination<GetPostsData, GetPostsVariables>({
    dataKey: 'playerPostFind',
    query: GetPosts,
    useSkip: true,
    variables: {
      showId,
    },
  });

  return result;
}

type GetPostsData = {
  playerPostFind: Post[];
};

type GetPostsVariables = {
  showId: string;
};

export function useGetPostDetail(postId: string) {
  const result = useQuery<GetPostDetailData, GetPostDetailVariables>(
    GetPostDetail,
    {
      variables: {
        postId,
      },
      ssr: false,
    },
  );

  return result;
}

type GetPostDetailData = {
  playerPostFindOne: Post;
};

type GetPostDetailVariables = {
  postId: string;
};
