import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Define } from '../../../define';
import { AudienceSpotify } from '../../../entities/membership';
import InfoCard from '../InfoCard';
import PlatformIcon from '../../../components/icons/PlatformIcon';
import SpotifyLoginButton from '../../../components/Spotify/LoginButton';

function SpotifyConnection({ spotify }: SpotifyConnectionProps): JSX.Element {
  return (
    <InfoCard
      icon={
        <PlatformIcon
          size={56}
          type={{
            variant: 'distribution',
            type: Define.Distribution.Platform.spotify,
          }}
        />
      }
      title={<FormattedMessage id="donation.app.spotify" />}
      description={
        spotify ? (
          <FormattedMessage
            id="donation.app.spotify.connectedAccount"
            values={{
              b: (chunk: string) => <b>{chunk}</b>,
              name: spotify.name,
            }}
          />
        ) : (
          <FormattedMessage id="donation.app.spotify.description" />
        )
      }
      action={
        spotify ? null : <SpotifyLoginButton scopes={['user-soa-link']} />
      }
    />
  );
}

type SpotifyConnectionProps = {
  spotify?: AudienceSpotify | null;
};

export default SpotifyConnection;
