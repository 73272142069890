import React from 'react';
import { useHistory } from 'react-router-dom';
import { useApolloClient } from '@apollo/client';
import useNextLocation from '../../hooks/useNextLocation';
import { useTracker } from '../../analytics/tracker';
import { loadMixpanelSdk } from '../../utils';
import { ContextException } from '../../utils/exception.custom';

const AuthContext = React.createContext<AuthContextValue | undefined>(
  undefined,
);

export function AuthProvider({
  children,
  token,
  onTokenChange,
}: AuthProviderProps): JSX.Element {
  const client = useApolloClient();
  const tracker = useTracker();
  const history = useHistory();
  const goNext = useNextLocation();

  const authGoNext = React.useCallback(
    next => {
      goNext(next);
    },
    [goNext],
  );

  const getMe = React.useCallback(() => {
    try {
      const tokenPayload: TokenPayload | null = token
        ? JSON.parse(atob(token.split('.')[1]))
        : null;
      return tokenPayload;
    } catch (err) {
      return null;
    }
  }, [token]);

  const handleLogin = React.useCallback(
    async ({ isSignup, method, token, show, next }: HandleLoginArgs) => {
      const user = show;

      onTokenChange?.(token);
      await client.clearStore();

      if (user) {
        const mixpanel = await loadMixpanelSdk();
        mixpanel.people.set({
          $name: user.name || '',
          $email: user.email || '',
        });

        if (user) {
          mixpanel.identify(user.id);
        }
      }

      if (isSignup) {
        tracker.signupSuccess(method);
      } else {
        tracker.loginSuccess(method);
      }

      authGoNext(next);
    },
    [client, onTokenChange, authGoNext, tracker],
  );

  const handleLogout = React.useCallback(
    async (options?: HandleLogoutArgs) => {
      await fetch('/logout');

      onTokenChange?.(null);
      await client.clearStore();

      const mixpanel = await loadMixpanelSdk();
      mixpanel.reset();

      history.push('/');

      if (options?.next) {
        history.push(options.next);
      }
    },
    [client, history, onTokenChange],
  );

  const value = React.useMemo(
    () => ({ token, handleLogin, handleLogout, authGoNext, getMe }),
    [token, handleLogin, handleLogout, authGoNext, getMe],
  );

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}

export function useAuth() {
  const value = React.useContext(AuthContext);
  if (!value) {
    throw new ContextException('useAuth', 'AuthProvider');
  }
  return value;
}

type HandleLoginArgs = {
  isSignup: boolean;
  token: string | null;
  show: { id: string; name: string; email: string } | null;
  method?: string;
  hasInfo?: boolean;
  next?: string;
};

type HandleLogoutArgs = {
  next?: string;
};

type AuthContextValue = {
  token?: string | null;
  handleLogin(args: HandleLoginArgs): Promise<void>;
  handleLogout(args?: HandleLogoutArgs): Promise<void>;
  authGoNext(next?: string): void;
  getMe: () => TokenPayload | null;
};

type AuthProviderProps = {
  token?: string | null;
  onTokenChange?(token: string | null): void;
  children: React.ReactNode;
};

type TokenPayload = {
  showId: string;
  userId: string;
};

export default AuthContext;
