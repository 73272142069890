import React from 'react';
import { FormattedMessage } from 'react-intl';
import { makeStyles } from '@material-ui/styles';
import { Typography, Theme, Paper, Button } from '@material-ui/core';
import Html from '../../../components/Html';
import {
  isSubscriptionActiveLike,
  Plan,
  Subscription,
  Tier,
} from '../../../entities/membership';

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    padding: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  title: {
    textAlign: 'center',
  },
  amount: {
    textAlign: 'center',
    fontWeight: 'bold',
  },
  interval: {
    textAlign: 'center',
    display: 'block',
  },
  description: {
    padding: theme.spacing(2, 0),
    marginBottom: theme.spacing(2),
  },
  submitButton: {
    width: '100%',
    marginTop: theme.spacing(2),
  },
}));

function FreeTierItem({
  tier,
  currentSubscription,
  onSelect,
}: FreeTierItemProps): JSX.Element | null {
  const classes = useStyles();

  const firstPlan = tier.plans[0];
  const hasSubscribed = isSubscriptionActiveLike(currentSubscription);

  const handleSelect = React.useCallback(() => {
    onSelect(tier, firstPlan);
  }, [tier, firstPlan, onSelect]);

  if (tier.plans.length === 0) {
    return null;
  }

  return (
    <Paper variant="outlined" className={classes.container}>
      <Typography className={classes.title}>{tier.title}</Typography>
      {tier.description && (
        <Html html={tier.description} className={classes.description} />
      )}
      <Button
        disabled={hasSubscribed}
        variant="contained"
        color="primary"
        size="large"
        className={classes.submitButton}
        onClick={handleSelect}
      >
        {hasSubscribed ? (
          <FormattedMessage id="donation.subscribe.duplicate" />
        ) : (
          <FormattedMessage id="donation.subscribe.free" />
        )}
      </Button>
    </Paper>
  );
}

type FreeTierItemProps = {
  tier: Tier;
  currentSubscription?: Subscription | null;
  onSelect(tier: Tier, plan: Plan): void;
};

export default FreeTierItem;
