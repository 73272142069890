import React from 'react';
import { useHistory } from 'react-router-dom';
import { FormattedMessage, useIntl } from 'react-intl';
import cx from 'classnames';
import { makeStyles } from '@material-ui/styles';
import {
  Avatar,
  Typography,
  TableRow,
  TableCell,
  Theme,
} from '@material-ui/core';
import { PaginationTable } from '../../../components/Table';
import CenterLoading from '../../../components/CenterLoading';
import { formatDate } from '../../../utils';
import { MessageId } from '../../../i18n';
import { useGetSubscriptions } from '../../../gql/hooks/membership/subscription';
import { useSearchShow } from '../../../gql/hooks/show/search';
import { SubscriptionWithShow } from '../type';
import { SubscriptionStatus } from '../../../entities/membership';

const useStyles = makeStyles((theme: Theme) => ({
  active: {
    color: theme.palette.success.light,
  },
  cancel: {
    color: theme.palette.error.light,
  },
  info: {
    display: 'flex',
    alignItems: 'center',
  },
  name: {
    minWidth: 150,
    marginLeft: theme.spacing(1),
    fontWeight: 'bold',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    display: '-webkit-box',
    '-webkit-line-clamp': 2,
    '-webkit-box-orient': 'vertical',
  },
  row: {
    cursor: 'pointer',
  },
  nowrap: {
    whiteSpace: 'nowrap',
  },
}));

function Row({ data, onClick }: RowProps): JSX.Element {
  const intl = useIntl();
  const classes = useStyles();

  const { createdAt, nextPaymentDate, status, tier, show } = data;
  const { name, avatar } = show;

  const statusTextIds = {
    [SubscriptionStatus.active]: 'donation.recurring.active',
    [SubscriptionStatus.canceled]: 'donation.recurring.canceled',
    [SubscriptionStatus.canceling]: 'donation.recurring.canceled',
    [SubscriptionStatus.trialing]: 'donation.recurring.trialing',
  };

  const statusId = (statusTextIds[status] as MessageId) || null;

  const handleClick = React.useCallback(() => {
    onClick(data);
  }, [data, onClick]);

  return (
    <TableRow className={classes.row} onClick={handleClick}>
      <TableCell component="th" scope="row">
        <div className={classes.info}>
          <Avatar variant="rounded" src={avatar || ''} />
          <Typography className={classes.name}>{name}</Typography>
        </div>
      </TableCell>
      <TableCell
        className={cx(classes.nowrap, {
          [classes.active]:
            status === SubscriptionStatus.active ||
            status === SubscriptionStatus.trialing,
          [classes.cancel]:
            status === SubscriptionStatus.canceled ||
            status === SubscriptionStatus.canceling,
        })}
      >
        {statusId ? intl.formatMessage({ id: statusId }) : '-'}
      </TableCell>
      <TableCell className={classes.nowrap}>{tier.title}</TableCell>
      <TableCell className={classes.nowrap}>{formatDate(createdAt)}</TableCell>
      <TableCell className={classes.nowrap}>
        {formatDate(nextPaymentDate)}
      </TableCell>
    </TableRow>
  );
}

function SubscriptionList(): JSX.Element {
  const classes = useStyles();
  const history = useHistory();
  const { loading, data, ...paginationProps } = useGetSubscriptions();

  const subscriptions = data?.subscriptions || [];

  const showIds = Array.from(new Set(subscriptions.map(s => s.show.showId)));

  const { loading: showLoading, data: showData } = useSearchShow({
    showIds,
    skip: showIds.length === 0,
  });

  const handleRowClick = React.useCallback(
    item => {
      history.push(`/donation/recurring/${item.id}`);
    },
    [history],
  );

  if (loading || showLoading) {
    return <CenterLoading />;
  }

  const showMap: Record<
    string,
    {
      id: string;
      name: string;
      avatar: string | null;
      urlSlug: string | null;
    }
  > = {};
  showData?.playerShowFind?.forEach(s => {
    showMap[s.id] = s;
  });

  const arr = subscriptions.map(s => ({
    ...s,
    show: {
      ...s.show,
      ...showMap[s.show.showId],
    },
  }));

  return (
    <PaginationTable
      columnCount={6}
      renderHeader={() => (
        <TableRow>
          <TableCell>
            <FormattedMessage id="donation.podcast" />
          </TableCell>
          <TableCell>
            <FormattedMessage id="donation.status" />
          </TableCell>
          <TableCell>
            <FormattedMessage id="donation.tier" />
          </TableCell>
          <TableCell>
            <FormattedMessage id="donation.startDate" />
          </TableCell>
          <TableCell className={classes.nowrap}>
            <FormattedMessage id="donation.nextDate" />
          </TableCell>
        </TableRow>
      )}
      data={arr}
      {...paginationProps}
      renderRow={data => (
        <Row key={data.id} data={data} onClick={handleRowClick} />
      )}
    />
  );
}

type RowProps = {
  data: SubscriptionWithShow;
  onClick(item: SubscriptionWithShow): void;
};

export default SubscriptionList;
