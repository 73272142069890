import React from 'react';
import { FormattedMessage } from 'react-intl';
import cx from 'classnames';
import { makeStyles } from '@material-ui/styles';
import { Typography, Theme, Paper } from '@material-ui/core';
import { Plan } from '../../../entities/membership';
import { formatMoney } from './util';

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    padding: theme.spacing(1),
    cursor: 'pointer',
  },
  title: {
    textAlign: 'center',
  },
  amount: {
    textAlign: 'center',
    fontWeight: 'bold',
  },
  interval: {
    textAlign: 'center',
    display: 'block',
  },
  focus: {
    borderColor: theme.palette.primary.main,
    borderWidth: 2,
  },
}));

function PlanItem({ plan, selected, onSelect }: PlanItemProps): JSX.Element {
  const classes = useStyles();

  const handleSelect = React.useCallback(() => {
    onSelect(plan);
  }, [plan, onSelect]);

  return (
    <Paper
      color="primary"
      variant="outlined"
      className={cx(classes.container, {
        [classes.focus]: selected,
      })}
      onClick={handleSelect}
    >
      <Typography variant="body2" className={classes.amount}>
        {formatMoney(plan.price.money)}
      </Typography>
      {plan.price.interval ? (
        <Typography variant="caption" className={classes.interval}>
          <FormattedMessage
            id={
              plan.price.interval.unit === 'm'
                ? 'donation.monthly'
                : 'donation.yearly'
            }
          />
        </Typography>
      ) : null}
    </Paper>
  );
}

type PlanItemProps = {
  plan: Plan;
  selected: boolean;
  onSelect(plan: Plan): void;
};

export default PlanItem;
