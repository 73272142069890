import { Helmet } from 'react-helmet-async';
import { useParams } from 'react-router';
import CenterLoading from '../../../components/CenterLoading';
import NotFound from '../../../components/NotFound';
import { useGetPostDetail } from '../../../gql/hooks/post';
import Layout from '../../WebPlayer/Layout';
import RedirectWithStatus from '../../../components/RedirectWithStatus';
import Content from './Content';

function PostDetail(): JSX.Element {
  const { userId, postId } = useParams<{ userId: string; postId: string }>();
  const { loading, data } = useGetPostDetail(postId);

  if (loading) {
    return (
      <Layout hideController hideNavbar>
        <CenterLoading />
      </Layout>
    );
  }

  const post = data?.playerPostFindOne;
  const isAuthor = post?.show.id === userId || post?.show.urlSlug === userId;

  if (!post || !isAuthor) {
    return (
      <Layout hideController hideNavbar>
        <NotFound />
      </Layout>
    );
  }

  if (userId !== post?.show.urlSlug?.toLowerCase()) {
    const slug = post?.show.urlSlug?.toLowerCase() || userId;
    return (
      <RedirectWithStatus to={`/user/${slug}/posts/${postId}`} status={301} />
    );
  }

  return (
    <Layout hideController hideNavbar>
      <Helmet>
        <title>{post.title}</title>
      </Helmet>
      <Content post={post} />
    </Layout>
  );
}

export default PostDetail;
