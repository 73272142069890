import { useQuery } from '@apollo/client';
import { Show } from '../../../entities/show';
import { SearchShow, SearchShowWithSpotify } from '../../v2/show';

export function useSearchShow(args: SearchShowArgs) {
  const result = useQuery<SearchShowData, SearchShowVariables>(SearchShow, {
    variables: {
      showIds: args.showIds,
    },
    skip: args.skip,
  });

  return result;
}

type SearchShowArgs = {
  showIds?: string[];
  skip?: boolean;
};

type SearchShowData = {
  playerShowFind: Pick<Show, 'id' | 'name' | 'avatar' | 'urlSlug'>[];
};

type SearchShowVariables = {
  showIds?: string[];
};

export function useSearchShowWithSpotify(args: SearchShowArgs) {
  const result = useQuery<SearchShowWithSpotifyData, SearchShowVariables>(
    SearchShowWithSpotify,
    {
      variables: {
        showIds: args.showIds,
      },
      skip: args.skip,
    },
  );

  return result;
}

type SearchShowWithSpotifyData = {
  playerShowFind: Pick<
    Show,
    'id' | 'name' | 'avatar' | 'urlSlug' | 'distributions'
  >[];
};
