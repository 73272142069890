import React from 'react';
import { makeStyles } from '@material-ui/styles';
import PlatformIcon from '../../components/icons/PlatformIcon';
import config from '../../config';
import PlatformItem from './PlatformItem';

const useStyles = makeStyles(() => ({
  icon: {
    width: 28,
    height: 28,
  },
}));

function FirstoryItem({
  type,
  id,
  onClick,
}: FirstoryItemProps): JSX.Element | null {
  const classes = useStyles();
  const prefix = type === 'show' ? 'user' : 'story';
  const url = `${config.rssUrlBase}/${prefix}/${id}`;

  if (!id) {
    return null;
  }

  return (
    <PlatformItem
      title="Firstory"
      url={url}
      startIcon={
        <PlatformIcon
          className={classes.icon}
          type={{ type: undefined, variant: 'firstory' }}
        />
      }
      onClick={() => onClick('firstory')}
    />
  );
}

type FirstoryItemProps = {
  type: 'show' | 'episode';
  id?: string | null;
  onClick: (platform: string) => void;
};

export default FirstoryItem;
