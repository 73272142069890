import React from 'react';
import cx from 'classnames';
import { makeStyles } from '@material-ui/styles';
import { Button, ButtonProps, ButtonTypeMap } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  button: {
    background: '#1db954',
    '&:hover': {
      background: '#1ed760',
    },
    '&:active': {
      background: '#1ed760',
    },
  },
  spotifyIcon: {
    width: 28,
    height: 28,
  },
}));

function SpotifyBaseButton<D extends React.ElementType, P>({
  children,
  ...buttonProps
}: SpotifyBaseButtonProps<D, P>): JSX.Element {
  const classes = useStyles();

  return (
    <Button
      {...buttonProps}
      className={cx(classes.button, buttonProps?.className)}
      startIcon={
        <img
          src="/assets/platforms/spotify-white.png"
          alt="spotify"
          className={classes.spotifyIcon}
        />
      }
    >
      {children}
    </Button>
  );
}

type SpotifyBaseButtonProps<
  D extends React.ElementType = ButtonTypeMap['defaultComponent'],
  P = Record<string, unknown>,
> = ButtonProps<D, P>;

export default SpotifyBaseButton;
