import { Avatar } from '@material-ui/core';
import React from 'react';

function DiscordGuildIcon({
  guildId,
  name,
  icon,
  className,
  size,
}: DiscordGuildIconProps): JSX.Element {
  const sizeStyle = size ? { width: size, height: size } : {};

  if (!icon) {
    return (
      <Avatar
        variant="rounded"
        className={className}
        alt={name}
        src="#"
        style={sizeStyle}
      />
    );
  }

  const url = `https://cdn.discordapp.com/icons/${guildId}/${icon}.png`;

  return (
    <Avatar
      variant="rounded"
      className={className}
      alt={name}
      src={url}
      style={sizeStyle}
    />
  );
}

type DiscordGuildIconProps = {
  guildId: string;
  name: string;
  icon?: string | null;
  className?: string;
  size?: number;
};

export default DiscordGuildIcon;
