import { IWhiteList, getDefaultCSSWhiteList, getDefaultWhiteList } from 'xss';

export function useSanitizedHtml(html: string | null | undefined) {
  if (!html) {
    return { sanitizedHtml: '' };
  }

  const defaultWhiteList = getDefaultWhiteList();
  const defaultCSSWhiteList = getDefaultCSSWhiteList();
  const customWhiteList: IWhiteList = {
    ...defaultWhiteList,
    a: [...(defaultWhiteList?.a || []), 'rel'],
    p: [...(defaultWhiteList?.p || []), 'style'],
  };

  const customCSSWhiteList = {
    ...defaultCSSWhiteList,
    'text-align': true,
  };

  const sanitizedHtml = filterXSS(html, {
    whiteList: customWhiteList,
    stripIgnoreTag: true,
    css: customCSSWhiteList,
  });
  return { sanitizedHtml };
}
