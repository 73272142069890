import { useQuery } from '@apollo/client';
import { GetShowInfo } from '../../v2/show';
import { SearchEpisode } from '../../v2/episode';
import usePagination from '../../../hooks/usePagination';
import {
  transformArray,
  transformShow,
  transformEpisode,
} from '../../transform';

export function useGetShowInfoLegacy(showId) {
  const { data, ...args } = useQuery(GetShowInfo, {
    variables: { showId },
    fetchPolicy: 'cache-first',
  });

  if (!data) {
    return { data, ...args };
  }

  return {
    data: {
      userInfo: transformShow(data.playerShowFindOneByUrlSlug),
    },
    ...args,
  };
}

export function useGetShowEpisodesLegacy(showId) {
  const { data, ...args } = usePagination({
    dataKey: 'playerEpisodeFind',
    query: SearchEpisode,
    variables: { showId },
    ssr: false,
    skip: !showId,
    useSkip: true,
  });

  if (!data) {
    return args;
  }

  return {
    data: {
      feeds: transformArray(data.playerEpisodeFind, transformEpisode),
    },
    ...args,
  };
}

export function useGetShowData(showId) {
  const { loading: showLoading, data: showData } = useGetShowInfoLegacy(showId);
  const { data: episodeData } = useGetShowEpisodesLegacy(showId);

  const data = {
    ...showData,
    ...episodeData,
  };

  return {
    loading: showLoading,
    data,
  };
}
