import React from 'react';
import { makeStyles } from '@material-ui/styles';
import {
  Table as MuiTable,
  TableContainer,
  TableBody,
  TableHead,
  TableRow,
  TableCell,
} from '@material-ui/core';

const useStyles = makeStyles({
  tableContainer: {
    maxHeight: (props: StyleProps) => props.maxHeight || 'auto',
  },
  table: {
    minWidth: props => props.minWidth || 500,
  },
});

function Header({ renderHeader, headers }: HeaderProps): JSX.Element | null {
  if (renderHeader) {
    return renderHeader();
  }

  if (!headers) {
    return null;
  }

  return (
    <TableRow>
      {headers.map((header, i) => (
        <TableCell key={i}>{header}</TableCell>
      ))}
    </TableRow>
  );
}

function Table<DataType>({
  maxHeight,
  minWidth,
  size,
  headers,
  data,
  columnCount,
  renderHeader,
  renderRow,
  footer,
  ...props
}: TableProps<DataType>): JSX.Element {
  const classes = useStyles({ minWidth, maxHeight });

  return (
    <>
      <TableContainer className={classes.tableContainer}>
        <MuiTable size={size} className={classes.table} {...props}>
          <TableHead>
            <Header renderHeader={renderHeader} headers={headers} />
          </TableHead>
          <TableBody>{data?.length ? data?.map(renderRow) : null}</TableBody>
        </MuiTable>
      </TableContainer>
      {footer}
    </>
  );
}

type StyleProps = {
  maxHeight?: number;
  minWidth?: number;
};

export type TableProps<DataType> = {
  maxHeight?: number;
  minWidth?: number;
  size?: 'medium' | 'small';
  headers?: string[];
  data?: DataType[];
  columnCount?: number;
  total?: number;
  renderHeader?: () => JSX.Element;
  renderRow(data: DataType, index: number): JSX.Element;
  footer?: React.ReactElement;
};

type HeaderProps = {
  renderHeader?: () => JSX.Element;
  headers?: string[];
};

export default Table;
