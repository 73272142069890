export function loadScript(name: string, url: string) {
  if (window.loadScriptPromise[name]) {
    return window.loadScriptPromise[name];
  }

  const p = new Promise<void>(resolve => {
    const existingScript = document.getElementById(name);
    if (existingScript) {
      resolve();
    } else {
      const script = document.createElement('script');
      script.src = url;
      script.id = name;
      document.body.appendChild(script);

      script.onload = () => {
        resolve();
      };

      script.onerror = () => {
        resolve();
      };
    }
  });

  window.loadScriptPromise[name] = p;
  return p;
}
