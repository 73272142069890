import { useQuery } from '@apollo/client';
import { Episode } from '../../../entities/episode';
import usePagination from '../../../hooks/usePagination';
import { GetEpisodeDetail, SearchEpisode } from '../../v2/episode';

export function useGetEpisodeDetail(episodeId: string) {
  const result = useQuery<GetEpisodeDetailData, GetEpisodeDetailVariables>(
    GetEpisodeDetail,
    {
      variables: { episodeId },
    },
  );

  return result;
}

type GetEpisodeDetailData = {
  playerEpisodeFindOne?: Episode;
};

type GetEpisodeDetailVariables = {
  episodeId: string;
};

export function useGetShowEpisode(args: UseGetShowEpisodeArgs) {
  const result = usePagination<GetShowEpisodeData, GetShowEpisodeVariables>({
    dataKey: 'playerEpisodeFind',
    query: SearchEpisode,
    variables: { showId: args.showId, queryString: args.queryString },
    useSkip: true,
  });

  return result;
}

type UseGetShowEpisodeArgs = {
  showId: string;
  queryString?: string;
};

type GetShowEpisodeData = {
  playerEpisodeFind: Episode[];
};

type GetShowEpisodeVariables = {
  showId: string;
  queryString?: string;
};
