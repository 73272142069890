import React from 'react';
import { Route, Switch } from 'react-router-dom';
import Layout from '../WebPlayer/Layout';
import LinkWithSpotify from './LinkWithSpotify';
import LinkWithSpotifyResult from './LinkWithSpotifyResult';

function LinkWithSpotifyRoutes() {
  return (
    <Layout hideController>
      <Switch>
        <Route path="/link-with-spotify/callback">
          <LinkWithSpotifyResult />
        </Route>
        <Route>
          <LinkWithSpotify />
        </Route>
      </Switch>
    </Layout>
  );
}

export default LinkWithSpotifyRoutes;
