import React from 'react';
import { makeStyles } from '@material-ui/styles';
import Typography from '@material-ui/core/Typography';
import TappayLogo from '../assets/tappay-logo.png';

const useStyles = makeStyles({
  container: {
    maxWidth: 400,
  },
  tappayLogo: {
    height: 30,
    marginRight: 10,
  },
});

function PoweredByTappay() {
  const classes = useStyles();
  return (
    <div className={classes.container}>
      <img src={TappayLogo} alt="tappay" className={classes.tappayLogo} />
      <Typography color="textSecondary" variant="caption">
        本公司採用喬睿科技 TapPay 金流交易系統,消費者刷卡時直接在銀行端
        系統中交易,本公司不會留下您的信用卡資料,以保障你的權益,資料
        傳輸過程採用嚴密的 SSL 2048bit 加密技術保護。
      </Typography>
    </div>
  );
}

export default PoweredByTappay;
