import React from 'react';
import { FormattedMessage } from 'react-intl';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  CircularProgress,
} from '@material-ui/core';

function AlertDialog({
  variant,
  title,
  description,
  confirmLoading,
  open,
  onConfirm,
  onClose,
  onExited,
}: AlertDialogProps): JSX.Element {
  return (
    <Dialog
      fullWidth
      maxWidth="xs"
      open={open}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      TransitionProps={{
        onExited,
      }}
    >
      <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {description}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        {variant === 'confirm' && (
          <>
            {!confirmLoading && (
              <Button color="primary" onClick={onClose} autoFocus>
                <FormattedMessage id="general.cancel" />
              </Button>
            )}
            <Button color="primary" onClick={onConfirm}>
              {confirmLoading ? (
                <CircularProgress size={24} />
              ) : (
                <FormattedMessage id="general.confirm" />
              )}
            </Button>
          </>
        )}
        {variant === 'info' && (
          <Button color="primary" onClick={onConfirm}>
            OK
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
}

type AlertDialogVariant = 'confirm' | 'info';

export type AlertDialogProps = {
  variant?: AlertDialogVariant;
  title?: string;
  description?: string;
  confirmLoading: boolean;
  open: boolean;
  onConfirm(): void;
  onClose(): void;
  onExited(): void;
};

export default AlertDialog;
