import React from 'react';
import ReactLinkify from 'react-linkify';
import Link from '@material-ui/core/Link';

function createComponentDecorator({ ugc }: CreateComponentDecoratorArgs) {
  function componentDecorator(
    href: string,
    text: string,
    key: number,
  ): JSX.Element {
    let rel = 'noreferrer noopener';

    if (ugc) {
      rel += ' ugc';
    }

    return (
      <Link color="secondary" href={href} key={key} target="_blank" rel={rel}>
        {text}
      </Link>
    );
  }

  return componentDecorator;
}

function Linkify({ ugc, children }: LinkifyProps): JSX.Element {
  const componentDecorator = React.useMemo(
    () => createComponentDecorator({ ugc }),
    [ugc],
  );

  return (
    <ReactLinkify componentDecorator={componentDecorator}>
      {children}
    </ReactLinkify>
  );
}

type LinkifyProps = {
  ugc?: boolean;
  children?: React.ReactNode;
};

type CreateComponentDecoratorArgs = {
  ugc?: boolean;
};

export default Linkify;
