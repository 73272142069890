import React from 'react';
import { Link } from 'react-router-dom';
import { FormattedMessage, useIntl } from 'react-intl';
import cx from 'classnames';
import { makeStyles } from '@material-ui/styles';
import { Avatar, Chip, Typography, Theme } from '@material-ui/core';
import { formatDate } from '../../../../utils';
import { MessageId } from '../../../../i18n';
import { SubscriptionWithShow } from '../../type';
import { SubscriptionStatus } from '../../../../entities/membership';

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    display: 'flex',
    '@media (max-width: 700px)': {
      flexDirection: 'column',
    },
    paddingBottom: theme.spacing(4),
  },
  avatar: {
    width: 200,
    height: 200,
    marginRight: theme.spacing(4),
    '@media (max-width: 700px)': {
      marginRight: 0,
    },
  },
  headerInfo: {
    marginBottom: theme.spacing(2),
  },
  title: {
    fontWeight: 'bold',
  },
  status: {
    marginTop: theme.spacing(1),
  },
  active: {
    color: theme.palette.success.light,
    borderColor: theme.palette.success.light,
  },
  cancel: {
    color: theme.palette.error.light,
    borderColor: theme.palette.error.light,
  },
  info: {
    display: 'flex',
    alignItems: 'center',
  },
  name: {
    marginLeft: theme.spacing(1),
    fontWeight: 'bold',
  },
}));

function SubscriptionInfo({
  subscription,
}: SubscriptionInfoProps): JSX.Element {
  const classes = useStyles();
  const intl = useIntl();

  const { createdAt, nextPaymentDate, status, tier, show } = subscription;
  const { name, avatar } = show;

  const statusTextIds = {
    [SubscriptionStatus.active]: 'donation.recurring.active',
    [SubscriptionStatus.canceled]: 'donation.recurring.canceled',
    [SubscriptionStatus.canceling]: 'donation.recurring.canceled',
    [SubscriptionStatus.trialing]: 'donation.recurring.trialing',
  };

  const statusId = (statusTextIds[status] as MessageId) || null;

  return (
    <div className={classes.container}>
      <Avatar variant="rounded" src={avatar || ''} className={classes.avatar} />
      <div>
        <div className={classes.headerInfo}>
          <Typography
            color="textPrimary"
            variant="h5"
            className={classes.title}
            component={Link}
            to={`/user/${show.id}`}
          >
            {name} - {tier.title}
          </Typography>
          <div>
            <Typography variant="caption">
              <FormattedMessage id="donation.startDate" />:{' '}
              {formatDate(createdAt)}，
              {status === SubscriptionStatus.trialing ||
              status === SubscriptionStatus.active ||
              status === SubscriptionStatus.canceling ? (
                <span>
                  <FormattedMessage id="donation.nextDate" />:{' '}
                  {formatDate(nextPaymentDate)}
                </span>
              ) : null}
            </Typography>
          </div>
          <Chip
            variant="outlined"
            className={cx(classes.status, {
              [classes.active]:
                status === SubscriptionStatus.active ||
                status === SubscriptionStatus.trialing,
              [classes.cancel]:
                status === SubscriptionStatus.canceled ||
                status === SubscriptionStatus.canceling,
            })}
            label={statusId ? intl.formatMessage({ id: statusId }) : '-'}
          />
        </div>
      </div>
    </div>
  );
}

type SubscriptionInfoProps = {
  subscription: SubscriptionWithShow;
};

export default SubscriptionInfo;
