const info = require('./info.json');

const version = info.version;
const serviceName = info.serviceName;

const Configs: { dev: Config; staging: Config; prod: Config } = {
  dev: {
    serviceName,
    version: `dev-${version}`,
    env: 'dev',
    url: 'https://open.firstory.me',
    // url: 'https://open-beta.firstory.me',
    rssUrlBase: 'https://open.firstory.me/staging',
    endpoints: {
      // simple: 'http://localhost:3030/v1/graphql',
      // simple: 'https://staging-api.firstory.me/hosting/v1/graphql',
      simple: 'https://prod-api.firstory.me/hosting/v1/graphql',
      // membership: 'http://localhost:3456/v1/graphql',
      // membership: 'https://staging-api.firstory.me/membership/v1/graphql',
      membership: 'https://prod-api.firstory.me/membership/v1/graphql',
      auth: 'https://prod-api.firstory.me/hosting/v1/graphql',
      v1: 'https://api-v1.firstory.me',
      trackPlay: 'https://backend.endpoints.firstory-709db.cloud.goog',
      // server: 'http://localhost:3030/v1/graphql',
      server: 'https://prod-api.firstory.me/hosting/v1/graphql',
      apm: 'https://staging-api.firstory.me/apm',
    },
    firebase: {
      apiKey: 'AIzaSyANhw7kEbm7_CS1l1cEZybYeFb7EdDFuI4',
      authDomain: 'firstory-dev-5a8e8.firebaseapp.com',
      databaseURL: 'https://firstory-dev-5a8e8.firebaseio.com',
      projectId: 'firstory-dev-5a8e8',
      storageBucket: 'firstory-dev-5a8e8.appspot.com',
      messagingSenderId: '93689108236',
      dynamicLinkDomain: 'firstorydev.page.link',
    },
    tappay: {
      appId: '15370',
      appKey:
        'app_RVI9GzD1hDlgr51uVIOr6nbjLVCpilXJoE8uMF2nL3tXcSW2EedjaZrAS7bJ',
      serverType: 'sandbox',
    },
    googleMerchantId: 'sandbox',
    applePayMerchantId: 'merchant.com.firstory',
    ga: 'UA-113063265-3',
    fbAppId: '665576853857854',
    fbPixelId: '256901005598539',
    mixpanelToken: '99a374a10235aac74deea8c20c157dd2',
    spotifyOpenAccessClientId: 'f06f3a86c00d43578c25a8f8887ae69f',
    hcaptchaSiteKey: 'a6bcbc93-794e-4e3d-9469-a92f67ead639',
    discordClientId: '1125704038831358014',
  },
  staging: {
    serviceName,
    version: `staging-${version}`,
    env: 'staging',
    url: 'https://open-beta.firstory.me',
    rssUrlBase: 'https://open.firstory.me/staging',
    endpoints: {
      auth: 'https://firstory-web-player-staging-dot-firstory-709db.uc.r.appspot.com/api',
      simple: 'https://staging-api.firstory.me/hosting/v1/graphql',
      v1: 'https://api-v1.firstory.me',
      trackPlay: 'https://backend.endpoints.firstory-709db.cloud.goog',
      server: 'https://staging-api.firstory.me/hosting/v1/graphql',
      membership: 'https://staging-api.firstory.me/membership/v1/graphql',
      apm: 'https://staging-api.firstory.me/apm',
    },
    firebase: {
      apiKey: 'AIzaSyANhw7kEbm7_CS1l1cEZybYeFb7EdDFuI4',
      authDomain: 'firstory-dev-5a8e8.firebaseapp.com',
      databaseURL: 'https://firstory-dev-5a8e8.firebaseio.com',
      projectId: 'firstory-dev-5a8e8',
      storageBucket: 'firstory-dev-5a8e8.appspot.com',
      messagingSenderId: '93689108236',
      dynamicLinkDomain: 'firstorydev.page.link',
    },
    tappay: {
      appId: '15370',
      appKey:
        'app_RVI9GzD1hDlgr51uVIOr6nbjLVCpilXJoE8uMF2nL3tXcSW2EedjaZrAS7bJ',
      serverType: 'sandbox',
    },
    googleMerchantId: 'sandbox',
    applePayMerchantId: 'merchant.com.firstory',
    ga: 'UA-113063265-3',
    fbAppId: '665576853857854',
    fbPixelId: '256901005598539',
    mixpanelToken: '99a374a10235aac74deea8c20c157dd2',
    spotifyOpenAccessClientId: 'f06f3a86c00d43578c25a8f8887ae69f',
    hcaptchaSiteKey: 'a6bcbc93-794e-4e3d-9469-a92f67ead639',
    discordClientId: '1125704038831358014',
  },
  prod: {
    serviceName,
    version,
    env: 'prod',
    url: 'https://open.firstory.me',
    rssUrlBase: 'https://open.firstory.me',
    endpoints: {
      auth: 'https://open.firstory.me/api',
      simple: 'https://prod-api.firstory.me/hosting/v1/graphql',
      v1: 'https://api-v1.firstory.me',
      trackPlay: 'https://backend.endpoints.firstory-709db.cloud.goog',
      server: 'https://prod-api.firstory.me/hosting/v1/graphql',
      membership: 'https://prod-api.firstory.me/membership/v1/graphql',
      apm: 'https://prod-api.firstory.me/apm',
    },
    firebase: {
      apiKey: 'AIzaSyAhUYupQ-NV4okBivsxci49V4pi4CA3wxE',
      authDomain: 'firstory-709db.firebaseapp.com',
      databaseURL: 'https://firstory-709db.firebaseio.com',
      projectId: 'firstory-709db',
      storageBucket: 'firstory-709db.appspot.com',
      messagingSenderId: '1052149404106',
      dynamicLinkDomain: 'link.firstory.me',
    },
    tappay: {
      appId: '15370',
      appKey:
        'app_RVI9GzD1hDlgr51uVIOr6nbjLVCpilXJoE8uMF2nL3tXcSW2EedjaZrAS7bJ',
      serverType: 'production',
    },
    googleMerchantId: '13403442244508269400',
    applePayMerchantId: 'merchant.com.firstory',
    ga: 'UA-113063265-3',
    fbAppId: '1373748122771244',
    fbPixelId: '256901005598539',
    mixpanelToken: '1fa276e4e72e21867df7c429e861eecf',
    spotifyOpenAccessClientId: '246a81a8122e4354a8281c47be2ef1c2',
    hcaptchaSiteKey: 'a6bcbc93-794e-4e3d-9469-a92f67ead639',
    discordClientId: '1125704038831358014',
  },
};

function getConfig() {
  if (process.env.NODE_ENV === 'development') {
    return Configs.dev;
  }
  if (process.env.RAZZLE_NODE_ENV === 'staging') {
    return Configs.staging;
  }
  return Configs.prod;
}

const config = getConfig();

type Config = {
  version: string;
  serviceName: string;
  env: 'dev' | 'staging' | 'prod';
  url: string;
  rssUrlBase: string;
  endpoints: {
    auth: string;
    simple: string;
    trackPlay: string;
    v1: string;
    membership: string;
    server: string;
    apm: string;
  };
  firebase: {
    apiKey: string;
    authDomain: string;
    databaseURL: string;
    projectId: string;
    storageBucket: string;
    messagingSenderId: string;
    dynamicLinkDomain: string;
  };
  tappay: {
    appId: string;
    appKey: string;
    serverType: 'production' | 'sandbox';
  };
  googleMerchantId: string;
  applePayMerchantId: string;
  ga: string;
  fbAppId: string;
  fbPixelId: string;
  mixpanelToken: string;
  spotifyOpenAccessClientId: string;
  hcaptchaSiteKey: string;
  discordClientId: string;
};

export default config;
