import React from 'react';
import { FormattedMessage } from 'react-intl';
import { makeStyles } from '@material-ui/styles';
import { Avatar, Button, Paper, Theme, Typography } from '@material-ui/core';
import ListenOnSpotify from '../../components/Spotify/ListenOnSpotify';
import {
  Audience,
  ShowMembership,
  SubscriptionStatus,
} from '../../entities/membership';

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    padding: theme.spacing(2),
    display: 'flex',
    '@media (max-width: 500px)': {
      flexDirection: 'column',
    },
  },
  name: {
    margin: 0,
  },
  image: {
    height: 75,
    width: 75,
    marginRight: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  subscribe: {
    marginTop: theme.spacing(2),
  },
  currentTier: {
    marginTop: theme.spacing(2),
  },
  listenOnSpotify: {
    marginTop: theme.spacing(2),
  },
}));

function ShowInfo({
  spotifyId,
  show,
  showMembership,
  audience,
}: ShowInfoProps): JSX.Element | null {
  const classes = useStyles();

  if (!showMembership) {
    return null;
  }

  const { currentSubscription } = showMembership;
  const hasActiveOrCancelingStatus =
    currentSubscription?.status === SubscriptionStatus.active ||
    currentSubscription?.status === SubscriptionStatus.canceling;

  return (
    <Paper variant="outlined" className={classes.container}>
      <Avatar
        src={show.avatar || undefined}
        className={classes.image}
        variant="rounded"
      />
      <div>
        <Typography variant="h6" className={classes.name}>
          {show.name}
        </Typography>
        <Typography>{show.author}</Typography>
        {currentSubscription && hasActiveOrCancelingStatus ? (
          <div>
            <Typography className={classes.currentTier}>
              <FormattedMessage
                id="donation.currentTier"
                values={{ title: <b>{currentSubscription.tier.title}</b> }}
              />
            </Typography>
            {audience?.hasLinkSpotify || audience?.spotify ? (
              <ListenOnSpotify
                spotifyId={spotifyId}
                className={classes.listenOnSpotify}
              />
            ) : null}
          </div>
        ) : (
          <Button
            variant="contained"
            className={classes.subscribe}
            component="a"
            href={`/join/${show.urlSlug || show.id}?tab=subscription`}
            target="_blank"
            rel="noopener noreferrer"
          >
            <FormattedMessage id="donation.subscribeToUnlock" />
          </Button>
        )}
      </div>
    </Paper>
  );
}

type ShowInfoProps = {
  spotifyId: string;
  show: {
    id: string;
    name: string | null;
    author: string | null;
    avatar: string | null;
    urlSlug: string | null;
  };
  showMembership?: ShowMembership | null;
  audience?: Audience | null;
};

export default ShowInfo;
