import { Link } from 'react-router-dom';
import { useIntl } from 'react-intl';
import { makeStyles } from '@material-ui/styles';
import HomeIcon from '@material-ui/icons/Home';
import SearchIcon from '@material-ui/icons/Search';
import Logo from '../../components/Logo';
import LanguagePicker from '../../components/LanguagePicker';
import useDisclosure from '../../hooks/useDisclosure';
import NavbarItem from './NavbarItem';
import NavbarAuth from './NavbarAuth';
import AccountMenu from './AccountMenu';

const useStyles = makeStyles(theme => ({
  container: {
    position: 'relative',
    display: 'flex',
    width: 250,
    backgroundColor: '#131314',
    flexDirection: 'column',
    paddingBottom: 120,
    '@media (max-width: 900px)': {
      width: '100%',
      flexDirection: 'row',
      alignItems: 'center',
      padding: theme.spacing(1),
      paddingBottom: theme.spacing(1),
    },
  },
  logoContainer: {
    display: 'flex',
    alignItems: 'center',
    color: 'white',
    padding: theme.spacing(2),
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    '@media (max-width: 900px)': {
      padding: 0,
      marginTop: 0,
      marginBottom: 0,
      marginLeft: theme.spacing(2),
    },
    '@media (max-width: 500px)': {
      marginLeft: theme.spacing(1),
    },
  },
  logo: {
    height: 50,
    width: 50,
    cursor: 'pointer',
    '@media (max-width: 900px)': {
      height: 30,
      width: 30,
    },
  },
  padding: {
    flex: 1,
    '@media (min-width: 900px)': {
      display: 'none',
    },
  },
  scrollContainer: {
    overflowY: 'auto',
    display: 'flex',
    flexDirection: 'column',
    '@media (max-width: 900px)': {
      flexDirection: 'row',
      alignItems: 'center',
    },
  },
  hideOnSmall: {
    '@media (max-width: 900px)': {
      display: 'none',
    },
  },
  hideOnLarge: {
    '@media (min-width: 900px)': {
      display: 'none',
    },
  },
  authContainer: {
    position: 'absolute',
    bottom: 0,
    width: '100%',
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end',
    '@media (max-width: 900px)': {
      display: 'none',
    },
  },
  languagePicker: {
    paddingBottom: theme.spacing(4),
    display: 'flex',
    justifyContent: 'center',
  },
}));

function Navbar() {
  const intl = useIntl();
  const classes = useStyles();
  const accountMenu = useDisclosure();

  return (
    <div className={classes.container}>
      <Link to="/" className={classes.logoContainer} aria-label="home-page">
        <Logo className={classes.logo} />
      </Link>
      <div className={classes.padding} />
      <div className={classes.scrollContainer}>
        <NavbarItem
          link
          path="/browse"
          title={intl.formatMessage({ id: 'home' })}
          Icon={HomeIcon}
          className={classes.hideOnSmall}
          aria-label="home-page"
        />
        <NavbarItem
          link
          path="/search"
          title={intl.formatMessage({ id: 'general.search' })}
          Icon={SearchIcon}
          aria-label="search"
        />
        <div className={classes.hideOnLarge}>
          <AccountMenu
            open={accountMenu.isOpen}
            onOpen={accountMenu.open}
            onClose={accountMenu.close}
          />
        </div>
      </div>
      <div className={classes.authContainer}>
        <div className={classes.languagePicker}>
          <LanguagePicker />
        </div>
        <NavbarAuth />
      </div>
    </div>
  );
}

export default Navbar;
