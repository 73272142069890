import { gql } from '@apollo/client';
import { Define } from '../../define';
import {
  ShowMembershipFragment,
  DonationFragment,
  SubscriptionFragment,
} from './fragments';

export const GetAudience = gql`
  query GetAudience {
    audience {
      id
    }
  }
`;

export const GetAudienceSpotify = gql`
  query GetAudience {
    audience {
      id
      hasLinkSpotify
      spotify {
        id
        name
        imageUrl
        url
      }
    }
  }
`;

export const GetShowMembership = gql`
  query GetShowMembership($showId: ID!) {
    show(id: $showId) {
      ...ShowMembershipFragment
    }
  }
  ${ShowMembershipFragment}
`;

export const GetDonations = gql`
  query GetDonations($first: Int!, $skip: Int!) {
    donations(first: $first, skip: $skip) {
      ...DonationFragment
    }
  }
  ${DonationFragment}
`;

export const GetSubscriptions = gql`
  query GetSubscriptions($first: Int!, $skip: Int!) {
    subscriptions(first: $first, skip: $skip) {
      ...SubscriptionFragment
    }
  }
  ${SubscriptionFragment}
`;

export const GetSubscriptionDetail = gql`
  query GetSubscriptionDetail($id: ID!) {
    subscription(id: $id) {
      ...SubscriptionFragment
      show {
        id
        hasResourceEntitlement(type: ${Define.Membership.Resource.Type.episode})
      }
      paymentMethod {
        id
        cardFirst6
        cardLast4
        cardType
        cardExpDate
      }
    }
  }
  ${SubscriptionFragment}
`;

export const GetDonationDetail = gql`
  query GetDonationDetail($id: ID!) {
    donation(id: $id) {
      ...DonationFragment
    }
  }
  ${DonationFragment}
`;

export const DonateShow = gql`
  mutation DonateShow(
    $provider: Int!
    $prime: String
    $showId: ID!
    $planId: ID
    $customPrice: CustomPrice
    $email: String!
    $donationName: String!
    $message: String!
    $paymentName: String
    $phoneNumber: String
  ) {
    audienceDonateShow(
      provider: $provider
      prime: $prime
      showId: $showId
      planId: $planId
      customPrice: $customPrice
      email: $email
      donationName: $donationName
      message: $message
      paymentName: $paymentName
      phoneNumber: $phoneNumber
    ) {
      purchaseOrderId
      status
      redirectUrl
    }
  }
`;

export const SubscribeShow = gql`
  mutation SubscribeShow(
    $provider: Int!
    $prime: String
    $showId: ID!
    $planId: ID!
    $email: String!
    $donationName: String!
    $message: String!
    $paymentName: String
    $phoneNumber: String
  ) {
    audienceSubscribeShow(
      provider: $provider
      prime: $prime
      showId: $showId
      planId: $planId
      email: $email
      donationName: $donationName
      message: $message
      paymentName: $paymentName
      phoneNumber: $phoneNumber
    ) {
      purchaseOrderId
      status
      redirectUrl
    }
  }
`;

export const AudienceAuthorizedSpotify = gql`
  mutation AudienceAuthorizedSpotify($code: String!) {
    audienceAuthorizedSpotify(code: $code) {
      completionUrl
    }
  }
`;

export const AudienceAuthorizedDiscord = gql`
  mutation AudienceAuthorizedDiscord($code: String!) {
    audienceAuthorizedDiscord(code: $code) {
      success
    }
  }
`;

export const AudienceDisconnectDiscord = gql`
  mutation AudienceDisconnectDiscord {
    audienceDisconnectDiscord {
      success
    }
  }
`;

export const CreateAudience = gql`
  mutation CreateAudience($email: String!) {
    createAudience(email: $email) {
      id
    }
  }
`;

export const CancelSubscription = gql`
  mutation CancelSubscription($subscriptionId: ID!) {
    audienceCancelSubscription(subscriptionId: $subscriptionId) {
      id
    }
  }
`;

export const GetShowForSubscriptionDetail = gql`
  query GetShowForSubscriptionDetail($urlSlug: String!, $showId: ID!) {
    playerShowFindOneByUrlSlug(urlSlug: $urlSlug) {
      id
      name
      avatar
      urlSlug
      distributions {
        status
        platformType
        platformId
        platformUrl
      }
      setting {
        enableSpotifyOpenAccess
      }
    }
    audienceShowSubscriptionFindOne(showId: $showId) {
      privateRssToken
    }
  }
`;

export const GetAudienceDiscord = gql`
  query GetAudienceDiscord {
    audience {
      id
      discord {
        id
        username
        discriminator
        globalName
        avatar
      }
    }
  }
`;

export const GetSubscriptionsWithDiscord = gql`
  query GetSubscriptionsWithDiscord(
    $statuses: [Int!]!
    $first: Int!
    $skip: Int!
  ) {
    subscriptions(statuses: $statuses, first: $first, skip: $skip) {
      id
      status
      show {
        id
        showId
        discord {
          showId
          guild {
            id
            name
            icon
          }
        }
      }
    }
  }
`;

export const GetPaymentMethods = gql`
  query GetPaymentMethods {
    paymentMethods {
      id
      cardFirst6
      cardLast4
      cardType
      cardExpDate
      purchaseOrderCount
    }
  }
`;

export const CreatePaymentMethod = gql`
  mutation CreatePaymentMethod(
    $prime: String!
    $email: String!
    $paymentName: String!
    $phoneNumber: String!
  ) {
    audienceCreatePaymentMethod(
      prime: $prime
      email: $email
      paymentName: $paymentName
      phoneNumber: $phoneNumber
    ) {
      redirectUrl
    }
  }
`;

export const UpdateSubscriptionPaymentMethod = gql`
  mutation UpdateSubscriptionPaymentMethod(
    $subscriptionId: ID!
    $paymentMethodId: ID!
  ) {
    audienceUpdateSubscriptionPaymentMethod(
      subscriptionId: $subscriptionId
      paymentMethodId: $paymentMethodId
    ) {
      success
    }
  }
`;

export const DeletePaymentMethod = gql`
  mutation DeletePaymentMethod($paymentMethodId: ID!) {
    audienceDeletePaymentMethod(paymentMethodId: $paymentMethodId) {
      success
    }
  }
`;

export const SubscribeShowFreeTier = gql`
  mutation SubscribeShowFreeTier(
    $showId: ID!
    $planId: ID!
    $email: String!
    $donationName: String!
    $message: String!
  ) {
    audienceSubscribeShowFreeTier(
      showId: $showId
      planId: $planId
      email: $email
      donationName: $donationName
      message: $message
    ) {
      subscriptionId
      status
    }
  }
`;
