import React from 'react';
import { FormattedMessage } from 'react-intl';
import { makeStyles } from '@material-ui/styles';
import { Typography, Theme, Container } from '@material-ui/core';
import SpotifyLoginButton from '../../components/Spotify/LoginButton';
import CenterLoading from '../../components/CenterLoading';
import useQueryString from '../../hooks/useQueryString';
import { useGetAudienceSpotify } from '../../gql/hooks/membership/audience';
import { useGetShowInfoByPlatformId } from '../../gql/hooks/show/platform';
import { Distribution } from '../../entities/distribution';
import { useGetShowMembership } from '../../gql/hooks/membership/get-show-setting';
import {
  Audience,
  ShowMembership,
  SubscriptionStatus,
} from '../../entities/membership';
import SpotifyAccountInfo from './SpotifyAccountInfo';
import ShowInfo from './ShowInfo';

// https://open.firstory.me/link-with-spotify?spotifyShowUri=spotify%3Ashow%3A413rlEgm6z68l6PltDEBss

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    padding: theme.spacing(4),
    '@media (max-width: 500px)': {
      padding: theme.spacing(2),
    },
  },
  title: {
    marginBottom: theme.spacing(4),
  },
  subtitle: {
    marginBottom: theme.spacing(2),
  },
  showInfoSection: {
    marginBottom: theme.spacing(6),
  },
}));

function ConnectedSpotifyAccount({
  showMembership,
  audience,
}: ConnectedSpotifyAccountProps): JSX.Element | null {
  const classes = useStyles();

  const currentSubscription = showMembership.currentSubscription;
  const hasActiveOrCancelingStatus =
    currentSubscription?.status === SubscriptionStatus.active ||
    currentSubscription?.status === SubscriptionStatus.canceling;

  if (!audience) {
    return null;
  }

  if (audience.hasLinkSpotify && audience.spotify) {
    return (
      <div>
        <Typography variant="h5" className={classes.subtitle}>
          <FormattedMessage id="donation.connectedSpotifyAccount" />
        </Typography>
        <SpotifyAccountInfo
          hasLinkSpotify={audience.hasLinkSpotify}
          spotify={audience.spotify}
        />
      </div>
    );
  }

  if (hasActiveOrCancelingStatus) {
    return (
      <div>
        <Typography variant="h5" className={classes.subtitle}>
          <FormattedMessage id="donation.connectedSpotifyAccount" />
        </Typography>
        <SpotifyLoginButton scopes={['user-soa-link']} />
      </div>
    );
  }

  return null;
}

function LinkWithSpotify({
  spotifyId,
  show,
  audience,
}: LinkWithSpotifyProps): JSX.Element {
  const classes = useStyles();

  const { loading, data } = useGetShowMembership(show?.id);

  if (loading) {
    return (
      <Container className={classes.container}>
        <Typography variant="h4" className={classes.title}>
          <FormattedMessage id="donation.linkWithSpotify" />
        </Typography>
        <CenterLoading />
      </Container>
    );
  }

  return (
    <Container className={classes.container}>
      <Typography variant="h4" className={classes.title}>
        <FormattedMessage id="donation.linkWithSpotify" />
      </Typography>
      {spotifyId && show ? (
        <div className={classes.showInfoSection}>
          <ShowInfo
            spotifyId={spotifyId}
            show={show}
            showMembership={data?.show}
            audience={audience}
          />
        </div>
      ) : null}
      {data?.show ? (
        <ConnectedSpotifyAccount
          showMembership={data?.show}
          audience={audience}
        />
      ) : null}
    </Container>
  );
}

function LinkWithSpotifyContainer() {
  const qs = useQueryString();
  const spotifyShowUri = qs.get('spotifyShowUri');
  const spotifyId = spotifyShowUri?.startsWith('spotify:show:')
    ? spotifyShowUri.replace('spotify:show:', '')
    : null;

  const { loading, data } = useGetAudienceSpotify();

  const { loading: showLoading, data: showData } = useGetShowInfoByPlatformId(
    Distribution.Platform.spotify,
    spotifyId,
  );

  if (loading || showLoading) {
    return <CenterLoading />;
  }

  return (
    <LinkWithSpotify
      spotifyId={spotifyId}
      show={showData?.playerShowFindOneByPlatformId}
      audience={data?.audience}
    />
  );
}

type LinkWithSpotifyProps = {
  spotifyId: string | null;
  show?: {
    id: string;
    name: string | null;
    author: string | null;
    avatar: string | null;
    urlSlug: string | null;
  } | null;
  audience?: Audience | null;
};

type ConnectedSpotifyAccountProps = {
  showMembership: ShowMembership;
  audience?: Audience | null;
};

export default LinkWithSpotifyContainer;
