import { useQuery } from '@apollo/client';
import { GetShowMembership } from '../../v2/membership';
import { ShowMembership } from '../../../entities/membership';

export function useGetShowMembership(showId?: string) {
  const result = useQuery<GetShowMembershipData, GetShowMembershipVariables>(
    GetShowMembership,
    {
      variables: { showId },
      context: { endpoint: 'membership' },
      skip: !showId,
    },
  );

  return result;
}

type GetShowMembershipData = {
  show: ShowMembership;
};

type GetShowMembershipVariables = {
  showId?: string;
};
