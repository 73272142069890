import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { makeStyles } from '@material-ui/styles';
import { Paper, Typography, Theme, TextField, Button } from '@material-ui/core';
import { GetMeLegacyData } from '../../../gql/hooks/user';
import { Plan, Tier } from '../../../entities/membership';
import TierItem from './TierItem';

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    padding: theme.spacing(2),
  },
  customContainer: {
    padding: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  label: {
    marginBottom: theme.spacing(1),
  },
  input: {
    width: '100%',
  },
  submitButton: {
    width: '100%',
    marginTop: theme.spacing(2),
  },
}));

function DonationContent({
  showCustom,
  enabled,
  tiers,
  onSelect,
  onCustomSelect,
}: DonationContentProps): JSX.Element {
  const intl = useIntl();
  const classes = useStyles();
  const [value, setValue] = React.useState('');
  const [error, setError] = React.useState('');

  const handleCustomChange = React.useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setValue(e.target.value);
    },
    [],
  );

  const handleCustomSelect = React.useCallback(() => {
    const amount = parseInt(value);

    if (amount < 50) {
      setError(intl.formatMessage({ id: 'donation.amountTooSmall' }));
      return;
    }

    onCustomSelect(amount);
  }, [value, onCustomSelect, intl]);

  if (!enabled) {
    return (
      <Paper variant="outlined" className={classes.container}>
        <Typography>
          <FormattedMessage id="donation.noDonationTier" />
        </Typography>
      </Paper>
    );
  }

  return (
    <div>
      {showCustom && (
        <Paper variant="outlined" className={classes.customContainer}>
          <Typography className={classes.label}>
            <FormattedMessage id="donation.customValue" />
          </Typography>
          <TextField
            type="number"
            variant="outlined"
            value={value}
            error={!!error}
            helperText={error}
            onChange={handleCustomChange}
            className={classes.input}
          />
          <Button
            disabled={!(parseInt(value) >= 50)}
            variant="contained"
            color="primary"
            size="large"
            className={classes.submitButton}
            onClick={handleCustomSelect}
          >
            <FormattedMessage id="donation.donate" />
          </Button>
        </Paper>
      )}
      {tiers.map(t => (
        <TierItem key={t.id} tier={t} onSelect={onSelect} />
      ))}
    </div>
  );
}

type DonationContentProps = {
  showCustom: boolean;
  enabled: boolean;
  tiers: Tier[];
  me: GetMeLegacyData | null;
  onSelect(tier: Tier, plan: Plan): void;
  onCustomSelect(amount: number): void;
};

export default DonationContent;
