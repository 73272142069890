import React from 'react';
import { Link, useRouteMatch } from 'react-router-dom';
import cx from 'classnames';
import { makeStyles } from '@material-ui/styles';
import Typography from '@material-ui/core/Typography';
import { useGetMeLegacy } from '../../gql/hooks/user';

const useStyles = makeStyles(theme => ({
  container: props => ({
    position: 'relative',
    display: 'flex',
    height: 30,
    alignItems: 'center',
    marginTop: props.nested ? 0 : 10,
    marginBottom: 10,
    paddingLeft: props.hasIcon ? 20 : 20 + 24,
    textDecoration: 'none',
    color: 'white',
    opacity: props.match ? 1 : 0.5,
    '&:hover': {
      opacity: props.link ? 1 : 0.5,
    },
    '@media (max-width: 900px)': {
      margin: 0,
      paddingLeft: 10,
    },
  }),
  block: {
    height: 30,
    width: 5,
    backgroundColor: theme.palette.primary.main,
    position: 'absolute',
    top: 0,
    left: 0,
    '@media (max-width: 900px)': {
      display: 'none',
    },
  },
  title: props => ({
    paddingLeft: 10,
    fontSize: props.nested ? '0.95rem' : '1rem',
    fontWeight: props.nested ? 'normal' : '600',
    '@media (max-width: 900px)': {
      display: 'none',
    },
  }),
  padding: {
    height: 30,
  },
}));

function NavbarItem({ className, link, path, title, Icon, nested, ...props }) {
  const match = useRouteMatch(path);
  const classes = useStyles({ match, nested, link, hasIcon: !!Icon });

  const content = (
    <div className={cx(classes.container, className)}>
      {Icon && <Icon />}
      <Typography className={classes.title}>{title}</Typography>
      {link && match ? <div className={classes.block} /> : null}
    </div>
  );

  if (link) {
    return (
      <Link to={path} {...props}>
        {content}
      </Link>
    );
  }

  return <div {...props}>{content}</div>;
}

function NavbarPrivateItem({ hideWhenLogin, children }) {
  const { loading, data } = useGetMeLegacy();

  if (loading || !data) {
    return null;
  }

  if (hideWhenLogin && data.me) {
    return null;
  }

  if (!hideWhenLogin && !data.me) {
    return null;
  }

  return children;
}

function NavbarPadding() {
  const classes = useStyles();
  return <div className={classes.padding} />;
}

export default NavbarItem;
export { NavbarPrivateItem, NavbarPadding };
