import React from 'react';
import { apm } from '@elastic/apm-rum';
import { useAuth } from '../pages/Auth/context';

export function useUserContext() {
  const { token } = useAuth();

  React.useEffect(() => {
    if (!token) {
      return;
    }

    try {
      const data = JSON.parse(atob(token.split('.')[1]));
      if (data && data.userId) {
        apm.setUserContext({
          id: data.userId,
        });
      }
    } catch (e) {
      return;
    }
  }, [token]);
}
