import React from 'react';
import { CardType } from '../../../entities/membership';
import {
  amex,
  card,
  jcb,
  mastercard,
  union,
  visa,
} from '../../../assets/cards';

const cardImgMap = {
  [CardType.amex]: amex,
  [CardType.jcb]: jcb,
  [CardType.mastercard]: mastercard,
  [CardType.unionpay]: union,
  [CardType.visa]: visa,
};

function CardTypeImage({ className, type }: CardTypeImageProps): JSX.Element {
  const img: string = cardImgMap[type] || card;

  return <img src={img} className={className} />;
}

type CardTypeImageProps = {
  className?: string;
  type: CardType.Type;
};

export default CardTypeImage;
