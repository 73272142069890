import React from 'react';
import { useIntl } from 'react-intl';
import Message from '@material-ui/icons/Message';
import config from '../../config';
import PlatformItem from './PlatformItem';

function CommentItem({ episodeId }: CommentItemProps): JSX.Element {
  const commentUrl = `${config.url}/story/${episodeId}`;
  const intl = useIntl();

  return (
    <PlatformItem
      title={intl.formatMessage({ id: 'general.comment' })}
      url={commentUrl}
      startIcon={<Message />}
    />
  );
}

type CommentItemProps = {
  episodeId: string;
};

export default CommentItem;
