import { Link } from 'react-router-dom';
import { makeStyles } from '@material-ui/styles';
import { Grid, Paper, Typography, Theme, Tooltip } from '@material-ui/core';
import { FormattedMessage } from 'react-intl';
import { Post } from '../../entities/post';
import { Show } from '../../entities/show';
import { LockOutlined, LockOpenOutlined } from '@material-ui/icons';
import { Stack } from '../../components/Stack';

const useStyles = makeStyles((theme: Theme) => ({
  item: {
    padding: theme.spacing(2),
  },
  itemInfo: {
    display: 'flex',
  },
  title: {
    color: theme.palette.text.primary,
    fontWeight: 'bold',
  },
  unlock: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  unlockText: {
    marginBottom: theme.spacing(2),
  },
  tooltip: {
    fontSize: '0.9rem',
    backgroundColor: 'black',
    maxWidth: 'none',
  },
}));

function PostItem({ author, post }: PostItemProps): JSX.Element {
  const classes = useStyles();
  const { title, createdAt, contentUrl } = post;

  const hasEntitlement = !!post.entitlements?.length;
  const postLocked = !contentUrl && hasEntitlement;
  const postUnlocked = contentUrl && hasEntitlement;

  let Icon: React.ReactNode = null;

  if (postLocked) {
    Icon = <LockOutlined color="primary" />;
  } else if (postUnlocked) {
    Icon = <LockOpenOutlined color="disabled" />;
  }

  const link = `/user/${author.urlSlug || author.id}/posts/${post.id}`;
  const date = createdAt
    ? new Intl.DateTimeFormat('en-US', {
        dateStyle: 'medium',
      }).format(new Date(createdAt))
    : '';

  const content = (
    <Paper variant="outlined" className={classes.item}>
      <Stack direction="row" spacing={1.5} alignItems="center">
        {Icon ? (
          <Tooltip
            title={<FormattedMessage id="general.paidOnlyContent" />}
            classes={{ tooltip: classes.tooltip }}
          >
            <span style={{ display: 'flex' }}>{Icon}</span>
          </Tooltip>
        ) : null}
        <Typography variant="h5" color="textPrimary" className={classes.title}>
          {title}
        </Typography>
      </Stack>
      <Typography
        variant="caption"
        color="textPrimary"
        style={{ marginTop: '0.5rem' }}
        component="div"
      >
        {date}
      </Typography>
    </Paper>
  );

  return (
    <Grid item xs={12}>
      <Link to={link}>{content}</Link>
    </Grid>
  );
}

type PostItemProps = {
  hasLogin: boolean;
  author: Show;
  post: Post;
};

export default PostItem;
