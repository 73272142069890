import React from 'react';
import { useParams } from 'react-router-dom';
import NotFound from '../../../../components/NotFound';
import CenterLoading from '../../../../components/CenterLoading';
import {
  useCancelSubscription,
  useGetShowForSubscriptionDetail,
  useGetSubscriptionDetail,
} from '../../../../gql/hooks/membership/subscription';
import SubscriptionDetailContent from './Content';

function SubscriptionDetail() {
  const { id } = useParams<{ id: string }>();

  const cancelSubscription = useCancelSubscription(id);

  const { loading, data } = useGetSubscriptionDetail(id);

  const showId = data?.subscription?.show.showId;

  const { loading: showLoading, data: showData } =
    useGetShowForSubscriptionDetail({
      showId: showId || '',
      skip: !showId,
    });

  const handleCancel = React.useCallback(() => {
    cancelSubscription();
  }, [cancelSubscription]);

  if (loading || showLoading) {
    return <CenterLoading />;
  }

  if (!data?.subscription || !showData?.playerShowFindOneByUrlSlug) {
    return <NotFound />;
  }

  const subscription = {
    ...data.subscription,
    show: {
      ...data.subscription.show,
      ...showData.playerShowFindOneByUrlSlug,
      ...showData.audienceShowSubscriptionFindOne,
    },
  };

  return (
    <SubscriptionDetailContent
      subscription={subscription}
      onCancel={handleCancel}
    />
  );
}

export default SubscriptionDetail;
