import React from 'react';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import { useIntl, FormattedMessage } from 'react-intl';
import { makeStyles } from '@material-ui/styles';
import {
  Button,
  Typography,
  Link,
  IconButton,
  TextField,
  Theme,
} from '@material-ui/core';
import ArrowDown from '@material-ui/icons/KeyboardArrowDown';
import HCaptcha from '@hcaptcha/react-hcaptcha';
import AlbumCover from '../AlbumCover';
import MultipleAccountMenu from '../MultipleAccountMenu';
import config from '../../config';
import ProfileModal from './ProfileModal';

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  meWrapper: {
    display: 'flex',
    alignItems: 'center',
    paddingBottom: theme.spacing(1),
  },
  meContainer: {
    display: 'flex',
    alignItems: 'center',
    paddingRight: theme.spacing(1),
    cursor: 'pointer',
  },
  name: {
    fontWeight: 'bold',
    marginLeft: theme.spacing(2),
  },
  avatar: {
    width: 32,
    height: 32,
    objectFit: 'cover',
    borderRadius: 3,
  },
  nameRow: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: theme.spacing(2),
  },
  nameInput: {
    marginRight: theme.spacing(2),
  },
  input: {
    marginBottom: theme.spacing(2),
    width: '100%',
  },
  loginRequiredContainer: {
    border: `1px solid ${theme.palette.divider}`,
    borderRadius: 4,
    height: 96,
    maxWidth: 500,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginBottom: 50,
  },
  submitRow: {
    marginTop: theme.spacing(1),
    display: 'flex',
  },
  ratingContainer: {
    flex: 1,
  },
  rating: {},
  submitButton: {},
}));

function CommentForm({
  isEmbed,
  me,
  user,
  record,
  comment,
  showMe,
  inputRef,
  autoFocus,
  createComment,
}: CommentFormProps): JSX.Element {
  const intl = useIntl();
  const classes = useStyles();
  const location = useLocation();

  const captchaRef = React.useRef<HCaptcha>(null);

  const [open, setOpen] = React.useState(false);
  const [name, setName] = React.useState('');
  const [message, setMessage] = React.useState('');
  const [hcaptchaToken, setHCaptchaToken] = React.useState<string | null>(null);

  const handleOpen = React.useCallback(() => {
    setOpen(true);
  }, []);

  const handleClose = React.useCallback(() => {
    setOpen(false);
  }, []);

  const handleNameChange = React.useCallback(e => {
    setName(e.target.value);
  }, []);

  const handleMessageChange = React.useCallback(e => {
    setMessage(e.target.value);
  }, []);

  const handleHCaptchaSuccess = React.useCallback((token: string) => {
    setHCaptchaToken(token);
  }, []);

  const handleSubmit = React.useCallback(
    async e => {
      e.preventDefault();
      try {
        if (message.trim().length > 0) {
          await createComment({
            name: name.trim(),
            message: message.trim(),
            showId: user?.id,
            episodeId: record?.id,
            commentId: comment?.id,
          });

          setMessage('');
        }
      } finally {
        captchaRef.current?.resetCaptcha();
        setHCaptchaToken(null);
      }
    },
    [user, record, comment, name, message, createComment],
  );

  const loginLinkProps = isEmbed
    ? {
        target: '_blank',
        rel: 'noreferrer noopener',
        href: `https://open.firstory.me/story/${record?.id}`,
      }
    : {
        component: RouterLink,
        to: {
          pathname: '/login',
          search: `?next=${location.pathname}`,
          state: { background: location },
        },
      };

  if (isEmbed) {
    return (
      <div className={classes.loginRequiredContainer}>
        <Typography color="textSecondary">
          <FormattedMessage
            id="comment.login"
            values={{
              link: (chunk: string) => <Link {...loginLinkProps}>{chunk}</Link>,
            }}
          />
        </Typography>
      </div>
    );
  }

  return (
    <div>
      <form className={classes.container} onSubmit={handleSubmit}>
        {me && showMe && (
          <div className={classes.meWrapper}>
            <div className={classes.meContainer} onClick={handleOpen}>
              <AlbumCover
                imageUrl={me.avatar || '#'}
                createdAt={me.createdAt}
                className={classes.avatar}
              />
              <Typography className={classes.name}>{me.name}</Typography>
            </div>
            <MultipleAccountMenu>
              {openMenu => (
                <IconButton size="small" onClick={openMenu}>
                  <ArrowDown />
                </IconButton>
              )}
            </MultipleAccountMenu>
          </div>
        )}
        {!me && (
          <div className={classes.nameRow}>
            <TextField
              variant="outlined"
              placeholder={intl.formatMessage({ id: 'comment.name' })}
              value={name}
              onChange={handleNameChange}
              className={classes.nameInput}
            />
            <Typography>
              <FormattedMessage
                id="comment.orLogin"
                values={{
                  link: (chunk: string) => (
                    <Link {...loginLinkProps}>{chunk}</Link>
                  ),
                }}
              />
            </Typography>
          </div>
        )}
        <TextField
          autoFocus={autoFocus}
          classes={{ root: classes.input }}
          variant="outlined"
          multiline
          minRows="3"
          placeholder={intl.formatMessage({ id: 'comment.leaveComment' })}
          value={message}
          onChange={handleMessageChange}
          inputProps={{ ref: inputRef }}
        />
        <HCaptcha
          ref={captchaRef}
          sitekey={config.hcaptchaSiteKey}
          onVerify={handleHCaptchaSuccess}
        />
        <div className={classes.submitRow}>
          <Button
            type="submit"
            color="primary"
            disabled={!hcaptchaToken}
            className={classes.submitButton}
          >
            <FormattedMessage id="comment.submit" />
          </Button>
        </div>
      </form>
      {me && <ProfileModal me={me} open={open} onClose={handleClose} />}
    </div>
  );
}

type CommentFormProps = {
  isEmbed?: boolean;
  me: {
    id: string;
    name: string;
    avatar: string | null;
    createdAt: string;
  } | null;
  user?: { id: string; urlSlug: string | null };
  record?: {
    id: string;
    author: {
      id: string;
      name: string;
    };
    review?: {
      rating: number;
    };
  };
  comment?: { id: string };
  showMe?: boolean;
  inputRef: React.MutableRefObject<HTMLInputElement | undefined>;
  autoFocus?: boolean;
  createComment(args: {
    name: string;
    message: string;
    showId?: string;
    episodeId?: string;
    commentId?: string;
  }): Promise<unknown>;
};

export default CommentForm;
