import { Link as RouterLink, useLocation } from 'react-router-dom';
import { useIntl, FormattedMessage } from 'react-intl';
import { makeStyles } from '@material-ui/styles';
import {
  Grid,
  Button,
  Typography,
  Chip,
  Tabs,
  Tab,
  Theme,
} from '@material-ui/core/';
import MoneyIcon from '@material-ui/icons/AttachMoney';
import ListenPlatformRow from '../../components/ListenPlatformRow';
import RatingRow from '../../components/RatingRow';
import Linkify from '../../components/Linkify';
import LazyImage from '../../components/LazyImage';
import NameWithBlueCheck from '../../components/NameWithBlueCheck';
import { imageproxy } from '../../utils';
import { Distribution } from '../../entities/distribution';
import { ShowMembership } from '../../entities/membership';
import { AggregateRating } from '../../entities/episode';
import {
  Category,
  ExternalLink,
  Show,
  WebsiteSetting,
} from '../../entities/show';
import { MessageId } from '../../i18n';
import ExternalLinkRow from '../../components/ExternalLinkRow';

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    '@media (max-width: 500px)': {
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(4),
    },
  },
  infoContainer: {
    display: 'flex',
    paddingBottom: theme.spacing(8),
    '@media (max-width: 700px)': {
      flexDirection: 'column',
      paddingBottom: theme.spacing(4),
    },
  },
  imageContainer: {
    width: 220,
    paddingRight: theme.spacing(2),
    '@media (max-width: 700px)': {
      width: 200,
      paddingRight: 0,
      paddingBottom: theme.spacing(2),
    },
    '@media (max-width: 500px)': {
      width: 80,
    },
  },
  image: {
    height: 200,
    width: 200,
    borderRadius: 5,
    objectFit: 'cover',
    '@media (max-width: 500px)': {
      height: 80,
      width: 80,
    },
  },
  titleContainer: {
    position: 'relative',
    paddingBottom: theme.spacing(2),
  },
  title: {
    paddingRight: theme.spacing(4),
  },
  ratingRow: {
    paddingTop: 5,
    display: 'flex',
  },
  ratingText: {
    paddingLeft: 10,
  },
  followButton: {
    position: 'absolute',
    right: 0,
    top: 0,
  },
  titleText: {
    fontWeight: 'bold',
  },
  description: {
    width: '100%',
    whiteSpace: 'pre-line',
    overflowWrap: 'anywhere',
  },
  recordCount: {
    textAlign: 'center',
    paddingTop: 5,
  },
  websiteRow: {
    display: 'flex',
    marginBottom: theme.spacing(2),
    alignItems: 'center',
  },
  websiteLinkContainer: {
    marginRight: theme.spacing(1),
  },
  listenPlatform: {
    fontWeight: 'bold',
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  flink: {
    marginBottom: theme.spacing(1),
  },
  tabs: {
    marginBottom: theme.spacing(2),
  },
}));

function WebsiteLinkButton({ urlSlug }) {
  const classes = useStyles();

  let subdomain = urlSlug;
  if (urlSlug === 'firstorylab') {
    subdomain = 'lab';
  }
  return (
    <div className={classes.websiteLinkContainer}>
      <Button
        variant="contained"
        component="a"
        href={`https://${subdomain}.firstory.io`}
      >
        <FormattedMessage id="podcast.gotoWebsite" />
      </Button>
    </div>
  );
}

function UserTabs({ urlSlug, showMembership }) {
  const intl = useIntl();
  const classes = useStyles();
  const location = useLocation();

  const tabs: { path: string; labelId: MessageId }[] = [
    { path: `/user/${urlSlug}`, labelId: 'podcast.episodes' },
    { path: `/user/${urlSlug}/comments`, labelId: 'general.comment' },
    { path: `/user/${urlSlug}/voicemails`, labelId: 'website.voicemail' },
    { path: `/user/${urlSlug}/community`, labelId: 'community' },
  ];

  const value = Math.max(
    0,
    tabs.findIndex(t => t.path === location.pathname),
  );

  return (
    <div className={classes.tabs}>
      <Tabs value={value}>
        <Tab
          label={intl.formatMessage({ id: tabs[0].labelId })}
          component={RouterLink}
          to={tabs[0].path}
        />
        <Tab
          label={intl.formatMessage({ id: tabs[1].labelId })}
          component={RouterLink}
          to={tabs[1].path}
        />
        <Tab
          label={intl.formatMessage({ id: tabs[2].labelId })}
          component={RouterLink}
          to={tabs[2].path}
        />
        {showMembership && (
          <Tab
            label={intl.formatMessage({ id: tabs[3].labelId })}
            component={RouterLink}
            to={tabs[3].path}
          />
        )}
      </Tabs>
    </div>
  );
}

function ShowInfo({
  showBlueCheck,
  showId,
  urlSlug,
  title,
  subtitle,
  explicit,
  category,
  description,
  imageUrl,
  episodeCount,
  showImport,
  distributions,
  websiteSetting,
  showMembership,
  aggregateRating,
  externalLinks,
  hideRss,
  ugc,
}: ShowInfoProps): JSX.Element {
  const intl = useIntl();
  const classes = useStyles();

  return (
    <div>
      <div className={classes.container}>
        <div className={classes.infoContainer}>
          <div className={classes.imageContainer}>
            <LazyImage
              src={imageproxy(imageUrl, 500)}
              placeholder={imageproxy(imageUrl, 50)}
              className={classes.image}
              alt="avatar"
            />
            {episodeCount ? (
              <Typography
                variant="body2"
                component="div"
                className={classes.recordCount}
              >
                <FormattedMessage
                  id="podcast.episodeCount"
                  values={{ count: episodeCount }}
                />
              </Typography>
            ) : null}
          </div>
          <Grid container direction="column">
            <div className={classes.titleContainer}>
              <NameWithBlueCheck
                user={{ name: title, isCreator: showBlueCheck, explicit }}
                component="h1"
                variant="h5"
                className={classes.title}
                textClassName={classes.titleText}
              />
              {subtitle && (
                <Typography component="h2" variant="h6">
                  {subtitle}
                </Typography>
              )}
              {category && (
                <Typography variant="body2" color="textSecondary">
                  {intl.locale === 'zh' ? category.nameZh : category.nameEn}
                </Typography>
              )}
              {aggregateRating && <RatingRow {...aggregateRating} />}
            </div>
            <div className={classes.websiteRow}>
              {websiteSetting && websiteSetting.active && urlSlug && (
                <WebsiteLinkButton urlSlug={urlSlug} />
              )}
              {showMembership && (
                <Button
                  variant="outlined"
                  component="a"
                  href={`/join/${urlSlug || showId}`}
                  startIcon={<MoneyIcon />}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FormattedMessage id="podcast.donate" />
                </Button>
              )}
            </div>
            {distributions?.length ? (
              <>
                <Typography className={classes.listenPlatform}>
                  <FormattedMessage id="podcast.distribution" />
                </Typography>
                <div className={classes.flink}>
                  <Chip
                    size="small"
                    color="secondary"
                    variant="outlined"
                    label={intl.formatMessage({ id: 'podcast.flink' })}
                    clickable
                    component={RouterLink}
                    to={`/user/${urlSlug || showId}/platforms`}
                  />
                </div>
                <ListenPlatformRow
                  showId={showId}
                  showImport={showImport}
                  distributions={distributions}
                  hideRss={hideRss}
                />
              </>
            ) : null}
            {externalLinks?.length ? (
              <>
                <Typography className={classes.listenPlatform}>
                  <FormattedMessage id="podcast.externalLinks" />
                </Typography>
                <ExternalLinkRow externalLinks={externalLinks} />
              </>
            ) : null}
          </Grid>
        </div>
        <Typography className={classes.description}>
          <Linkify ugc={ugc}>{description}</Linkify>
        </Typography>
      </div>
      <UserTabs urlSlug={urlSlug || showId} showMembership={showMembership} />
    </div>
  );
}

type ShowInfoProps = {
  showBlueCheck?: boolean;
  title: string;
  subtitle: string | null;
  explicit: boolean;
  category?: Category;
  description?: string | null;
  imageUrl?: string | null;
  episodeCount?: number;
  showId: string;
  urlSlug: string | null;
  showImport?: Show['import'];
  distributions?: Distribution[];
  websiteSetting?: WebsiteSetting;
  showMembership?: ShowMembership;
  aggregateRating?: AggregateRating;
  externalLinks?: ExternalLink[];
  hideRss?: boolean;
  ugc?: boolean;
};

export default ShowInfo;
