import React from 'react';
import { apm } from '@elastic/apm-rum';

class ErrorBoundary extends React.Component {
  // static getDerivedStateFromError() {
  //   return {};
  // }

  componentDidCatch(error) {
    apm.captureError(error);
  }

  render() {
    return this.props.children;
  }
}

export default ErrorBoundary;
