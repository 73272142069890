import React from 'react';
import { Define } from '../../define';
import { Distribution } from '../../entities/distribution';
import { WebsiteSetting } from '../../entities/show';
import DistributionItem from './DistributionItem';

function DistributionList({
  type,
  websiteSetting,
  distributionMap,
  showDistributionMap,
  onItemClick,
}: DistributionListProps): JSX.Element {
  const useShowDistributionIfNoUrl =
    type === 'episode' && !!websiteSetting?.flinkShowPlatforms;

  return (
    <React.Fragment>
      {Define.Distribution.Platform.list.map((type, i) => {
        const d = distributionMap.get(type);

        if (d?.status !== Define.Distribution.Status.active) {
          const showD = showDistributionMap?.get(type);
          if (showD && useShowDistributionIfNoUrl) {
            return (
              <DistributionItem
                key={i}
                distribution={showD}
                onClick={onItemClick}
              />
            );
          }

          return null;
        }

        return (
          <DistributionItem key={i} distribution={d} onClick={onItemClick} />
        );
      })}
    </React.Fragment>
  );
}

type DistributionListProps = {
  type: 'episode' | 'show';
  websiteSetting?: WebsiteSetting;
  distributionMap: Map<Define.Distribution.Platform.Type, Distribution>;
  showDistributionMap?: Map<Define.Distribution.Platform.Type, Distribution>;
  onItemClick: (platform: string) => void;
};

export default DistributionList;
