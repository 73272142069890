import React from 'react';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import IconButton, { IconButtonProps } from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import MoreIcon from '@material-ui/icons/MoreHoriz';

function MoreMenu({ size, children }: MoreMenuProps): JSX.Element {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleMoreClick = React.useCallback(e => {
    e.stopPropagation();
    setAnchorEl(e.currentTarget);
  }, []);

  const handleMenuClose = React.useCallback(e => {
    if (e) {
      e.stopPropagation();
    }
    setAnchorEl(null);
  }, []);

  return (
    <ClickAwayListener onClickAway={handleMenuClose}>
      <div>
        <IconButton size={size} onClick={handleMoreClick}>
          <MoreIcon />
        </IconButton>
        <Menu
          disablePortal
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={handleMenuClose}
        >
          {children}
        </Menu>
      </div>
    </ClickAwayListener>
  );
}

type MoreMenuProps = {
  size?: IconButtonProps['size'];
  children: React.ReactNode;
};

export default MoreMenu;
