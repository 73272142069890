import { useQuery } from '@apollo/client';
import { GetPlaylistDetail, GetPlaylistEpisodes } from '../../v2/playlist';
import usePagination from '../../../hooks/usePagination';
import { Playlist } from '../../../entities/playlist';
import { Episode } from '../../../entities/episode';

export function useGetPlaylistDetail(playlistId: string) {
  return useQuery<GetPlaylistDetailData, GetPlaylistDetailVariables>(
    GetPlaylistDetail,
    {
      variables: { playlistId },
    },
  );
}

type GetPlaylistDetailData = {
  playerPlaylistFindOne: Playlist;
};

type GetPlaylistDetailVariables = {
  playlistId: string;
};

export function useGetPlaylistEpisodes({
  playlistId,
  isDraft,
  skip,
}: {
  playlistId: string;
  isDraft?: boolean;
  skip?: boolean;
}) {
  return usePagination<GetPlaylistEpisodesData, GetPlaylistEpisodesVariables>({
    dataKey: 'playerEpisodeFind',
    query: GetPlaylistEpisodes,
    variables: { playlistId, isDraft },
    useSkip: true,
    skip,
  });
}

type GetPlaylistEpisodesData = {
  playerEpisodeFind: Episode[];
};

type GetPlaylistEpisodesVariables = {
  playlistId: string;
  isDraft?: boolean;
};
