import { FormattedMessage } from 'react-intl';
import { makeStyles } from '@material-ui/styles';
import Button from '@material-ui/core/Button';
import CenterLoading from './CenterLoading';

const useStyles = makeStyles(theme => ({
  buttonContainer: {
    paddingTop: theme.spacing(4),
    display: 'flex',
    justifyContent: 'center',
  },
}));

function LoadMore({ hasMore, loading, loadMore }) {
  const classes = useStyles();

  if (!hasMore) {
    return null;
  }

  return (
    <div>
      {loading && <CenterLoading />}
      <div className={classes.buttonContainer}>
        <Button
          color="primary"
          variant="contained"
          size="large"
          onClick={loadMore}
        >
          <FormattedMessage id="general.loadMore" />
        </Button>
      </div>
    </div>
  );
}

export default LoadMore;
