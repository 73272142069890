import React from 'react';
import { makeStyles } from '@material-ui/styles';
import { Container, Theme } from '@material-ui/core';
import GoogleAd from '../../components/GoogleAd';
import { useAlert } from '../../components/Alert';
import CenterLoading from '../../components/CenterLoading';
import LoadMore from '../../components/LoadMore';
import { useGetMeLegacy } from '../../gql/hooks/user';
import { isSameTrack } from '../../utils';
import { Episode } from '../../entities/episode';
import { PlayableData, PlayRecordFn } from '../../components/Player/type';
import { useWebPlayer } from './context';
import EpisodeListItem from './EpisodeListItem';
import { EpisodeListType } from './type';

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    paddingBottom: theme.spacing(8),
  },
  buttonContainer: {
    paddingTop: theme.spacing(4),
    display: 'flex',
    justifyContent: 'center',
  },
  ads: {
    width: '100%',
    height: 90,
    margin: theme.spacing(1, 0),
  },
}));

function EpisodeList({
  type,
  loading,
  episodes,
  hasMore,
  loadMore,
  isPlaying,
  nowPlaying,
  onPlayPauseClick,
  isFetchingAfter,
  hideAds,
}: EpisodeListProps): JSX.Element {
  const classes = useStyles();
  const { isWebPlayer } = useWebPlayer();
  const { showSnackbarMessage } = useAlert();
  const { loading: meLoading, data } = useGetMeLegacy();

  const handlePlayPauseClick = React.useCallback(
    record => {
      return onPlayPauseClick(record, { from: type });
    },
    [type, onPlayPauseClick],
  );

  const handleCopy = React.useCallback(() => {
    showSnackbarMessage('general.copied');
  }, [showSnackbarMessage]);

  if (loading || meLoading) {
    return <CenterLoading />;
  }

  return (
    <Container maxWidth="md" className={classes.container}>
      {episodes.map((r, i) => {
        const isRecordPlaying = isSameTrack(nowPlaying, r) ? isPlaying : false;
        return (
          <React.Fragment key={r.id}>
            <EpisodeListItem
              me={data ? data.me : null}
              type={type}
              isPlaying={isRecordPlaying}
              isWebPlayer={isWebPlayer}
              record={r}
              onPlayPauseClick={handlePlayPauseClick}
              onCopy={handleCopy}
            />
            {!hideAds && i % 10 === 6 && (
              <div className={classes.ads}>
                <GoogleAd
                  slot="9072666274"
                  format="horizontal"
                  fullWidthResponsive
                  className={classes.ads}
                />
              </div>
            )}
          </React.Fragment>
        );
      })}
      {episodes.length > 0 ? (
        <LoadMore
          hasMore={hasMore}
          loading={loading || isFetchingAfter}
          loadMore={loadMore}
        />
      ) : null}
    </Container>
  );
}

type EpisodeListProps = {
  type: EpisodeListType;
  loading: boolean;
  episodes: Episode[];
  hasMore: boolean;
  loadMore(): unknown;
  isPlaying: boolean;
  nowPlaying?: PlayableData | null;
  onPlayPauseClick: PlayRecordFn;
  isFetchingAfter?: boolean;
  hideAds?: boolean;
};

export default EpisodeList;
