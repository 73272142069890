import React from 'react';
import { useMutation, useQuery } from '@apollo/client';
import { useIntl } from 'react-intl';
import {
  getMaskedCardNumber,
  PaymentMethod,
} from '../../../entities/membership';
import {
  GetPaymentMethods,
  CreatePaymentMethod,
  UpdateSubscriptionPaymentMethod,
  DeletePaymentMethod,
} from '../../v2/membership';
import { useAlert } from '../../../components/Alert';

export function useGetPaymentMethods() {
  return useQuery<GetPaymentMethodsData>(GetPaymentMethods, {
    context: { endpoint: 'membership' },
  });
}

type GetPaymentMethodsData = {
  paymentMethods: PaymentMethod[];
};

export function useCreatePaymentMethod() {
  const [fn] = useMutation<
    CreatePaymentMethodData,
    CreatePaymentMethodVariables
  >(CreatePaymentMethod);

  const createPaymentMethod = React.useCallback(
    (variables: CreatePaymentMethodVariables) => {
      return fn({
        variables,
        context: { endpoint: 'membership' },
      });
    },
    [fn],
  );

  return createPaymentMethod;
}

type CreatePaymentMethodData = {
  audienceCreatePaymentMethod: {
    redirectUrl: string | null;
  };
};

type CreatePaymentMethodVariables = {
  prime: string;
  email: string;
  paymentName: string;
  phoneNumber: string;
};

export function useUpdateSubscriptionPaymentMethod() {
  const [fn] = useMutation<
    UpdateSubscriptionPaymentMethodData,
    UpdateSubscriptionPaymentMethodVariables
  >(UpdateSubscriptionPaymentMethod);

  const updateSubscriptionPaymentMethod = React.useCallback(
    (variables: UpdateSubscriptionPaymentMethodVariables) => {
      return fn({
        variables,
        context: { endpoint: 'membership' },
        refetchQueries: ['GetSubscriptionDetail'],
      });
    },
    [fn],
  );

  return updateSubscriptionPaymentMethod;
}

type UpdateSubscriptionPaymentMethodData = {
  audienceCreatePaymentMethod: {
    success: true;
  };
};

type UpdateSubscriptionPaymentMethodVariables = {
  subscriptionId: string;
  paymentMethodId: string;
};

export function useDeletePaymentMethod() {
  const [fn] = useMutation<
    DeletePaymentMethodData,
    DeletePaymentMethodVariables
  >(DeletePaymentMethod, {
    context: { endpoint: 'membership' },
  });
  const intl = useIntl();
  const { openAlertDialog, openErrorDialog } = useAlert();

  const deletePaymentMethod = React.useCallback(
    (p: PaymentMethod) => {
      openAlertDialog({
        variant: 'confirm',
        title: intl.formatMessage({ id: 'general.confirm' }),
        description: intl.formatMessage(
          { id: 'general.confirmDelete' },
          { title: getMaskedCardNumber(p) },
        ),
        async onConfirmClick() {
          try {
            await fn({
              variables: {
                paymentMethodId: p.id,
              },
            });
          } catch (error) {
            setTimeout(() => {
              openErrorDialog('donation.paymentMethod.deleteError.description');
            }, 500);
          }
        },
      });
    },
    [fn, intl, openAlertDialog, openErrorDialog],
  );

  return deletePaymentMethod;
}

type DeletePaymentMethodData = {
  audienceDeletePaymentMethod: {
    success: boolean;
  };
};

type DeletePaymentMethodVariables = {
  paymentMethodId: string;
};
