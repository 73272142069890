import React from 'react';
import cx from 'classnames';
import { makeStyles } from '@material-ui/styles';

const isDev = process.env.NODE_ENV === 'development';

const useStyles = makeStyles({
  ad: {
    display: 'block',
  },
  devAd: {
    background: 'steelblue',
    width: '100%',
    height: 300,
  },
});

function GoogleAd({
  className,
  style,
  slot,
  format,
  fullWidthResponsive,
  disabled,
}: GoogleAdProps): JSX.Element | null {
  const classes = useStyles();
  const [size, setSize] = React.useState({ width: 0, height: 0 });
  const ref = React.useRef<HTMLModElement>(null);

  React.useEffect(() => {
    if (ref.current) {
      const width = ref.current.clientWidth;
      const height = ref.current.clientHeight;
      setSize({ width, height });
    }
  }, []);

  React.useEffect(() => {
    if (!global.window || disabled) {
      return;
    }

    const { width } = size;
    if (width) {
      try {
        if (!window.adsbygoogle) {
          window.adsbygoogle = [];
        }
        window.adsbygoogle.push({});
      } catch (error) {
        //
      }
    }
  }, [disabled, size]);

  if (disabled) {
    return null;
  }

  return (
    <ins
      ref={ref}
      className={cx(className, classes.ad, {
        [classes.devAd]: isDev,
        adsbygoogle: !isDev,
      })}
      style={{
        display: 'block',
        ...style,
      }}
      data-ad-client="ca-pub-9708189819117526"
      data-ad-format={format}
      data-ad-slot={slot}
      data-full-width-responsive={fullWidthResponsive}
    />
  );
}

type GoogleAdProps = {
  className?: string;
  style?: React.CSSProperties;
  format:
    | 'autorelaxed'
    | 'auto'
    | 'rectangle'
    | 'vertical'
    | 'horizontal'
    | 'rectangle, vertical'
    | 'rectangle, horizontal';
  slot: string;
  fullWidthResponsive?: boolean;
  disabled?: boolean;
};

export default GoogleAd;
