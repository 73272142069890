import React from 'react';
import { makeStyles } from '@material-ui/styles';
import { TablePagination, TableRow, TableCell, Theme } from '@material-ui/core';
import Table, { TableProps } from './Table';
import usePagination from './useTablePagination';

const useStyles = makeStyles((theme: Theme) => ({
  footer: {
    marginTop: theme.spacing(2),
  },
}));

function PaginationTable<DataType>({
  data = [],
  total,
  pageSize = 20,
  renderRow,
  hasMore,
  loadMore,
  loadBefore,
  hasRouterParams,
  dataHeadIndex,
  dataEndIndex,
  isFetchingAfter,
  isFetchingBefore,
  columnCount = 1,
  ...props
}: PaginationTableProps<DataType>) {
  const classes = useStyles();
  const {
    page,
    setPage,
    data: slice,
  } = usePagination({
    data,
    pageSize,
    hasMore,
    loadMore,
    loadBefore,
    hasRouterParams,
    dataHeadIndex,
    dataEndIndex,
  });

  const loading =
    (isFetchingAfter && slice.length === 0) ||
    (isFetchingBefore && slice.length > 0 && !slice[0]);

  const emptySlice = [...new Array(pageSize)].map(() => null);

  const handleChangePage = React.useCallback(
    (_, page) => {
      setPage(page);
    },
    [setPage],
  );

  const labelDisplayedRows = React.useCallback(
    ({ from }) => `${from}-${from + pageSize - 1}`,
    [pageSize],
  );

  const renderLoadingRow = React.useCallback(
    (_, i) => {
      return (
        <TableRow key={i}>
          <TableCell colSpan={columnCount}>
            <div />
          </TableCell>
        </TableRow>
      );
    },
    [columnCount],
  );

  return (
    <Table
      {...props}
      columnCount={columnCount}
      data={loading ? emptySlice : slice}
      renderRow={loading ? renderLoadingRow : renderRow}
      footer={
        <div className={classes.footer}>
          <TablePagination
            component="div"
            rowsPerPageOptions={[pageSize]}
            rowsPerPage={pageSize}
            page={page}
            count={total || data.length + pageSize}
            labelDisplayedRows={total ? undefined : labelDisplayedRows}
            onPageChange={handleChangePage}
          />
        </div>
      }
    />
  );
}

type PaginationTableProps<DataType> = {
  total?: number;
  pageSize?: number;
  hasMore?: boolean;
  loadMore?: () => void;
  loadBefore?: () => void;
  hasRouterParams?: boolean;
  dataHeadIndex?: number;
  dataEndIndex?: number;
  isFetchingAfter?: boolean;
  isFetchingBefore?: boolean;
  columnCount?: number;
} & TableProps<DataType>;

export default PaginationTable;
