import React from 'react';
import { useLocation, Link } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import { FormattedMessage, useIntl } from 'react-intl';
import { makeStyles } from '@material-ui/styles';
import { Button, Tab, Tabs, Theme } from '@material-ui/core';
import { ArrowBack } from '@material-ui/icons';
import { SubscriptionWithShow } from '../../type';
import { SubscriptionStatus } from '../../../../entities/membership';
import { MessageId } from '../../../../i18n';
import PrivatePlatform from './PrivatePlatform';
import SubscriptionInfo from './SubscriptionInfo';
import SubscriptionDescription from './SubscriptionDescription';
import SubscriptionPaymentMethod from './PaymentMethod';

const useStyles = makeStyles((theme: Theme) => ({
  backContainer: {
    paddingBottom: theme.spacing(2),
  },
  infoContainer: {
    display: 'flex',
    '@media (max-width: 700px)': {
      flexDirection: 'column',
    },
  },
  avatar: {
    width: 200,
    height: 200,
    marginRight: theme.spacing(4),
    '@media (max-width: 700px)': {
      marginRight: 0,
    },
  },
  headerInfo: {
    marginBottom: theme.spacing(2),
  },
  title: {
    fontWeight: 'bold',
  },
  status: {
    marginTop: theme.spacing(1),
  },
  active: {
    color: theme.palette.success.light,
    borderColor: theme.palette.success.light,
  },
  cancel: {
    color: theme.palette.error.light,
    borderColor: theme.palette.error.light,
  },
  info: {
    display: 'flex',
    alignItems: 'center',
  },
  name: {
    marginLeft: theme.spacing(1),
    fontWeight: 'bold',
  },
  sectionTitle: {
    fontWeight: 'bold',
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(1),
  },
  description: {},
  bottomRow: {
    paddingTop: theme.spacing(30),
  },
  cancelButton: {},
}));

function TabContent({
  value,
  subscription,
}: TabContentProps): JSX.Element | null {
  const { show } = subscription;

  if (value === 'description') {
    return <SubscriptionDescription subscription={subscription} />;
  }

  if (value === 'platforms') {
    return (
      <PrivatePlatform show={show} privateRssToken={show.privateRssToken} />
    );
  }

  if (value === 'payment-methods' && subscription.paymentMethod) {
    return (
      <SubscriptionPaymentMethod
        subscription={subscription}
        paymentMethod={subscription.paymentMethod}
      />
    );
  }

  return null;
}

function SubscriptionDetailContent({
  subscription,
  onCancel,
}: SubscriptionDetailContentProps): JSX.Element {
  const intl = useIntl();
  const classes = useStyles();
  const location = useLocation();

  const { show, status, tier } = subscription;
  const { name } = show;

  const tabs: { value: TabValue; path: string; labelId: MessageId }[] = [
    {
      value: 'description',
      path: `/donation/recurring/${subscription.id}/info`,
      labelId: 'general.description',
    },
  ];

  if (show.hasResourceEntitlement) {
    tabs.push({
      value: 'platforms',
      path: `/donation/recurring/${subscription.id}/platforms`,
      labelId: 'podcast.distribution',
    });
  }

  if (subscription.paymentMethod) {
    tabs.push({
      value: 'payment-methods',
      path: `/donation/recurring/${subscription.id}/payment-methods`,
      labelId: 'donation.paymentMethod',
    });
  }

  const index = tabs.findIndex(tab => location.pathname.includes(tab.value));
  const tabIndex = index > -1 ? index : 0;
  const value = tabs[tabIndex].value;

  const showCancelButton =
    status === SubscriptionStatus.active ||
    status === SubscriptionStatus.trialing;

  return (
    <div>
      <Helmet>
        <title>
          {intl.formatMessage({ id: 'donation.subscription' })} {name || ''} -{' '}
          {tier.title || ''}
        </title>
      </Helmet>
      <div className={classes.backContainer}>
        <Button
          startIcon={<ArrowBack />}
          component={Link}
          to="/donation/recurring"
        >
          <FormattedMessage id="donation.manage" />
        </Button>
      </div>
      <SubscriptionInfo subscription={subscription} />
      <Tabs value={tabIndex}>
        {tabs.map((tab, index) => (
          <Tab
            key={index}
            label={intl.formatMessage({ id: tab.labelId })}
            component={Link}
            to={tab.path}
          />
        ))}
      </Tabs>
      <TabContent value={value} subscription={subscription} />
      {showCancelButton && (
        <div className={classes.bottomRow}>
          <Button
            color="primary"
            className={classes.cancelButton}
            onClick={onCancel}
          >
            <FormattedMessage id="donation.cancelSubscription" />
          </Button>
        </div>
      )}
    </div>
  );
}

type SubscriptionDetailContentProps = {
  subscription: SubscriptionWithShow;
  onCancel(): void;
};

type TabContentProps = {
  value: TabValue;
  subscription: SubscriptionWithShow;
};

type TabValue = 'description' | 'platforms' | 'payment-methods';

export default SubscriptionDetailContent;
