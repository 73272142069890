import { makeStyles, Theme, Typography } from '@material-ui/core';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import CenterLoading from '../../../components/CenterLoading';
import { Define } from '../../../define';
import { useGetSubscriptions } from '../../../gql/hooks/membership/subscription';
import { useSearchShowWithSpotify } from '../../../gql/hooks/show/search';
import InfoCard from '../InfoCard';
import SpotifyShowItem from './ShowItem';

const useStyles = makeStyles((theme: Theme) => ({
  title: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(2),
  },
}));

function SpotifyShowList(): JSX.Element {
  const classes = useStyles();
  const { loading, data } = useGetSubscriptions();

  const showIds = Array.from(
    new Set(data?.subscriptions.map(s => s.show.showId)),
  );

  const { loading: showLoading, data: showData } = useSearchShowWithSpotify({
    showIds,
    skip: showIds.length === 0,
  });

  const showMap: Record<
    string,
    {
      id: string;
      name: string;
      avatar: string | null;
      urlSlug: string | null;
      spotifyPlatformId: string;
    }
  > = {};
  showData?.playerShowFind?.forEach(s => {
    const spotify = s.distributions?.find(
      d =>
        d.platformType === Define.Distribution.Platform.spotify &&
        !!d.platformId,
    );

    if (spotify?.platformId) {
      showMap[s.id] = {
        ...s,
        spotifyPlatformId: spotify.platformId,
      };
    }
  });

  if (loading || showLoading) {
    return <CenterLoading />;
  }

  const subscriptions = data?.subscriptions || [];

  const noShow = subscriptions.filter(s => showMap[s.show.showId]).length === 0;

  if (noShow) {
    return (
      <div>
        <Typography variant="h5" className={classes.title}>
          <FormattedMessage id="donation.app.spotify.availableShows" />
        </Typography>
        <InfoCard
          title={<FormattedMessage id="donation.app.spotify.noShow" />}
        />
      </div>
    );
  }

  return (
    <div>
      <Typography variant="h5" className={classes.title}>
        <FormattedMessage id="donation.app.spotify.availableShows" />
      </Typography>
      {subscriptions.map(s =>
        s.show.discord ? (
          <SpotifyShowItem key={s.id} show={showMap[s.show.showId]} />
        ) : null,
      )}
    </div>
  );
}

export default SpotifyShowList;
