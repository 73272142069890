import en from './en.json';
import zh from './zh.json';

export type SupportedLangs = keyof typeof supportedLangs;
export const supportedLangs = {
  en: 'English',
  zh: '中文',
};

export const defaultLang: SupportedLangs = 'en';

export function determineUserLang(
  acceptedLangs: readonly string[],
): SupportedLangs {
  const acceptedLangCodes = acceptedLangs.map(stripCountry);

  for (const code of acceptedLangCodes) {
    if (code in supportedLangs) {
      return code as SupportedLangs;
    }
  }
  return defaultLang;
}

function stripCountry(lang: string): string {
  return lang.trim().replace('_', '-').split('-')[0];
}

export const messages = {
  en,
  zh: {
    ...en,
    ...zh,
  },
};

export type MessageId = keyof typeof en;

declare global {
  namespace FormatjsIntl {
    interface Message {
      ids: MessageId;
    }
  }
}
