import React from 'react';
import { Helmet } from 'react-helmet-async';
import { Route, Switch } from 'react-router-dom';
import { useIntl, FormattedMessage } from 'react-intl';
import { makeStyles } from '@material-ui/styles';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import Layout from '../WebPlayer/Layout';
import GoogleAd from '../../components/GoogleAd';
import { useGetHomeData } from '../../gql/hooks/home';
import { useTracker } from '../../analytics/tracker';
import HorizontalUserRow from './HorizontalUserRow';
import CategoryList from './CategoryList';
import UserList from './UserList';
import CategoryUserList from './CategoryUserList';

const useStyles = makeStyles(theme => ({
  container: {
    paddingBottom: theme.spacing(6),
  },
  loadingContainer: {
    height: '100%',
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  header: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(4),
  },
  title: {
    fontWeight: '600',
    paddingBottom: theme.spacing(1),
  },
  buttonContainer: {
    '@media(max-width:500px)': {
      paddingTop: 20,
      width: '100%',
      display: 'flex',
      justifyContent: 'center',
    },
  },
  list: {
    width: '100%',
    overflowX: 'auto',
    paddingBottom: theme.spacing(1),
    marginBottom: theme.spacing(6),
    display: 'flex',
    flexWrap: 'nowrap',
    '&::-webkit-scrollbar': {
      display: 'none',
    },
  },
  ads: {
    maxHeight: 180,
    marginBottom: theme.spacing(4),
  },
}));

function Header() {
  const intl = useIntl();
  const title = intl.formatMessage({ id: 'home.title' });
  const description = intl.formatMessage({ id: 'home.description' });

  const schemaOrg = {
    '@context': 'https://schema.org',
    '@type': 'WebSite',
    url: 'https://open.firstory.me',
    name: 'Firstory',
    potentialAction: {
      '@type': 'SearchAction',
      target: 'https://open.firstory.me/search/result/{search_term_string}',
      'query-input': 'required name=search_term_string',
    },
  };

  return (
    <Helmet>
      <title>Firstory - {title}</title>
      <meta name="description" content={description} />
      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} />
      <link rel="canonical" href="https://open.firstory.me/browse" />
      <meta property="og:type" content="website" />
      <meta property="og:url" content="https://open.firstory.me/browse" />
      <script type="application/ld+json">{JSON.stringify(schemaOrg)}</script>
    </Helmet>
  );
}

function Content({ loading, data }) {
  const intl = useIntl();
  const classes = useStyles();
  const { banner, hot, trending } = data;
  const categories =
    data && data.podcastCategories ? data.podcastCategories : [];

  return (
    <Container className={classes.container}>
      <div className={classes.header}>
        <Typography component="h1" variant="h4" className={classes.title}>
          <FormattedMessage id="home.title" />
        </Typography>
      </div>
      <HorizontalUserRow
        title={intl.formatMessage({ id: 'home.editorChoice' })}
        loading={loading}
        users={banner}
      />
      <HorizontalUserRow
        title={intl.formatMessage({ id: 'home.topPodcasts' })}
        users={hot}
        moreLink="/browse/shows?t=hot"
      />
      <GoogleAd
        slot="3899945233"
        format="horizontal"
        fullWidthResponsive
        className={classes.ads}
      />
      <HorizontalUserRow
        title={intl.formatMessage({ id: 'home.trending' })}
        users={trending}
        moreLink="/browse/shows?t=trending"
      />
      <CategoryList categories={categories} />
    </Container>
  );
}

function Home() {
  const { loading, data } = useGetHomeData();
  const tracker = useTracker();

  React.useEffect(() => {
    tracker.viewHomeTab();
  }, [tracker]);

  return (
    <Layout>
      <Header />
      <Content loading={loading} data={data} />
    </Layout>
  );
}

function HomeRoute() {
  return (
    <Switch>
      <Route path="/browse/shows">
        <UserList />
      </Route>
      <Route path="/browse/category/:categoryId">
        <CategoryUserList />
      </Route>
      <Route path="/browse">
        <Home />
      </Route>
    </Switch>
  );
}

export default HomeRoute;
