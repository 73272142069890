import React from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useIntl } from 'react-intl';
import { DefaultTheme, ThemeProvider, useTheme } from '@material-ui/styles';
import useDisclosure from '../../hooks/useDisclosure';
import { ContextException } from '../../utils/exception.custom';
import { MessageId } from '../../i18n';
import { darkTheme } from '../../theme';
import Snackbar, { SnackbarProps } from './Snackbar';
import AlertDialog, { AlertDialogProps } from './AlertDialog';

const AlertContext = React.createContext<AlertContextValue | undefined>(
  undefined,
);

function AlertProvider({ children }) {
  const dialogPromiseRef = React.useRef<{
    resolve(value: unknown): void;
    reject(reason?: unknown): void;
  }>();
  const dialog = useDisclosure();
  const snackbar = useDisclosure();
  const [dialogState, setDialogState] = React.useState<DialogState | null>(
    null,
  );

  const [snackbarState, setSnackbarState] =
    React.useState<SnackbarState | null>(null);
  const [confirmLoading, setConfirmLoading] = React.useState(false);
  const [theme, setTheme] = React.useState<DefaultTheme>(darkTheme);
  const history = useHistory();
  const location = useLocation();
  const intl = useIntl();

  const openAlertDialog = React.useCallback(
    ({ variant, title, description, onConfirmClick }: DialogState) => {
      dialog.open();
      setDialogState({ variant, title, description, onConfirmClick });
      return new Promise((resolve, reject) => {
        dialogPromiseRef.current = { resolve, reject };
      });
    },
    [dialog],
  );

  const openErrorDialog = React.useCallback(
    (errorId: MessageId, values?: any) => {
      const title = intl.formatMessage({ id: 'error.error' });
      const description = intl.formatMessage({ id: errorId }, values);
      return openAlertDialog({
        variant: 'info',
        title,
        description,
      });
    },
    [intl, openAlertDialog],
  );

  const handleDialogClose = React.useCallback(() => {
    if (dialogPromiseRef.current) {
      dialogPromiseRef.current.resolve(false);
    }
    dialog.close();
  }, [dialog]);

  const handleDialogConfirm = React.useCallback(async () => {
    if (dialogState?.onConfirmClick) {
      setConfirmLoading(true);
      try {
        await dialogState.onConfirmClick();
      } finally {
        setConfirmLoading(false);
      }
    }
    if (dialogPromiseRef.current) {
      dialogPromiseRef.current.resolve(true);
    }
    dialog.close();
  }, [dialog, dialogState]);

  const handleDialogExited = React.useCallback(() => {
    setDialogState(null);
  }, []);

  const showSnackbarMessage = React.useCallback(
    (messageId, options = {}) => {
      const { variant, horizontal, vertical } = options;
      snackbar.open();
      setSnackbarState({ variant, messageId, horizontal, vertical });
    },
    [snackbar],
  );

  const handleSnackbarClose = React.useCallback(() => {
    snackbar.close();
  }, [snackbar]);

  const showUpgradeModal = React.useCallback(
    state => {
      history.push(`/upgrade/${state.type}`, { background: location });
    },
    [history, location],
  );

  const contextValue = React.useMemo(() => {
    return {
      openAlertDialog,
      openErrorDialog,
      showSnackbarMessage,
      showUpgradeModal,
      setTheme,
    };
  }, [openAlertDialog, openErrorDialog, showSnackbarMessage, showUpgradeModal]);

  return (
    <AlertContext.Provider value={contextValue}>
      {children}
      <ThemeProvider theme={theme}>
        <AlertDialog
          open={dialog.isOpen}
          confirmLoading={confirmLoading}
          onClose={handleDialogClose}
          onExited={handleDialogExited}
          onConfirm={handleDialogConfirm}
          {...dialogState}
        />
        <Snackbar
          open={snackbar.isOpen}
          onClose={handleSnackbarClose}
          {...snackbarState}
        />
      </ThemeProvider>
    </AlertContext.Provider>
  );
}

function useAlert() {
  const theme = useTheme();
  const alertContext = React.useContext(AlertContext);
  React.useEffect(() => {
    if (alertContext) {
      alertContext.setTheme(theme);
    }
  }, [alertContext, theme]);

  if (alertContext === undefined) {
    throw new ContextException('useAlert', 'AlertProvider');
  }

  return alertContext;
}

type AlertContextValue = {
  openAlertDialog(state: DialogState): Promise<unknown>;
  openErrorDialog(errorId: MessageId, values?: any): void;
  showSnackbarMessage(
    messageId: MessageId,
    options?: Pick<SnackbarState, 'variant' | 'horizontal' | 'vertical'>,
  ): void;
  showUpgradeModal(state: { type: string }): void;
  setTheme<T extends DefaultTheme>(theme: T): void;
};

type DialogState = Pick<
  AlertDialogProps,
  'variant' | 'title' | 'description'
> & {
  onConfirmClick?: () => Promise<unknown>;
};

type SnackbarState = Pick<
  SnackbarProps,
  'variant' | 'messageId' | 'horizontal' | 'vertical'
>;

export { useAlert, AlertProvider };
