import React from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@material-ui/core';
import { FormattedMessage } from 'react-intl';

function FreeResultModal({
  open,
  success,
  onClose,
}: FreeResultModalProps): JSX.Element {
  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>
        <FormattedMessage
          id={success ? 'donation.subscribe.success' : 'error.general'}
        />
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          {success ? (
            <FormattedMessage id="donation.payment.success.description" />
          ) : (
            <FormattedMessage id="error.general" />
          )}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          size="large"
          color="primary"
          onClick={onClose}
        >
          <FormattedMessage id="general.confirm" />
        </Button>
      </DialogActions>
    </Dialog>
  );
}

type FreeResultModalProps = {
  open: boolean;
  success: boolean;
  onClose(): void;
};

export default FreeResultModal;
