import React from 'react';
import { ContextException } from '../utils/exception.custom';
import { determineUserLang, supportedLangs, SupportedLangs } from './index';

export const LangContext = React.createContext<LangContextValue | undefined>(
  undefined,
);

const LANG_KEY = '@firstory/lang';

export function ClientLangProvider({
  children,
}: ClientLangProviderProps): JSX.Element {
  const [lang, setLang] = React.useState<SupportedLangs>(() => {
    const defaultLang = determineUserLang(navigator.languages || []) || 'en';

    try {
      const localLang = localStorage.getItem(LANG_KEY);
      if (localLang && localLang in supportedLangs) {
        return localLang as SupportedLangs;
      }
    } catch (error) {
      console.log(error);
    }

    return defaultLang;
  });

  const onLangChange = React.useCallback((lang: SupportedLangs) => {
    setLang(lang);
    localStorage.setItem(LANG_KEY, lang);
  }, []);

  const value = React.useMemo(() => {
    return {
      lang,
      onLangChange,
    };
  }, [lang, onLangChange]);

  return <LangContext.Provider value={value}>{children}</LangContext.Provider>;
}

export function ServerLangProvider({
  lang,
  children,
}: ServerLangProviderProps): JSX.Element {
  const value = React.useMemo(() => {
    return {
      lang,
      onLangChange() {
        // eslint-disable @typescript-eslint/no-empty-function
      },
    };
  }, [lang]);

  return <LangContext.Provider value={value}>{children}</LangContext.Provider>;
}

export function useLang() {
  const value = React.useContext(LangContext);
  if (!value) {
    throw new ContextException('useLang', 'LangProvider');
  }
  return value;
}

type ClientLangProviderProps = {
  children: React.ReactNode;
};

type ServerLangProviderProps = {
  lang: SupportedLangs;
  children: React.ReactNode;
};

type LangContextValue = {
  lang: SupportedLangs;
  onLangChange(lang: SupportedLangs): void;
};
