import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import { makeStyles } from '@material-ui/styles';
import {
  Popover,
  Typography,
  Theme,
  Button,
  DialogActions,
  DialogContent,
} from '@material-ui/core';
import { Episode } from '../entities/episode';

const useStyles = makeStyles((theme: Theme) => ({
  popover: {
    width: 300,
    paddingBottom: theme.spacing(2),
  },
  buttonRow: {
    marginTop: theme.spacing(4),
    display: 'flex',
    justifyContent: 'flex-end',
  },
}));

function SubscribeTierToUnlock({
  me,
  open,
  anchorEl,
  episode,
  onClose,
}: SubscribeTierToUnlockProps): JSX.Element | null {
  const classes = useStyles();
  const location = useLocation();

  if (!episode.entitlements) {
    return null;
  }

  return (
    <Popover
      open={open}
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
      transformOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      onClose={onClose}
    >
      <div>
        <DialogContent className={classes.popover}>
          <Typography>
            <FormattedMessage
              id="community.unlock"
              values={{
                name: <b>{episode.show.name}</b>,
                tiers: (
                  <b>
                    {episode.entitlements
                      .map(e => e.tier?.title || '')
                      .join(', ')}
                  </b>
                ),
              }}
            />
          </Typography>
        </DialogContent>
        <DialogActions>
          {!me && (
            <Button
              component={Link}
              to={{
                pathname: '/login',
                search: `?next=${location.pathname}`,
                state: { background: location },
              }}
            >
              <FormattedMessage id="auth.login" />
            </Button>
          )}
          <Button
            variant="contained"
            color="primary"
            component="a"
            href={`/join/${episode.show.id}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            <FormattedMessage id="community.join" />
          </Button>
        </DialogActions>
      </div>
    </Popover>
  );
}

type SubscribeTierToUnlockProps = {
  me: { id: string } | null;
  anchorEl: HTMLDivElement | null;
  episode: Episode;
  open: boolean;
  onClose(): void;
};

export default SubscribeTierToUnlock;
