import React from 'react';
import { Button } from '@material-ui/core';
import { FormattedMessage } from 'react-intl';
import { ShowDiscord } from '../../../entities/membership';
import InfoCard from '../InfoCard';
import DiscordGuildIcon from './GuildIcon';

function DiscordServerItem({
  discord,
  show,
}: DiscordServerItemProps): JSX.Element {
  return (
    <InfoCard
      title={discord.guild.name}
      description={show.name}
      icon={
        <DiscordGuildIcon
          size={56}
          guildId={discord.guild.id}
          name={discord.guild.name}
          icon={discord.guild.icon}
        />
      }
      action={
        <Button
          variant="contained"
          component="a"
          href={`https://discord.com/channels/${discord.guild.id}`}
          target="_blank"
          rel="noopener noreferrer"
        >
          <FormattedMessage id="donation.app.discord.openServer" />
        </Button>
      }
    />
  );
}

type DiscordServerItemProps = {
  discord: ShowDiscord;
  show: {
    id: string;
    name: string;
    avatar: string | null;
    urlSlug: string | null;
  };
};

export default DiscordServerItem;
