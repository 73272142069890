import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';
import { ApolloProvider } from '@apollo/client';
import { loadableReady } from '@loadable/component';
import { apm } from '@elastic/apm-rum';
import Main from './Main';
import { AuthProvider } from './pages/Auth/context';
import useApollo from './hooks/useApollo';
import { ClientLangProvider } from './i18n/LangProvider';
import { logHiringMessage } from './utils/console';
import config from './config';

try {
  logHiringMessage();
} catch (error) {}

function useSubdomain() {
  return React.useMemo(() => {
    if (window.location.hostname.endsWith('firstory.io')) {
      const [subdomain] = window.location.hostname.split('.');
      return subdomain;
    }
    return 'open';
  }, []);
}

function App() {
  React.useEffect(() => {
    const jssStyles = document.querySelector('#jss-server-side');
    if (jssStyles) {
      jssStyles.parentNode.removeChild(jssStyles);
    }

    apm.init({
      serviceName: config.serviceName,
      serverUrl: config.endpoints.apm,
      serverUrlPrefix: '/intake/v2/rum-events',
      serviceVersion: config.version,
    });
  }, []);

  const { client, token, onTokenChange } = useApollo();
  const subdomain = useSubdomain();

  return (
    <HelmetProvider>
      <ApolloProvider client={client}>
        <BrowserRouter>
          <AuthProvider token={token} onTokenChange={onTokenChange}>
            <ClientLangProvider>
              <Main subdomain={subdomain} />
            </ClientLangProvider>
          </AuthProvider>
        </BrowserRouter>
      </ApolloProvider>
    </HelmetProvider>
  );
}

loadableReady(() => {
  ReactDOM.render(<App />, document.getElementById('root'));
});

if (module.hot) {
  module.hot.accept();
  ReactDOM.render(<App />, document.getElementById('root'));
}
