import {
  Dialog,
  Drawer,
  makeStyles,
  ThemeProvider,
  useMediaQuery,
} from '@material-ui/core';
import React from 'react';
import { ShowMembership } from '../../../entities/membership';
import { lightTheme } from '../../../theme';
import DonorInfoForm from './DonorInfoForm';
import { PaymentFormValues, PaymentTierPlanValues } from './type';

const useStyles = makeStyles({
  dialogContainer: {
    width: 500,
  },
  contentContainer: {
    minHeight: 300,
  },
  loadingContainer: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
  },
});

function FreeJoinModal({
  open,
  paymentTier,
  values,
  disableEmailEdit,
  onValueChange,
  onSubmit,
  onCancel,
}: FreeJoinModalProps): JSX.Element {
  const classes = useStyles();
  const isSmall = useMediaQuery('(max-width: 800px)');

  const content = (
    <div className={classes.contentContainer}>
      <DonorInfoForm
        hasStripe={false}
        paymentTier={paymentTier}
        values={values}
        disableEmailEdit={disableEmailEdit}
        onValueChange={onValueChange}
        onSubmit={onSubmit}
        onCancel={onCancel}
      />
    </div>
  );

  return isSmall ? (
    <Drawer keepMounted anchor="bottom" open={open}>
      {content}
    </Drawer>
  ) : (
    <Dialog
      keepMounted
      open={open}
      classes={{ paper: classes.dialogContainer }}
    >
      {content}
    </Dialog>
  );
}

function FreeJoinModalWithTheme(props: FreeJoinModalProps): JSX.Element {
  return (
    <ThemeProvider theme={lightTheme}>
      <FreeJoinModal {...props} />
    </ThemeProvider>
  );
}

type FreeJoinModalProps = {
  membershipSetting: ShowMembership;
  open: boolean;
  submitting: boolean;
  values: PaymentFormValues;
  disableEmailEdit?: boolean;
  paymentTier: PaymentTierPlanValues;
  onValueChange(
    name: keyof PaymentFormValues,
  ): (e: React.ChangeEvent<HTMLInputElement>) => void;
  onSubmit(): Promise<void>;
  onCancel(): void;
};

export default FreeJoinModalWithTheme;
