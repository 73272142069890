import { useState, useEffect, ReactNode } from 'react';
import {
  Avatar,
  Box,
  Container,
  Divider,
  IconButton,
  Popover,
  Typography,
  makeStyles,
} from '@material-ui/core';
import { Link } from 'react-router-dom';
import { FormatSizeOutlined } from '@material-ui/icons';
import {
  HtmlStyleProvider,
  useSanitizedHtml,
} from '../../../components/HtmlStyleProvider';
import CenterLoading from '../../../components/CenterLoading';
import { Stack } from '../../../components/Stack';

const useStyles = makeStyles(theme => ({
  container: {
    maxWidth: 750,
  },
  title: {
    fontSize: '2rem',
    fontWeight: 700,
    marginLeft: 'auto',
    marginRight: 'auto',
    marginBottom: theme.spacing(3),
    '@media (max-width: 950px)': {
      fontSize: '1.8rem',
    },
  },
  subtitle: {
    fontSize: '1.1rem',
    fontWeight: 600,
    lineHeight: 1.3,
    color: '#b0b0b0', // adjusted according to the dark theme
    '@media (max-width: 950px)': {
      fontSize: '1rem',
    },
  },
  author: {
    fontSize: '0.8rem',
    margin: 0,
    fontWeight: 500,
    textTransform: 'uppercase',
  },
  date: {
    color: '#999', // adjusted according to the dark theme
    fontSize: '12px',
    margin: 0,
    fontWeight: 600,
    textTransform: 'uppercase',
  },

  avatar: {
    width: '2.5rem',
    height: '2.5rem',
  },
}));

export function Content({
  contentUrl,
  title,
  subtitle,
  avatarUrl,
  author,
  date,
  communityUrl,
  LockComponent,
}: ContentProps): JSX.Element {
  const [html, setHtml] = useState<string | null>(null);
  const { sanitizedHtml } = useSanitizedHtml(html);
  const [fontSize, setFontSize] = useState<string>('1.15rem');
  const classes = useStyles();

  useEffect(() => {
    if (contentUrl) {
      fetch(contentUrl)
        .then(res => res.text())
        .then(text => {
          setHtml(text || '* This post is empty *');
        });
    } else {
      setHtml('-');
    }
  }, [contentUrl]);

  if (html === null) {
    return <CenterLoading />;
  }

  return (
    <Container maxWidth={false} className={classes.container}>
      <Box className={classes.title}>{title}</Box>
      <Stack spacing={2}>
        {subtitle && <Box className={classes.subtitle}>{subtitle}</Box>}
        <Stack direction="row" justifyContent="space-between">
          <Stack direction="row" spacing={2}>
            <Link to={communityUrl}>
              <Avatar src={avatarUrl} alt="Avatar" className={classes.avatar} />
            </Link>
            <Stack spacing={0.25}>
              <Typography className={classes.author}>{author}</Typography>
              <Typography className={classes.date}>{date}</Typography>
            </Stack>
          </Stack>
          <FontResizer
            onChange={fontSize => setFontSize(fontSize)}
            value={fontSize}
          />
        </Stack>
        <Divider />
        <LockComponent>
          <HtmlStyleProvider style={{ fontSize }}>
            <div
              dangerouslySetInnerHTML={{
                __html: sanitizedHtml,
              }}
            />
          </HtmlStyleProvider>
        </LockComponent>
      </Stack>
    </Container>
  );
}

function FontResizer(props: {
  onChange: (fontSize: string) => void;
  value: string;
}) {
  const [anchorEl, setAnchorEl] = useState<Element | null>(null);

  const handleClick: React.MouseEventHandler<Element> = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'fontsize-popover' : undefined;

  return (
    <>
      <IconButton onClick={handleClick} title="font size">
        <FormatSizeOutlined />
      </IconButton>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <div style={{ padding: '1rem 0.5rem' }}>
          <Stack>
            {['0.85rem', '1rem', '1.15rem', '1.3rem', '1.5rem'].map(
              fontSize => (
                <IconButton
                  key={fontSize}
                  onClick={() => props.onChange(fontSize)}
                  color={props.value === fontSize ? 'secondary' : undefined}
                >
                  <FormatSizeOutlined style={{ fontSize }} />
                </IconButton>
              ),
            )}
          </Stack>
        </div>
      </Popover>
    </>
  );
}

type ContentProps = {
  title: string;
  avatarUrl: string;
  date: string;
  author: string;
  subtitle: string | null;
  contentUrl: string | null;
  communityUrl: string;
  LockComponent: (props: { children: ReactNode }) => JSX.Element;
};

export default Content;
