import { Button } from '@material-ui/core';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import CenterLoading from '../../../components/CenterLoading';
import { PaymentMethod } from '../../../entities/membership';
import {
  useGetPaymentMethods,
  useCreatePaymentMethod,
  useDeletePaymentMethod,
} from '../../../gql/hooks/membership/payment-method';
import useDisclosure from '../../../hooks/useDisclosure';
import PaymentMethodCard from './Card';
import { CreateCreditCardSubmitValues } from './CreateCreditCardForm';
import CreateCreditCardModal from './CreateCreditCardModal';

function PaymentMethodList(): JSX.Element {
  const modal = useDisclosure();
  const [submitting, setSubmitting] = React.useState(false);
  const { loading, data } = useGetPaymentMethods();
  const createPaymentMethod = useCreatePaymentMethod();
  const deletePaymentMethod = useDeletePaymentMethod();

  const handleSubmit = React.useCallback(
    async (values: CreateCreditCardSubmitValues) => {
      setSubmitting(true);
      try {
        const result = await createPaymentMethod(values);
        const url = result.data?.audienceCreatePaymentMethod.redirectUrl;

        if (url) {
          window.location.href = url;
        }
      } catch (e) {
        console.log(e);
      } finally {
        setSubmitting(false);
      }
    },
    [createPaymentMethod],
  );

  const handleDelete = React.useCallback(
    (paymentMethod: PaymentMethod) => {
      deletePaymentMethod(paymentMethod);
    },
    [deletePaymentMethod],
  );

  if (loading) {
    return <CenterLoading />;
  }

  const paymentMethods = data?.paymentMethods || [];

  return (
    <div>
      {paymentMethods.map(p => (
        <PaymentMethodCard
          key={p.id}
          onDelete={() => handleDelete(p)}
          paymentMethod={p}
        />
      ))}
      <Button variant="contained" color="primary" onClick={modal.open}>
        <FormattedMessage id="general.add" />
      </Button>
      <CreateCreditCardModal
        open={modal.isOpen}
        onClose={modal.close}
        submitting={submitting}
        onSubmit={handleSubmit}
      />
    </div>
  );
}

export default PaymentMethodList;
