import React from 'react';
import NavigateNext from '@material-ui/icons/NavigateNext';
import { FormattedMessage } from 'react-intl';
import PlatformIcon, {
  PlatformIconType,
} from '../../../components/icons/PlatformIcon';
import { MessageId } from '../../../i18n';
import InfoCard from '../InfoCard';

function AppItem({
  titleId,
  descriptionId,
  icon,
  link,
}: AppItemProps): JSX.Element {
  return (
    <InfoCard
      title={<FormattedMessage id={titleId} />}
      description={<FormattedMessage id={descriptionId} />}
      icon={<PlatformIcon type={icon} size={36} />}
      action={<NavigateNext color="action" />}
      link={link}
    />
  );
}

type AppItemProps = {
  titleId: MessageId;
  descriptionId: MessageId;
  icon: PlatformIconType;
  link: string;
};

export default AppItem;
