import { Define } from '../../../../define';

export const SpotifyPlatform = [Define.Distribution.Platform.spotify] as const;
export const SupportedPlatforms = [
  Define.Distribution.Platform.apple,
  Define.Distribution.Platform.spotify,
  Define.Distribution.Platform.google,
  Define.Distribution.Platform.pocketcast,
  Define.Distribution.Platform.overcast,
  Define.Distribution.Platform.castro,
] as const;
export const WebSupportedPlatforms = [
  Define.Distribution.Platform.apple,
  Define.Distribution.Platform.spotify,
  Define.Distribution.Platform.google,
] as const;

export function getPlatformUrl(
  type: Define.Distribution.Platform.Type,
  rssUrl: string,
): string {
  const urlWithoutProtocol = rssUrl.replace(/(^\w+:|^)\/\//, '');
  const base64Url = btoa(rssUrl);

  if (type === Define.Distribution.Platform.apple) {
    return `podcast://${urlWithoutProtocol}`;
  }

  if (type === Define.Distribution.Platform.overcast) {
    return `overcast://x-callback-url/add?url=${encodeURIComponent(rssUrl)}`;
  }

  if (type === Define.Distribution.Platform.pocketcast) {
    return `pktc://subscribe/${urlWithoutProtocol}`;
  }

  if (type === Define.Distribution.Platform.castro) {
    return `castros://subscribe/${urlWithoutProtocol}`;
  }

  if (type === Define.Distribution.Platform.google) {
    return `https://podcasts.google.com/subscribe-by-rss-feed?feed=${base64Url}`;
  }

  return rssUrl;
}
