import React from 'react';
import { useRouteMatch } from 'react-router-dom';
import { NoSsr } from '@material-ui/core';
import useAnimationFrame from '../../hooks/useAnimationFrame';
import { PlayableData } from '../Player/type';
import NormalController from './NormalController';
import EmbedController from './EmbedController';

function Controller(props: ControllerProps): JSX.Element {
  const isEmbedRoute = useRouteMatch('/embed');
  const [currentTimeMs, setCurrentTimeMs] = React.useState(0);
  const timeMs = props.slidingMs >= 0 ? props.slidingMs : currentTimeMs;

  useAnimationFrame(() => {
    setCurrentTimeMs(props.currentTimeMsRef.current);
  }, props.isPlaying);

  function handleSliderChange(_: unknown, value: number) {
    props.onSliderChange(value);
  }

  function handleSliderAfterChange() {
    props.onSliderAfterChange(props.slidingMs);
    setCurrentTimeMs(props.slidingMs);
  }

  const _props = {
    ...props,
    timeMs,
    onSliderChange: handleSliderChange,
    onSliderAfterChange: handleSliderAfterChange,
  };

  if (props.isEmbed || isEmbedRoute) {
    return <EmbedController {..._props} />;
  }
  return <NormalController {..._props} />;
}

function ControllerContainer(props: ControllerProps): JSX.Element {
  return (
    <NoSsr>
      <Controller {...props} />
    </NoSsr>
  );
}

type ControllerProps = {
  isEmbed?: boolean;
  defaultTitle?: string;
  defaultSubtitle?: string;
  defaultImageUrl?: string | null;
  logoLink?: string | null;
  nowPlaying: PlayableData | null;
  isLoading: boolean;
  isPlaying: boolean;
  slidingMs: number;
  currentTimeMsRef: React.MutableRefObject<number>;
  onPlayPauseClick: () => void;
  onForwardClick?: () => void;
  onBackwardClick?: () => void;
  onSpeedChange?: (value: number) => void;
  onSliderChange: (value: number) => void;
  onSliderAfterChange: (value: number) => void;
};

export default ControllerContainer;
