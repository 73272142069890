const parseHeaders = (rawHeaders: string) => {
  const headers = new Headers();
  // Replace instances of \r\n and \n followed by at least one space or horizontal tab with a space
  // https://tools.ietf.org/html/rfc7230#section-3.2
  const preProcessedHeaders = rawHeaders.replace(/\r?\n[\t ]+/g, ' ');
  preProcessedHeaders.split(/\r?\n/).forEach(line => {
    const parts = line.split(':');
    const key = parts.shift()?.trim();
    if (key) {
      const value = parts.join(':').trim();
      headers.append(key, value);
    }
  });
  return headers;
};

export const uploadFetch = (
  url: string,
  options: UploadFetchOptions,
): Promise<Response> =>
  new Promise((resolve, reject) => {
    const xhr = new XMLHttpRequest();
    xhr.onload = () => {
      const opts: ResponseInit = {
        status: xhr.status,
        statusText: xhr.statusText,
        headers: parseHeaders(xhr.getAllResponseHeaders() || ''),
      };
      // opts.url =
      //   'responseURL' in xhr
      //     ? xhr.responseURL
      //     : opts.headers.get('X-Request-URL');

      let body = xhr.responseText;

      if ('response' in xhr) {
        body = xhr.response;
      }

      resolve(new Response(body, opts));
    };
    xhr.onerror = () => {
      reject(new TypeError('Network request failed'));
    };
    xhr.ontimeout = () => {
      reject(new TypeError('Network request failed'));
    };
    xhr.open(options.method, url, true);

    if (options.headers) {
      Object.keys(options.headers).forEach(key => {
        if (options?.headers?.[key]) {
          xhr.setRequestHeader(key, options.headers[key]);
        }
      });
    }

    if (xhr.upload) {
      xhr.upload.onprogress = options.onProgress;
    }

    if (options.onAbortPossible) {
      options.onAbortPossible(() => {
        xhr.abort();
      });
    }

    xhr.send(options.body);
  });

const customFetch = (
  uri: string,
  options?: CustomFetchOptions,
): Promise<Response> => {
  if (options?.useUpload) {
    return uploadFetch(uri, options);
  }
  return fetch(uri, options);
};

type CustomFetchOptions =
  | (RequestInit & { useUpload?: false })
  | UploadFetchOptions;

type UploadFetchOptions = RequestInit & {
  method: 'POST';
  useUpload: true;
  body: XMLHttpRequestBodyInit;
  onProgress(): void;
  onAbortPossible?: (callback: () => void) => void;
};

export default customFetch;
