import React from 'react';
import { Theme, Tooltip } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { Distribution } from '../entities/distribution';
import { Show } from '../entities/show';
import { Define } from '../define';
import config from '../config';
import PlatformIcon from './icons/PlatformIcon';

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    display: 'flex',
  },
  item: {
    marginRight: theme.spacing(1),
    width: 30,
    height: 30,
  },
  icon: {
    width: 30,
    height: 30,
  },
}));

function Item({ title, url, children }: ItemProps): JSX.Element {
  const classes = useStyles();

  return (
    <Tooltip title={title}>
      <a
        className={classes.item}
        href={url}
        target="_blank"
        rel="noopener noreferrer"
      >
        {children}
      </a>
    </Tooltip>
  );
}

function PlatformItem({ distribution }: PlatformItemProps): JSX.Element | null {
  const classes = useStyles();

  const isActive = distribution.status === Define.Distribution.Status.active;
  const hasUrl = !!distribution.platformUrl;
  if (!isActive || !hasUrl) {
    return null;
  }

  if (Define.Distribution.Platform.deprecated[distribution.platformType]) {
    return null;
  }

  return (
    <Item
      title={Define.Distribution.Platform.Title[distribution.platformType]}
      url={distribution.platformUrl}
    >
      <PlatformIcon
        type={{
          type: distribution.platformType,
          variant: 'distribution',
        }}
        className={classes.icon}
      />
    </Item>
  );
}

function RssItem({ showId, showImport }: RssItemProps): JSX.Element | null {
  const classes = useStyles();

  const firstoryRssUrl = `${config.rssUrlBase}/rss/user/${showId}`;
  const isImport = showImport?.status === Define.Show.ImportStatus.pending;
  const rssUrl = isImport ? showImport?.originRssUrl : firstoryRssUrl;

  return (
    <Item title="RSS Feed" url={rssUrl || '#'}>
      <PlatformIcon
        type={{
          type: undefined,
          variant: 'rss',
        }}
        className={classes.icon}
      />
    </Item>
  );
}

function ListenPlatformRow({
  distributions,
  showId,
  showImport,
  hideRss,
}: ListenPlatformRowProps): JSX.Element {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      {distributions.map((distribution, i) => (
        <PlatformItem key={i} distribution={distribution} />
      ))}
      {!hideRss && <RssItem showId={showId} showImport={showImport} />}
    </div>
  );
}

type ListenPlatformRowProps = {
  distributions: Distribution[];
  showId: string;
  showImport?: Show['import'];
  hideRss?: boolean;
};

type PlatformItemProps = {
  distribution: Distribution;
};

type RssItemProps = {
  showId: string;
  showImport?: Show['import'];
};

type ItemProps = {
  title: string;
  url: string;
  children: React.ReactNode;
};

export default ListenPlatformRow;
