import { Dialog, ThemeProvider } from '@material-ui/core';
import React from 'react';
import { lightTheme } from '../../../theme';
import CreateCreditCardForm, {
  CreateCreditCardSubmitValues,
} from './CreateCreditCardForm';

function CreateCreditCardModal({
  open,
  submitting,
  onSubmit,
  onClose,
}: CreateCreditCardModalProps): JSX.Element {
  return (
    <ThemeProvider theme={lightTheme}>
      <Dialog open={open}>
        <CreateCreditCardForm
          submitting={submitting}
          onSubmit={onSubmit}
          onCancel={onClose}
        />
      </Dialog>
    </ThemeProvider>
  );
}

type CreateCreditCardModalProps = {
  open: boolean;
  onClose: () => void;
  submitting: boolean;
  onSubmit: (values: CreateCreditCardSubmitValues) => Promise<void>;
};

export default CreateCreditCardModal;
