import { Plan, Interval, Money } from '../../../entities/membership';

function compareInterval(
  a: Interval | null | undefined,
  b: Interval | null | undefined,
): number {
  if (!a || !b) {
    if (!a && b) {
      return -1;
    }

    if (a && !b) {
      return 1;
    }

    return 0;
  }

  if (a.unit === b.unit) {
    return a.amount - b.amount;
  }

  if (a.unit === 'm' && b.unit === 'y') {
    return -1;
  }

  if (a.unit === 'y' && b.unit === 'm') {
    return 1;
  }

  return 0;
}

export function sortPlans(plans: Plan[]): Plan[] {
  return plans.slice().sort((a, b) => {
    const c = compareInterval(a.price.interval, b.price.interval);

    if (c === 0) {
      return a.price.money.amount - b.price.money.amount;
    }

    return c;
  });
}

export function formatNumber(num?: number): string {
  if (!num) {
    return '0';
  }
  return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
}

export function formatMoneyAmount(value: string, decimalCount: number): string {
  if (!decimalCount) {
    return value;
  }

  const a = value.slice(0, value.length - decimalCount);
  const int = formatNumber(parseInt(a));
  const dec = value.slice(value.length - decimalCount);

  return int + '.' + dec;
}

export function formatMoney(money: Money) {
  const amount = money.amount;

  if (money.currency === 'TWD') {
    return `NT$ ${formatMoneyAmount(amount.toString(), 0)}`;
  }

  if (money.currency === 'USD') {
    return `USD$ ${formatMoneyAmount(amount.toString(), 2)}`;
  }

  return `$ ${amount}`;
}
