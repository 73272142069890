import { makeStyles, Theme, Typography } from '@material-ui/core';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import CenterLoading from '../../../components/CenterLoading';
import { Define } from '../../../define';
import {
  useCreateAudience,
  useGetAudience,
} from '../../../gql/hooks/membership/audience';
import { useGetMe } from '../../../gql/hooks/user';
import AppItem from './AppItem';

const useStyles = makeStyles((theme: Theme) => ({
  title: {
    marginBottom: theme.spacing(2),
  },
}));

function ConnectedApps(): JSX.Element {
  const classes = useStyles();
  const { loading, data } = useGetMe();
  const { loading: audienceLoading, data: audienceData } = useGetAudience();
  const createAudience = useCreateAudience();

  const [ready, setReady] = React.useState(false);

  React.useEffect(() => {
    const email = data?.studioUserFindOne.email;
    if (audienceData?.audience) {
      setReady(true);
    } else if (email) {
      createAudience(email).then(() => {
        setReady(true);
      });
    }
  }, [data, audienceData, createAudience]);

  if (loading || audienceLoading || !ready) {
    return <CenterLoading />;
  }

  return (
    <div>
      <Typography variant="h5" className={classes.title}>
        <FormattedMessage id="donation.app.connected" />
      </Typography>
      <AppItem
        titleId="donation.app.discord"
        descriptionId="donation.app.discord.description"
        icon={{
          variant: 'external',
          type: Define.Show.ExternalLink.discord,
        }}
        link="/donation/apps/discord"
      />
      <AppItem
        titleId="donation.app.spotify"
        descriptionId="donation.app.spotify.description"
        icon={{
          variant: 'distribution',
          type: Define.Distribution.Platform.spotify,
        }}
        link="/donation/apps/spotify"
      />
    </div>
  );
}

export default ConnectedApps;
