import React from 'react';
import { Redirect } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import { makeStyles } from '@material-ui/styles';
import { Typography, Theme, Container } from '@material-ui/core';
import ListenOnSpotify from '../../components/Spotify/ListenOnSpotify';
import useQueryString from '../../hooks/useQueryString';
import { useAuthorizedSpotify } from '../../gql/hooks/membership/authorized-spotify';
import CenterLoading from '../../components/CenterLoading';

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    padding: theme.spacing(4),
  },
  listenOnSpotify: {
    marginTop: theme.spacing(4),
  },
}));

function LinkWithSpotifyResult() {
  const classes = useStyles();
  const [completionUrl, setCompletionUrl] = React.useState<string | null>(null);
  const [error, setError] = React.useState<Error | null>(null);

  const qs = useQueryString();
  const code = qs.get('code');

  const authorizedSpotify = useAuthorizedSpotify();

  React.useEffect(() => {
    if (typeof code !== 'string') {
      return;
    }

    authorizedSpotify(code)
      .then(result => {
        const url = result.data?.audienceAuthorizedSpotify.completionUrl;

        if (url) {
          setCompletionUrl(url);
        }
      })
      .catch(error => {
        setError(error);
      });
  }, [code, authorizedSpotify]);

  if (typeof code !== 'string') {
    return <Redirect to="/link-with-spotify" />;
  }

  return (
    <Container className={classes.container}>
      <Typography variant="h5">
        <FormattedMessage
          id={
            error
              ? 'donation.linkWithSpotify.failed'
              : 'donation.linkWithSpotify.success'
          }
        />
      </Typography>
      {completionUrl ? (
        <ListenOnSpotify
          url={completionUrl}
          className={classes.listenOnSpotify}
        />
      ) : (
        <CenterLoading />
      )}
    </Container>
  );
}

export default LinkWithSpotifyResult;
