import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import { makeStyles } from '@material-ui/styles';
import {
  Avatar,
  Button,
  Container,
  Paper,
  Link,
  Typography,
  Theme,
} from '@material-ui/core';
import PoweredByFirstory from '../../../../components/PoweredByFirstory';
import CenterLoading from '../../../../components/CenterLoading';
import NotFound from '../../../../components/NotFound';
import Html from '../../../../components/Html';
import { useGetShowInfoLegacy } from '../../../../gql/hooks/show';
import { Subscription } from '../../../../entities/membership';

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    padding: theme.spacing(2),
    [theme.breakpoints.up('sm')]: {
      padding: theme.spacing(4),
    },
  },
  avatar: {
    margin: 'auto',
    width: theme.spacing(12),
    height: theme.spacing(12),
    marginBottom: theme.spacing(2),
    [theme.breakpoints.up('sm')]: {
      marginBottom: theme.spacing(4),
      width: theme.spacing(16),
      height: theme.spacing(16),
    },
  },
  success: {
    textAlign: 'center',
    fontWeight: 'bold',
    marginBottom: theme.spacing(2),
  },
  title: {
    textAlign: 'center',
  },
  description: {
    padding: theme.spacing(4, 0),
  },
  content: {
    padding: theme.spacing(2),
    [theme.breakpoints.up('sm')]: {
      padding: theme.spacing(4),
    },
    marginBottom: theme.spacing(2),
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: theme.spacing(2),
  },
  button: {
    width: 350,
  },
}));

const helpLink =
  'https://firstory.crisp.help/zh-tw/article/5luy6lk76kej6y6w6kic6zax5bci5bgs5ywn5a65-1ve8z94/';

function SubscriptionResultContent({
  subscription,
}: SubscriptionResultContentProps): JSX.Element {
  const classes = useStyles();

  const showId = subscription.show.showId;
  const { loading, data } = useGetShowInfoLegacy(showId);

  if (loading) {
    return <CenterLoading />;
  }

  if (!data?.userInfo) {
    return <NotFound />;
  }

  const show: {
    id: string;
    name: string;
    avatar: string;
  } = data.userInfo;

  return (
    <Container maxWidth="sm" className={classes.container}>
      <Avatar variant="rounded" src={show.avatar} className={classes.avatar} />
      <Paper variant="outlined" className={classes.content}>
        <Typography variant="h5" className={classes.success}>
          <FormattedMessage id="donation.subscribe.success" />
        </Typography>
        <FormattedMessage
          id="donation.subscribe.success.description"
          values={{
            p: (chunk: string) => (
              <Typography className={classes.title}>{chunk}</Typography>
            ),
            b: (chunk: React.ReactNode) => (
              <Typography variant="h6" className={classes.title}>
                {chunk}
              </Typography>
            ),
            show: show.name,
            tier: subscription.tier.title,
          }}
        />
        <Html
          className={classes.description}
          html={subscription.tier.hiddenContent || '-'}
        />
      </Paper>
      {subscription.show.hasResourceEntitlement ? (
        <Paper variant="outlined" className={classes.content}>
          <Typography color="textPrimary">
            <FormattedMessage
              id="donation.showHasPrivateContent"
              values={{
                link: (chunk: string) => (
                  <Link
                    href={helpLink}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {chunk}
                  </Link>
                ),
              }}
            />
          </Typography>
        </Paper>
      ) : null}
      {subscription.show.hasResourceEntitlement ? (
        <div className={classes.buttonContainer}>
          <Button
            className={classes.button}
            variant="contained"
            color="primary"
            size="large"
            component={RouterLink}
            to={`/donation/recurring/${subscription.id}/platforms`}
          >
            <FormattedMessage id="donation.listenExclusiveContent" />
          </Button>
        </div>
      ) : null}
      <div className={classes.buttonContainer}>
        <Button
          className={classes.button}
          variant="contained"
          size="large"
          component={RouterLink}
          to="/donation/recurring"
        >
          <FormattedMessage id="donation.manage" />
        </Button>
      </div>
      <PoweredByFirstory />
    </Container>
  );
}

type SubscriptionResultContentProps = {
  subscription: Subscription;
};

export default SubscriptionResultContent;
