import { gql } from '@apollo/client';
import { WebsiteSettingFragment } from './fragments';

export const GetPlaylistDetail = gql`
  query GetPlaylistDetail($playlistId: ID!) {
    playerPlaylistFindOne(playlistId: $playlistId) {
      id
      title
      description
      show {
        id
        name
        urlSlug
        avatar
        websiteSetting {
          ...WebsiteSettingFragment
        }
      }
    }
  }
  ${WebsiteSettingFragment}
`;

export const GetPlaylistEpisodes = gql`
  query GetPlaylistEpisodes(
    $playlistId: ID!
    $first: Int
    $skip: Int
    $isDraft: Boolean
  ) {
    playerEpisodeFind(
      playlistId: $playlistId
      take: $first
      skip: $skip
      isDraft: $isDraft
    ) {
      id
      title
      audioUrl
      imageUrl
      description
      duration
      publishedAt
      createdAt
    }
  }
`;

export const GetShowPlaylists = gql`
  query GetShowPlaylists($showId: ID!) {
    playerPlaylistFind(showId: $showId) {
      id
      title
    }
  }
`;

export const GetPlaylistDetailLegacy = gql`
  query GetPlaylistEpisodes(
    $playlistId: ID!
    $first: Int
    $skip: Int
    $isDraft: Boolean
  ) {
    playerPlaylistFindOne(playlistId: $playlistId) {
      id
      title
      description
      show {
        id
        name
        urlSlug
        avatar
        websiteSetting {
          ...WebsiteSettingFragment
        }
      }
    }
    playerEpisodeFind(
      playlistId: $playlistId
      take: $first
      skip: $skip
      isDraft: $isDraft
    ) {
      id
      title
      audioUrl
      imageUrl
      description
      duration
      publishedAt
      createdAt
      show {
        id
        avatar
      }
    }
  }
  ${WebsiteSettingFragment}
`;
