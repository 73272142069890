import { useQuery } from '@apollo/client';
import { Show } from '../../../entities/show';
import { GetShowInfo } from '../../v2/show';

export function useGetShowInfo(showIdOrUrlSlug: string) {
  const result = useQuery<GetShowInfoData, GetShowInfoVariables>(GetShowInfo, {
    variables: { showId: showIdOrUrlSlug },
  });

  return result;
}

type GetShowInfoData = {
  playerShowFindOneByUrlSlug: Show | null;
};

type GetShowInfoVariables = {
  showId: string;
};
