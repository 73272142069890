import React from 'react';
import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';
import { filterXss } from '../utils';
import Linkify from './Linkify';

type Child = JSX.Element | string | null;

function _deserialize(
  el: HTMLElement,
  level: number,
  index: number,
  options?: { ugc?: boolean; parentHasLink?: boolean },
): Child | Child[] {
  const key = `${level}-${index}`;

  // nodeType 3 is text node
  if (el.nodeType === 3) {
    if (options?.parentHasLink) {
      return el.textContent;
    } else {
      return (
        <Linkify ugc={options?.ugc} key={key}>
          {el.textContent}
        </Linkify>
      );
    }
  } else if (el.nodeType !== 1) {
    return null;
  }

  if (el.nodeName === 'A') {
    options = { ...options, parentHasLink: true };
  }
  let children = Array.from(el.childNodes).map((e, i) =>
    _deserialize(e as HTMLElement, level + 1, i, options),
  );

  if (Array.isArray(children) && children.length === 0) {
    children = [<br key={key} />];
  }

  switch (el.nodeName) {
    case 'BODY':
      return <div>{children}</div>;
    case 'BR':
      return <br key={key} />;
    case 'DIV':
      return <div key={key}>{children}</div>;
    case 'P':
      return (
        <Typography key={key} color="textPrimary">
          {children}
        </Typography>
      );
    case 'A':
      let rel = 'noreferrer noopener';
      if (options?.ugc) {
        rel += ' ugc';
      }

      return (
        <Link
          key={key}
          color="secondary"
          target="_blank"
          rel={rel}
          href={el.getAttribute('href') || '#'}
        >
          {children}
        </Link>
      );
    case 'STRONG':
      return <strong key={key}>{children}</strong>;
    case 'EM':
      return <em key={key}>{children}</em>;
    case 'OL':
      return <ol key={key}>{children}</ol>;
    case 'UL':
      return <ul key={key}>{children}</ul>;
    case 'LI':
      return (
        <Typography key={key} component="li" color="textPrimary">
          {children}
        </Typography>
      );
    case 'IMG':
      return (
        <img
          key={key}
          width={500}
          src={el.getAttribute('src') || ''}
          alt={el.getAttribute('alt') || ''}
        />
      );
    default:
      return el.textContent;
  }
}

function Html({ ugc, className, html }: HtmlProps): JSX.Element {
  if (global.DOMParser) {
    const filteredHtml = filterXss(html).replace(/\n/g, '');
    const document = new DOMParser().parseFromString(filteredHtml, 'text/html');

    return (
      <div className={className}>
        {_deserialize(document.body, 0, 0, { ugc })}
      </div>
    );
  }

  return (
    <Typography
      className={className}
      component="div"
      color="textPrimary"
      dangerouslySetInnerHTML={{ __html: filterXss(html, { ugc }) }}
    />
  );
}

type HtmlProps = {
  ugc?: boolean;
  className?: string;
  html: string;
};

export default Html;
