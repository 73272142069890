import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Button } from '@material-ui/core';
import { Define } from '../../../define';
import { AudienceDiscord } from '../../../entities/membership';
import config from '../../../config';
import InfoCard from '../InfoCard';
import PlatformIcon from '../../../components/icons/PlatformIcon';
import { useDisconnectDiscord } from '../../../gql/hooks/membership/discord';
import ButtonWithLoading from '../../../components/ButtonWithLoading';

function DiscordConnection({ discord }: DiscordConnectionProps): JSX.Element {
  const disconnectDiscord = useDisconnectDiscord();
  const [disconnecting, setDisconnecting] = React.useState(false);

  const handleConnect = React.useCallback(() => {
    const url = new URL('https://discord.com/oauth2/authorize');

    url.searchParams.set('client_id', config.discordClientId);
    url.searchParams.set('redirect_uri', `${config.url}/auth/discord/callback`);
    url.searchParams.set('scope', 'guilds.join identify guilds');
    url.searchParams.set('access_type', 'offline');
    url.searchParams.set('response_type', 'code');

    const authUrl = url.href;

    window.open(authUrl, '_blank');
  }, []);

  const handleDisconnect = React.useCallback(async () => {
    setDisconnecting(true);

    try {
      await disconnectDiscord();
    } catch (error) {
      //
    } finally {
      setDisconnecting(false);
    }
  }, [disconnectDiscord]);

  return (
    <InfoCard
      icon={
        <PlatformIcon
          size={56}
          type={{
            variant: 'external',
            type: Define.Show.ExternalLink.discord,
          }}
        />
      }
      title={<FormattedMessage id="donation.app.discord" />}
      description={
        discord ? (
          <FormattedMessage
            id="donation.app.discord.connectedAccount"
            values={{
              b: (chunk: string) => <b>{chunk}</b>,
              name: `${discord.username}#${discord.discriminator}`,
            }}
          />
        ) : (
          <FormattedMessage id="donation.app.discord.detailDescription" />
        )
      }
      action={
        discord ? (
          <ButtonWithLoading
            loading={disconnecting}
            variant="contained"
            color="primary"
            onClick={handleDisconnect}
          >
            <FormattedMessage id="donation.app.disconnect" />
          </ButtonWithLoading>
        ) : (
          <Button variant="contained" color="primary" onClick={handleConnect}>
            <FormattedMessage id="donation.app.connect" />
          </Button>
        )
      }
    />
  );
}

type DiscordConnectionProps = {
  discord?: AudienceDiscord | null;
};

export default DiscordConnection;
