import { gql } from '@apollo/client';

export const WebsiteSettingFragment = gql`
  fragment WebsiteSettingFragment on WebsiteSetting {
    showId
    active
    gaTrackingId
    fbPixelId
    themeHexFirst
    themeHexSecond
    themeHexThird
    flinkShowDonate
    flinkShowExternal
    flinkShowRssFeed
    flinkShowPlatforms
    flinkShowComment
    flinkShowVoiceMail
    flinkShowDownloadAudioFile
    playerShowDonate
    playerShowRssFeed
    playerHideLogo
    playerShowShownote
    playerShowComment
    playerShowVoicemail
    playerShowDownloadAudioFile
  }
`;

export const ShowInfoFragment = gql`
  fragment ShowInfoFragment on Show {
    id
    name
    avatar
    intro
    isCreator
    author
    urlSlug
    language
    explicit
    categories {
      id
      nameEn
      nameZh
    }
    import {
      status
      originRssUrl
    }
    distributions {
      status
      platformType
      platformId
      platformUrl
    }
    externalLinks {
      title
      type
      url
    }
    websiteSetting {
      ...WebsiteSettingFragment
    }
  }
  ${WebsiteSettingFragment}
`;

export const DonationSettingFragment = gql`
  fragment DonationSettingFragment on DonationSetting {
    id
    active
    title
    rankTitle
    showDonationRank
    showDonationRankInDonationPage
    oneTimeInfo {
      title
      descriptionHtml
    }
    recurringInfo {
      title
      descriptionHtml
    }
    allowOneTime
    allowRecurring
    tiers {
      id
      type
      title
      amount
      memberCount
      descriptionHtml
      successDescriptionHtml
    }
  }
`;

export const EpisodeFragment = gql`
  fragment EpisodeFragment on Episode {
    id
    title
    show {
      id
      name
      avatar
      isCreator
      urlSlug
      explicit
    }
    audioUrl
    imageUrl
    publishedAt
    duration
    description
    explicit
    seasonNumber
    episodeNumber
  }
`;

export const CommentFragment = gql`
  fragment CommentContentFragment on Comment {
    id
    type
    name
    createdAt
    author {
      id
      name
      avatar
    }
    show {
      id
      name
      avatar
    }
    episode {
      id
      title
      imageUrl
    }
    textComment {
      message
    }
    audioComment {
      url
      duration
    }
  }
  fragment CommentFragment on Comment {
    ...CommentContentFragment
    comments {
      ...CommentContentFragment
    }
  }
`;

export const CategoryFragment = gql`
  fragment CategoryFragment on Category {
    id
    nameEn
    nameZh
    parentCategory {
      id
      nameEn
      nameZh
    }
  }
`;

export const TierFragment = gql`
  fragment TierFragment on Tier {
    id
    status
    title
    description
    plans {
      id
      price {
        money {
          amount
          currency
        }
        interval {
          amount
          unit
        }
      }
    }
  }
`;

export const ShowMembershipFragment = gql`
  fragment ShowMembershipFragment on ShowMembershipSetting {
    id
    description
    active
    donationEnabled
    subscriptionEnabled
    donationTiers {
      ...TierFragment
    }
    subscriptionTiers {
      ...TierFragment
    }
    currentSubscription {
      id
      status
      tier {
        id
        title
      }
      plan {
        price {
          money {
            amount
            currency
          }
          interval {
            amount
            unit
          }
        }
      }
    }
    paymentProviders {
      provider
      status
    }
    trialInterval {
      amount
      unit
    }
    customerStatus {
      hasTrialed
    }
  }
  ${TierFragment}
`;

export const SubscriptionFragment = gql`
  fragment SubscriptionFragment on MemberSubscription {
    id
    status
    createdAt
    nextPaymentDate
    show {
      id
      showId
    }
    tier {
      id
      title
      description
      hiddenContent
    }
    plan {
      price {
        money {
          amount
          currency
        }
        interval {
          amount
          unit
        }
      }
    }
  }
`;

export const DonationFragment = gql`
  fragment DonationFragment on Donation {
    id
    status
    createdAt
    show {
      id
      showId
    }
    tier {
      id
      title
    }
    plan {
      price {
        money {
          amount
          currency
        }
      }
    }
  }
`;

export const EntitlementFragment = gql`
  fragment EntitlementFragment on Entitlement {
    tier {
      id
      title
    }
  }
`;
