import React from 'react';
import { useMutation, useQuery } from '@apollo/client';
import {
  CreateAudience,
  GetAudienceSpotify,
  GetAudienceDiscord,
  GetAudience,
} from '../../v2/membership';
import { Audience } from '../../../entities/membership';

export function useGetAudience() {
  const result = useQuery<GetAudienceData>(GetAudience, {
    context: { endpoint: 'membership' },
  });

  return result;
}

type GetAudienceData = {
  audience?: Pick<Audience, 'id'> | null;
};

export function useCreateAudience() {
  const [fn] = useMutation<
    CreateAudienceData,
    CreateAudienceVariables,
    ApolloContext
  >(CreateAudience);

  const createAudience = React.useCallback(
    (email: string) => {
      return fn({
        variables: { email },
        context: { endpoint: 'membership' },
        refetchQueries: ['GetAudience'],
      });
    },
    [fn],
  );

  return createAudience;
}

type CreateAudienceData = {
  id: string;
};

type CreateAudienceVariables = {
  email: string;
};

export function useGetAudienceSpotify() {
  const result = useQuery<GetAudienceSpotifyData>(GetAudienceSpotify, {
    context: { endpoint: 'membership' },
  });

  return result;
}

type GetAudienceSpotifyData = {
  audience?: Pick<Audience, 'id' | 'hasLinkSpotify' | 'spotify'> | null;
};

export function useGetAudienceDiscord() {
  return useQuery<GetAudienceDiscordData>(GetAudienceDiscord, {
    context: { endpoint: 'membership' },
  });
}

type GetAudienceDiscordData = {
  audience?: Pick<Audience, 'id' | 'discord'> | null;
};
