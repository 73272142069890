import React from 'react';
import { FormattedMessage } from 'react-intl';
import { makeStyles } from '@material-ui/styles';
import { Typography, Theme } from '@material-ui/core';
import { formatMoney } from '../Join/util';
import { Money } from '../../../entities/membership';
import { PaymentTierPlanValues } from './type';

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    marginBottom: theme.spacing(2),
    textAlign: 'center',
  },
}));

function TierTitle({ paymentTier }: PriceInfoProps): JSX.Element {
  const { tierPlan, customPrice } = paymentTier;

  if (tierPlan) {
    return <React.Fragment>{tierPlan.tier.title}</React.Fragment>;
  }
  if (customPrice) {
    return <FormattedMessage id="donation.customValue" />;
  }

  return <React.Fragment />;
}

function PriceText({ paymentTier }: PriceInfoProps): JSX.Element | null {
  let money: Money;

  if (paymentTier.tierPlan) {
    money = { ...paymentTier.tierPlan.plan.price.money };
  } else if (paymentTier.customPrice) {
    money = { ...paymentTier.customPrice };
  } else {
    return null;
  }

  if (paymentTier.isBindCard) {
    money.amount = 0;
  }

  return <>{formatMoney(money)}</>;
}

function PriceInfo({ paymentTier }: PriceInfoProps): JSX.Element {
  const classes = useStyles();
  const { tierPlan } = paymentTier;
  const isFree = paymentTier.tierPlan?.plan.price.money.amount === 0;

  return (
    <div className={classes.container}>
      <Typography variant="caption">
        <TierTitle paymentTier={paymentTier} />
      </Typography>
      <br />
      {isFree ? null : (
        <Typography variant="caption">
          <FormattedMessage
            id={
              tierPlan?.plan.price.interval
                ? 'donation.recurring'
                : 'donation.oneTime'
            }
          />{' '}
          <PriceText paymentTier={paymentTier} />
        </Typography>
      )}
    </div>
  );
}

type PriceInfoProps = {
  paymentTier: PaymentTierPlanValues;
};

export default PriceInfo;
