import React from 'react';
import { IconButton } from '@material-ui/core';
import { Lock } from '@material-ui/icons';
import PlayPauseIcon from '../../components/icons/PlayPauseIcon';
import { Episode } from '../../entities/episode';
import useDisclosure from '../../hooks/useDisclosure';
import SubscribeTierToUnlock from '../../components/SubscribeTierToUnlock';
import { Me } from '../../entities/me';

function ListItemPlayButton({
  me,
  episode,
  isPlaying,
  iconColor,
  onPlayPauseClick,
}: ListItemPlayButtonProps): JSX.Element {
  const [anchorEl, setAnchorEl] = React.useState<HTMLDivElement | null>(null);
  const popover = useDisclosure();

  if (!episode.audioUrl && episode.entitlements?.length) {
    return (
      <div ref={setAnchorEl}>
        <IconButton onClick={popover.open}>
          <Lock />
        </IconButton>
        <SubscribeTierToUnlock
          me={me}
          anchorEl={anchorEl}
          episode={episode}
          open={popover.isOpen}
          onClose={popover.close}
        />
      </div>
    );
  }

  return (
    <PlayPauseIcon
      isPlaying={isPlaying}
      button
      color={iconColor}
      size={25}
      onClick={onPlayPauseClick}
    />
  );
}

type ListItemPlayButtonProps = {
  me: Me | null;
  episode: Episode;
  isPlaying: boolean;
  iconColor: string;
  onPlayPauseClick(): void;
};

export default ListItemPlayButton;
