import { Avatar, Button } from '@material-ui/core';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import InfoCard from '../InfoCard';

function SpotifyShowItem({ show }: SpotifyShowItemProps): JSX.Element {
  return (
    <InfoCard
      title={show.name}
      description={''}
      icon={<Avatar src={show.avatar || '#'} alt={show.name} />}
      action={
        <Button
          variant="contained"
          component="a"
          href={`https://open.spotify.com/show/${show.spotifyPlatformId}`}
          target="_blank"
          rel="noopener noreferrer"
        >
          <FormattedMessage id="donation.app.spotify.openSpotify" />
        </Button>
      }
    />
  );
}

type SpotifyShowItemProps = {
  show: {
    id: string;
    name: string;
    avatar: string | null;
    urlSlug: string | null;
    spotifyPlatformId: string;
  };
};

export default SpotifyShowItem;
