import React from 'react';
import { useParams } from 'react-router';
import CenterLoading from '../../components/CenterLoading';
import NotFound from '../../components/NotFound';
import { Define } from '../../define';
import { Distribution } from '../../entities/distribution';
import { useViewFlink } from '../../gql/hooks/flink';
import { useGetShowMembership } from '../../gql/hooks/membership/get-show-setting';
import { useGetShowInfo } from '../../gql/hooks/show/show';
import FlinkHeader from '../UserSite/header/FlinkHeader';
import Flink from './Flink';

function ShowFlink(): JSX.Element {
  const { showId } = useParams<{ showId: string }>();
  const { loading, data } = useGetShowInfo(showId);
  const { loading: membershipLoading, data: membershipData } =
    useGetShowMembership(data?.playerShowFindOneByUrlSlug?.id);

  const show = data?.playerShowFindOneByUrlSlug;
  // useViewFlink({ showId: show?.id, skip: !show });

  const distributionMap = React.useMemo(() => {
    const map = new Map<Define.Distribution.Platform.Type, Distribution>();
    data?.playerShowFindOneByUrlSlug?.distributions?.forEach(d => {
      map.set(d.platformType, d);
    });
    return map;
  }, [data]);

  if (loading || membershipLoading) {
    return <CenterLoading />;
  }

  if (!show) {
    return <NotFound />;
  }

  return (
    <React.Fragment>
      <FlinkHeader
        type="show"
        title={show.name}
        description={show.intro || ''}
        imageUrl={show.avatar || ''}
        language={show.language}
        urlSlugOrId={show.urlSlug || show.id}
        distributions={show.distributions || []}
      />
      <Flink
        type="show"
        hideAds={!!show.canRemoveFlinkAds}
        showId={show.id}
        imageUrl={show.avatar}
        title={show.name}
        titleLink=""
        subtitle={show.author || ''}
        subtitleLink=""
        distributionMap={distributionMap}
        externalLinks={show.externalLinks || []}
        websiteSetting={show.websiteSetting}
        showMembership={membershipData?.show}
      />
    </React.Fragment>
  );
}

export default ShowFlink;
