import React from 'react';
import { useParams } from 'react-router';
import CenterLoading from '../../components/CenterLoading';
import NotFound from '../../components/NotFound';
import { Define } from '../../define';
import { Distribution } from '../../entities/distribution';
import { useGetEpisodeDetail } from '../../gql/hooks/episode/episode';
import { useViewFlink } from '../../gql/hooks/flink';
import { useGetShowMembership } from '../../gql/hooks/membership/get-show-setting';
import FlinkHeader from '../UserSite/header/FlinkHeader';
import Flink from './Flink';

function EpisodeFlink(): JSX.Element {
  const { episodeId } = useParams<{ episodeId: string }>();
  const { loading, data } = useGetEpisodeDetail(episodeId);
  const { loading: membershipLoading, data: membershipData } =
    useGetShowMembership(data?.playerEpisodeFindOne?.show.id);

  const episode = data?.playerEpisodeFindOne;
  // useViewFlink({ episodeId, skip: !episode?.canViewFlink });

  const distributionMap = React.useMemo(() => {
    const map = new Map<Define.Distribution.Platform.Type, Distribution>();
    data?.playerEpisodeFindOne?.distributions?.forEach(d => {
      map.set(d.platformType, d);
    });
    return map;
  }, [data]);

  const showDistributionMap = React.useMemo(() => {
    const map = new Map<Define.Distribution.Platform.Type, Distribution>();
    data?.playerEpisodeFindOne?.show.distributions?.forEach(d => {
      map.set(d.platformType, d);
    });
    return map;
  }, [data]);

  if (loading || membershipLoading) {
    return <CenterLoading />;
  }

  if (!episode || !episode.canViewFlink) {
    return <NotFound />;
  }

  const show = episode.show;

  return (
    <React.Fragment>
      <FlinkHeader
        type="episode"
        title={episode.title || ''}
        description={episode.description || ''}
        imageUrl={episode.imageUrl || show.avatar || ''}
        language={show.language}
        urlSlugOrId={episode.id}
        distributions={episode.distributions || []}
      />
      <Flink
        type="episode"
        hideAds={!!show.canRemoveFlinkAds}
        showId={show.id}
        episodeId={episode.id}
        imageUrl={episode.imageUrl || show.avatar}
        title={episode.title || ''}
        titleLink=""
        subtitle={show.name || ''}
        subtitleLink=""
        distributionMap={distributionMap}
        showDistributionMap={showDistributionMap}
        externalLinks={show.externalLinks || []}
        websiteSetting={show.websiteSetting}
        showMembership={membershipData?.show}
      />
    </React.Fragment>
  );
}

export default EpisodeFlink;
