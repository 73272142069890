import React from 'react';
import { makeStyles } from '@material-ui/styles';
import Typography, { TypographyProps } from '@material-ui/core/Typography';
import Linkify from '../Linkify';

const useStyles = makeStyles({
  message: { maxWidth: 500 },
});

function TextContent({
  variant = 'body2',
  message,
}: TextContentProps): JSX.Element {
  const classes = useStyles();

  return (
    <Typography
      variant={variant}
      className={classes.message}
      color="textPrimary"
    >
      {message.split('\n').map((text, i) => (
        <React.Fragment key={i}>
          {i === 0 ? null : <br />}
          <Linkify ugc>{text}</Linkify>
        </React.Fragment>
      ))}
    </Typography>
  );
}

type TextContentProps = {
  variant?: TypographyProps['variant'];
  message: string;
};

export default TextContent;
