import React from 'react';
import { Link, Redirect } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import { makeStyles } from '@material-ui/styles';
import { Typography, Theme, Container, Button } from '@material-ui/core';
import useQueryString from '../../hooks/useQueryString';
import { useAuthorizedDiscord } from '../../gql/hooks/membership/discord';
import Layout from '../WebPlayer/Layout';
import CenterLoading from '../../components/CenterLoading';

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    paddingTop: theme.spacing(4),
  },
}));

function DiscordAuthorizeResult() {
  const classes = useStyles();
  const [ready, setReady] = React.useState<boolean>(false);
  const [error, setError] = React.useState<Error | null>(null);

  const qs = useQueryString();
  const code = qs.get('code');

  const authorizedDiscord = useAuthorizedDiscord();

  React.useEffect(() => {
    if (typeof code !== 'string') {
      return;
    }

    authorizedDiscord(code)
      .catch(error => {
        setError(error);
      })
      .finally(() => {
        setReady(true);
      });
  }, [code, authorizedDiscord]);

  const backPath = '/donation/apps/discord';

  if (typeof code !== 'string') {
    return <Redirect to={backPath} />;
  }

  if (!ready) {
    return (
      <Layout hideController>
        <CenterLoading />
      </Layout>
    );
  }

  if (error) {
    return (
      <Layout hideController>
        <Container maxWidth="sm" className={classes.container}>
          <Typography variant="h4" gutterBottom>
            <FormattedMessage id="donation.app.discord.connectFailed" />
          </Typography>
          <Button variant="contained" component={Link} to={backPath}>
            <FormattedMessage id="general.back" />
          </Button>
        </Container>
      </Layout>
    );
  }

  return <Redirect to={backPath} />;
}

export default DiscordAuthorizeResult;
