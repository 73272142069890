import React from 'react';
import { Link } from 'react-router-dom';
import { makeStyles } from '@material-ui/styles';
import { Link as MuiLink, Theme } from '@material-ui/core';
import { useWebPlayer } from '../pages/WebPlayer/context';

const useStyles = makeStyles((theme: Theme) => ({
  tag: {
    paddingRight: theme.spacing(1),
  },
}));

function TagRow({ tags }: TagRowProps): JSX.Element | null {
  const classes = useStyles();
  const { isWebPlayer } = useWebPlayer();

  if (!tags) {
    return null;
  }

  return (
    <React.Fragment>
      {tags.map(t => (
        <MuiLink
          key={t}
          variant="caption"
          color="secondary"
          component={Link}
          className={classes.tag}
          to={`${isWebPlayer ? '/search/' : '/'}tags/${encodeURIComponent(t)}`}
        >
          #{t}
        </MuiLink>
      ))}
    </React.Fragment>
  );
}

type TagRowProps = {
  tags: string[];
};

export default TagRow;
