import React from 'react';
import { useIntl } from 'react-intl';
import { makeStyles } from '@material-ui/styles';
import { Tab, Tabs, Theme, Container } from '@material-ui/core';
import PoweredByFirstory from '../../../components/PoweredByFirstory';
import LanguagePicker from '../../../components/LanguagePicker';
import { Plan, ShowMembership, Tier } from '../../../entities/membership';
import { GetMeLegacyData } from '../../../gql/hooks/user';
import { Define } from '../../../define';
import { Show } from '../../../entities/show';
import SubscriptionContent from './SubscriptionContent';
import DonationContent from './DonationContent';
import Faq from './Faq';
import ShowInfo from './ShowInfo';

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    width: '100%',
    padding: theme.spacing(4),
  },
  content: {
    marginTop: theme.spacing(4),
  },
  tabItem: {
    minWidth: 'auto',
  },
  languagePickerContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
}));

function useInitTabIndex() {
  return 0;
}

function TabContent({
  tabIndex,
  membershipSetting,
  hasStripe,
  me,
  onTierPlanSelect,
  onCustomSelect,
}: TabContentProps): JSX.Element | null {
  if (tabIndex === 0) {
    return (
      <SubscriptionContent
        trialInterval={membershipSetting?.trialInterval}
        customerStatus={membershipSetting?.customerStatus}
        currentSubscription={membershipSetting?.currentSubscription}
        enabled={!!membershipSetting?.subscriptionEnabled}
        tiers={membershipSetting?.subscriptionTiers || []}
        me={me}
        onSelect={onTierPlanSelect}
      />
    );
  }

  if (tabIndex === 1) {
    return (
      <DonationContent
        showCustom={!hasStripe}
        enabled={!!membershipSetting?.donationEnabled}
        tiers={membershipSetting?.donationTiers || []}
        me={me}
        onSelect={onTierPlanSelect}
        onCustomSelect={onCustomSelect}
      />
    );
  }

  return null;
}

function JoinMemberContent({
  show,
  me,
  membershipSetting,
  onTierPlanSelect,
  onCustomSelect,
}: JoinMemberContentProps): JSX.Element {
  const intl = useIntl();
  const classes = useStyles();

  const initTabIndex = useInitTabIndex();
  const [tabIndex, setTabIndex] = React.useState(initTabIndex);

  const handleTabChange = React.useCallback((_, index) => {
    setTabIndex(index);
  }, []);

  const hasStripe = !!membershipSetting?.paymentProviders.some(
    p => p.provider === Define.Payment.Provider.stripe,
  );

  return (
    <Container maxWidth="md" className={classes.container}>
      <ShowInfo show={show} membershipSetting={membershipSetting} />
      <Tabs
        variant="fullWidth"
        textColor="secondary"
        value={tabIndex}
        onChange={handleTabChange}
      >
        <Tab label={intl.formatMessage({ id: 'donation.recurring' })} />
        <Tab label={intl.formatMessage({ id: 'donation.oneTime' })} />
      </Tabs>
      <div className={classes.content}>
        <TabContent
          tabIndex={tabIndex}
          membershipSetting={membershipSetting}
          hasStripe={hasStripe}
          me={me}
          onTierPlanSelect={onTierPlanSelect}
          onCustomSelect={onCustomSelect}
        />
      </div>
      <Faq />
      <div className={classes.languagePickerContainer}>
        <LanguagePicker />
      </div>
      <PoweredByFirstory />
    </Container>
  );
}

type JoinMemberContentProps = {
  show: Pick<Show, 'id' | 'name' | 'author' | 'urlSlug'>;
  me: GetMeLegacyData | null;
  membershipSetting?: ShowMembership;
  onTierPlanSelect(tier: Tier, plan: Plan): void;
  onCustomSelect(amount: number): void;
};

type TabContentProps = {
  tabIndex: number;
  membershipSetting?: ShowMembership;
  hasStripe: boolean;
  me: GetMeLegacyData | null;
  onTierPlanSelect(tier: Tier, plan: Plan): void;
  onCustomSelect(amount: number): void;
};

export default JoinMemberContent;
